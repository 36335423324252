import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, Provider } from '@angular/core';
import { UiToastMessageService } from './ui-toast-message.service';
import {
	UI_TOAST_MESSAGE_DEFAULT_DANGER_EXPIRATION,
	UI_TOAST_MESSAGE_DEFAULT_EXPIRATION,
	UI_TOAST_MESSAGE_DEFAULT_MAX_VISIBLE,
	UI_TOAST_MESSAGE_DEFAULT_OPTIONS,
	UI_TOAST_MESSAGE_DEFAULT_POSITION,
	UI_TOAST_MESSAGE_DEFAULT_POSITION_OFFSET,
	UI_TOAST_MESSAGE_DEFAULT_TYPE,
	UiToastMessageServiceDefaultOptions,
} from './ui-toast-message';

export const provideToastMessages = (defaultOptions?: Partial<UiToastMessageServiceDefaultOptions>): Provider[] => [
	UiToastMessageService,
	{
		provide: UI_TOAST_MESSAGE_DEFAULT_OPTIONS,
		useValue: {
			maxToasts: defaultOptions?.maxToasts || UI_TOAST_MESSAGE_DEFAULT_MAX_VISIBLE,
			expiration: defaultOptions?.expiration || UI_TOAST_MESSAGE_DEFAULT_EXPIRATION,
			errorMessageExpiration:
				defaultOptions?.errorMessageExpiration || UI_TOAST_MESSAGE_DEFAULT_DANGER_EXPIRATION,
			position: defaultOptions?.position || UI_TOAST_MESSAGE_DEFAULT_POSITION,
			positionOffset: defaultOptions?.positionOffset || UI_TOAST_MESSAGE_DEFAULT_POSITION_OFFSET,
			defaultType: defaultOptions?.defaultType || UI_TOAST_MESSAGE_DEFAULT_TYPE,
		} as UiToastMessageServiceDefaultOptions,
	},
];

@NgModule({
	imports: [OverlayModule],
	providers: [...provideToastMessages()],
})
export class UiToastMessageModule {}

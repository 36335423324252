import { Injectable, Injector, inject } from '@angular/core';

import { CollectionFacadeAbstract } from '@imt-web-zone/core/util-state-facade';
import {
	DetailOrganizationPayload,
	ListOrganizationsPayload,
	ORGANIZATIONS_STATE_TOKEN,
	OrganizationsService,
	detailOrganizationRequest,
	listOrganizationsRequest,
	disableOrganizationSsoRequest,
	DisableOrganizationSsoPayload,
	deleteOrganizationRequest,
	DeleteOrganizationPayload,
	setActiveOrganization,
	addOrganizationWithRedirect,
	OrganizationModel,
	enableDisableOrganizationFeatureRequest,
	EnableDisableOrganizationFeaturePayload,
} from '@imt-web-zone/shared/data-access';
import { ActionMetadata, CollectionState } from '@imt-web-zone/shared/util-store';
import { SessionFacade } from '@imt-web-zone/zone/state-session';
import { OrganizationSelectors } from '@imt-web-zone/zone/util-store';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationsFacade extends CollectionFacadeAbstract<
	typeof ORGANIZATIONS_STATE_TOKEN,
	OrganizationsService
> {
	private sessionFacade = inject(SessionFacade);

	public zoneOrganizations$ = this.store.select$(OrganizationSelectors.zoneOrganizations);

	public zoneOrganizationsRxSnapshot = this.rxSnapshotFn(OrganizationSelectors.zoneOrganizations);
	public zoneOrganizationsSignal = this.store.selectSignal(OrganizationSelectors.zoneOrganizations);

	public static organizationIdExist = (injector: Injector) =>
		injector.get(Store).select(CollectionState.getActiveId(ORGANIZATIONS_STATE_TOKEN));

	protected service = inject(OrganizationsService);

	constructor() {
		super(ORGANIZATIONS_STATE_TOKEN);
	}

	public loadOrganizationsList$(payload: ListOrganizationsPayload, meta: ActionMetadata) {
		return this.store.dispatch$(listOrganizationsRequest(payload, meta));
	}

	public loadOrganizationDetail$(payload: DetailOrganizationPayload, meta: ActionMetadata) {
		return this.store.dispatch$(detailOrganizationRequest(payload, meta));
	}

	public deleteOrganizationRequest$(payload: DeleteOrganizationPayload, meta?: ActionMetadata) {
		return this.store.dispatch$(deleteOrganizationRequest(payload, meta));
	}

	public disableOrganizationSsoRequest$(payload: DisableOrganizationSsoPayload) {
		return this.store.dispatch$(disableOrganizationSsoRequest(payload));
	}

	public setActiveOrganization$(organizationId: string) {
		return this.store
			.dispatch$(setActiveOrganization({ payload: { id: organizationId } }))
			.pipe(
				switchMap(() =>
					this.sessionFacade.setActiveOrganizationTeam$({ isOrganization: true, id: organizationId }),
				),
			);
	}

	public addOrganizationWithRedirect$(organization: OrganizationModel, redirect: boolean) {
		return this.store.dispatch$(addOrganizationWithRedirect({ payload: organization, redirect }));
	}

	public enableDisableOrganizationFeatureRequest$(payload: EnableDisableOrganizationFeaturePayload) {
		return this.store.dispatch$(enableDisableOrganizationFeatureRequest(payload));
	}

	public getById$(organizationId: string) {
		return this.store.select$(CollectionState.getById(ORGANIZATIONS_STATE_TOKEN, organizationId));
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
	standalone: true,
	name: 'resolveAdminUrl',
})
export class ResolveAdminUrlPipe implements PipeTransform {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private router: Router) {}

	public transform(url: string | Array<string>): any {
		let parts = [];
		const adminPath = 'admin';
		const isArray = url instanceof Array;
		let isAbsolute: boolean;
		if (this.router && this.router.url.startsWith('/admin')) {
			if (typeof url === 'string') {
				isAbsolute = url.startsWith('/');
				url = url.split('/');
			}
			parts = parts.concat(url);
			let index = parts.reduce((res, path, i) => {
				// convert to string is needed, because the value doesn't really have to be string
				if (path.toString().includes('.')) {
					return i;
				}
				return res;
			}, -1);
			if (index === -1) {
				parts.unshift(adminPath);
			} else {
				index++;
				parts.splice(index, 0, adminPath);
			}

			url = isArray
				? parts
				: (isAbsolute ? ['/'] : []).concat(parts).join('/').replace(/\/\//g, '/').replace(/^\.\//g, '');
		}
		return url;
	}
}

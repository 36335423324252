import { Injectable } from '@angular/core';
import { UrlSerializer, DefaultUrlSerializer, UrlTree } from '@angular/router';
import * as qs from 'qs';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
	private readonly defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

	public parse(url: string) {
		const tree = this.defaultSerializer.parse(url);
		if (url.split('?')[1]) {
			tree.queryParams = qs.parse(url.split('?')[1]);
		}
		return tree;
	}

	public serialize(tree: UrlTree) {
		let url = this.defaultSerializer.serialize(tree);

		if (Object.keys(tree.queryParams).length) {
			const query = qs.stringify(tree.queryParams);
			url = url.split('?')[0] + `?${query}`;
		}

		return url;
	}
}

import { ActionType, createAction, props } from '@imt-web-zone/shared/util-store';
import { ImtInspectorModel } from './imt-inspector.model';

export const patchImtInspectorData = createAction(
	'[ImtInspector] update data',
	props<{ payload: Partial<ImtInspectorModel> }>(),
);
export type PatchImtInspectorData = ActionType<typeof patchImtInspectorData>;

export const clearImtInspectorData = createAction('[ImtInspector] clear data');

export enum CodeownersTeamsEnum {
	ATLAS = 'atlas',
	NEXUS = 'nexus',
	APPS_PLATFORM = 'apps-platform',
	GROWTH_ENG = 'growth-engineering',
	MEDUSA = 'medusa',
	POSEIDON = 'poseidon',
	TYPHON = 'typhon',
	UI_PLATFORM = 'ui-platform',
}

export interface CodeownersTeamInterface {
	name: string;
	ghName: GithubTeamsNamesEnum;
	contributors?: Array<string>;
}

type CodeownersTeamsInterface = Record<CodeownersTeamsEnum, CodeownersTeamInterface>;

/**
 * Names of the teams in Github dedicated for FE devs
 */
export enum GithubTeamsNamesEnum {
	ATLAS = '@integromat/atlas-frontend',
	NEXUS = '@integromat/nexus-frontend',
	APPS_PLATFORM = '@integromat/apps-platform',
	GROWTH_ENG = '@integromat/growth-engineering-frontend',
	MEDUSA = '@integromat/medusa-frontend',
	POSEIDON = '@integromat/poseidon-frontend',
	TYPHON = '@integromat/typhon-frontend',
	UI_PLATFORM = '@integromat/ui-platform',
}

export const CodeownersTeams: CodeownersTeamsInterface = {
	atlas: {
		name: 'Atlas',
		ghName: GithubTeamsNamesEnum.ATLAS,
		contributors: [],
	},

	nexus: {
		name: 'Nexus',
		ghName: GithubTeamsNamesEnum.NEXUS,
		contributors: [],
	},

	'apps-platform': {
		name: 'Apps Platform',
		ghName: GithubTeamsNamesEnum.APPS_PLATFORM,
		contributors: [],
	},

	'growth-engineering': {
		name: 'Growth Engineering',
		ghName: GithubTeamsNamesEnum.GROWTH_ENG,
		contributors: [],
	},

	medusa: {
		name: 'Medusa',
		ghName: GithubTeamsNamesEnum.MEDUSA,
		contributors: [],
	},

	poseidon: {
		name: 'Poseidon',
		ghName: GithubTeamsNamesEnum.POSEIDON,
		contributors: [],
	},

	typhon: {
		name: 'Typhon',
		ghName: GithubTeamsNamesEnum.TYPHON,
		contributors: [],
	},

	'ui-platform': {
		name: 'UI platform',
		ghName: GithubTeamsNamesEnum.UI_PLATFORM,
		contributors: [],
	},
};

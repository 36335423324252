import { StateContext } from '@ngxs/store';
import { AsyncLoadActionMeta, LoadActionParams } from './async-action.interface';
import { CacheFunctions } from '../cache/cache.functions';
import { GuardHandlers } from '../cache/entity.guard.class';

interface GuardMetaData {
	url: string;
	handler: GuardHandlers;
	isCollection: boolean;
	cacheKey: string;
	/**
	 * if true guard will wait for finishing request
	 *
	 * default: false
	 */
	resolveImmediately?: boolean;
}

export class AsyncActionHooks {
	public deleteExpiredItemsFromCache?: (
		ctx: StateContext<any>,
		action: AsyncAction<LoadActionParams, unknown>,
		responseData: { [key: string]: any } | Array<{ [key: string]: any }>,
	) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class AsyncAction<P extends LoadActionParams, R, M extends Record<string, any> = {}> extends AsyncActionHooks {
	/**
	 * response data from original request;
	 */
	public response?: R;
	public error?: Error;
	/**
	 * true for the actions that starts request
	 */
	public request = false;
	public hideFromDevtools = false;
	constructor(public params: P = {} as P, public metadata: M) {
		super();
	}
}

export class AsyncLoadAction<
	T extends LoadActionParams,
	R,
	M extends AsyncLoadActionMeta = AsyncLoadActionMeta,
> extends AsyncAction<T, R, M> {
	public guardMeta: GuardMetaData = {} as GuardMetaData;
	public cacheKey: string;

	constructor(type: string, public override params: T = {} as T, public override metadata: M = {} as M) {
		super(params, metadata);
		this.cacheKey = CacheFunctions.serializeParams(this, type);
	}
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { UiEmbedContentComponent, UiEmbedContentType } from '@imt-web-zone/make-design-system/util';
import {
	UiNotificationButtonComponent,
	UiNotificationButtonType,
} from '@imt-web-zone/make-design-system/ui-notification-button';
import { UiToastMessageButtonPosition, UiToastMessageType } from '../ui-toast-message.interface';

function transformType(type: string) {
	const allowedTypes: string[] = Object.values(UiToastMessageType);
	if (type && !allowedTypes.includes(type)) {
		throw `Unknown alert type "${type}".`;
	}
	return type;
}

function transformPosition(position: string) {
	const allowedPositions: string[] = Object.values(UiToastMessageButtonPosition);
	if (position && !allowedPositions.includes(position)) {
		throw `Unknown alert button position "${position}".`;
	}
	return position;
}

/**
 * Component representing a single toast message. This should not be used directly and always instantiated
 * via `UiToastMessageService`.
 */
@Component({
	selector: 'dmo-toast-message',
	standalone: true,
	imports: [NgIf, NgClass, UiEmbedContentComponent, UiNotificationButtonComponent, NgTemplateOutlet],
	templateUrl: './ui-toast-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiToastMessageComponent {
	public readonly UiToastMessageType = UiToastMessageType;
	public readonly UiToastMessageButtonPosition = UiToastMessageButtonPosition;

	/**
	 * @ignore
	 */
	@HostBinding('class') public get hostClasses() {
		const hostClasses = [
			'dmo-toast-shadow dmo-flex dmo-flex-row dmo-gap-8 dmo-rounded dmo-w-[400px] dmo-max-w-[400px] dmo-pointer-events-auto',
		];

		if (!this.withoutPadding) {
			hostClasses.push('dmo-p-12');
		}

		switch (this.type) {
			case UiToastMessageType.BRAND:
				hostClasses.push('dmo-bg-active-primary dmo-text-inverse');
				break;
			case UiToastMessageType.INFO:
				hostClasses.push('dmo-bg-active-info dmo-text-inverse');
				break;
			case UiToastMessageType.SUCCESS:
				hostClasses.push('dmo-bg-active-success dmo-text-inverse');
				break;
			case UiToastMessageType.WARNING:
				hostClasses.push('dmo-bg-active-warning dmo-text-bothblack');
				break;
			case UiToastMessageType.DANGER:
				hostClasses.push('dmo-bg-active-danger dmo-text-inverse');
				break;
		}

		return hostClasses.join(' ');
	}

	/**
	 * Toast type.
	 */
	@HostBinding('attr.aria-label')
	@Input({ transform: transformType, required: true })
	public type: UiToastMessageType | string = UiToastMessageType.SUCCESS;
	/**
	 * Toast content.
	 */
	@Input({ required: true }) public text!: UiEmbedContentType;
	/**
	 * Optional toast title.
	 */
	@Input() public title?: string;
	/**
	 * Button position.
	 */
	@Input({ transform: transformPosition }) public buttonPosition: UiToastMessageButtonPosition | string =
		UiToastMessageButtonPosition.RIGHT;
	/**
	 * Label for the optional CTA button. Setting label will enable the button.
	 */
	@Input() public buttonLabel?: string;
	/**
	 * @ignore
	 */
	@Input() public withoutPadding = false;

	/**
	 * Emits when the cross icon in the top right corner is clicked.
	 */
	@Output() public closeClicked = new EventEmitter<void>();
	/**
	 * Emits when the toast button is clocked.
	 */
	@Output() public buttonClicked = new EventEmitter<void>();

	public get buttonType() {
		// Right now both enums have the same values
		return this.type as any as UiNotificationButtonType;
	}
}

import { RouterStateSerializer } from '@ngxs/router-plugin';
import { RouterStateSnapshot } from '@angular/router';
import { RouterUrlStateModel } from './router.model';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterUrlStateModel> {
	public serialize(routerState: RouterStateSnapshot): RouterUrlStateModel {
		const {
			url,
			root: { queryParams },
		} = routerState;

		let { root: route } = routerState;
		while (route.firstChild) {
			route = route.firstChild;
		}

		const { params, data } = route;

		if (route.params['title']) {
			data['title'] = route.params['title'];
		}

		const parsedQueryParams: Record<string, boolean> = {};

		Object.keys(queryParams).forEach((key) => {
			if (queryParams[key] === 'true') {
				parsedQueryParams[key] = true;
			} else if (queryParams[key] === 'false') {
				parsedQueryParams[key] = false;
			}
		});

		return {
			url,
			params,
			data,
			queryParams: {
				...queryParams,
				...parsedQueryParams,
			},
		};
	}
}

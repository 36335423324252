/**
 *
 *
 * export class SomeClass {
 *
 *   @ngDebounce(4000)
 *   callMe(value) {
 *     console.log('called after 4000ms');
 *   }
 *
 *   cancel() {
 *     (this.callMe as any).cancelDebounce();
 *   }
 * }
 *
 */
export function Debounce(timeout: number) {
	let timeoutRef: ReturnType<typeof setTimeout>;
	// tslint:disable-next-line:only-arrow-functions
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		const original = descriptor.value;

		descriptor.value = function debounce(...args: Array<any>) {
			clearTimeout(timeoutRef);

			timeoutRef = setTimeout(() => {
				original.apply(this, args);
			}, timeout);

			// define a property to cancel existing debounce timmer
			Object.defineProperty(debounce, 'cancelDebounce', {
				configurable: true,
				value: () => {
					clearTimeout(timeoutRef);
				},
			});
		};
		return descriptor;
	};
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@imt-web-zone/shared/environments';
import { forceHttps } from '@imt-web-zone/shared/util';

import { AppModule } from './app/app.module';

if (environment.production) {
	enableProdMode();
}

function resolveLoader() {
	// remove zone loader from DOM for remote (iframe) pages
	if (window.location.pathname.startsWith('/remote')) {
		const loader = document.getElementById('app-init-loader');
		loader?.remove();
	}
}

// TODO: do this on server side (nginx)
// redirect to `https` if protocol is `http`
forceHttps();

resolveLoader();

const bootstrap = () =>
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then((appRef) => {
			if ('serviceWorker' in navigator && environment.serviceWorker.enable) {
				navigator.serviceWorker.register('ngsw-worker.js');
			}
			return appRef;
		});

bootstrap().catch((err) => console.error(err));

import { ActionOptions, ActionType } from '@ngxs/store';
import { ɵensureStoreMetadata } from '@ngxs/store/internals';
import { errorActionOf, fromCacheActionOf, successActionOf } from '../../effect';
export const asyncActionFactory = (
	actionType: 'Success' | 'Error' | 'FromCache',
	actions: ActionType | Array<ActionType>,
	options?: ActionOptions,
) => {
	return (target: any, name: string | symbol): void => {
		const isStaticMethod = target.hasOwnProperty('prototype');

		if (isStaticMethod) {
			throw new Error(`@AsyncAction${actionType}() decorator cannot be used with static methods`);
		}

		const meta = ɵensureStoreMetadata(target.constructor);

		if (!Array.isArray(actions)) {
			actions = [actions];
		}
		for (const action of actions) {
			let type;
			switch (actionType) {
				case 'Error':
					type = errorActionOf(action).type;
					break;
				case 'FromCache':
					type = fromCacheActionOf(action).type;
					break;
				default: {
					type = successActionOf(action).type;
				}
			}

			if (!meta.actions[type]) {
				meta.actions[type] = [];
			}

			meta.actions[type].push({
				fn: name,
				options: options || {},
				type,
			});
		}
	};
};

import { inject, Inject, Injectable } from '@angular/core';
import { catchError, map, mergeMap, shareReplay, startWith, take, takeWhile, tap } from 'rxjs/operators';
import { GET, HttpBuilder } from '@imt-web-zone/shared/util';
import { interval, lastValueFrom, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from '@imt-web-zone/shared/environments';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { APP_NAME, AppNames } from '@imt-web-zone/shared/core';
import { DOCUMENT } from '@angular/common';
import { isEqual } from '@imt-web-zone/core/util-functions';
import { ServiceInit } from '@imt-web-zone/core/util-core';
import { ApiConfigData, ApiConfigFacade, GetZoneEnvResponse, ModeEnum } from './index';
import { SessionChecks, SessionChecksService } from '@imt-web-zone/zone/data-access-session-checks';

@Injectable({ providedIn: 'root' })
export class ApiConfigProvider extends HttpBuilder implements ServiceInit {
	public resolved$: Observable<ApiConfigData | null>;
	private resolvedSubject = new Subject<ApiConfigData | null>();
	private tabActive: boolean;
	private zoneConfigInterval = 600000; // 10 minutes
	private zoneConfig: SessionChecks = {
		timer: null,
		callBackFn: () => {
			this.zoneConfig.subscription = this.startInterval(this.zoneConfigInterval);
		},
	};
	private apiConfigFacade = inject(ApiConfigFacade);
	private sessionChecksService = inject(SessionChecksService);

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(
		protected override http: HttpApiClient,
		@Inject(APP_NAME) private appName: AppNames,
		@Inject(DOCUMENT) document?: any,
	) {
		super(http);

		this.resolved$ = this.resolvedSubject.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
		this.tabActive = !document.hidden;

		document.addEventListener('visibilitychange', () => {
			this.tabActive = !document.hidden;
			if (this.tabActive) {
				this.getStorageAndStartInterval(true);
			} else {
				this.zoneConfig.subscription?.unsubscribe();
			}
		});
	}

	public async initialize() {
		await this.loadAppConfig();
	}

	public async loadAppConfig() {
		const apiConfig = await lastValueFrom(
			this._loadAppConfig$().pipe(
				mergeMap((env: ApiConfigData) => {
					return this.loadConfig$(env);
				}),
				tap((env) => {
					this.resolvedSubject.next(env);
				}),
				catchError((e) => {
					this.resolvedSubject.next(null);
					this.apiConfigFacade.changeTheme$(environment.appConfig.defaultTheme).pipe(take(1)).subscribe();
					return throwError(e);
				}),
			),
		);

		this.resolveLoader(apiConfig?.mode, this.appName);
		if (this.zoneConfig.subscription) {
			this.zoneConfig.subscription?.unsubscribe();
		}
		this.sessionChecksService.getOrSetLocalStorage('zoneConfig');
		this.getStorageAndStartInterval(true);

		return apiConfig;
	}

	public async reloadApiConfig() {
		return await lastValueFrom(
			this._loadAppConfig$().pipe(mergeMap((env: ApiConfigData) => this.loadConfig$(env))),
		);
	}

	/**
	 * Fetch config and assign it locally
	 */
	private loadConfig$(env: ApiConfigData) {
		const config = this.mapApiConfig(env);
		return this.apiConfigFacade.setConfig$(config).pipe(map(() => config));
	}

	private mapApiConfig(env: ApiConfigData) {
		if (!env.brand?.theme && this.appName !== AppNames.ZONE_HQ_ADMIN) {
			env.brand.theme = environment.appConfig.defaultTheme;
		}
		return env;
	}

	@GET('zone-config')
	private _loadAppConfig$(): Observable<GetZoneEnvResponse> {
		return null as unknown as Observable<GetZoneEnvResponse>;
	}

	private resolveLoader(mode?: ModeEnum, appName?: AppNames) {
		if (mode === ModeEnum.MASTER || appName === AppNames.ZONE_HQ_ADMIN) {
			const value = '; ' + document.cookie;
			const parts = value.split('; userId=');
			const userCookie = parts.length === 2 ? parts.pop()?.split(';').shift() : null;
			if (!userCookie || window.location.pathname.startsWith('/oauth')) {
				document.getElementById('app-init-loader')?.remove();
			}
		}
	}

	private getStorageAndStartInterval(checkForLastRequestTime?: boolean) {
		this.sessionChecksService.getStorageAndStartInterval(
			this.zoneConfig,
			'zoneConfig',
			checkForLastRequestTime,
			this.zoneConfigInterval,
		);
	}

	private startInterval(apiConfigInterval: number): Subscription {
		let currentApiConfig: ApiConfigData;
		const interval$ = interval(apiConfigInterval).pipe(
			startWith(0),
			takeWhile(() => this.tabActive),
			tap(() => {
				currentApiConfig = structuredClone(this.apiConfigFacade.configSnapshot);
				this.sessionChecksService.getOrSetLocalStorage('zoneConfig');
			}),
			mergeMap(() => this.reloadApiConfig()),
			tap((apiConfig) => {
				if (!isEqual(apiConfig, currentApiConfig)) {
					this.apiConfigFacade.setConfig$(this.apiConfigFacade.configSnapshot).subscribe();
				}
				this.sessionChecksService.getOrSetLocalStorage('zoneConfig');
			}),
		);
		return interval$.subscribe();
	}
}

import { Selector } from '@ngxs/store';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { TeamModel } from './teams.model';
import { ORGANIZATIONS_STATE_TOKEN } from '@imt-web-zone/shared/data-access';
import { TEAMS_STATE_TOKEN } from './teams.symbols';

export class TeamsSelectors {
	/**
	 * filter entities by folderId and tab queryParams and search form state
	 */
	@Selector([CollectionState.getActiveId(ORGANIZATIONS_STATE_TOKEN), CollectionState.getEntities(TEAMS_STATE_TOKEN)])
	public static teamsBySelectedOrganization(orgId: string | null, entities: Array<TeamModel>) {
		return entities && orgId ? entities.filter((x) => x.organizationId === orgId) : [];
	}

	@Selector([CollectionState.getActiveId(ORGANIZATIONS_STATE_TOKEN), CollectionState.getEntities(TEAMS_STATE_TOKEN)])
	public static teamsByOrgAndSortedByName(orgId: string | null, entities: Array<TeamModel>) {
		return entities && orgId
			? entities.filter((x) => x.organizationId === orgId).sort((a, b) => a.name.localeCompare(b.name))
			: [];
	}
}

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { UiToastMessageComponent } from '../ui-toast-message/ui-toast-message.component';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import {
	UiToastMessage,
	UiToastMessageButtonPosition,
	UiToastMessageUpdateContent,
} from '../ui-toast-message.interface';

// const TOAST_TOP_OFFSET = 136;
// const TOAST_SLIDE_DOWN_SPEED = 1400; // px per second

type UiToastMessageOptionsInternal = UiToastMessage & {
	/**
	 * @internal
	 */
	_toastIndex: number;
	/**
	 * @internal
	 *
	 * Flag indication if toast was closed but is still in DOM.
	 */
	_hidden: boolean;
	/**
	 * @internal
	 *
	 * Flag set after fade out animation is done. Depending on toast position it will be either removed from
	 * DOM or it will block space so other toast won't change their position.
	 */
	_readyForCleanup: boolean;
	/**
	 * @internal
	 *
	 * When a toast is `_readyForCleanup` and a new toast is added we run animation that shrinks its height and after
	 * that it's removed from DOM.
	 */
	_shrinkToastAnimation: boolean;
};

/**
 * @ignore
 *
 * A "dumb" component only used to render and animate a list of `<dmo-toast-message>`s and forward their `(output)`s.
 * This component should not be used outside of this package.
 */
@Component({
	selector: 'dmo-toast-container',
	standalone: true,
	imports: [NgIf, NgFor, UiToastMessageComponent],
	templateUrl: './ui-toast-message-container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		/**
		 * Fade out animation used when toast is closed by user or after expiration.
		 */
		trigger('toastVisible', [
			state('true', style({ opacity: 1 })),
			state('false', style({ opacity: 0, visibility: 'hidden' })),
			transition('true => false', [animate('200ms')]),
		]),
		/**
		 * Animation triggered when an already hidden toast is about to be removed from DOM.
		 */
		trigger('shrinkToast', [
			state('true', style({ height: '0', marginTop: '0', padding: '0' })),
			state('false', style({ height: '*', marginTop: '16px', padding: '12px' })),
			transition('false => true', [animate('150ms')]),
		]),
		/**
		 * When a new toast is sliding down we want to first start moving the existing toasts downwards before the new
		 * toast appears.
		 */
		trigger('toastEnter', [
			transition(':enter', [
				style({ transform: 'translateX(300px)', marginTop: '16px', padding: '12px' }),
				animate('150ms', style({ transform: 'translateX(0px)', padding: '12px' })),
			]),
		]),
	],
})
export class UiToastMessageContainerComponent {
	public readonly UiToastMessageButtonPosition = UiToastMessageButtonPosition;

	@HostBinding('class') public hostClasses = `dmo-pointer-events-none`;

	@HostListener('mouseenter') public onMouseOver() {
		this.hovered.next(true);
	}

	@HostListener('mouseleave') public onMouseOut() {
		this.hovered.next(false);
	}

	@Output() public closeToast = new EventEmitter<number>();
	@Output() public toastButtonClicked = new EventEmitter<number>();
	@Output() public hovered = new EventEmitter<boolean>();

	@ViewChildren('toasts', { read: ElementRef }) public toastMessageElms!: QueryList<ElementRef>;

	public get toasts() {
		return Object.values(this.visibleToasts).reverse();
	}

	private cdr = inject(ChangeDetectorRef);
	private visibleToasts: { [index: number]: UiToastMessageOptionsInternal } = {};

	public addToast(options: UiToastMessage) {
		// Each toast has unique index in an ever increasing sequence.
		let lastIndex = Number(Object.keys(this.visibleToasts).pop());
		if (!lastIndex) {
			lastIndex = 0;
		}

		const toastIndex = lastIndex + 1;
		this.visibleToasts[toastIndex] = {
			_toastIndex: toastIndex,
			_hidden: false,
			_readyForCleanup: false,
			_shrinkToastAnimation: false,
			...options,
		};
		this.shrinkHiddenToasts();
		this.cdr.detectChanges();

		return toastIndex;
	}

	public hideToast(index: number): boolean {
		const toast = this.visibleToasts[index];
		if (!toast || toast._hidden) {
			return false;
		}
		this.visibleToasts[index]._hidden = true;
		this.cdr.markForCheck();

		return true;
	}

	/**
	 * @ignore
	 * @deprecated
	 *
	 * Don't use this method. Implemented only for backward compatibility.
	 */
	public updateToast(toastIndex: number, updatedContent: UiToastMessageUpdateContent) {
		const options = this.visibleToasts[toastIndex] as any;
		if (updatedContent.title) {
			options.title = updatedContent.title;
		}
		if (updatedContent.text) {
			options.text = updatedContent.text;
		}
		if (updatedContent.type) {
			options.type = updatedContent.type;
		}
		this.cdr.markForCheck();
	}

	/**
	 * Toast faded out so it'll be either removed from DOM if it's the last toast in the container or will block
	 * space between other toasts.
	 */
	public onToastVisibilityAnimationDone(event: AnimationEvent, toastIndex: number) {
		if (event.triggerName === 'toastVisible' && !event.toState && this.visibleToasts[toastIndex]) {
			this.visibleToasts[toastIndex]._readyForCleanup = true;
			this.cleanupToasts();
		}
	}

	/**
	 * When toast has 0 height we always remove if from DOM.
	 */
	public onToastShrinkedDone(event: AnimationEvent, toastIndex: number) {
		if (event.triggerName === 'shrinkToast' && event.toState) {
			this.removeToast(toastIndex);
		}
	}

	public isToastVisible(toastIndex: number) {
		return !this.visibleToasts[toastIndex]._hidden;
	}

	/**
	 * Remove toasts that are not visible in bottom-up order.
	 * A hidden toast stays in DOM when there are more toasts bellow. It blocks space and the other toasts won't change
	 * their position.
	 */
	private cleanupToasts() {
		const toastIndices = Object.keys(this.visibleToasts);

		for (let i = toastIndices.length - 1; i >= 0; i--) {
			const index = Number(toastIndices[i]);
			if (this.visibleToasts[index]._readyForCleanup) {
				this.removeToast(index);
			} else {
				break;
			}
		}
	}

	/**
	 * When a new toast is added we rearrange existing toasts so the ones that aren't visible and are only blocking
	 * space are shrinked and then removed from DOM.
	 */
	private shrinkHiddenToasts() {
		Object.keys(this.visibleToasts).forEach((toastIndex) => {
			const toast = this.visibleToasts[Number(toastIndex)];
			if (toast._hidden) {
				toast._shrinkToastAnimation = true;
			}
		});
	}

	private removeToast(toastIndex: number) {
		delete this.visibleToasts[toastIndex];
	}
}

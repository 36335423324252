import type { Store } from '@ngxs/store';

import { FacadeStore } from './state-facade.store';
import { CacheData, CollectionStateModel, EffectSuccess } from '@imt-web-zone/shared/util-store';

export const SELECT_RX_SNAPSHOT_SYMBOL = (key: string) => `__rx_snapshot_${key}`;

export class StateFacadeAbstractError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'StateFacadeAbstractError';
		Object.setPrototypeOf(this, StateFacadeAbstractError.prototype);
	}
}

/**
 * Checks whether `Store` is proxied by `FacadeStore`.
 */
export const isFacadeStore = (store: Store | FacadeStore): store is FacadeStore => {
	return typeof (store as FacadeStore).select$ === 'function';
};

export interface EffectSuccessfulOrCachedData<T> {
	entities: CollectionStateModel<T>['entities'];
	ids: CollectionStateModel<T>['ids'];
	metadata: CacheData['metadata'];
}

export interface EffectMetadata {
	response?: EffectSuccess['response'];
	fromCache: boolean;
}

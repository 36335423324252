import { NavigationEnd, Router } from '@angular/router';
import { Injectable, Renderer2, RendererFactory2, inject as inject_1 } from '@angular/core';
import { ImtUiAssetsInjector, ImtUiAssetsInjectorResult, BodyTagDef } from '@imt-web-zone/shared/util-assets-injector';
import { Observable } from 'rxjs';
import { CookiesService } from '@imt-web-zone/shared/data-access-cookies';
import { ServiceInit } from '@imt-web-zone/core/util-core';

declare let gtag: (...args: Array<any>) => void;

declare global {
	interface Window {
		dataLayer: object[];
	}
}

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService implements ServiceInit {
	private router = inject_1(Router);
	private cookiesService = inject_1(CookiesService);
	private assetInjector = inject_1(ImtUiAssetsInjector);

	private renderer2: Renderer2;
	private els?: Array<ImtUiAssetsInjectorResult>;
	private initialized = false;
	private crossDomain = '.make.com';

	constructor() {
		const rendererFactory = inject_1(RendererFactory2);

		this.renderer2 = rendererFactory.createRenderer(null, null);
	}

	public static pushToDataLayer(data: object) {
		if (window.dataLayer) {
			window.dataLayer.push(data);
		}
	}

	public async initialize(config: {
		datalayer$: Observable<object>;
		userId$: Observable<string>;
		gaKey?: string;
		crossDomain?: string;
	}) {
		const { datalayer$, crossDomain, gaKey, userId$ } = config;
		if (this.initialized) {
			console.warn(
				'[GA] initialize() method on GoogleAnalyticsService is called more than once!\nCheck your implementation.',
			);
			return;
		}

		this.listenForDatalayerPushes(datalayer$);
		this.listenForUserId(userId$);

		crossDomain && (this.crossDomain = crossDomain);

		const scriptsForInject: BodyTagDef[] = [
			{
				// Script for adding gtag to window
				tag: 'script',
				position: 'head',
				body: `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				`,
				attributes: [{ attr: 'type', value: 'text/javascript' }],
			},
			{
				// Script for adding fbq to window
				tag: 'script',
				position: 'head',
				body: `
					!function(f,b,e,v,n,t,s){
					if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
					n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
					document,'script','https://connect.facebook.net/en_US/fbevents.js');`,
				attributes: [{ attr: 'type', value: 'text/javascript' }],
			},
			{
				// Script for adding lintrk to window
				tag: 'script',
				position: 'head',
				body: `
					(function(){
					var s = document.getElementsByTagName("script")[0];
					var b = document.createElement("script");
					b.type = "text/javascript";b.async = true;
					b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
					s.parentNode.insertBefore(b, s);})();
				`,
				attributes: [{ attr: 'type', value: 'text/javascript' }],
			},
			{
				//Script for adding rdt to window
				tag: 'script',
				position: 'head',
				body: `
					!(function (w, d) {
						if (!w.rdt) {
							var p = (w.rdt = function () {
								p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
							});
							p.callQueue = [];
							var t = d.createElement('script');
							(t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
							var s = d.getElementsByTagName('script')[0];
							s.parentNode.insertBefore(t, s);
						}
					})(window, document);
				`,
				attributes: [{ attr: 'type', value: 'text/javascript' }],
			},
		];

		if (gaKey) {
			scriptsForInject.push({
				tag: 'script',
				position: 'head',
				src: `https://www.googletagmanager.com/gtag/js?id=${gaKey}`,
				attributes: [
					{ attr: 'type', value: 'text/javascript' },
					{ attr: 'async', value: 'true' },
				],
			});

			scriptsForInject.push({
				tag: 'script',
				position: 'head',
				body: `
				gtag('config', '${gaKey}', {'send_page_view': false});
				console.log('[GA] Google analytics initialized');
				`,
				attributes: [{ attr: 'type', value: 'text/javascript' }],
			});
		}

		try {
			if (this.els) {
				this.els.forEach((el) => el.el.remove());
			}
			this.els = await this.assetInjector.inject(this.renderer2, scriptsForInject);

			if (gaKey) {
				this.listenForRouteChanges(gaKey);
			}
		} catch (e) {
			console.error('[GA] Error while appending google analytics: ', e);
		}
	}

	private listenForRouteChanges(gaKey: string) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				try {
					gtag('config', gaKey, {
						page_path: event.urlAfterRedirects,
					});
				} catch (e) {
					console.error('Error while calling gtag: ', e);
				}
			}
		});
	}

	public event(eventName: string, params: Record<string, any>) {
		gtag('event', eventName, params);
	}

	private listenForDatalayerPushes(data$: Observable<object>) {
		return data$.subscribe((data) => {
			GoogleAnalyticsService.pushToDataLayer(data);
		});
	}

	private listenForUserId(userId$: Observable<string>) {
		return userId$.subscribe((userId) => {
			this.cookiesService.setCookie('make_user_id', userId, this.crossDomain, 365); // one year
		});
	}
}

import { inject, Injectable } from '@angular/core';
import {
	GetExecutionLogResponse,
	GetFulltextPayload,
	GetFulltextResponse,
	WebStreamerPathKind,
} from './web-streamer.interface';
import { WebStreamerLib } from './web-streamer.interface';
import { TranslocoService } from '@jsverse/transloco';
import { UiToastMessageService } from '@imt-web-zone/make-design-system/ui-toast-message';
import { GET, HttpBuilder, Path, QueryData } from '@imt-web-zone/shared/util';
import { HttpStreamerClient } from '@imt-web-zone/shared/data-access-http-clients';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class WebStreamerService extends HttpBuilder {
	private instance: WebStreamerLib = null;
	private toastService = inject(UiToastMessageService);
	private transloco = inject(TranslocoService);

	constructor() {
		const http = inject(HttpStreamerClient);
		super(http);
	}

	public initialize() {
		if (this.instance) {
			return;
		}

		if (!(window as any).WebStreamerLib) {
			this.toastService.showDanger({
				text: this.transloco.translate('streamer.streaming_not_initialized'),
			});
			return;
		}

		this.instance = new (window as any).WebStreamerLib();
		this.instance.on('connected', () => {
			// eslint-disable-next-line no-restricted-syntax
			console.info('[imt] web-streamer: connected');
		});

		this.instance.on('disconnected', () => {
			// eslint-disable-next-line no-restricted-syntax
			console.info('[imt] web-streamer: disconnected');
		});

		this.instance.on('info', (msg) => {
			(window as any).imt.debug('info', msg);
		});
		(window as any).webStreamerInstance = this.instance;
	}

	@GET('{kind}/{kindId}/log/{executionId}')
	public getStreamerExecutionLog$(
		@Path('kind') kind: WebStreamerPathKind,
		@Path('kindId') kindId: string,
		@Path('executionId') executionId: string,
	): Observable<Array<GetExecutionLogResponse>> {
		return undefined;
	}

	@GET('{kind}/{kindId}/fulltext')
	public postFulltext(
		@Path('kind') kind: WebStreamerPathKind,
		@Path('kindId') kindId: string,
		@QueryData payload: GetFulltextPayload,
	): Observable<Array<GetFulltextResponse>> {
		return undefined;
	}
}

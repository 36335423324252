import { Provider, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { debugLogger } from '@imt-web-zone/core/util-core';

import { APP_ROOT_SELECTOR } from '@imt-web-zone/shared/core';

import {
	AssetsDomain,
	ASSETS_PUBLIC_PATH,
	AssetsPublicPath,
	ASSETS_PUBLIC_PATH_DATA_ATTR_NAME,
	ASSETS_PUBLIC_PATH_DATA_ATTR_PLACEHOLDER,
} from './assets';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let __webpack_public_path__: string;

const debug = debugLogger.create('provideAssetsPublicPath');

/**
 * Sets application wide public path (via `__webpack_public_path__`) so all the application assets
 * (lazy loaded libraries, etc.) will be loaded from the given public path.
 *
 * It also creates relation between provided provided `assetsPublicPathDomain` and public path and once
 * this domain is used for getting particular asset, public path will be used.
 *
 * Assets public path must be provided at first place during application bootstrap because it may happen,
 * that will be needed even if the bootstrapping fails (for loading error page assets, theme, etc.).
 */
export const provideAssetsPublicPath = <T extends AssetsDomain>(
	assetsPublicPathDomain: T,
	assetsPublicPath?: string | ((...deps: Array<unknown>) => string),
	...deps: Array<unknown>
): Provider => {
	return {
		provide: ASSETS_PUBLIC_PATH,
		useFactory: (...deps: Array<unknown>): AssetsPublicPath<T> => {
			const context = inject(DOCUMENT);
			const rootElementSelector = inject(APP_ROOT_SELECTOR);

			const publicPath = resolvePublicPath(
				// Default public path can be overwritten by provided value.
				assetsPublicPath != undefined
					? typeof assetsPublicPath === 'function'
						? assetsPublicPath(...deps)
						: assetsPublicPath
					: // By default public path is taken from the root element's specific data attribute.
					  defaultAssetsPublicPathFn(context, rootElementSelector),
			);

			// Set original `__webpack_public_path__` as public path if public path didn't change.
			if (!publicPath || publicPath === __webpack_public_path__) {
				return {
					assetsPublicPath: __webpack_public_path__,
					assetsPublicPathDomain,
				};
			}

			debug(`Updating... public path (from "${__webpack_public_path__}") to "${publicPath}"`);

			return {
				assetsPublicPath: (__webpack_public_path__ = publicPath),
				assetsPublicPathDomain,
			};
		},
		deps,
	};
};

/**
 * If needed, corrects given public path into ideal form.
 */
const resolvePublicPath = (publicPath?: string): string | undefined => {
	if (!publicPath) {
		return publicPath;
	}

	// Public path has to always end with slash `/`, otherwise webpack won't be
	// able to correctly load lazy loaded chunks.
	return publicPath.endsWith('/') ? publicPath : `${publicPath}/`;
};

/**
 * Gets value (representing default app public path) from the exact data attribute of the given (root) element.
 */
const defaultAssetsPublicPathFn = (context: Document, selector: string) => {
	const publicPathDataAttrValue = (
		context.querySelector(`${selector}[${ASSETS_PUBLIC_PATH_DATA_ATTR_NAME}]`) as HTMLElement
	)?.dataset?.['publicPath'];

	// Check if value contains initial placeholder string (which is meant to be replaced).
	if (publicPathDataAttrValue?.includes(ASSETS_PUBLIC_PATH_DATA_ATTR_PLACEHOLDER)) {
		return undefined;
	}

	return publicPathDataAttrValue;
};

import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';

export const TRANSLATE_KEY_PATTERN = /{{!(\S+)}}/gm;
// todo monorepo: move to ng-web-core
export class Utils {
	public static jsonStringify(value: any, format = true): string {
		if (!value) {
			return undefined;
		}
		return format ? JSON.stringify(value, null, 2) : JSON.stringify(value);
	}

	public static getUrlSegments(router: Router): Array<string> {
		const tree = router.createUrlTree([]);
		if (
			tree &&
			tree.root &&
			tree.root.children &&
			tree.root.children.primary &&
			tree.root.children.primary.segments
		) {
			return tree.root.children.primary.segments.map((x) => x.path);
		}
		return [];
	}

	public static translateString(value: string, transloco: TranslocoService): string {
		let result = value;
		if (result) {
			const reg = new RegExp(TRANSLATE_KEY_PATTERN);
			let regResult: RegExpExecArray;
			// tslint:disable-next-line: no-conditional-assignment
			while ((regResult = reg.exec(value)) !== null) {
				const match = regResult[0];
				const key = regResult[1];
				const text = transloco.translate(key);

				result = value.split(match).join(text);
			}
		}

		return result;
	}
}

import { Actions } from '@ngxs/store';
import { Injectable, Type } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import {
	UiToastMessageForType,
	UiToastMessageService,
	UiToastMessageType,
} from '@imt-web-zone/make-design-system/ui-toast-message';
import { ActionMessageMetadata } from '@imt-web-zone/shared/util-store';
import { ServiceInit } from '@imt-web-zone/core/util-core';

export interface NotificationMapItem {
	action: Type<Record<string, unknown>>;
	translationKey?: string;
	text?: string;
	error?: boolean;
}

/**
 * Service responsible for:
 * 	- setting the default toast type according to the app mode
 * 	- dispatching toasts if action streams emits action containing toast metadata
 */
@Injectable({ providedIn: 'root' })
export class ZoneToastService implements ServiceInit {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(
		protected toastService: UiToastMessageService,
		protected actions: Actions,
		protected transloco: TranslocoService,
	) {}

	public initialize(isMasterMode: boolean): Promise<void> {
		// for the master mode change default type to success
		if (isMasterMode) {
			// eslint-disable-next-line @nx/workspace-zone-toasts-usage
			this.toastService.defaultOptions.defaultType = UiToastMessageType.SUCCESS;
		}

		this.actions.subscribe((x) => {
			// old actions
			// TODO: remove when the refactoring to action creators is completed
			if (x.status === 'SUCCESSFUL' && this.hasMessageMeta(x.action) && this.isNotRequestAction(x.action)) {
				this.showToast(x.action.metadata);
			} else if (
				// action creators
				x.status === 'SUCCESSFUL' &&
				x.action.type &&
				x.action.prevAction &&
				this.hasMessageMeta(x.action.prevAction)
			) {
				this.showToastFromActionCreators(x.action, x.action.prevAction.metadata);
			}
		});

		return null;
	}

	protected showToastFromActionCreators(action: any, metadata: ActionMessageMetadata) {
		let data: UiToastMessageForType;
		if (metadata.messageData && action.type.includes('[success]')) {
			data = metadata.messageData;
			data.text = this.transloco.translate(data.text);
			this.toastService.show(data);
		} else if (metadata.errorMessageData && action.type.includes('[error]')) {
			return; // data = metadata.errorMessageData;
		}
	}

	protected showToast(metadata: ActionMessageMetadata) {
		let data: UiToastMessageForType;
		if (metadata.messageData) {
			data = metadata.messageData;
		} else if (metadata.errorMessageData) {
			data = metadata.errorMessageData;
		}
		data.text = this.transloco.translate(data.text);
		this.toastService.show(data);
	}

	private isNotRequestAction(action: any) {
		return !action.request;
	}

	private hasMessageMeta(action: ActionMessageMetadata): boolean {
		return action.metadata && (action.metadata.messageData || action.metadata.errorMessageData);
	}
}

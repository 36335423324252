import { APP_INITIALIZER, inject, InjectionToken, Injector, Provider, Type } from '@angular/core';
import { Router } from '@angular/router';
import { debugLogger } from './debug-logger';
const debug = debugLogger.create('appBootstrap');

const BASE_DEPENDENCY = new InjectionToken<Array<() => Promise<void>>>('BASE_DEPENDENCY');
const SERVICE_INIT = new InjectionToken<Array<() => Promise<void>>>('SERVICE_INIT');

type ServiceInitClass = Type<ServiceInit> & StaticProps;

type FactoryFn<S extends Array<any>> = (
	deps1?: InstanceType<S[0]>,
	deps2?: InstanceType<S[1]>,
	deps3?: InstanceType<S[2]>,
	deps4?: InstanceType<S[3]>,
	deps5?: InstanceType<S[4]>,
	deps6?: InstanceType<S[5]>,
	deps7?: InstanceType<S[6]>,
	deps8?: InstanceType<S[7]>,
	deps9?: InstanceType<S[8]>,
	deps10?: InstanceType<S[9]>,
) => Promise<void>;

type ConfigFactory<T extends ServiceInit, S extends Array<any>> = (
	deps1?: InstanceType<S[0]>,
	deps2?: InstanceType<S[1]>,
	deps3?: InstanceType<S[2]>,
	deps4?: InstanceType<S[3]>,
	deps5?: InstanceType<S[4]>,
	deps6?: InstanceType<S[5]>,
	deps7?: InstanceType<S[6]>,
	deps8?: InstanceType<S[7]>,
	deps9?: InstanceType<S[8]>,
	deps10?: InstanceType<S[9]>,
) => Promise<Parameters<T['initialize']>[0]>;

type LazyProvider<T, S extends Array<any>> = (
	deps1?: InstanceType<S[0]>,
	deps2?: InstanceType<S[1]>,
	deps3?: InstanceType<S[2]>,
	deps4?: InstanceType<S[3]>,
	deps5?: InstanceType<S[4]>,
	deps6?: InstanceType<S[5]>,
	deps7?: InstanceType<S[6]>,
	deps8?: InstanceType<S[7]>,
	deps9?: InstanceType<S[8]>,
	deps10?: InstanceType<S[9]>,
) => Promise<T>;

export function initializeApp() {
	return {
		provide: APP_INITIALIZER,
		useFactory: initializeAppProvider,
		multi: true,
	};
}

/**
 * application bootstrap which initializes all BASE_DEPENDENCY and SERVICE_INIT tokens
 *
 * BASE_DEPENDENCY tokens are resolved first and sequentially
 *
 * SERVICE_INIT tokens are resolved in parallel and after the BASE_DEPENDENCY
 *
 */
function initializeAppProvider() {
	const baseDeps = inject(BASE_DEPENDENCY, { optional: true }) || [];
	const serviceDeps = inject(SERVICE_INIT, { optional: true }) || [];
	const router = inject(Router);

	/**
	 * initialize service, ignore error if bootstrap already failed
	 */
	const init = async (service: () => Promise<any>, failed: boolean) => {
		try {
			return await service();
		} catch (e) {
			if (!failed) {
				console.error(e);
			}
		}
	};

	return async () => {
		let failed = false;

		// initialize configsInitiators sequentially
		for (const depInit of baseDeps) {
			try {
				await depInit();
			} catch (e) {
				failed = true;
				console.error('BASE_DEPENDENCY init failed', e);
				break;
			}
		}

		// initialize servicesInitiators
		await Promise.all(serviceDeps.map((service) => init(service, failed)));
		if (failed) {
			router.navigate(['/error']);
		}
	};
}

/**
 *
 * Dependencies which are resolved first and sequentially in the APP_INITIALIZER
 *
 */
export function baseDependencyInit<T, S extends Array<any>>(
	injectable: InjectionToken<T>,
	factoryFn: FactoryFn<S>,
	...deps: S
): ReturnType<typeof providerFactory>;
export function baseDependencyInit<T extends ServiceInitClass, S extends Array<any>>(
	injectable: T | LazyProvider<T, S>,
	factoryFn?: ConfigFactory<InstanceType<T>, S>,
	...deps: S
): ReturnType<typeof providerFactory>;
export function baseDependencyInit<T extends ServiceInitClass, S extends Array<any>>(
	Service: T | LazyProvider<T, S>,
	configFactory?: ConfigFactory<InstanceType<T>, S>,
	...deps: S
) {
	return providerFactory(BASE_DEPENDENCY, Service, configFactory, deps);
}

/**
 *
 * Dependencies which are resolved in parallel and after the base dependencies in the APP_INITIALIZER
 *
 */
export function serviceInit<T extends ServiceInitClass, S extends Array<any>>(
	injectable: T | LazyProvider<T, S>,
	factoryFn?: ConfigFactory<InstanceType<T>, S>,
	...deps: S
): ReturnType<typeof providerFactory>;
export function serviceInit<S extends Array<any>>(
	injectable: unknown,
	factoryFn?: unknown,
	...deps: S
): ReturnType<typeof providerFactory> {
	return providerFactory(SERVICE_INIT, injectable as any, factoryFn as any, deps);
}

function providerFactory<T extends Type<ServiceInit> & StaticProps, S extends Array<any>>(
	TOKEN: typeof BASE_DEPENDENCY | typeof SERVICE_INIT,
	provider: T | InjectionToken<T> | LazyProvider<T, S> | LazyProvider<InjectionToken<T>, S>,
	fnFactory?: ConfigFactory<InstanceType<T>, S> | FactoryFn<S>,
	deps?: S,
): Array<Provider> {
	const providers = getAdditionalProviders(provider, fnFactory);
	const loadingMode = isClassOrFunction(provider) === 'function' ? 'lazy' : 'normal';
	return [
		...providers,
		{
			provide: TOKEN,
			useFactory: (injector: Injector, ...args: Array<any>) => {
				return async () => {
					let providerPromise: null | Promise<T | InjectionToken<any>> = null;
					// lazy load
					if (loadingMode === 'lazy') {
						const service = provider as LazyProvider<T, S>;
						const result = await service(...args);
						providerPromise = result !== null ? Promise.resolve(result) : null;
					} else if (loadingMode === 'normal') {
						providerPromise = Promise.resolve(provider as T | InjectionToken<T>);
					}

					if (!providerPromise) {
						return;
					}
					const InjectableToken: T | InjectionToken<any> = await providerPromise;
					const injectableTokenInstance = injector.get(InjectableToken);
					let name;

					if (InjectableToken instanceof InjectionToken && isInjectionToken(injectableTokenInstance)) {
						await injectableTokenInstance(...args);
						name = InjectableToken.toString();
					} else if (isClass(InjectableToken)) {
						const config = fnFactory ? await fnFactory(...args) : undefined;
						await injectableTokenInstance.initialize(config);
						name = injectableTokenInstance.constructor.name;
					}

					debug(
						`${TOKEN.toString().replace(
							'InjectionToken ',
							'',
						)}: "${name}" initialized in ${loadingMode} mode.`,
					);
				};
			},
			multi: true,
			deps: [Injector, ...(deps || [])],
		},
	];
}

function isClassOrFunction(arg: any): 'class' | 'function' | 'neither' {
	if (typeof arg === 'function') {
		// Check if it's a class by examining the string representation
		const isClass = /^class\s/.test(Function.prototype.toString.call(arg));
		if (isClass) {
			return 'class';
		}

		return 'function';
	}

	return 'neither';
}

function getAdditionalProviders(provider: unknown, factoryFn?: (...args: Array<any>) => Promise<any>) {
	const providers: Provider[] = [];
	if (provider instanceof InjectionToken) {
		providers.push({
			provide: provider,
			useValue: factoryFn,
		} as Provider);
	} else if (isClass(provider)) {
		providers.push(...(provider?.providers || []));
	}
	return providers;
}

function isInjectionToken(arg: unknown): arg is (...args: Array<any>) => Promise<void> {
	return true;
}

function isClass(arg: unknown): arg is ServiceInitClass {
	return isClassOrFunction(arg) === 'class';
}

interface StaticProps {
	providers?: Array<Provider>;
}

export interface ServiceInit {
	initialize(...args: Array<any>): Promise<void>;

	// implements StaticProps
}

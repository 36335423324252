import { InjectionToken, Injector, Type } from '@angular/core';

// A `Symbol` which is used to save the `Injector` onto the class instance.
const InjectorInstance: unique symbol = Symbol('InjectorInstance');

export function localInject<T>(instance: PrivateInstance, token: InjectionToken<T> | Type<T>): T | null {
	const injector: Injector | undefined = instance[InjectorInstance];
	return injector ? injector.get(token) : null;
}

interface PrivateInstance {
	[InjectorInstance]?: Injector;
}

import { Injectable } from '@angular/core';

import { StateFacadeAbstract } from '@imt-web-zone/core/util-state-facade';
import { ApiConfigSelectors } from './api-config.selectors';
import { ApiConfigState } from './api-config.state';
import { ChangeTheme, FinishInstallGuide, SetApiConfig } from './api-config.actions';
import { ApiConfigData, ModeEnum } from './api-config.interface';
import { environment } from '@imt-web-zone/shared/environments';
import { API_CONFIG_STATE_TOKEN } from './api-config-base.state';

@Injectable({ providedIn: 'root' })
export class ApiConfigFacade extends StateFacadeAbstract<typeof API_CONFIG_STATE_TOKEN, null> {
	public static provideStore() {
		return ApiConfigState;
	}
	protected service = null;

	/**
	 * Selectors
	 */
	public config$ = this.store.select$(this.stateToken);
	public tokens$ = this.store.select$(ApiConfigSelectors.apiTokens);

	public configRxSnapshot = this.rxSnapshotFn(this.stateToken);
	public configSignal = this.store.selectSignal(this.stateToken);

	public get tokensSnapshot() {
		return this.store.selectSnapshot(ApiConfigSelectors.apiTokens);
	}
	public get configSnapshot() {
		return this.store.selectSnapshot(this.stateToken);
	}

	public get isMaster(): boolean {
		return this.configSnapshot.mode === ModeEnum.MASTER;
	}

	public get isSlave(): boolean {
		return this.configSnapshot.mode === ModeEnum.SLAVE;
	}

	public get isEms() {
		return this.configSnapshot?.brand?.theme?.startsWith('ems');
	}

	public get hqDomain() {
		return this.configSnapshot.domains['*'].replace('*', '');
	}

	constructor() {
		super(API_CONFIG_STATE_TOKEN);
	}

	public isExampleZone(): boolean {
		return this.store.selectSnapshot(ApiConfigSelectors.isExampleZone());
	}

	public setConfig$(config: Partial<ApiConfigData>) {
		return this.store.dispatch$(new SetApiConfig(config));
	}

	public finishInstallGuide$() {
		return this.store.dispatch$(new FinishInstallGuide());
	}

	public changeTheme$(newTheme = environment.appConfig.defaultTheme, prevTheme?: string) {
		return this.store.dispatch$(new ChangeTheme(environment.appConfig.defaultTheme, prevTheme));
	}
}

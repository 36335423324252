import { Cols } from '@imt-web-zone/shared/util';
import { OrganizationModel } from '@imt-web-zone/shared/data-access';

export class AuthUserApiModel {
	constructor(
		public avatar: string,
		public email: string,
		public features: Record<string, unknown>,
		public id: number,
		public locale: string,
		public name: string,
		public timezone: string,
		public timezoneId: number,
		public countryId: number,
		public localeId: number,
		public usersAdminsRoleId?: number,
		public hasPassword?: boolean,
		public forceSetPassword?: boolean,
		public language?: string,
		public tfaEnabled?: boolean,
		public tfaCodes?: Array<string>,
		public isAffiliatePartner?: boolean,
		public created?: Date,
	) {}
}

export interface LoadAuthUserResponse {
	authUser: {
		avatar: string;
		email: string;
		features: Record<string, unknown>;
		countryId: number;
		id: number;
		locale: string;
		localeId: number;
		name: string;
		timezone: string;
		timezoneId: number;
		usersAdminsRoleId?: number;
		// eslint-disable-next-line max-len
		userOrganizationRoles?: Array<{
			usersRoleId: number;
			userId: number;
			organizationId: number;
			invitationPending?: boolean;
		}>;
		userTeamRoles?: Array<{ usersRoleId: number; userId: number; teamId: number; changeable: boolean }>;
		forceSetPassword?: boolean;
		language?: string;
		tfaEnabled?: boolean;
		tfaCodes?: Array<string>;
		hasPassword?: boolean;
		isAffiliatePartner?: boolean;
		created?: Date;
	};
}

export interface LoadAuthUserPayload {
	query: {
		// eslint-disable-next-line max-len
		cols: Cols<
			LoadAuthUserResponse['authUser'],
			| 'usersAdminsRoleId'
			| 'avatar'
			| 'countryId'
			| 'email'
			| 'features'
			| 'id'
			| 'locale'
			| 'localeId'
			| 'timezone'
			| 'name'
			| 'timezoneId'
			| 'userOrganizationRoles'
			| 'userTeamRoles'
			| 'forceSetPassword'
			| 'language'
			| 'tfaEnabled'
			| 'hasPassword'
			| 'isAffiliatePartner'
			| 'created'
		>;
	};
}

export interface LoginPayload {
	payload: {
		password: string;
		email: string;
	};
}

export interface LoginResponse {
	tfaEnabled?: boolean;
	userId: number;
}

export interface SsoAuthorizePayload {
	redirect: string;
}

export interface SsoAuthorizeResponse {
	authorizeUrl: string;
}

export interface TfaLoginPayload {
	payload: {
		tfaCode: number;
	};
}
export interface TfaLoginResponse {
	code: number;
}

export interface LogoutPayload {
	payload?: {
		// do not wait for response
		sync?: boolean;
		redirect?: boolean;
		soft?: boolean;
		sessionExpired?: boolean;
		userEmailChanged?: boolean;
	};
}

export interface LogoutResponse {
	ok: 1;
	redirect?: string;
}

export interface CreateInitialAdminUserInstallPayload {
	payload: {
		email: string;
		password: string;
		name: string;
		countryId: number;
	};
}

export interface UsersUnreadNotificationsResponse {
	userUnreadNotifications: number;
}

/**
 * PasswordResetCheckHashUsersPayload
 */

export interface PasswordResetCheckHashUsersPayload {
	query: {
		hash?: string;
	};
}

/**
 * PasswordResetCheckHashUsersResponse
 */

export interface PasswordResetCheckHashResponse {
	ok: number;
}

/**
 * PasswordResetUpdateUserPayload
 */

export interface PasswordResetPayload {
	payload: {
		newPassword1: string;
		newPassword2: string;
	};
}

/**
 * PasswordResetUpdateUserResponse
 */

export interface PasswordResetResponse {
	ok: number;
}

/**
 * PasswordUpdateUserPayload
 */

export interface PasswordUpdatePayload {
	payload: {
		currentPassword: string;
		newPassword1: string;
		newPassword2: string;
	};
	params: {
		userId: string;
	};
}

/**
 * PasswordUpdateUserResponse
 */

export interface PasswordUpdateResponse {
	changed: boolean;
}

export interface Get2faResponse {
	oneTimePasswords: Array<string>;
	userId: number;
}

/**
 * Disable2faPayload
 */
export interface Disable2faPayload {
	tfaEnabled: boolean;
}

/**
 * Disable2faResponse
 */
export interface Disable2faResponse {
	tfaEnabled: boolean;
}

/**
 * GenerateTfaResponse
 */
export interface Enable2faResponse {
	oneTimePasswords: Array<string>;
	tfaEnabled: boolean;
}

export interface GetActiveOrganizationTeamRequest {
	query: {
		organizationId?: string;
		teamId?: string;
	};
}

export interface GetActiveOrganizationTeamResponse {
	activeOrganizationTeam: {
		activeOrganizationId: number;
		activeOrganizationName: string;
		activeOrganizationLicense: OrganizationModel['license'];
		activeTeamId: number;
		activeTeamName: string;
	};
}

export interface GetSanityCheckResponse {
	authUser: {
		email: string;
		id: number;
		name: string;
	};
	userSessionExpiresAt: Date;
}

export interface RegisterPartnerPayload {
	payload: {
		partnerCode: string;
		paypalMeLink: string;
		survey_audience: Array<string>;
		survey_promotion_method: Array<string>;
		survey_reference: Array<string>;
		termsAndConditions: boolean;
	};
}

export interface RegisterPartnerResponse {
	ok: 1;
}

export interface PartialUpdateAdminUserResponse {
	user: {
		id: number;
		email: string;
		name: string;
		avatar: string;
		timezoneId: number;
		countryId: number;
		localeId: number;
		features: Record<string, unknown>;
		deleted: boolean;
		created: Date;
		organizations: number;
		scenarios: number;
		activeScenarios: number;
		lastLogin: Date;
		service: string;
		usersAdminsRoleId: number;
		language: string;
	};
}

export interface PartialUpdateUserPayload {
	params: { userId: string };
	payload: { language: string };
}

export type PartialUpdateUserResponse = PartialUpdateAdminUserResponse;

export interface UserZoneUnreadNotification {
	imtZoneId: number;
	unreadNotifications: number;
}

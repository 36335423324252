import { ActionType, createAction, createAsyncLoadAction, props } from '@imt-web-zone/shared/util-store';
import { DlqsLogModel } from './dlqs-logs.model';
import {
	ListDlqsLogsPayload,
	DlqExecutionLogPayload,
	ListDlqsLogsResponse,
	DlqExecutionLogResponse,
} from './dlqs-logs.interface';
import { ActionMessageMetadata } from '@imt-web-zone/shared/util-store';
import { ScenariosLogStatus } from '@imt-web-zone/zone/state-scenario-logs';

// tslint:disable:max-line-length

export const listDlqsLogs = createAsyncLoadAction<
	ListDlqsLogsPayload,
	ListDlqsLogsResponse,
	ActionMessageMetadata & { ignoreRunning?: boolean }
>('[DlqsLogs API] GET list dlqs logs');
export type ListDlqsLogs = ActionType<typeof listDlqsLogs>;

export const dlqExecutionLog = createAsyncLoadAction<DlqExecutionLogPayload, DlqExecutionLogResponse>(
	'[DlqsLogs API] GET execution log',
);
export type DlqExecutionLog = ActionType<typeof dlqExecutionLog>;

export const addDlqLog = createAction('[WebStreamerLib] Add dlq log', props<{ payload: DlqsLogModel }>());
export type AddDlqLog = ActionType<typeof addDlqLog>;

export const updateDlqLog = createAction(
	'[WebStreamerLib] Update dlq log',
	props<{
		payload: {
			id: string;
			imtId: string;
			data: {
				duration: number;
				transfer: number;
				operations: number;
				status: ScenariosLogStatus;
				instant: boolean;
			};
		};
	}>(),
);
export type UpdateDlqLog = ActionType<typeof updateDlqLog>;

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@imt-web-zone/shared/environments';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CanduService, RudderStackService } from '@imt-web-zone/zone/data-access';
import { LogoLoaderIndicatorComponent } from '@imt-web-zone/zone-feature-shared-module';
import { AppNames } from '@imt-web-zone/shared/core';
import { ZoneCoreModule } from './zone-core.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		FormsModule,
		ZoneCoreModule.forRoot(
			AppNames.ZONE,
			[
				{ prefix: '/i18n/web-zone.', suffix: '.json' },
				{ prefix: '/i18n/inspector.', suffix: '.json' },
				{ prefix: '/i18n/web-zone-ems.', suffix: '.json' },
				{ prefix: '/i18n/inspector-ems.', suffix: '.json' },
			],
			[RudderStackService, CanduService],
		),
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.serviceWorker?.enable,
			registrationStrategy: 'registerImmediately',
		}),
		LogoLoaderIndicatorComponent,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

import { EnumsResponses } from './enums.interface';
import { Injectable, inject } from '@angular/core';
import { HttpBuilder, GET, Path } from '@imt-web-zone/shared/util';
import { Observable } from 'rxjs';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';

@Injectable({ providedIn: 'root' })
export class EnumsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('enums/{enum}')
	public loadEnumValues$(@Path('enum') enumName: string): Observable<EnumsResponses> {
		return undefined;
	}
}

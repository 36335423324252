import { State, Action, StateContext } from '@ngxs/store';
import { CollectionState, CollectionStateModel, IdStrategy } from '@imt-web-zone/shared/util-store';
import { Injectable, inject } from '@angular/core';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';

import { ListDlqsLogs, UpdateDlqLog, DlqsLogModel } from '@imt-web-zone/zone/state-dlqs-logs';
import {
	ScenarioLogModel,
	UpdateScenarioLog,
	ListScenarioLogs,
	ScenarioLogsType,
	SCENARIO_LOGS_STATE_TOKEN,
	getInitialScenariosLogsStateModel,
	ScenarioLogsService,
	listScenarioLogs,
	ScenarioLogsAdapter,
	scenarioExecutionLog,
	ScenarioExecutionLog,
	addScenarioLog,
	AddScenarioLog,
	updateScenarioLog,
	dropExecution,
	DropExecution,
} from '@imt-web-zone/zone/state-scenario-logs';

export const updateLog = <T extends ScenarioLogModel | DlqsLogModel>(
	ctx: StateContext<CollectionStateModel<T>>,
	action: UpdateScenarioLog | UpdateDlqLog,
) => {
	ctx.setState((state) => {
		// make sure that running logs from scenarios/logs endpoint are not duplicated
		// by running logs from webStreamerLib
		const imtId = action.payload.imtId.startsWith('new_')
			? state.ids.find((id) => state.entities[id].id === action.payload.id) || action.payload.imtId
			: action.payload.imtId;

		const log = state.entities[imtId];
		const { data } = action.payload;

		if (log) {
			log.status = data.status !== undefined ? data.status : log.status;
			log.operations = data.operations !== undefined ? data.operations : log.operations;
			log.duration = data.duration !== undefined ? data.duration : log.duration;
			log.transfer = data.transfer !== undefined ? data.transfer : log.transfer;
			log.instant = data.instant;
			state.entities[action.payload.imtId] = log;
		}

		return state;
	});
};

export const listLogsSuccess = <T extends ScenarioLogModel | DlqsLogModel>(
	ctx: StateContext<CollectionStateModel<T>>,
	logs: Array<T>,
	metadata: ListScenarioLogs['metadata'] | ListDlqsLogs['metadata'],
) => {
	// when ignoreRunning metadata flag is set, don't store running logs
	const payload = metadata?.ignoreRunning
		? logs.filter((l) => (l.type !== ScenarioLogsType.MANUAL && l.type !== ScenarioLogsType.AUTO) || l.status > 0)
		: logs;

	if (metadata.clearStore) {
		const state = ctx.getState();
		state.active = undefined;
		state.cache = {};
		state.lastUpdated = Date.now();
		state.entities = {};
		state.ids = [];
		ctx.setState(state);
	}

	ctx.setState((state) => {
		const { entities } = state;
		let removeActive = metadata?.clearActive;

		payload.forEach((incommingLog) => {
			// ignore incomming log that is in Running status if store has the same  log in a different status
			if (entities[incommingLog.imtId] && entities[incommingLog.imtId].status > 0 && !incommingLog.status) {
				return;
			}

			if (incommingLog.imtId === state.active) {
				removeActive = false;
			}

			const fakeImtId = `new_execution_${incommingLog.id}`;
			// update log's fake imtId from webStreamerLib event if incomming log has the same executionId
			if (
				!entities[incommingLog.imtId] &&
				entities[fakeImtId] &&
				(incommingLog.type === ScenarioLogsType.AUTO || incommingLog.type === ScenarioLogsType.MANUAL)
			) {
				const existingLog = entities[fakeImtId];
				delete entities[fakeImtId];
				state.ids.splice(state.ids.indexOf(fakeImtId), 1);
				entities[incommingLog.imtId] = {
					...existingLog,
					imtId: incommingLog.imtId,
				};
				state.ids.push(incommingLog.imtId);
			} else {
				if (!entities[incommingLog.imtId]) {
					state.ids.push(incommingLog.imtId);
				}

				entities[incommingLog.imtId] = incommingLog;
			}
		});

		if (removeActive && state.active) {
			state.entities[state.active] = null;
			state.ids.splice(state.ids.indexOf(state.active), 1);
		}

		return state;
	});
};

@State<CollectionStateModel<ScenarioLogModel>>({
	name: SCENARIO_LOGS_STATE_TOKEN,
	defaults: getInitialScenariosLogsStateModel(),
})
@Injectable()
export class ScenarioLogsState extends CollectionState<ScenarioLogModel> {
	private service = inject(ScenarioLogsService);

	constructor() {
		super(ScenarioLogsState, 'imtId', IdStrategy.EntityIdGenerator);
	}

	@Action(listScenarioLogs)
	public listLogsRequest(ctx: StateContext<CollectionStateModel<ScenarioLogModel>>, action: ListScenarioLogs) {
		const { params, query } = action.params;
		const request = params.scenarioId
			? this.service.listScenariosLogs$(params.scenarioId, query, query.pg)
			: this.service.listAdminScenariosLogs$(query, query.pg);
		return this.createEffect$(ctx, action, request, 'scenarioLogs', (res) => {
			this.listLogsRequestSuccess(
				ctx,
				res.scenarioLogs.map((log) => ScenarioLogsAdapter.toStore(log, action.params.params.scenarioId)),
				action.metadata,
			);
		});
	}

	@ImmutableContext()
	public listLogsRequestSuccess(
		ctx: StateContext<CollectionStateModel<ScenarioLogModel>>,
		payload: Array<ScenarioLogModel>,
		metadata: ListScenarioLogs['metadata'],
	) {
		listLogsSuccess(ctx, payload, metadata);
	}

	@Action(scenarioExecutionLog)
	public scenarioExecutionLogRequest(
		ctx: StateContext<CollectionStateModel<ScenarioLogModel>>,
		action: ScenarioExecutionLog,
	) {
		const { params } = action.params;
		const request = this.service.executionLog$(params.scenarioId, params.executionId);
		return this.createEffect$(ctx, action, request, 'scenarioLog', (res) => {
			this.upsert(ctx, { payload: ScenarioLogsAdapter.toStore(res.scenarioLog, params.scenarioId) });
			this.setActive(ctx, { payload: res.scenarioLog.imtId });
		});
	}

	@Action(addScenarioLog)
	public addScenarioLog(ctx: StateContext<CollectionStateModel<ScenarioLogModel>>, action: AddScenarioLog) {
		const state = ctx.getState();
		const log = state.entities[state.ids.find((id) => state.entities[id].id === action.payload.id)];

		if (log && action.payload.imtId.startsWith('new_')) {
			this.update(ctx, {
				payload: { id: log.imtId, data: action.payload },
			});
		} else {
			this.createOrReplace(ctx, action);
		}
	}

	@Action(updateScenarioLog)
	@ImmutableContext()
	public updateScenarioLog(ctx: StateContext<CollectionStateModel<ScenarioLogModel>>, action: UpdateScenarioLog) {
		updateLog(ctx, action);
	}

	@Action(dropExecution)
	public dropExecutions(ctx: StateContext<CollectionStateModel<ScenarioLogModel>>, action: DropExecution) {
		this.remove(ctx, action);
	}
}

import { InjectionToken } from '@angular/core';
import { OverlayConfig } from '@angular/cdk/overlay';
import { UiToastMessageType } from './ui-toast-message.interface';

/**
 * Position where toast messages will appear (Y_X)
 */
export enum UiToastMessagePosition {
	TOP_CENTER = 'top-center',
	TOP_LEFT = 'top-left',
	TOP_RIGHT = 'top-right',

	BOTTOM_LEFT = 'bottom-left',
	BOTTOM_RIGHT = 'bottom-right',
	BOTTOM_CENTER = 'bottom-center',

	CENTER = 'top-center',
	CENTER_LEFT = 'center-left',
	CENTER_RIGHT = 'center-right',
}

/**
 * Basic set of configuration options that can be provided via DI and serve as default values if not set when
 * calling `show()` or its variants.
 */
export interface UiToastMessageServiceDefaultOptions {
	/**
	 * Maximum number of toast messages visible.
	 */
	maxToasts: number;
	/**
	 * Default expiration time for all toast message other than danger.
	 */
	expiration: number;
	/**
	 * Default expiration time for danger toast message.
	 */
	errorMessageExpiration: number;
	/**
	 * Position for all toast messages. Use this option ony in very special cases because the default position
	 * is defined in styleguide.
	 */
	position: UiToastMessagePosition;
	positionOffset: string;

	/**
	 * default type of the toast
	 */
	defaultType: UiToastMessageType;
}

/**
 * @todo: add tests
 */
export const UI_TOAST_MESSAGE_DEFAULT_OPTIONS = new InjectionToken<UiToastMessageServiceDefaultOptions>(
	'UI_TOAST_MESSAGE_SERVICE_OPTIONS',
);
export const UI_TOAST_MESSAGE_DEFAULT_MAX_VISIBLE = 5;
export const UI_TOAST_MESSAGE_DEFAULT_EXPIRATION = 3_000;
export const UI_TOAST_MESSAGE_DEFAULT_DANGER_EXPIRATION = 5_000;
export const UI_TOAST_MESSAGE_DEFAULT_POSITION = UiToastMessagePosition.BOTTOM_RIGHT;
export const UI_TOAST_MESSAGE_DEFAULT_POSITION_OFFSET = '32px';
export const UI_TOAST_MESSAGE_DEFAULT_TYPE = UiToastMessageType.BRAND;

/**
 * Injection token used in custom elements providing a function that triggers change detection.
 */
export const UI_TOAST_MESSAGE_CE_UPDATE_VIEW = new InjectionToken<() => void>('UI_TOAST_MESSAGE_CE_UPDATE_VIEW');

/**
 * Injection token used in custom elements adding custom CSS class to the CDK overlay pane element.
 */
export const UI_TOAST_MESSAGE_OVERLAY_PANEL_CLASS = new InjectionToken<OverlayConfig['panelClass']>(
	'UI_TOAST_MESSAGE_OVERLAY_PANEL_CLASS',
);

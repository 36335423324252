import { generateActionObject } from '../internal/internal';
import { EntityActionType, EntitySelector, Updater } from './type-alias';
import { EntityState, EntityType } from '../internal/entity-state';
import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';

export interface EntityUpdateAction<T> {
	payload: {
		id: EntitySelector<T>;
		data: Updater<T>;
	};
}

export class Update<T extends Record<string, any>> {
	/**
	 * Generates an action that will update the current active entity.
	 * If no entity is active a runtime error will be thrown.
	 * @param target The targeted state class
	 * @param id An EntitySelector that determines the entities to update
	 * @param data An Updater that will be applied to the selected entities
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see EntitySelector
	 * @see Updater
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		id: EntitySelector<T extends CollectionStateModel<any> ? EntityType<T> : T>,
		data: Updater<T extends CollectionStateModel<any> ? EntityType<T> : T>,
		context: string,
	) {
		return generateActionObject(EntityActionType.Update, target, { id, data }).setContext(context);
	}
}

import { inject, Injectable } from '@angular/core';
import { debugLogger, ServiceInit } from '@imt-web-zone/core/util-core';
import { Observable, filter, Subscription, distinctUntilChanged, combineLatest } from 'rxjs';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';

const debug = debugLogger.create('chameleon');

export type IdentifyArgs = [string, Record<string, any>];

interface ChameleonConfig {
	token$: Observable<string>;
	identify$: Observable<IdentifyArgs>;
}

@Injectable({
	providedIn: 'root',
})
export class ChameleonService implements ServiceInit {
	private config: ChameleonConfig;
	private identifySub: Subscription;
	private growthBookService = inject(GrowthbookService);

	public async initialize(config: ChameleonConfig) {
		this.config = config;
		combineLatest([this.growthBookService.isOn$('enable-chameleon'), config.token$.pipe(distinctUntilChanged())])
			.pipe(
				filter(([enabled]) => {
					debug(`feature flag ${enabled ? 'enabled' : 'disabled'}`);
					return enabled;
				}),
				filter(([enabled, token]) => {
					// skip if token is empty during the first init
					if (!this.identifySub && !token) {
						return false;
					}
					return true;
				}),
			)
			.subscribe(([, token]) => this.connectChameleon(token));
	}

	public async connectChameleon(token: string) {
		let forceOverride = false;

		// if previously inited, set override to true
		if (this.identifySub) {
			this.identifySub.unsubscribe();
			forceOverride = true;
		}

		const { init, identify } = await this.loadSource();
		const initConfig: { fastUrl?: string; forceOverride?: boolean } = {
			fastUrl: 'https://fast.chameleon.io/',
			forceOverride,
		};
		init(token, initConfig);
		debug('init called', 'id:', token || undefined, 'config:', initConfig);
		this.identifySub = this.config.identify$
			.pipe(
				filter(([id]) => {
					return !!id;
				}),
				distinctUntilChanged((prevUser, currentUser) => {
					return (
						prevUser[0] === currentUser[0] &&
						prevUser[1]['organization']?.id === currentUser[1]['organization']?.id
					);
				}),
			)

			.subscribe(([id, options]) => {
				debug('identify called', 'id:', id, 'options:', options);
				identify(id, options);
			});
	}

	private async loadSource() {
		return await import('@chamaeleonidae/chmln');
	}
}

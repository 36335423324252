import { Injectable, isDevMode, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { TRANSLATE_KEY_PATTERN, Utils, WINDOW, CsrfProtectionService } from '@imt-web-zone/shared/data-access';
import { HttpResponse } from '@angular/common/http';
import {
	NativeFetchInterceptMethod,
	NativeFetchInterceptor,
} from '@imt-web-zone/zone/data-access-native-fetch-interceptor';

declare interface Window {
	jQuery: { ajaxSetup(data: any): void };
	fetch: (...args) => void;
}

interface FormulaErrorResponse {
	detail: string;
	suberrors?: Array<{ message: string }>;
}

@Injectable({
	providedIn: 'root',
})
export class ImtUiFormulaTranslateService {
	private window = inject<Window>(WINDOW);
	private transloco = inject(TranslocoService);
	private fetchInterceptor = inject(NativeFetchInterceptor);
	private csrfProtectionService = inject(CsrfProtectionService);

	private themeProvider?: () => string;

	public initialize(themeProvider: () => string) {
		this.themeProvider = themeProvider;
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		if (!this.window || !this.window.jQuery) {
			return;
		}

		if (this.window.fetch) {
			this.fetchInterceptor.register(async (response) => {
				if (response.headers.has('imt-remote-formula') && response.status === 200) {
					const body = await response.json();
					const data = self.translate(body, self.transloco);

					response.text = () =>
						new Promise<string>((r) => {
							r(JSON.stringify(data));
						});

					response.json = () =>
						new Promise<unknown>((r) => {
							r(data);
						});
				}

				return response;
			}, NativeFetchInterceptMethod.RESPONSE);
		}

		if (this.window.jQuery) {
			this.window.jQuery.ajaxSetup({
				beforeSend: (xhr: any, options: any) => {
					const hashedCsrfToken = this.csrfProtectionService.getHashedToken();

					if (hashedCsrfToken) {
						xhr.setRequestHeader(this.csrfProtectionService.xsrfHeaderName, hashedCsrfToken);
					}

					const originalSuccess = options.success;
					const originalError = options.error;
					options.success = function (data: any, textStatus: string, jqXhr: any) {
						if (!!jqXhr.getResponseHeader('imt-remote-formula') && data.config) {
							data = self.translate(data, self.transloco);
						} else {
							data = self.specialCases(data, self);
						}
						// eslint-disable-next-line prefer-rest-params
						originalSuccess.apply(this, arguments);
					};
					options.error = function (data: any, textStatus: string, jqXhr: any) {
						// if (self.hasCodeTranslation(self.transloco, data?.responseJSON?.code)) {
						// 	data.responseJSON.message =
						//		self.transloco.translate(`errors.${data.responseJSON.code}`);
						// 	data.responseText = JSON.stringify(data.responseJSON);
						// } else {
						// 	data.responseJSON = self.translateError(data.responseJSON);
						// 	data.responseText = JSON.stringify(data.responseJSON);
						// }

						// TODO investigate why RPCs return data only in text form
						if (data.responseText && !data.responseJSON) {
							try {
								data.responseJSON = JSON.parse(data.responseText);
							} catch (err) {
								/**/
							}
						}

						if (data.responseJSON) {
							data.responseJSON = self.translateError(data.responseJSON);
							data.responseText = JSON.stringify(data.responseJSON);
						}
						// eslint-disable-next-line prefer-rest-params
						originalError.apply(this, arguments);
					};
				},
			});
		}
	}

	public interceptResponse(ev: HttpResponse<any>) {
		if (ev.headers.get('imt-remote-formula') === '1' && ev.body && ev.body.config) {
			if (isDevMode()) {
				// saves original response so we can use it in Formula.getInlineTemplate()
				ev.body.__original = JSON.parse(JSON.stringify(ev.body));
			}
			this.translate(ev.body, this.transloco);
		}
	}

	public translate(
		data: {
			config: Array<any>;
			response: Array<any>;
			buttons: Array<any>;
			steps: Array<any>;
			datasets: Array<any>;
		},
		transloco: TranslocoService,
	): any {
		data.response?.forEach((cfg) => this.translateConfigs(cfg, transloco));
		data.config?.forEach((cfg) => this.translateConfigs(cfg, transloco));
		data.buttons?.forEach((cfg) => this.translateConfigs(cfg, transloco));
		data.steps?.forEach((stepData) => this.translate(stepData, transloco));
		data.datasets?.forEach((cfg) => this.translateConfigs(cfg, transloco));
		return data;
	}

	/**
	 *
	 * iterate configs and find and translate keys
	 *
	 */
	private translateConfigs(cfg: any, transloco: TranslocoService) {
		const keys = Object.keys(cfg);
		keys.forEach((key) => this.translateConfigKey(key, cfg, transloco));

		if (cfg.options) {
			if (Array.isArray(cfg.options) && cfg.type === 'select') {
				cfg.options.forEach((option) => {
					if (option.nested && Array.isArray(option.nested)) {
						option.nested.forEach((nested) => this.translateConfigs(nested, transloco));
					}
					if (option.label) {
						this.translateConfigKey('label', option, transloco);
					}
				});
			}

			if (cfg.options.store && Array.isArray(cfg.options.store)) {
				cfg.options.store.forEach((item) => {
					if (item.nested) {
						if (Array.isArray(item.nested)) {
							item.nested.forEach((nested) => this.translateConfigs(nested, transloco));
						} else {
							this.translateConfigs(item.nested, transloco);
						}
					}
				});
			}
		}

		if (cfg.spec && Array.isArray(cfg.spec)) {
			cfg.spec.forEach((spec) => this.translateConfigs(spec, transloco));
		}

		if (cfg.nested) {
			if (Array.isArray(cfg.nested)) {
				cfg.nested.forEach((nested) => this.translateConfigs(nested, transloco));
			} else {
				this.translateConfigs(cfg.nested, transloco);
			}
		}

		if (typeof cfg.validate?.pattern === 'object') {
			this.translateConfigs(cfg.validate.pattern, transloco);
		}
	}

	private translateError(errorJson: FormulaErrorResponse): FormulaErrorResponse {
		if (errorJson && errorJson.detail && errorJson.suberrors) {
			errorJson.detail = Utils.translateString(errorJson.detail, this.transloco);
			errorJson.suberrors = errorJson.suberrors?.map((x) => {
				x.message = Utils.translateString(x.message, this.transloco);
				return x;
			});
		}

		return errorJson;
	}

	private specialCases(data: any, self: any): any {
		// consumption chart (dashboard page)
		if (data && data.type === 'line' && data.data.datasets) {
			const theme = this.themeProvider();
			// change chart config for EMS theme
			if (theme && theme.startsWith('ems')) {
				data.data.datasets.map((s, i) => {
					s.borderColor = ['#3bd7f7', '#06171a'][i] || s.borderColor;
					s.backgroundColor = ['rgba(58,215,247,0.1)', 'rgba(6,23,26,0.1)'][i] || s.backgroundColor;

					if (i < 2) {
						s.fill = true;
					}

					return s;
				});

				data.options.tooltips.backgroundColor = '#fff';
				data.options.tooltips.bodyFontColor = '#000';
				data.options.tooltips.titleFontColor = '#000';
				data.options.tooltips.borderColor = '#efefef';
				data.options.tooltips.borderWidth = 1;
			}

			data.data.datasets = self.translate(data.data, self.transloco).datasets;
		}

		return data;
	}

	private translateConfigKey(key: string, cfg: any, transloco: TranslocoService) {
		if (key === 'options' && typeof cfg[key] === 'object') {
			Object.keys(cfg[key]).forEach((nestedKey) => this.translateConfigKey(nestedKey, cfg[key], transloco));
		} else if (typeof cfg[key] === 'string') {
			const results = cfg[key].match(TRANSLATE_KEY_PATTERN);
			if (results && results.length) {
				let data: Record<string, any>;
				if (cfg.translateData && cfg.translateData[key]) {
					data = cfg.translateData[key];
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const result of results) {
					const exec = new RegExp(TRANSLATE_KEY_PATTERN).exec(cfg[key]);
					cfg[key] = cfg[key].replace(exec[0], transloco.translate(exec[1], data));
				}
			}
		}
	}
}

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Store, ofActionSuccessful, Actions } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import { successActionOf } from '@imt-web-zone/shared/util-store';
import { ApiConfigFacade, ModeEnum } from '@imt-web-zone/zone/state-api-config';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { EnumNames, EnumsModel, loadEnum, EnumsState } from '@imt-web-zone/zone/state-enums';

@Injectable({ providedIn: 'root' })
export abstract class BaseEnumGuard<T extends EnumNames> implements CanActivate, CanActivateChild {
	public loggedIn$ = inject(AuthFacade).userId$;
	protected abstract enumSelection$: Observable<EnumsModel[T]>;
	protected abstract enumName: T;
	protected abstract waitForAuthUser: boolean;
	protected enumKey = 'id';

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(protected store: Store, private actions$: Actions) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.resolveGuard();
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.resolveGuard();
	}

	protected resolveGuard() {
		const loadSuccessful = this.actions$.pipe(
			ofActionSuccessful(successActionOf(loadEnum(this.enumName, this.enumKey))),
			map(() => true),
		);

		return this.enumSelection$.pipe(
			mergeMap((enums) =>
				this.waitForAuthUser
					? this.loggedIn$.pipe(
							filter((id) => !!id),
							map(() => enums),
							take(1),
					  )
					: of(enums),
			),
			switchMap((enumData) => {
				if (!enumData) {
					return this.store
						.dispatch(loadEnum(this.enumName, this.enumKey))
						.pipe(mergeMap(() => loadSuccessful));
				}

				return of(true);
			}),
		);
	}
}

@Injectable({ providedIn: 'root' })
export class ZonesGuard extends BaseEnumGuard<EnumNames.IMT_ZONES> {
	private apiConfigFacade = inject(ApiConfigFacade);
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.IMT_ZONES));
	protected enumName: EnumNames.IMT_ZONES = EnumNames.IMT_ZONES;
	protected waitForAuthUser = true;

	public get apiConfig() {
		return this.apiConfigFacade.configSnapshot;
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.apiConfig?.mode === ModeEnum.MASTER) {
			return of(true);
		}
		return super.resolveGuard();
	}

	public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.apiConfig?.mode === ModeEnum.MASTER) {
			return of(true);
		}
		return super.resolveGuard();
	}
}

@Injectable({ providedIn: 'root' })
export class CountriesGuard extends BaseEnumGuard<EnumNames.COUNTRIES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.COUNTRIES));
	protected enumName: EnumNames.COUNTRIES = EnumNames.COUNTRIES;
	protected waitForAuthUser = false;
}

@Injectable({ providedIn: 'root' })
export class LocalesGuard extends BaseEnumGuard<EnumNames.LOCALES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.LOCALES));
	protected enumName: EnumNames.LOCALES = EnumNames.LOCALES;
	protected waitForAuthUser = false;
}

@Injectable({ providedIn: 'root' })
export class LanguagesGuard extends BaseEnumGuard<EnumNames.LANGUAGES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.LANGUAGES));
	protected enumName: EnumNames.LANGUAGES = EnumNames.LANGUAGES;
	protected waitForAuthUser = false;
}

@Injectable({ providedIn: 'root' })
export class TimezonesGuard extends BaseEnumGuard<EnumNames.TIMEZONES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.TIMEZONES));
	protected enumName: EnumNames.TIMEZONES = EnumNames.TIMEZONES;
	protected waitForAuthUser = false;
}

@Injectable({ providedIn: 'root' })
export class UserFeaturesGuard extends BaseEnumGuard<EnumNames.USER_FEATURES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.USER_FEATURES));
	protected enumName: EnumNames.USER_FEATURES = EnumNames.USER_FEATURES;
	protected enumKey = 'name';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class UserEmailNotificationsGuard extends BaseEnumGuard<EnumNames.USER_EMAIL_NOTIFICATIONS> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.USER_EMAIL_NOTIFICATIONS));
	protected enumName: EnumNames.USER_EMAIL_NOTIFICATIONS = EnumNames.USER_EMAIL_NOTIFICATIONS;
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class ModuleTypesGuard extends BaseEnumGuard<EnumNames.MODULE_TYPES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.MODULE_TYPES));
	protected enumName: EnumNames.MODULE_TYPES = EnumNames.MODULE_TYPES;
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class UserApiTokenScopesGuard extends BaseEnumGuard<EnumNames.USER_API_TOKEN_SCOPES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.USER_API_TOKEN_SCOPES));
	protected enumName: EnumNames.USER_API_TOKEN_SCOPES = EnumNames.USER_API_TOKEN_SCOPES;
	protected enumKey = 'name';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class OrganizationFeaturesGuard extends BaseEnumGuard<EnumNames.ORGANIZATION_FEATURES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.ORGANIZATION_FEATURES));
	protected enumName: EnumNames.ORGANIZATION_FEATURES = EnumNames.ORGANIZATION_FEATURES;
	protected enumKey = 'name';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class AppReviewStatusGuard extends BaseEnumGuard<EnumNames.APP_REVIEW_STATUSES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.APP_REVIEW_STATUSES));
	protected enumName: EnumNames.APP_REVIEW_STATUSES = EnumNames.APP_REVIEW_STATUSES;
	protected enumKey = 'value';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class UserOauthScopesGuard extends BaseEnumGuard<EnumNames.USER_OAUTH_SCOPES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.USER_OAUTH_SCOPES));
	protected enumName: EnumNames.USER_OAUTH_SCOPES = EnumNames.USER_OAUTH_SCOPES;
	protected enumKey = 'id';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class VariableTypesGuard extends BaseEnumGuard<EnumNames.VARIABLE_TYPES> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.VARIABLE_TYPES));
	protected enumName: EnumNames.VARIABLE_TYPES = EnumNames.VARIABLE_TYPES;
	protected enumKey = 'id';
	protected waitForAuthUser = true;
}

@Injectable({ providedIn: 'root' })
export class ConnnectedSystemAppsGuard extends BaseEnumGuard<EnumNames.CONNECTED_SYSTEM_APPS> {
	public enumSelection$ = this.store.select(EnumsState.getEnum(EnumNames.CONNECTED_SYSTEM_APPS));
	protected enumName: EnumNames.CONNECTED_SYSTEM_APPS = EnumNames.CONNECTED_SYSTEM_APPS;
	protected enumKey = 'name';
	protected waitForAuthUser = false;
}

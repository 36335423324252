// TODO: Not all code paths return a value.
// TODO: What to do in a case of a double `//` slash
/** Removes slash at the end of the string if exists */
export function trimLastSlash(value: string) {
	if (!value) {
		return null;
	} else if (value.endsWith('/')) {
		return value.substring(0, value.length - 1);
	}

	// ! This was added because of `Not all code paths return a value.` warning.
	// ! Originally it was not included, but function behaved like that.
	return undefined;
}

/**
 * Default options provided via `DEFAULT_TOOLTIP_CONFIG` token.
 */
export interface UiTooltipConfig {
	inDelay?: number;
	outDelay?: number;
	offset: UiTooltipOffset;
}

/**
 * List of all possible positions for a tooltip. `top`, `right`, `bottom` and `left` have priority and are tested first.
 */
export enum UiTooltipPosition {
	Top = 'top',
	TopRight = 'top-right',
	RightTop = 'right-top',
	Right = 'right',
	RightBottom = 'right-bottom',
	BottomRight = 'bottom-right',
	Bottom = 'bottom',
	BottomLeft = 'bottom-left',
	LeftBottom = 'left-bottom',
	Left = 'left',
	LeftTop = 'left-top',
	TopLeft = 'top-left',
}

export enum UiTooltipArrowPosition {
	Top = 'top',
	TopRight = 'top-right',
	RightTop = 'right-top',
	Right = 'right',
	RightBottom = 'right-bottom',
	BottomRight = 'bottom-right',
	Bottom = 'bottom',
	BottomLeft = 'bottom-left',
	LeftBottom = 'left-bottom',
	Left = 'left',
	LeftTop = 'left-top',
	TopLeft = 'top-left',
}

/**
 * Event that shows the tooltip.
 */
export enum UiTooltipTrigger {
	Hover = 'hover',
	Click = 'click',
	Focus = 'focus',
}

/**
 * Offset from tooltip's anchored element. Properties `x` and `y` always set distance from the anchor element outwards.
 * `vertical` is offset used only for vertically positioned tooltips which means all "top*" and "bottom*" positions.
 * `horizontal` is offset used only for horizontal positioned tooltips which means all "left*" and "right*" positions.
 */
export interface UiTooltipOffset {
	x?: number;
	y?: number;
	vertical?: number;
	horizontal?: number;
}

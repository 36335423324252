import { Injectable } from '@angular/core';

import {
	TRANSLOCO_TRANSPILER,
	TranslocoTranspiler,
	isString,
	isDefined,
	isObject,
	flatten,
	TranspileParams,
} from '@jsverse/transloco';
import { MessageFormatTranspiler } from '@jsverse/transloco-messageformat';

/**
 * Custom Transloco transpiler which allows to use translations with nested params:
 *
 * HTML template:
 * `{{ 'message' | transloco: { key1: { key2: 'Value2' } } }}`
 *
 * i18n JSON:
 * { message: "Translation: {{key1.key2}}" }
 *
 * Output:
 * 'Translation: Value2'
 *
 * ===
 *
 * It also uses same fallback transpilation logic as with ngx-translate when no or invalid params are provided:
 *
 * HTML template:
 * `{{ 'message' | transloco }}`
 *
 * i18n JSON:
 * { message: "Translation: {{key1.key2}}" }
 *
 * Output:
 * 'Translation: {{key1.key2}}'
 *
 */
@Injectable({ providedIn: 'root' })
export class TranslocoNestedParamsTranspiler extends MessageFormatTranspiler implements TranslocoTranspiler {
	public override transpile({ value, params, translation, key }: TranspileParams): any {
		// In case of nested params (e.g. `{ key1: { key2: 'value2' } }`) apply flattening function
		// in order to support expressions like: `Hello {{key1.key2}}`.
		if (isString(value)) {
			const flatParams = isObject(params) ? flatten(params as object) : params;

			return value.replace(this.interpolationMatcher, (substring, match) => {
				match = match.trim();
				if (!!flatParams && isDefined(flatParams[match])) {
					return flatParams[match];
				}

				return isDefined(translation[match])
					? this.transpile({ value, params, translation, key })
					: // When translation is not found return the original string that should have been replaced,
					  // e.g. `{{key1.key2}}`.
					  substring;
			});
		}

		// Remain original logic of `DefaultTranspiler` for all other cases.
		return super.transpile({ value, params, translation, key });
	}
}

export const translocoNestedParamsTranspiler = {
	provide: TRANSLOCO_TRANSPILER,
	useClass: TranslocoNestedParamsTranspiler,
};

import { Injectable, inject } from '@angular/core';
import { CsrfProtectionService } from '@imt-web-zone/shared/data-access';

export enum NativeFetchInterceptMethod {
	REQUEST = 'request',
	RESPONSE = 'response',
}

export type FetchRequestInterceptor = (
	input: RequestInfo | URL,
	init?: RequestInit,
) => Promise<[RequestInfo, RequestInit?]>;
export type FetchResponseInterceptor = (
	res: Response,
	input: RequestInfo | URL,
	init?: RequestInit,
) => Promise<Response>;

@Injectable({
	providedIn: 'root',
})
export class NativeFetchInterceptor {
	private csrfProtection = inject(CsrfProtectionService);

	private interceptors: {
		request: Array<FetchRequestInterceptor>;
		response: Array<FetchResponseInterceptor>;
	} = { request: [], response: [] };

	// this is the list of hostnames without csrf header
	private csrfDomains: string[] = [];

	/**
	 * Registers interceptor for `window.fetch` function
	 * @returns Interceptor id (usefull for unregistering it later)
	 */
	public register(
		interceptor: FetchRequestInterceptor | FetchResponseInterceptor,
		method: NativeFetchInterceptMethod,
	): number {
		// TODO: this surely by typesafe, but I cannot find how to do that
		// for NativeFetchInterceptMethod.REQUEST interceptor always has to be of type FetchRequestInterceptor
		// for NativeFetchInterceptMethod.RESPONSE interceptor always has to be of type FetchResponseInterceptor
		return this.interceptors[method].push(interceptor as any);
	}

	public unregister(id: number, method: NativeFetchInterceptMethod) {
		if (this.interceptors[method][id]) {
			this.interceptors[method].splice(id, 1);
		}
	}

	public initialize(csrfDomains: string[]): void {
		this.csrfDomains = csrfDomains;
		this.modifyFetch();
	}

	private modifyFetch() {
		if (window?.fetch) {
			const originalFetch = window.fetch;
			window.fetch = async (...args) => {
				let [input, init] = args;
				for (const interceptor of this.interceptors.request) {
					[input, init] = await interceptor(input, init);
				}

				if (
					(typeof input === 'string' &&
						this.csrfDomains.some((origin) => input.toString().includes(origin))) ||
					(typeof input === 'object' && this.csrfDomains.includes((input as URL).hostname))
				) {
					(init?.headers as any)[this.csrfProtection.xsrfHeaderName as string] =
						this.csrfProtection.getHashedToken();
				}

				let response = await originalFetch(...args);

				for (const interceptor of this.interceptors.response) {
					response = await interceptor(response, input, init);
				}

				return response;
			};
		}
	}
}

import { toNumber, toString, removeUndefined } from '@imt-web-zone/shared/util-store';
import { DlqsLogApiModel } from './dlqs-logs.interface';
import { DlqsLogModel } from './dlqs-logs.model';

export class DlqsLogsAdapter {
	public static toStore(item: Partial<DlqsLogApiModel>, dlqId: string): DlqsLogModel {
		return removeUndefined(
			new DlqsLogModel(
				item.imtId,
				item.duration,
				item.transfer,
				item.operations,
				toString(item.teamId),
				item.id,
				item.type,
				toString(item.authorId),
				new Date(item.timestamp),
				item.status,
				dlqId,
				item.reason,
				item.instant,
			),
		);
	}

	public static toApi(item: Partial<DlqsLogModel>): DlqsLogApiModel {
		return removeUndefined(
			new DlqsLogApiModel(
				item.imtId,
				item.duration,
				item.transfer,
				item.operations,
				toNumber(item.teamId),
				item.id,
				item.type,
				toNumber(item.authorId),
				new Date(item.timestamp),
				item.status,
				item.reason,
				item.instant,
			),
		);
	}
}

import { ConnectedPosition } from '@angular/cdk/overlay';

export const dropdownPositions: ConnectedPosition[] = [
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
	},
	{
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
	},
	{
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'bottom',
	},
	{
		originX: 'start',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'bottom',
	},
];

import { Output, EventEmitter, Input, HostListener, TemplateRef, Directive } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImtModalRef, ImtModalOptions } from '@imt-web-zone/shared/model';
import { ImtUiModalService } from './imt-ui-modal.service';

export enum DismissReason {
	CrossClick = 'CROSS_CLICK',
	BtnClick = 'BTN_CLICK',
}

const DEFAULT_OPTIONS: ImtModalOptions = {
	backdrop: 'static',
};

/**
 * Example of usage:
 * <example-url>/demo/modal</example-url>
 */

@Directive() // tslint:disable-next-line:directive-class-suffix
export abstract class ImtUiModalBase {
	/**
	 * Modal dissmiss reason
	 */
	@Input() public reason: DismissReason = DismissReason.CrossClick;

	@Input() public headerText: string | null = null;
	@Input() public bodyText: string | null = null;

	@Input() public hideHeader = false;
	@Input() public hideBody = false;
	@Input() public hideFooter = false;

	@Output() public dismissed: EventEmitter<unknown> = new EventEmitter();
	@Output() public closed: EventEmitter<unknown> = new EventEmitter();

	protected abstract modalRef?: NgbActiveModal;
	protected isOpen = false;

	private disableBackdropClick = false;
	private waitingForMouseUp = false;

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(protected modalService: ImtUiModalService) {}

	@HostListener('window:mousedown', ['$event'])
	public onMouseDown(event: MouseEvent): void {
		if (!this.disableBackdropClick && this.isBackdropClick(event)) {
			this.waitingForMouseUp = true;
		}
	}

	@HostListener('window:mouseup', ['$event'])
	public onMouseUp(event: MouseEvent): void {
		if (!this.disableBackdropClick && this.waitingForMouseUp && this.isBackdropClick(event)) {
			this.close(ModalDismissReasons.BACKDROP_CLICK);
		}

		this.waitingForMouseUp = false;
	}

	public onDismiss(reason?: DismissReason): void {
		this.isOpen = false;
		if (this.modalRef) {
			this.modalRef.dismiss(reason);
		}
		this.dismissed.emit(reason);
	}

	public onClose(result?: ModalDismissReasons | unknown): void {
		this.isOpen = false;
		if (this.modalRef) {
			this.modalRef.close(result);
		}
		this.closed.emit(result);
	}

	public close(reason?: ModalDismissReasons): void {
		this.isOpen = false;
		if (this.modalRef) {
			this.modalRef.close(reason);
		}
	}

	protected openModal(template: TemplateRef<ImtUiModalBase>, options?: ImtModalOptions): ImtModalRef {
		this.isOpen = true;
		if (options && options.backdrop === 'static') {
			this.disableBackdropClick = true;
		}
		return this.modalService.open(template, { ...DEFAULT_OPTIONS, ...options });
	}

	private isBackdropClick(event: MouseEvent): boolean {
		return this.isOpen && (event.target as HTMLElement).nodeName === 'NGB-MODAL-WINDOW';
	}
}

import { Action, State, StateContext } from '@ngxs/store';
import { getInitialImtInspectorModel, ImtInspectorModel } from './imt-inspector.model';
import { clearImtInspectorData, PatchImtInspectorData, patchImtInspectorData } from './imt-inspector.actions';
import { Injectable } from '@angular/core';
import { IMT_INSPECTOR_STATE_TOKEN } from './imt-inspector.symbols';

@State<ImtInspectorModel>({
	name: IMT_INSPECTOR_STATE_TOKEN,
	defaults: getInitialImtInspectorModel(),
})
@Injectable()
export class ImtInspectorState {
	@Action(patchImtInspectorData)
	public patchInspectorData(
		{ patchState, getState }: StateContext<ImtInspectorModel>,
		action: PatchImtInspectorData,
	) {
		const state = getState() || {};
		patchState({ ...state, ...action.payload });
	}

	@Action(clearImtInspectorData)
	public clearInspectorData({ setState }: StateContext<ImtInspectorModel>) {
		setState(() => null);
	}
}

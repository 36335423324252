<div class="dmo-mt-2">
	<i
		class="dmo-text-base dmo-leading-none dmo-icon"
		data-testid="static-message-icon"
		[ngClass]="{
			'dmo-fa-solid': type !== UiStaticMessageType.DANGER,
			'before:dmo-fa-circle-info': type === UiStaticMessageType.INFO || type === UiStaticMessageType.BRAND,
			'before:dmo-fa-circle-check': type === UiStaticMessageType.SUCCESS,
			'before:dmo-fa-hexagon-exclamation': type === UiStaticMessageType.WARNING,
			'domino-icon-error-circle-solid': type === UiStaticMessageType.DANGER,
		}"
	></i>
</div>
<div class="dmo-flex-grow dmo-text-xs dmo-leading-4 dmo-mt-4" data-testid="static-message-content">
	<header *ngIf="title" class="dmo-font-bold" data-testid="static-message-title">{{ title }}</header>
	<div>
		<ng-content></ng-content>
	</div>
	<ul *ngIf="bulletPoints && bulletPoints.length > 0" class="dmo-mt-16 dmo-mb-0 dmo-list-disc dmo-ml-24">
		<li *ngFor="let point of bulletPoints">{{ point }}</li>
	</ul>

	<div
		*ngIf="buttonLabel"
		class="dmo-mt-16"
		[ngClass]="{
			'min-[320px]:dmo-hidden': buttonPosition === UiStaticMessageButtonPosition.RIGHT
		}"
	>
		<ng-container *ngTemplateOutlet="button; context: { testid: 'static-message-cta-bottom' }"></ng-container>
	</div>
</div>
<div *ngIf="buttonLabel && buttonPosition === UiStaticMessageButtonPosition.RIGHT" class="max-[320px]:dmo-hidden">
	<ng-container *ngTemplateOutlet="button; context: { testid: 'static-message-cta-right' }"></ng-container>
</div>
<div *ngIf="showCloseButton" class="dmo-pl-4">
	<button (click)="close(); $event.preventDefault()" data-testid="static-message-close-btn">
		<i class="dmo-far before:dmo-fa-xmark dmo-text-base icon"></i>
	</button>
</div>

<ng-template #button let-testid="testid">
	<dmo-notification-button (click)="buttonClicked.emit()" [type]="buttonType" [attr.data-testid]="testid">
		{{ buttonLabel }}
	</dmo-notification-button>
</ng-template>

import { ChangeDetectionStrategy, Component, Input, OnInit, inject, AfterViewInit, HostListener } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ImtUiModalActive } from '@imt-web-zone/shared/ui-modal';
import { UiButtonType } from '@imt-web-zone/make-design-system/ui-button';

interface MappedData {
	id: string;
	name: string;
	link: Array<string>;
	entity: Entities;
	zoneDomain?: string;
}

enum Entities {
	SCENARIO = 0,
	ORGANIZATION = 1,
	SDK_APP = 2,
	FUNCTION = 3,
}

export enum EntityTypes {
	ORGANIZATION = 'organization',
	TEAM = 'team',
}

interface BulletListConfig {
	messageAboveBullets: string;
	messageBelowBullets: string;
	title: string;
	bullets: Array<string>;
}

@Component({
	selector: 'prompt',
	templateUrl: './prompt.component.html',
	styleUrls: ['./prompt.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptComponent implements OnInit, AfterViewInit {
	public activeModal = inject(ImtUiModalActive);
	private transloco = inject(TranslocoService);

	@Input() public teamId: string;
	@Input() public title: string;
	@Input() public metadata: Array<{
		type: string;
		title?: string;
		data?: Array<{ [key: string]: any }>;
		textdata?: { [keys: string]: any };
		checkbox: Array<{ name: string; description: string }>;
		binaryRadio?: {
			name: string;
			descriptionTrue: string;
			descriptionFalse: string;
		};
		mappedData?: Array<MappedData>;
		normalTextFormat?: boolean;
	}>;
	@Input() public hideConfirm: boolean;
	@Input() public btnPrimaryText: string = null;
	@Input() public btnPrimaryClass = 'btn-primary';
	@Input() public disableBtnPrimary = false;
	@Input() public scrollableBody = false;
	@Input() public deleteEntity: {
		name?: string;
		type?: EntityTypes;
	};
	@Input() public btnSecondaryText: string = null;
	public entityInput: string;
	public checkboxValues: { [key: string]: boolean } = {};

	public bulletListConfig?: BulletListConfig;
	protected readonly EntityTypes = EntityTypes;
	protected readonly MkUiButtonType = UiButtonType;
	public expandedEntities = false;

	public ngOnInit() {
		if (this.metadata) {
			for (const data of this.metadata) {
				if (Array.isArray(data.data)) {
					data.mappedData = (data.data || []).map((d) => {
						let id: string;
						let name: string;
						let entity: Entities;
						let link: Array<string>;
						let zoneDomain: string;
						let type: string;
						switch (data.type) {
							case 'userDelOrganizations':
							case 'userOrgRemove':
							case 'userSetFreeSubscriptionCancellation':
								id = d['organizationId'];
								name = d['organizationName'];
								link = this.appendAdminUrlPath(['organization', id]);
								zoneDomain = d['zoneDomain'];
								entity = Entities.ORGANIZATION;
								break;
							case 'missingappsconfirm':
								id = d['name'];
								name = d['label'];
								link = this.appendAdminUrlPath(['apps', id, d['version'], 'base']);
								entity = Entities.SDK_APP;
								break;

							case 'usedfunctions':
								id = d['id'];
								name = d['name'];
								link = [this.teamId, 'functions', id];
								entity = Entities.FUNCTION;

								break;
							default: {
								id = d['scenarioId'];
								name = d['scenarioName'];
								type = d['type'];

								if (type === 'scenario' || !type) {
									if (this.teamId && !window.location.pathname.startsWith('/admin')) {
										link = [this.teamId, 'scenarios', id, 'edit'];
									} else {
										link = ['admin', 'scenarios', id];
									}
									if (d['zoneDomain']) {
										zoneDomain = d['zoneDomain'];
									}
									entity = Entities.SCENARIO;
								}
							}
						}
						return { id, name, link, entity, zoneDomain };
					});

					this.scrollableBody = data?.textdata?.scenarioCount > 3;

					if (data.type === 'userDelOrganizations') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
					} else if (data.type === 'leaveOrgRemoveAccounts') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`organizations.leaveOrganization`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
						this.btnPrimaryClass = 'btn-danger';
						this.disableBtnPrimary = true;
					} else if (data.type === 'userOrgRemoveAccounts') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`organizations.removeUser`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
						this.btnPrimaryClass = 'btn-danger';
						this.disableBtnPrimary = true;
					} else if (data.type === 'leaveTeamRemoveAccounts') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`teams.leaveTeam`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
						this.btnPrimaryClass = 'btn-danger';
						this.disableBtnPrimary = true;
					} else if (data.type === 'userTeamRemoveAccounts') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`teams.removeUser`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
						this.btnPrimaryClass = 'btn-danger';
						this.disableBtnPrimary = true;
					}
				} else {
					const dataType = data.type;

					if (dataType === 'multidelHooks') {
						this.title = this.transloco.translate('hooks.multidel');
						this.btnPrimaryText = this.transloco.translate('common.delete');
						this.btnSecondaryText = this.transloco.translate('common.cancel');
					} else if (dataType === 'bulletedList') {
						this.title = this.transloco.translate(`prompts.${(data.data as BulletListConfig).title}`);
						this.btnPrimaryText = this.transloco.translate('common.save');
						this.btnSecondaryText = this.transloco.translate('common.cancel');
						this.bulletListConfig = {
							title: this.title,
							bullets: (data.data as BulletListConfig).bullets,
							messageAboveBullets: `prompts.${(data.data as BulletListConfig).messageAboveBullets}`,
							messageBelowBullets: `prompts.${(data.data as BulletListConfig).messageBelowBullets}`,
						};
					} // TODO: handle customization of the prompts https://integromat.atlassian.net/browse/UP-314
					else if (dataType === 'deleteOrganization' || dataType === 'deleteTeam') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText =
							this.deleteEntity.type === EntityTypes.ORGANIZATION
								? this.transloco.translate('organizations.delete')
								: this.transloco.translate('teams.delete');
						this.btnPrimaryClass = 'btn-danger';
						this.disableBtnPrimary = true;
						this.btnSecondaryText = this.transloco.translate('common.cancel');
					} else if (dataType === 'turnOffSso') {
						this.btnPrimaryText = this.transloco.translate('organizations.disableSso');
						this.btnSecondaryText = this.transloco.translate('common.cancel');
					} else if (dataType === 'activateAnotherCertificate') {
						this.title = this.transloco.translate(`organizations.activateCertificate`);
						this.btnPrimaryText = this.transloco.translate(`common.activate`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
					} else if (data.type === 'leaveOrganization') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`organizations.leaveOrganization`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
					} else if (data.type === 'removeOrgUser') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`organizations.removeUser`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
					} else if (data.type === 'removeTeamUser') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`teams.removeUser`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
					} else if (data.type === 'leaveTeam') {
						this.title = this.transloco.translate(`prompts.pleaseConfirm`);
						this.btnPrimaryText = this.transloco.translate(`teams.leaveTeam`);
						this.btnSecondaryText = this.transloco.translate(`common.cancel`);
					}
				}
			}
		}
	}

	public ngAfterViewInit() {
		if (this.scrollableBody) {
			this.calculateHeightForModalBody();
		}
	}

	public text(key: string, data: Record<string, unknown> = {}): string {
		return this.transloco.translate(`prompts.${key}`, data);
	}

	public getEntityIcon(entity: Entities) {
		switch (entity) {
			case Entities.ORGANIZATION:
				return 'far fa-fw fa-users';
			case Entities.FUNCTION:
				return 'fal fa-fw fa-function';

			default:
				return 'far fa-share-alt';
		}
	}

	public footerText(type: string) {
		switch (type) {
			case 'usedfunctions':
				return 'prompts.usedfunctionsFooter';
		}

		return null;
	}

	public onClose(e: any) {
		this.activeModal.close(this.checkboxValues);
	}

	public onDismissed(e: any) {
		this.deleteEntity = null;
		this.activeModal.dismiss();
	}

	private appendAdminUrlPath(url: Array<any>) {
		if (window.location.pathname.startsWith('admin')) {
			url.unshift('admin');
		}
		return url;
	}

	public onInputChange(value: string) {
		this.disableBtnPrimary = this.deleteEntity ? value !== this.deleteEntity.name : false;
	}

	public expandEntities() {
		this.expandedEntities = true;
	}

	@HostListener('window:resize')
	public onWinResize() {
		if (this.scrollableBody) {
			this.calculateHeightForModalBody();
		}
	}

	private calculateHeightForModalBody() {
		const modalDialog = document.querySelector('.modal-dialog');
		const modalBody = modalDialog.querySelector('.modal-body') as HTMLElement;
		const computedStyle = window.getComputedStyle(modalDialog);

		const calculatedHeight =
			document.body.offsetHeight -
			(modalDialog.querySelector('.modal-header') as HTMLElement)?.offsetHeight -
			(modalDialog.querySelector('.modal-footer') as HTMLElement)?.offsetHeight -
			parseFloat(computedStyle.marginTop) -
			parseFloat(computedStyle.marginBottom);

		modalBody.style.maxHeight = `${calculatedHeight}px`;
		modalBody.style.overflowY = 'auto';
	}
}

import { inject } from '@angular/core';

import { translocoConfig } from '@jsverse/transloco';

import { TRANSLOCO_AVAILABLE_LANGS } from './transloco.symbol';

/**
 * Factory function for providing `translocoConfig` which injects available languages currently
 * set in the application (`TRANSLOCO_AVAILABLE_LANGS`).
 * Use it once in app root (module) and do not forget to provide `TRANSLOCO_AVAILABLE_LANGS`.
 */
export const getTranslocoConfig = (config: Parameters<typeof translocoConfig>[0]) => {
	return () => {
		const availableLangs = inject(TRANSLOCO_AVAILABLE_LANGS) || [];

		return translocoConfig({
			availableLangs,
			fallbackLang: 'en',
			missingHandler: {
				// It will use the first language set in the `fallbackLang` property
				useFallbackTranslation: true,
			},
			...config,
		});
	};
};

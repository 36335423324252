import { createSelector, Selector } from '@ngxs/store';
import { ApiConfigData } from './api-config.interface';
import { API_CONFIG_STATE_TOKEN } from './api-config-base.state';

export class ApiConfigSelectors {
	/**
	 * Selector function that checks if current zone should show example sdk apps or not.
	 * Since example zones are available only on eu1 zones, we are hiding them from other zones.
	 * We still want to see them on localhost domains.
	 * @returns true / false if zone should contain example apps or not.
	 */
	public static isExampleZone() {
		return createSelector([API_CONFIG_STATE_TOKEN], (apiConfig) => {
			const zone = apiConfig.zone;

			if (zone.includes('celonis')) {
				return false;
			}

			const zonePrefix = zone.split('.')[0];
			return ['eu1', 'slave1', 'master', 'development', 'staging'].includes(zonePrefix);
		});
	}

	@Selector([API_CONFIG_STATE_TOKEN])
	public static apiTokens(apiConfig: ApiConfigData): ApiConfigData['tokens'] {
		return apiConfig?.tokens;
	}

	@Selector([API_CONFIG_STATE_TOKEN])
	public static apiConfig(apiConfig: ApiConfigData) {
		return apiConfig;
	}
}

import { ChangeDetectorRef, Type, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Actions, StateToken, Store } from '@ngxs/store';

import { createFacadeStoreProxy } from './state-facade.store';

export abstract class StateBaseFacadeAbstractClass {
	protected store = createFacadeStoreProxy(inject(Store));
	protected actions$ = inject(Actions);

	/**
	 *
	 *
	 * Returns data from the store as a snapshot.
	 * Internally it will call `markForCheck()` each time once selector is emitted.
	 *
	 *
	 * example:
	 *
	 * @Component({ ... })
	 * export class HooksComponent {
	 * 	  @SelectRxSnapshot() public hooks = this.hooksFacade.hooks;
	 *
	 *
	 * @Injectable({ providedIn: 'root' })
	 * export class HooksFacade extends StateFacadeAbstract {
	 * 	  public hooks = this.rxSnapshotFn(CollectionState.getEntities);
	 *
	 */
	protected rxSnapshotFn<T>(selector: (state: any, ...states: Array<any>) => T): T;
	protected rxSnapshotFn<T>(selector: string | Type<any>): T;
	protected rxSnapshotFn<T>(selector: StateToken<T>): T;
	protected rxSnapshotFn<T>(selector: unknown): () => T {
		return () => {
			const cdr = inject(ChangeDetectorRef);
			this.store
				.select$(selector as StateToken<T>)
				.pipe(takeUntilDestroyed())
				.subscribe((res) => {
					cdr.markForCheck();
				});

			return (() => {
				return this.store.selectSnapshot(selector as StateToken<T>) as T;
			}) as unknown as T;
		};
	}
}

import { getGuardOptionsSymbol, EntityGuardRunAfter, TruthySelector } from './internals';

export interface GuardOptionsInterface {
	runAfter$?: EntityGuardRunAfter[] | EntityGuardRunAfter;
	/**
	 * @deprecated use runAfter$ with the observable from the facade - see hooksFacade.loadHookList$()
	 */
	runAfter?: TruthySelector | TruthySelector[];
}

/**
 *
 * decorator which can configure methods that are used in the EntityGuard
 */
export function EntityGuardOptions(options: GuardOptionsInterface = {}) {
	return (target: any, key: string) => {
		target[getGuardOptionsSymbol(key)] = options;
	};
}

import { Router } from '@angular/router';
import { inject, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@jsverse/transloco';
import { ORGANIZATIONS_STATE_TOKEN } from '@imt-web-zone/shared/data-access';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { formatDate } from '@angular/common';

import { SCENARIOS_STATE_TOKEN } from '@imt-web-zone/zone/state-scenarios';

import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { TeamsFacade } from '@imt-web-zone/zone/state-teams';
import { EnumsState, EnumNames } from '@imt-web-zone/zone/state-enums';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';

interface FormanImlOptions {
	tabs?: Array<{
		icon: string;
		name: string;
		label?: string;
		position?: number;
		extraElement?: HTMLElement | string;
	}>;
	variables?: Array<{
		tab: string;
		category: string;
		label: string;
		items: { [key: string]: any };
		color?: string;
		backgroundColor?: string;
	}>;
	functions?: Array<{
		tab: string;
		category: string;
		label?: string;
		items: { [key: string]: any };
		color?: string;
		backgroundColor?: string;
	}>;
}

// formerly named FormulaWrapper
@Injectable({
	providedIn: 'root',
})
export class FormulaInspectorWrapper {
	private injector = inject(Injector);

	private _formula: any;

	private router: Router;
	private renderer: Renderer2;
	private store: Store;
	private transloco: TranslocoService;
	private apiConfigFacade = inject(ApiConfigFacade);
	private authFacade = inject(AuthFacade);
	private teamsFacade = inject(TeamsFacade);
	private gbService = inject(GrowthbookService);

	constructor() {
		this.router = this.injector.get(Router);
		this.renderer = this.injector.get(RendererFactory2).createRenderer(null, null);
		this.store = this.injector.get(Store);
		this.transloco = this.injector.get(TranslocoService);
	}

	public apply() {
		this._formula = window['Formula'];

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		window['Formula'] = function (...args) {
			const formula = new self._formula(...args);
			const apiConfig = self.apiConfigFacade.configSnapshot;
			formula.form.meta.imlOptions = self.getCustomItems();

			if (self.gbService.isOn('copilot-coder-panel-enabled')) {
				formula.form.meta.imlOptions.tabs.push({
					icon: 'fa-sparkles',
					name: 'ai',
					extraElement: '<div class="focusable" id="ai-coder-panel"></div>',
					position: 0,
				});
			}

			// TODO: replace this with used packages only once decouple-modules branch is merged
			if (window['Repository']?.packages ?? window['Inspector']?.instance?.usedPackages) {
				formula.form.meta.appRepositoryPackages =
					window['Repository']?.packages ?? window['Inspector'].instance?.usedPackages;
			}

			if (window['imt']?.features?.dynamicConnections && self.organization?.license?.dynamicConnections) {
				formula.form.options = {
					dynamicConnections: true,
					personalConnections:
						self.organization?.license?.personalConnections &&
						apiConfig.generalSettings.personalConnectionsEnabled,
				};

				const btvData = window['Inspector']?.instance?.scenario?._io?.buildTimeValues;
				if (btvData) {
					formula.form.meta.imlOptions.buildTimeVariables = btvData;
				}

				const createInput = (
					data: {
						inputName: string;
						account: string | number;
						inputType: string;
						asDefault: boolean;
						defaultAccount: string | number;
						connectionTypes: Array<{ type: string; scope: Array<string> }>;
					},
					inputToDelete: string = null,
				) => {
					const scenarioIo = window['Inspector']?.instance?.scenario?._io;
					const defaultAcc = data.asDefault ? data.account : data.defaultAccount;

					if (inputToDelete) {
						scenarioIo.spec.input = scenarioIo.spec.input.filter((x) => x.name !== inputToDelete);
					}

					if (scenarioIo.spec.input) {
						scenarioIo.spec.input.push({
							default: defaultAcc,
							label: '',
							name: data.inputName,
							type: data.inputType,
							scope: data.connectionTypes?.length > 0 ? data.connectionTypes[0].scope : [],
						});
					}

					scenarioIo.buildTimeValues[data.inputName] = !isNaN(+data.account)
						? 'TAC_' + data.account
						: data.account;
				};

				formula.addEventListener('accountScenarioInputCreated', (e) => {
					createInput(e.detail);
					window['Designer']?.instance?._updateIOLight();
				});

				formula.addEventListener('accountScenarioInputEdited', (e) => {
					createInput(e.detail, e.detail.originalInputName);
				});
			}

			return formula;
		} as any;
	}

	private get scenario() {
		return this.store.selectSnapshot(CollectionState.getActive(SCENARIOS_STATE_TOKEN));
	}

	private get zone() {
		return this.apiConfigFacade.configSnapshot?.zone;
	}

	private get team() {
		return this.teamsFacade.activeSnapshot;
	}

	private get organization() {
		return this.store.selectSnapshot(CollectionState.getActive(ORGANIZATIONS_STATE_TOKEN));
	}

	private get locale() {
		return this.authFacade.userSnapshot?.locale;
	}

	private getCustomFunctions() {
		const snapshot = this.store.snapshot();
		const functions = snapshot['functions'];
		const router = snapshot['router'];
		if (!functions?.entities || router.state.url.includes('templates')) {
			return {};
		}

		return Object.values(functions.entities).reduce((result, fn: any) => {
			const args = fn.args.match(/\(\s*([^)]+?)\s*\)/);
			const match = args ? args[1] : null;

			result[fn.name] = {
				custom: true,
				description: fn.description,
				args: match || '',
				group: 'general',
				name: fn.name,
				type: '*',
				isSystem: false,
			};

			return result;
		}, {});
	}

	private getCustomItems(): FormanImlOptions {
		const apiConfig = this.apiConfigFacade.configSnapshot;
		if (apiConfig.generalSettings.variablesEnabled || apiConfig.generalSettings.scenarioInputsEnabled) {
			return {
				tabs: [
					{
						icon: 'fa-brackets-curly',
						name: 'integromat',
						label: this.transloco.translate('variables.integromat.label'),
						extraElement: this.getMissingLicenseElm(),
					},
				],
				variables: [
					{
						tab: 'integromat',
						category: 'scenario_inputs',
						label: this.transloco.translate('variables.integromat.customVariablesLabels.scenarioInputs'),
						color: '#ffffff',
						backgroundColor: '#01bfd1',
						items: this.getScenarioInputs(),
					},
					{
						tab: 'integromat',
						category: 'scenario_variables',
						label: this.transloco.translate('variables.integromat.customVariablesLabels.scenarioVariables'),
						color: '#ffffff',
						backgroundColor: '#4e0092',
						items: this.getIMLCategory('scenario_variables'),
					},
					{
						tab: 'integromat',
						category: 'team_variables',
						label: this.transloco.translate('variables.integromat.customVariablesLabels.teamVariables'),
						color: '#ffffff',
						backgroundColor: '#6d00cc',
						items: this.getIMLCategory('team_variables'),
					},
					{
						tab: 'integromat',
						category: 'organization_variables',
						label: this.transloco.translate(
							'variables.integromat.customVariablesLabels.organizationVariables',
						),
						color: '#ffffff',
						backgroundColor: '#ad50ff',
						items: this.getIMLCategory('organization_variables'),
					},
				],
				functions: [
					{
						tab: 'general',
						category: 'functions',
						color: '#333333',
						backgroundColor: '#dedede',
						items: this.getCustomFunctions(),
					},
				],
			};
		}

		return {};
	}

	public getIMLCategory(category: string) {
		category = category.toUpperCase();
		switch (category) {
			case 'SCENARIO_INPUTS':
				return this.getScenarioInputs();
			case 'SCENARIO_VARIABLES':
				return this.getScenarioVariables();
			case 'TEAM_VARIABLES':
				return this.getVariables('teamVariables');
			case 'ORGANIZATION_VARIABLES':
				return this.getVariables('organizationVariables');
		}
	}

	private getVariables(type: 'teamVariables' | 'organizationVariables') {
		const variables = this.store.snapshot()[type];
		if (!variables?.entities) {
			return {};
		}
		const variableTypes = this.store.selectSnapshot(EnumsState.getEnumMap(EnumNames.VARIABLE_TYPES));
		return Object.values(variables.entities).reduce((result, variable: any) => {
			let value = variable.value;

			if (variableTypes[variable.typeId]?.name === 'date') {
				value = formatDate(value, 'medium', this.locale);
			}

			result[variable.name] = {
				type: variableTypes[variable.typeId]?.name || 'text',
				name: `{{var.${type === 'teamVariables' ? 'team' : 'organization'}.${variable.name}}}`,
				label: this.getVariableLabel(type, variable.name, variable.label || variable.name),
				help: this.getVariableHelp(type, variable.name),
				group: 'integromat',
				isSystem: variable.isSystem,
				value,
			};
			return result;
		}, {});
	}

	private getScenarioInputs() {
		const inputs = window['Inspector']?.instance?.scenario?._io?.spec?.input;
		if (inputs) {
			const result = {};
			inputs.forEach((input) => {
				result[input.name] = {
					group: 'integromat',
					name: `{{var.input.${input.name}}}`,
					type: input.type,
					value: '',
					isSystem: true,
				};
			});

			return result;
		}

		return {};
	}

	private getScenarioVariables() {
		return {
			id: {
				type: 'number',
				name: '{{var.scenario.id}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'id'),
				help: this.getVariableHelp('scenarioVariables', 'id'),
				isSystem: true,
				value: this.scenario?.id,
			},
			name: {
				type: 'text',
				name: '{{var.scenario.name}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'name'),
				help: this.getVariableHelp('scenarioVariables', 'name'),
				isSystem: true,
				value: this.scenario?.name,
			},
			url: {
				type: 'text',
				name: '{{var.scenario.url}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'url'),
				help: this.getVariableHelp('scenarioVariables', 'url'),
				isSystem: true,
				value:
					this.scenario && this.team
						? `https://${this.zone}/${this.team.id}/scenarios/${this.scenario?.id}`
						: null,
			},
			executionId: {
				type: 'text',
				name: '{{var.scenario.executionId}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'executionId'),
				help: this.getVariableHelp('scenarioVariables', 'executionId'),
				isSystem: true,
			},
			operationsConsumed: {
				type: 'number',
				name: '{{var.scenario.operationsConsumed}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'operationsConsumed'),
				help: this.getVariableHelp('scenarioVariables', 'operationsConsumed'),
				isSystem: true,
			},
			dataConsumed: {
				type: 'number',
				name: '{{var.scenario.dataConsumed}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'dataConsumed'),
				help: this.getVariableHelp('scenarioVariables', 'dataConsumed'),
				isSystem: true,
			},
			executionStartedAt: {
				type: 'date',
				name: '{{var.scenario.executionStartedAt}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'executionStartedAt'),
				help: this.getVariableHelp('scenarioVariables', 'executionStartedAt'),
				isSystem: true,
			},
			isDLQExecution: {
				type: 'boolean',
				name: '{{var.scenario.isDLQExecution}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'isDLQExecution'),
				help: this.getVariableHelp('scenarioVariables', 'isDLQExecution'),
				isSystem: true,
			},
			executionType: {
				type: 'text',
				name: '{{var.scenario.executionType}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'executionType'),
				help: this.getVariableHelp('scenarioVariables', 'executionType'),
				isSystem: true,
			},
			executionUrl: {
				type: 'text',
				name: '{{var.scenario.executionUrl}}',
				group: 'integromat',
				label: this.getVariableLabel('scenarioVariables', 'executionUrl'),
				help: this.getVariableHelp('scenarioVariables', 'executionUrl'),
				isSystem: true,
			},
		};
	}

	private getVariableLabel(category: string, variable: string, fallback?: string): string {
		const key = `variables.integromat.customVariables.${category}.${variable}.label`;
		const result = this.transloco.translate(key);

		if (result === key && !!fallback) {
			return fallback;
		}

		return result;
	}

	private getVariableHelp(category: string, variable: string): string {
		const key = `variables.integromat.customVariables.${category}.${variable}.help`;
		const result = this.transloco.translate(key);
		if (result === key) {
			return null;
		}

		return result;
	}

	private getMissingLicenseElm(): HTMLElement {
		const org = this.store.selectSnapshot(CollectionState.getActive(ORGANIZATIONS_STATE_TOKEN));

		if (!org) {
			return;
		}

		const license = org.license;
		const apiConfig = this.apiConfigFacade.configSnapshot;
		if (license && !license.customVariables && this.apiConfigFacade.isSlave) {
			const ad = this.createElm(`div.alert.alert-primary.variables-license-panel`);
			const header = this.createElm(`div.d-flex.justify-content-between`);

			header.appendChild(this.createElm('strong', `variables.integromat.customVariablesMissing.title`));

			const hideBtn = this.createElm(`button.close`);
			hideBtn.appendChild(this.createElm(`i.far.fa-times`));
			header.appendChild(hideBtn);

			hideBtn.onclick = () => {
				ad.classList.add('d-none');
			};

			ad.appendChild(header);
			ad.appendChild(
				this.createElm('p.mt-2', 'variables.integromat.customVariablesMissing.description', {
					helpUrl: apiConfig.generalSettings.helpRoot + '/functions/variables#custom-variables-1529751',
				}),
			);

			const btn = this.createElm(
				'button.btn.btn-sm.btn-primary',
				'variables.integromat.customVariablesMissing.btn',
			);
			btn.onclick = () => {
				this.router.navigate(['organization', org.id, 'subscription']);
			};

			ad.appendChild(btn);
			return ad;
		}

		return null;
	}

	private createElm(commands: string, text: string = null, interpolateParams = {}): HTMLElement {
		const parts = commands.split('.');
		const type = parts[0];
		const classes = parts.length > 1 ? parts.slice(1) : [];
		const elm = this.renderer.createElement(type);
		classes.forEach((cls) => {
			this.renderer.addClass(elm, cls);
		});

		if (text) {
			this.renderer.setProperty(elm, 'innerHTML', this.transloco.translate(text, interpolateParams));
		}

		return elm;
	}
}

import { Component, ViewChild, TemplateRef, Input, inject } from '@angular/core';
import { ImtUiModalBase } from './imt-ui-modal-base.component';
import { ImtUiTheme, ImtModalRef, ImtModalOptions } from '@imt-web-zone/shared/model';
import { ImtUiModalService } from './imt-ui-modal.service';

export enum ModalType {
	Normal,
}

/**
 * Example of usage:
 * <example-url>/demo/modal</example-url>
 *
 * @example
 * <imt-ui-modal #modal
 * 	(closed)="onClose($event)"
 *  (dismissed)="onDismiss($event)">
 *  <imt-ui-modal-header>Header</imt-ui-modal-header>
 *  <imt-ui-modal-body>Body content</imt-ui-modal-body>
 * </imt-ui-modal>
 * <button class="btn btn-primary" (click)="modal.open()">Open Modal</button>
 */

@Component({
	selector: 'imt-ui-modal',
	templateUrl: './imt-ui-modal.component.html',
	styleUrls: ['./imt-ui-modal.component.scss'],
})
export class ImtUiModalComponent extends ImtUiModalBase {
	@ViewChild('template', { static: true }) public template: TemplateRef<ImtUiModalComponent> | null = null;
	@Input() public theme?: ImtUiTheme;

	public modalType: ModalType = ModalType.Normal;

	public ModalType: typeof ModalType = ModalType;

	protected modalRef?: ImtModalRef;

	constructor() {
		const modalService = inject(ImtUiModalService);

		super(modalService);
	}

	/**
	 * Opens modal instance
	 * @param options modal options
	 * @returns modal reference
	 */
	public open(options?: ImtModalOptions): ImtModalRef {
		if (!this.template) {
			throw new Error("Cannot open modal because template doesn't exist.");
		}

		this.modalType = ModalType.Normal;
		const defaultOptions = { windowClass: 'imt' } as ImtModalOptions;

		this.modalRef = this.openModal(this.template, {
			...options,
			windowClass: defaultOptions.windowClass + (options?.windowClass ? ` ${options.windowClass}` : ''),
		});

		return this.modalRef;
	}
}

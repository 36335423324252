import { EFFECT_INIT, effectSymbol } from './internals';
import { HttpBuilder, RequestMethods } from '@imt-web-zone/shared/util';
import { Params } from '@angular/router';
import { HttpRequest } from '@angular/common/http';
import { getEffectOptions } from '../';
import { EffectFactoryOptions, RunEffectAction } from '@imt-web-zone/shared/util-store';

/**
 * Http service method's decorator for enabling Effect

 * @constructor
 */
export function Effect<T>(options: EffectFactoryOptions<T>) {
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		(target as any)[effectSymbol(propertyKey)] = options;
		return descriptor;
	};
}

/**
 *
 * Override httpBuilder with Custom builder
 */
export function EffectInit() {
	// eslint-disable-next-line @typescript-eslint/ban-types
	return function <TFunction extends Function>(Target: TFunction): TFunction {
		Target.prototype.getServiceName = function () {
			return Target.name;
		};

		Target.prototype[EFFECT_INIT] = EFFECT_INIT;

		Target.prototype.customBuilder = function (propertyKey: string) {
			const effectOptions = getEffectOptions(this, propertyKey);
			// effect do not exists for current method
			if (!effectOptions) {
				return null;
			}

			return function returnRunEffectAction(
				this: HttpBuilder,
				data: {
					queryParams: Params;
					urlParams: Params;
					headerParams: Params;
					body: Record<any, any>;
					method: RequestMethods;
					request: HttpRequest<any>;
					propertyKey: string;
				},
			) {
				const serviceName = this.getServiceName();
				const actionParams: Record<string, Params> = {};

				if (Object.keys(data.queryParams).length) {
					actionParams['query'] = data.queryParams;
				}
				if (Object.keys(data.urlParams).length) {
					actionParams['params'] = data.urlParams;
				}
				if (Object.keys(data.headerParams).length) {
					actionParams['headers'] = data.headerParams;
				}
				if (data.body && Object.keys(data.body).length) {
					actionParams['body'] = data.body;
				}
				return new RunEffectAction(
					effectOptions,
					this.performRequest(data.request),
					data.method,
					actionParams,
					data.propertyKey,
					serviceName,
				);
			};
		};
		return Target;
	};
}

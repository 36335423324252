import { Injectable } from '@angular/core';
import { formatNumber, formatDate, formatPercent, formatCurrency } from '@angular/common';
import { ServiceInit } from '@imt-web-zone/core/util-core';

declare global {
	interface Window {
		angularFormatter: AngularFormatterService;
	}
}

@Injectable({ providedIn: 'root' })
export class AngularFormatterService implements ServiceInit {
	private getLocale: () => string;
	private getTimezone: () => Record<string, any>;

	public get timezone() {
		return this.getTimezone();
	}
	public get locale() {
		return this.getLocale();
	}

	public initialize(config: {
		getLocale: AngularFormatterService['getLocale'];
		getTimezone: AngularFormatterService['getTimezone'];
	}): Promise<void> {
		this.getLocale = config.getLocale;
		this.getTimezone = config.getTimezone;
		window.angularFormatter = this;
		return Promise.resolve();
	}

	/**
	 * Formats a number as text, with group sizing, separator, and other
	 * parameters based on the locale.
	 *
	 * @param value The number to format.
	 * @param digitInfo Decimal representation options, specified by a string in the following format:
	 * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `DecimalPipe` for more details.
	 *
	 * @returns The formatted text string.
	 */
	public formatNumber(value: number, digitsInfo: string) {
		return formatNumber(value, this.locale, digitsInfo);
	}

	/**
	 * Formats a date according to locale rules.
	 *
	 * @param value The date to format, as a Date, or a number (milliseconds since UTC epoch)
	 * or an [ISO date-time string](https://www.w3.org/TR/NOTE-datetime).
	 * @param format The date-time components to include. See Angular `DatePipe` for details.
	 *
	 * @returns The formatted date string.
	 */
	public formatDate(value: string | number | Date, format: string) {
		return formatDate(value, format, this.locale, this.timezone.offset);
	}

	/**
	 * Formats a number as a percentage according to locale rules.
	 *
	 * @param value The number to format.
	 * @param digitInfo Decimal representation options, specified by a string in the following format:
	 * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `DecimalPipe` for more details.
	 *
	 * @returns The formatted percentage value.
	 *
	 */
	public formatPercent(value: number, digitsInfo?: string) {
		return formatPercent(value, this.locale, digitsInfo);
	}

	/**
	 * Formats a number as currency using locale rules.
	 *
	 * @param value The number to format.
	 * @param currency A string containing the currency symbol or its name,
	 * such as "$" or "Canadian Dollar". Used in output string, but does not affect the operation
	 * of the function.
	 * @param currencyCode The [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217)
	 * currency code, such as `USD` for the US dollar and `EUR` for the euro.
	 * Used to determine the number of digits in the decimal part.
	 * @param digitInfo Decimal representation options, specified by a string in the following format:
	 * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`. See `DecimalPipe` for more details.
	 *
	 * @returns The formatted currency value.
	 */
	public formatCurrency(value: number, currency: string, currencyCode?: string, digitsInfo?: string) {
		return formatCurrency(value, this.locale, currency, currencyCode, digitsInfo);
	}
}

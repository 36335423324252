import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

export enum RequestMethods {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
	Head = 'HEAD',
	Patch = 'PATCH',
}

export type CustomBuilder<R> = (requestData: {
	queryParams: Params;
	urlParams: Params;
	headerParams: Params;
	body: Record<any, any>;
	request: HttpRequest<any>;
	method: RequestMethods;
	propertyKey: string;
}) => R;

/**
 * IMPORTANT: This file contains extended ng2-http components
 * @see https://github.com/hboylan/ng2-http/blob/master/src/util.ts
 * Added code is wrapped in proper comments
 */

const createParams = (params: HttpParams, value: any): HttpParams => {
	params = value.limit ? params.append(`pg[limit]`, value.limit) : params;
	params = value.offset ? params.append(`pg[offset]`, value.offset) : params;
	params = value.sortBy ? params.append(`pg[sortBy]`, value.sortBy) : params;
	params = value.sortDir ? params.append(`pg[sortDir]`, value.sortDir) : params;
	params = value.last ? params.append(`pg[last]`, value.last) : params;
	params = value.returnTotalCount ? params.append(`pg[returnTotalCount]`, value.returnTotalCount) : params;

	return value.showLast || value.showLast === false ? params.append(`pg[showLast]`, value.showLast) : params;
};
// perform HTTP request
export function method(method: RequestMethods) {
	return function (url: string, requestHeaders?: Params, responseType?: 'text') {
		return function (target: HttpBuilder, propertyKey: string, descriptor: any) {
			const pPath = (target as any)[`${propertyKey}_Path_parameters`] || '';
			const pQuery: Array<{ key: string; parameterIndex: number }> = (target as any)[
				`${propertyKey}_Query_parameters`
			];
			const pQueryData = (target as any)[`${propertyKey}_QueryData_parameters`];
			const pPaginationQuery = (target as any)[`${propertyKey}_Pagination_parameters`];
			const pBody = (target as any)[`${propertyKey}_Body_parameters`];
			const pHeader = (target as any)[`${propertyKey}_Header_parameters`];

			(target as any)[`${propertyKey}_Method`] = method;
			(target as any)[`${propertyKey}_URL`] = url;

			descriptor.value = function (...args: any[]) {
				// Path
				let resUrl: string = url;
				const urlParams: Params = {};
				if (pPath) {
					for (const k in pPath) {
						if (pPath.hasOwnProperty(k)) {
							resUrl = resUrl.replace(
								'{' + pPath[k].key + '}',
								encodeURIComponent(args[pPath[k].parameterIndex]),
							);
							urlParams[pPath[k].key] = args[pPath[k].parameterIndex];
						}
					}
				}

				// Query
				let params = new HttpParams();
				const queryParams: Params = {};
				if (pQueryData) {
					const queryData = args[pQueryData[0].parameterIndex] || {};
					for (const queryParam in queryData) {
						if (
							queryData.hasOwnProperty(queryParam) &&
							queryData[queryParam] !== undefined &&
							queryData[queryParam] !== null
						) {
							const value = queryData[queryParam];
							if (value instanceof Array) {
								for (let i = 0; i < value.length; i++) {
									params = params.append(`${queryParam}[${i}]`, value[i]);
								}
								queryParams[`${queryParam}`] = value;
							} else if (queryParam !== 'pg') {
								params = params.append(queryParam, value);
								queryParams[queryParam] = value;
							}
						}
					}
				}

				// const queryParams = {};
				if (pQuery) {
					pQuery
						.filter((p) => typeof args[p.parameterIndex] !== 'undefined') // filter out optional parameters
						.forEach((p) => {
							const key = p.key;
							const value = args[p.parameterIndex];
							// if the value is an instance of an Object, we stringify it
							if (value instanceof Array) {
								for (let i = 0; i < value.length; i++) {
									params = params.append(`${key}[${i}]`, value[i]);
									queryParams[`${key}[${i}]`] = value[i];
								}
							} else if (key === 'pg') {
								// todo deprecated
								params = createParams(params, value);
								queryParams['pg'] = value;
							} else {
								params = params.append(key, value);
								queryParams[key] = value;
							}
						});
				}

				if (pPaginationQuery) {
					const paginationData = args[pPaginationQuery[0].parameterIndex] || {};
					params = createParams(params, paginationData);
					queryParams['pg'] = paginationData;
				}

				// Headers
				// set class default headers
				let headers = new HttpHeaders(this.getDefaultHeaders());
				const headerParams: Params = {};
				if (requestHeaders) {
					for (const key of Object.keys(requestHeaders)) {
						headers = headers.set(key, requestHeaders[key]);
						headerParams[key] = requestHeaders[key];
					}
				}

				// set parameter specific headers
				if (pHeader) {
					for (const k in pHeader) {
						if (pHeader.hasOwnProperty(k) && args[pHeader[k].parameterIndex]) {
							const headerKey = pHeader[k].key;
							const headerValue = args[pHeader[k].parameterIndex];
							headers = headers.set(headerKey, headerValue);
							headerParams[headerKey] = headerValue;
						}
					}
				}

				// Body
				let body = null;
				if (pBody) {
					body = args[pBody[0].parameterIndex];
				}
				const requestUrl = this.getBaseUrl().replace(/\/$/, '') + '/' + resUrl;

				const options = {
					headers,
					params,
					withCredentials: true,
					responseType,
				};
				const request = this.getRequestClass(method, requestUrl, body, options);
				const fn = this.customBuilder(propertyKey);
				if (fn) {
					const customBuilderFn: CustomBuilder<any> = fn.bind(this);
					return customBuilderFn({
						headerParams,
						queryParams,
						urlParams,
						body,
						method,
						request,
						propertyKey,
					});
				}
				return this.performRequest(request).pipe(take(1));
			};

			return descriptor;
		};
	};
}

// Store request parameters
function param(paramName: string) {
	return function (key: string) {
		return function (target: HttpBuilder, propertyKey: string, parameterIndex: number) {
			const metadataKey = `${propertyKey}_${paramName}_parameters`;
			const paramObj: any = {
				key: key,
				parameterIndex: parameterIndex,
			};
			if (Array.isArray((target as any)[metadataKey])) {
				(target as any)[metadataKey].push(paramObj);
			} else {
				(target as any)[metadataKey] = [paramObj];
			}
		};
	};
}

/**
 * GET method
 * @param {string} url - resource url of the method
 */
export const GET = method(RequestMethods.Get);
/**
 * POST method
 * @param {string} url - resource url of the method
 */
export const POST = method(RequestMethods.Post);
/**
 * PUT method
 * @param {string} url - resource url of the method
 */
export const PUT = method(RequestMethods.Put);
/**
 * DELETE method
 * @param {string} url - resource url of the method
 */
export const DELETE = method(RequestMethods.Delete);
/**
 * HEAD method
 * @param {string} url - resource url of the method
 */
export const HEAD = method(RequestMethods.Head);
/**
 * PATCH method
 * @param {string} url - resource url of the method
 */
export const PATCH = method(RequestMethods.Patch);

/**
 * Path variable of a method's url, type: string
 * @param {string} key - path key to bind value
 */
export const Path = param('Path');

/**
 * Query value of a method's url, type: string
 * @param {string} key - query key to bind value
 */
export const Query = param('Query');

/**
 * QueryData value of a method, type: key-value pair object
 * Only one QueryData per method!
 */
export const QueryData = param('QueryData')('QueryData');

export const Pagination = param('Pagination')('Pagination');
/**
 * Body of a REST method, type: key-value pair object
 * Only one body per method!
 */
export const Body = param('Body')('Body');
/**
 * Custom header of a REST method, type: string
 * @param {string} key - header key to bind value
 */
export const Header = param('Header');

@Injectable()
export class HttpBuilder {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(@Inject(HttpClient) protected http: HttpClient) {}

	protected getBaseUrl(): string {
		return '';
	}

	protected customBuilder(propertyKey: string): CustomBuilder<any> | null {
		return null;
	}

	protected getDefaultHeaders(): Record<string, unknown> {
		return {};
	}

	protected getServiceName() {
		return '';
	}

	protected getRequestClass(method: RequestMethods, requestUrl: string, body: any, options: any): HttpRequest<any> {
		switch (method) {
			case RequestMethods.Patch:
			case RequestMethods.Put:
			case RequestMethods.Post:
			case RequestMethods.Delete:
				return new HttpRequest(method, requestUrl, body, options);
			default: {
				return new HttpRequest(method, requestUrl, options);
			}
		}
	}

	protected performRequest(request: HttpRequest<any>): Observable<HttpResponse<any>['body']> {
		return this.http.request(request).pipe(
			filter((event) => event.type === HttpEventType.Response),
			map((event) => {
				event = event as HttpResponse<any>;
				return event.body;
			}),
			take(1),
		);
	}
}
/**
 * Set the base URL of REST resource
 * @param {String} url - base URL
 */
export function BaseUrl(url: string) {
	// eslint-disable-next-line @typescript-eslint/ban-types
	return function <TFunction extends Function>(Target: TFunction): TFunction {
		Target.prototype.getBaseUrl = function () {
			return url;
		};
		return Target;
	};
}

/**
 * Set default headers for every method of the RESTClient
 * @param {Object} headers - deafult headers in a key-value pair
 */
export function DefaultHeaders(headers: any) {
	// eslint-disable-next-line @typescript-eslint/ban-types
	return function <TFunction extends Function>(Target: TFunction): TFunction {
		Target.prototype.getDefaultHeaders = function () {
			return headers;
		};
		return Target;
	};
}

export const IGNORE_ERROR_HEADER = 'X-IGNORE-ERROR';
export const IGNORE_ERRORS_HEADER = 'X-IGNORE-ERRORS';

import { Injectable, inject } from '@angular/core';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { HttpBuilder, GET, QueryData, QueryType, DELETE, Path, ParamType } from '@imt-web-zone/shared/util';
import {
	DeleteTeamPayload,
	DeleteTeamResponse,
	DetailTeamPayload,
	DetailTeamResponse,
	LoadTeamsResponse,
	LoadTeamsPayload,
} from './teams.interface';
import { Effect, EffectInit } from '@imt-web-zone/core/util-state-effect';
import { RunEffectAction } from '@imt-web-zone/shared/util-store';

@Injectable({ providedIn: 'root' })
@EffectInit()
export class TeamsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@Effect<LoadTeamsResponse>({ responseEntityPath: 'teams' })
	@GET('teams')
	public loadTeams$(
		@QueryData queryData: QueryType<LoadTeamsPayload>,
	): RunEffectAction<LoadTeamsPayload, LoadTeamsResponse> {
		return undefined as unknown as RunEffectAction<LoadTeamsPayload, LoadTeamsResponse>;
	}

	@Effect<DetailTeamResponse>({ responseEntityPath: 'team' })
	@GET('teams/{id}')
	public loadTeam$(
		@Path('id') id: ParamType<DetailTeamPayload, 'teamId'>,
	): RunEffectAction<DetailTeamPayload, DetailTeamResponse> {
		return undefined as unknown as RunEffectAction<DetailTeamPayload, DetailTeamResponse>;
	}

	@Effect<DeleteTeamResponse>({ responseEntityPath: 'team' })
	@DELETE('teams/{id}')
	public deleteTeam$(
		@Path('id') id: ParamType<DeleteTeamPayload, 'id'>,
	): RunEffectAction<DeleteTeamPayload, DeleteTeamResponse> {
		return undefined as unknown as RunEffectAction<DeleteTeamPayload, DeleteTeamResponse>;
	}
}

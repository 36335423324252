import { Injectable, Injector, inject } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ImtModalActive } from '@imt-web-zone/shared/model';

@Injectable({ providedIn: 'any' })
export class ImtUiModalActive implements ImtModalActive {
	private injector = inject(Injector);

	private get activeModal(): NgbActiveModal {
		return this.injector.get(NgbActiveModal);
	}

	public close(result?: any): void {
		this.activeModal.close(result);
	}

	public dismiss(reason?: any): void {
		this.activeModal.dismiss(reason);
	}
}

import { DestroyRef, inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
} from '@angular/common/http';
import { Dispatch } from '@imt-web-zone/shared/util-dispatch';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngxs/store';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { ImtExceptionCodesEnum } from '@integromat/exceptions';
import { Debounce } from '@imt-web-zone/shared/util-store';
import { ImtModalTypeEnum } from '@imt-web-zone/shared/model';
import { IMT_MODAL_SERVICE } from '@imt-web-zone/shared/core';
import { setActiveOrganization, Utils } from '@imt-web-zone/shared/data-access';

import { AppLoader } from '@imt-web-zone/zone/util';
import { SessionSelectors } from '@imt-web-zone/zone/util-store';
import { IGNORE_ERROR_HEADER, IGNORE_ERRORS_HEADER } from '@imt-web-zone/shared/util';
import { ApiConfigFacade, ApiConfigProvider, ModeEnum } from '@imt-web-zone/zone/state-api-config';
import {
	UiToastMessage,
	UiToastMessageForType,
	UiToastMessageService,
} from '@imt-web-zone/make-design-system/ui-toast-message';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ZoneUserCookiesService } from '@imt-web-zone/zone/data-access-storages';
import { HttpErrorFormatterService } from './http-error-formatter';
import { TeamsFacade } from '@imt-web-zone/zone/state-teams';
import { SessionChecksService } from '@imt-web-zone/zone/data-access-session-checks';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EntityTypes } from '@imt-web-zone/zone/ui-prompt';
import { OrganizationsFacade } from '@imt-web-zone/zone/data-access-state/organizations';
import { CommonSelectors, changeLogoIndicatorVisibility } from '@imt-web-zone/zone/state-common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { VerificationStatusDescription } from '@imt-web-zone/zone/feature-organization-domain';
import { setActiveOrganizationTeam } from '@imt-web-zone/zone/state-session';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	private injector = inject(Injector);
	private router = inject(Router);
	private transloco = inject(TranslocoService);
	private store = inject(Store);
	private toastService = inject(UiToastMessageService);
	private cookiesService = inject(ZoneUserCookiesService);
	private httpErrorFormatter = inject(HttpErrorFormatterService);
	private apiConfigProvider = inject(ApiConfigProvider);

	private apiConfigFacade = inject(ApiConfigFacade);
	private authFacade = inject(AuthFacade);
	private destroyRef = inject(DestroyRef);
	private sessionChecksService = inject(SessionChecksService);

	private teamsFacade = inject(TeamsFacade);
	private organizationsFacade = inject(OrganizationsFacade);

	public get authUserId() {
		return this.authFacade.userIdSnapshot;
	}
	public get apiConfig() {
		return this.apiConfigFacade.configSnapshot;
	}
	public get teamId() {
		return this.teamsFacade.activeIdSnapshot;
	}
	public get logoIndicator() {
		return this.store.selectSnapshot(CommonSelectors.showLogoIndicator);
	}
	public get userData() {
		return this.store.selectSnapshot(SessionSelectors.getUserData());
	}

	// apiConfigReloading is used to prevent the app to make unnecessary additional requests
	private apiConfigReloading = false;

	public getToastConfig(error: any) {
		const getMessage = (e: Record<string, any>) => e['detail'] || e['message'] || '';
		const descParts = [];

		if (error.detail) {
			descParts.push(error.detail);
		}
		if (error.suberrors) {
			for (const serr of error.suberrors) {
				const sDescription = Utils.translateString(getMessage(serr), this.transloco);
				if (sDescription) {
					descParts.push(sDescription);
				}
			}
		}

		return {
			title: error.message,
			text: descParts.join('<br> > '),
			expiration: 6000,
		} as UiToastMessage;
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// ignore one particular error according to error.error?.code
		const ignoreHeader = request.headers.get(IGNORE_ERROR_HEADER);
		// do not show error toast
		const skipErrors = request.headers.get(IGNORE_ERRORS_HEADER);
		let skipShowError = false;

		if (skipErrors !== null) {
			skipShowError = true;
			request = request.clone({ headers: request.headers.delete(IGNORE_ERRORS_HEADER) });
		}

		if (ignoreHeader !== null) {
			request = request.clone({ headers: request.headers.delete(IGNORE_ERROR_HEADER) });
		}

		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				let shouldRedirect = true;
				if (request.urlWithParams.includes(`/hq/sanity-check`)) {
					return this.reThrowError(error);
				}
				if (request.urlWithParams.includes(`/users/password-reset`)) {
					shouldRedirect = false;
				}
				const toastErrorConfig = this.getToastConfig(error.error);
				if (error.error?.code) {
					const code = error.error.code;
					if (
						[
							ImtExceptionCodesEnum.IM004_CONFIRMATION_REQUIRED,
							ImtExceptionCodesEnum.IM451_CANNOT_BE_DELETED_MESSAGE_IN_QUEUE_FOUND,
							ImtExceptionCodesEnum.IM405_DEVICE_CANNOT_BE_DELETED_MESSAGES_IN_QUEUE_FOUND,
							ImtExceptionCodesEnum.IM016_ACTION_NOT_POSSIBLE_DUE_TO_DEPENDENCIES,
						].includes(code)
					) {
						return this.resolvePrompt$(request, error.error, next);
					} else if (code === ImtExceptionCodesEnum.IM019_CUSTOM_EXCEPTION) {
						// `IM019_CUSTOM_EXCEPTION` contains metadata with variables which will be used and formatted
						// in translation (e.g.: Date like `2022-07-03T13:14:27.974Z` will be formatted as `3. 7. 2022`)
						const toastText = this.httpErrorFormatter.formatAndTranslate(error.error?.metadata);

						// When there is existing translated (and formatted) toast text, we don't need description
						if (toastText) {
							toastErrorConfig.text = toastText;
							delete toastErrorConfig.title;
						}
					} else if (code === ImtExceptionCodesEnum.IM193_INVITATION_EXPIRED) {
						toastErrorConfig.title = this.transloco.translate('organizations.inviteexpired');
						shouldRedirect = false;
					} else if (code === ImtExceptionCodesEnum.IM194_INVITATION_FOR_DIFFERENT_USER) {
						toastErrorConfig.title = this.transloco.translate('organizations.invitedifferentuser');
						toastErrorConfig.text = this.transloco.translate('organizations.invitedifferentuserhint');
						shouldRedirect = false;
					} else if (code === ImtExceptionCodesEnum.SC_403_FORBIDDEN) {
						// when user looses permissions to last visited team or organization, remove them from local
						// storage and redirect him to root
						shouldRedirect = this.error403Handler(request, code);
					} else if (code === ImtExceptionCodesEnum.IM026_MAINTENANCE_MODE) {
						return this.resolveMaintenanceMode$(request, error, next);
					} else if (code === ImtExceptionCodesEnum.IM015_USER_NOT_LOGGED_IN) {
						if (this.authUserId) {
							this.logout();
						}
					} else if (
						(error.status === 502 || error.status === 503) &&
						Object.keys(this.apiConfig || {}).length
					) {
						toastErrorConfig.title = this.transloco.translate('errorpages.503title');
						// when app fails during initial loading, hide loader and redirect to root
					} else if (error?.error?.suberrors?.[0]?.message) {
						// exclude showing the error toast on certain subErrors types
						const excludedSubErrorsTypes: Array<any> = Object.values(VerificationStatusDescription);

						if (excludedSubErrorsTypes.includes(error?.error?.suberrors?.[0]?.message)) {
							skipShowError = true;
						}
					}

					if (error.status === 401 && this.apiConfig?.mode === ModeEnum.SLAVE) {
						this.sessionChecksService.emitUserActivityEvents();
						this.cookiesService.setVisitedCookie(true);
					}
				}

				if (this.isOnLoading(shouldRedirect)) {
					if (error.status >= 500) {
						this.router.navigate(['/error']);
					} else if (this.authUserId) {
						this.router.navigate(['/']);
					}
				}

				if (!toastErrorConfig.text) {
					let httpStatusCode = HttpStatusCode[error.status];
					const isPascalCase = /^[A-Z][a-z]+(?:[A-Z][a-z]+)+$/.test(httpStatusCode);

					if (isPascalCase) {
						httpStatusCode = httpStatusCode.replace(/([a-z])([A-Z])/g, '$1 $2');
					}

					toastErrorConfig.text = httpStatusCode;
				}

				this.hideLogoIndicator();

				// show error only if the ignore header was not set with this error code and skipShowError was not set
				if (
					(ignoreHeader === null && !skipShowError) ||
					(error.error?.code !== ignoreHeader && !skipShowError)
				) {
					this.showError(toastErrorConfig);
				}

				return this.reThrowError(error);
			}),
		);
	}

	private isOnLoading(shouldRedirect: boolean) {
		return (this.logoIndicator || AppLoader.isVisible) && shouldRedirect;
	}

	private showError(toastConfig: UiToastMessageForType) {
		toastConfig.ignoreRedundant = true;
		this.toastService.showDanger(toastConfig);
	}

	private error403Handler(request: HttpRequest<any>, code: string) {
		let shouldRedirect = true;
		if (
			(request.urlWithParams.includes(`/organizations/${this?.userData?.organizationId}`) &&
				request.method !== 'DELETE') ||
			request.urlWithParams.includes(`/teams/${this?.userData?.teamId}`) ||
			code === ImtExceptionCodesEnum.IM550_ORGANIZATION_DOES_NOT_EXIST ||
			code === ImtExceptionCodesEnum.IM201_TEAM_DOES_NOT_EXIST
		) {
			if (
				request.urlWithParams.includes(`/organizations/${this.userData?.organizationId}`) ||
				code === ImtExceptionCodesEnum.IM550_ORGANIZATION_DOES_NOT_EXIST
			) {
				shouldRedirect = false;
				// TODO: when organizations state will be refactored, try to use those 2
				//  actions below through the organizations facade
				this.store.dispatch(setActiveOrganization(null as any)).subscribe(() => {
					this.router.navigate(['/']);
				});
				this.store.dispatch(setActiveOrganizationTeam({ isOrganization: true, id: null }));
			} else if (
				request.urlWithParams.includes(`/teams/${this.userData?.teamId}`) ||
				code === ImtExceptionCodesEnum.IM201_TEAM_DOES_NOT_EXIST
			) {
				shouldRedirect = false;
				this.teamsFacade.setActiveTeam$(null).subscribe(() => this.router.navigate(['/']));
			}
		}
		return shouldRedirect;
	}

	private resolvePrompt$(request: HttpRequest<any>, errorData: { [key: string]: any } = {}, next: HttpHandler) {
		/**
		 * Extracts all the types from error metadata and makes CSS classes from them
		 * to easily target exact prompt dialog with CSS selector having respective CSS class.
		 */
		const promptClasses = (postfix: string) => {
			if (!Array.isArray(errorData['metadata'])) {
				return undefined;
			}

			return (
				errorData['metadata']
					.map((data) => data['type'] && `error-prompt-type-${data['type']}-${postfix}`)
					.join(' ') || undefined
			);
		};

		const modalService = this.injector.get(IMT_MODAL_SERVICE);
		const modal = modalService.open(ImtModalTypeEnum.Prompt, {
			windowClass: promptClasses('window'),
			backdropClass: promptClasses('backdrop'),
		});

		const hideConfirm = [ImtExceptionCodesEnum.IM016_ACTION_NOT_POSSIBLE_DUE_TO_DEPENDENCIES].includes(
			errorData['code'] as ImtExceptionCodesEnum,
		);

		const typesToCheck = [
			'deleteOrganization',
			'deleteOrganizationWithActiveScenarios',
			'deleteOrganizationWithActiveSubscription',
			'deleteTeam',
			'deleteTeamWithActiveScenarios',
			'deleteTeamWithActiveScenariosCount',
		];
		const type = errorData?.['metadata']?.[0]?.type;
		let deleteEntity: {
			entityType: EntityTypes;
			entityId: string;
			entityName: string;
		};

		if (type && typesToCheck.includes(type)) {
			deleteEntity = {
				entityType: null,
				entityId: null,
				entityName: null,
			};
			deleteEntity.entityType = type.startsWith('deleteOrganization')
				? EntityTypes.ORGANIZATION
				: EntityTypes.TEAM;

			const parts = request.url.split('/');
			deleteEntity.entityId = parts[parts.length - 1];
			deleteEntity.entityName =
				deleteEntity.entityType === EntityTypes.ORGANIZATION
					? this.organizationsFacade.entitiesSnapshot.find((e) => e.id === deleteEntity.entityId).name
					: this.teamsFacade.entitiesSnapshot.find((e) => e.id === deleteEntity.entityId).name;
		}

		if (deleteEntity?.entityType && deleteEntity?.entityName) {
			modal.componentInstance.deleteEntity = {
				type: deleteEntity.entityType,
				name: deleteEntity.entityName,
			};
		} else {
			modal.componentInstance.title = errorData['message'];
		}

		modal.componentInstance.teamId = this.teamId;
		modal.componentInstance.metadata = errorData['metadata'];
		modal.componentInstance.hideConfirm = hideConfirm;

		return from(modal.result).pipe(
			mergeMap((checkboxValues: { [key: string]: boolean }) => {
				let params = request.params.append('confirmed', 'true');
				Object.keys(checkboxValues).forEach((key) => {
					params = params.append(key, `${checkboxValues[key]}`);
				});
				return next.handle(request.clone({ params }));
			}),
			catchError((e) => {
				if (e?.error?.detail) {
					this.showError({ text: e.error.detail });
				}
				console.error(`PromptError:`, e);
				return throwError(() => e);
			}),
		);
	}

	private reThrowError(error: HttpErrorResponse) {
		const errorData = {
			error: {
				code: error.error.code || null,
				message: error.error.message,
				status: error.status,
				statusText: error.statusText,
				url: error.url,
				detail: error?.error?.detail,
				suberrors: error?.error?.suberrors,
			},
		};
		console.error(`${error.name}:`, errorData);
		return throwError(() => errorData);
	}

	private resolveMaintenanceMode$(request: HttpRequest<any>, error: HttpErrorResponse, next: HttpHandler) {
		if (this.apiConfigReloading === true) {
			return this.reThrowError(error);
		}

		this.apiConfigReloading = true;

		return from(this.apiConfigProvider.reloadApiConfig()).pipe(
			switchMap((apiConfig) => {
				if (apiConfig.generalSettings.maintenanceModeEnabled === true) {
					return from(this.router.navigate(['/maintenance-mode'])).pipe(
						switchMap(() => this.reThrowError(error)),
					);
				}

				// This should never happen, because once `IM026_MAINTENANCE_MODE` occurs
				// `maintenanceModeEnabled` should be always `true`.
				return next.handle(request);
			}),
			finalize(() => (this.apiConfigReloading = false)),
		);
	}

	@Debounce(300)
	private logout() {
		this.authFacade.logout$({ redirect: true }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
	}

	@Dispatch()
	private hideLogoIndicator() {
		return changeLogoIndicatorVisibility({ visible: false });
	}
}

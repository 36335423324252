import { getActionTypeFromInstance } from '@ngxs/store';
import { CollectionState, getInitialCollectionState, successActionOf } from '@imt-web-zone/shared/util-store';
import { changedActiveOrganization } from '@imt-web-zone/shared/data-access';
import { effectSuccess } from '@imt-web-zone/core/util-state-effect';
import { RootState, initialRootState } from './root-state';
import {
	leaveAdministration,
	leaveMaintenanceMode,
	leaveZoneInstall,
	login,
	logout,
} from '@imt-web-zone/zone/state-auth';
import { RouterNavigation } from '@ngxs/router-plugin';
import deepmerge from 'deepmerge';
import { USERS_STATE_TOKEN, UsersService } from '@imt-web-zone/zone/state-users';
import { changedActiveTeam } from '@imt-web-zone/zone/state-teams';

function updateInitialStatesWithMetadata(newState: any, state: any) {
	Object.keys(state).forEach((key) => {
		if (isCollectionState(state[key])) {
			if (!newState[key]) {
				newState[key] = {
					...getInitialCollectionState<unknown>(),
					idKey: state[key].idKey,
					storeKey: state[key].storeKey,
				};
			} else {
				newState[key] = { ...newState[key], idKey: state[key].idKey, storeKey: state[key].storeKey };
			}
		}
	});
}

function isCollectionState(state: any): state is CollectionState<any> {
	return state?.idKey && state?.storeKey;
}

function switchAdminSessions(state: RootState, action: RouterNavigation) {
	const userId = state.auth?.user?.id;
	if (userId) {
		const update = { session: { [userId]: {} } } as RootState;
		const userSession = state.session[userId];
		if (userSession) {
			if (action?.event.url.startsWith('/admin')) {
				if (userSession.teamId && !userSession.adminTeamId) {
					update.session[userId].adminTeamId = userSession.teamId;
				}

				if (userSession.organizationId && !userSession.adminOrganizationId) {
					update.session[userId].adminOrganizationId = userSession.organizationId;
				}
			} else {
				if (userSession.teamId && userSession.adminTeamId) {
					update.session[userId].teamId = userSession.adminTeamId;
					update.session[userId].adminTeamId = undefined;
				}

				if (userSession.organizationId && userSession.adminOrganizationId) {
					update.session[userId].organizationId = userSession.adminOrganizationId;
					update.session[userId].adminOrganizationId = undefined;
				}
			}
			return deepmerge(state, update);
		}
	}
	return state;
}

export const authMetaReducer = (
	state: RootState,
	action: unknown,
	// eslint-disable-next-line max-len
	// todo fix - returning RootState prints TS4023: Exported Variable <x> has or is using name <y> from external module but cannot be named
	next: (state: RootState, action: unknown) => any,
) => {
	const actionType = getActionTypeFromInstance(action);
	switch (actionType) {
		case login.type:
			break;

		case effectSuccess<UsersService>(USERS_STATE_TOKEN, 'loginAsAdminUser$').type:
		case successActionOf(logout).type: {
			const newState = { ...initialRootState() };
			updateInitialStatesWithMetadata(newState, state);
			if (successActionOf(logout).type === actionType) {
				newState.auth.signedOut = state.auth.signedOut;
			}
			newState.auth.type = state.auth.type;
			newState.router = state.router;
			newState.session = state.session;
			newState.apiConfig = state.apiConfig;
			state = newState;
			break;
		}

		case leaveZoneInstall.type:
		case leaveMaintenanceMode.type:
		case changedActiveTeam.type:
		case changedActiveOrganization.type:
		case leaveAdministration.type: {
			const newState = { ...initialRootState() };
			updateInitialStatesWithMetadata(newState, state);
			newState.apiConfig = state.apiConfig;
			newState.common = state.common;
			newState.enums = state.enums;
			newState.forms = state.forms;
			newState.oauth = state.oauth;
			newState.notifications = state.notifications;
			newState.router = state.router;
			newState.session = state.session;
			state = newState;
			break;
		}

		case RouterNavigation.type:
			state = switchAdminSessions(state, action as RouterNavigation);
			break;

		default: {
			/**/
		}
	}

	// return the next function with the empty state
	return next(state, action);
};

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AssetPathOptions, AssetsService } from '@imt-web-zone/core/util-assets';
import { UtilURL } from '@imt-web-zone/shared/util';

import { ZONE_ASSETS_PATH, ZoneAssetsDomain } from './zone-assets';

export type ZoneAssetPathOptions = AssetPathOptions<ZoneAssetsDomain>;

/**
 * Represents single source of truth when any part of the application under "Zone" context needs correct asset path.
 *
 * For the "Zone" assets (application assets), use `zoneAssetPath` method. For example:
 *
 * ```ts
 * this.zoneAssetsService.zoneAssetPath('/img/noicon.png');
 * ```
 *
 * Note that `zoneAssetPath` method automatically uses `ZoneAssetsDomain.Zone` domain
 * and prefixes relative path with `ZONE_ASSETS_PATH` (`'/static'`).
 *
 * For any other assets (Domino Elements, Tailwind Themes, etc.), use `assetPath()` or `assetPath()$` method
 * with corresponding `ZoneAssetsDomain`. For example:
 *
 * ```ts
 * this.zoneAssetsService.assetPath({ relativePath: '/domino-elements.js', domain: ZoneAssetsDomain.DominoElements });
 * ```
 */
@Injectable({ providedIn: 'root' })
export class ZoneAssetsService extends AssetsService<ZoneAssetsDomain> {
	public override assetPath({ relativePath, domain = ZoneAssetsDomain.Zone }: ZoneAssetPathOptions): string {
		return super.assetPath({
			domain,
			relativePath: this.resolveRelativePath(domain, relativePath),
		});
	}

	public override assetPath$({
		relativePath,
		domain = ZoneAssetsDomain.Zone,
	}: ZoneAssetPathOptions): Observable<string> {
		return super.assetPath$({
			domain,
			relativePath: this.resolveRelativePath(domain, relativePath),
		});
	}

	/**
	 * Returns correct path for the "Zone" asset on the given `relativePath`.
	 * Automatically uses `ZoneAssetsDomain.Zone` domain and prefixes relative path
	 * with `ZONE_ASSETS_PATH` (`'/static'`).
	 */
	public zoneAssetPath(relativePath: AssetPathOptions<ZoneAssetsDomain>['relativePath']): string {
		return this.assetPath({ relativePath, domain: ZoneAssetsDomain.Zone });
	}

	/**
	 * Contains logic dedicated exclusive for resolving path of the theme CSS assets.
	 * (Why? See comments bellow.)
	 */
	public zoneThemeAssetPath(relativePath: AssetPathOptions<ZoneAssetsDomain>['relativePath']): string {
		const domain = ZoneAssetsDomain.Zone;
		const isDev = process.env['DEV'] === '1';
		// Check if domain is local domain (same domain as location origin).
		const isLocal = this.isAssetsDomainLocal(domain);

		return super.assetPath({
			domain,
			relativePath:
				// If application runs locally and in the DEV mode it means that theme CSS assets are placed
				// in the root directory.
				isLocal && isDev
					? relativePath
					: // In any other case (application is built), theme CSS assets are moved from the root directory
					  // into `/static/css` directory
					  this.resolveRelativePath(domain, UtilURL.urlJoin('/css/', relativePath || '')),
		});
	}

	/**
	 * Modifies specific assets domain URLs by specific need.
	 * For example, it prefixes "Zone" domains with `ZONE_ASSETS_PATH` (`'/static'`).
	 */
	private resolveRelativePath(
		domain: ZoneAssetsDomain,
		relativePath: AssetPathOptions<ZoneAssetsDomain>['relativePath'],
	): AssetPathOptions<ZoneAssetsDomain>['relativePath'] {
		switch (domain) {
			case ZoneAssetsDomain.Zone:
				return UtilURL.urlJoin(ZONE_ASSETS_PATH, relativePath || '');

			default:
				return relativePath;
		}
	}

	/**
	 * Decides whether provided domain URL points out of current origin.
	 */
	private isAssetsDomainLocal(domain: ZoneAssetsDomain): boolean {
		const assetsDomain = this.assetsDomains[domain];

		if (
			assetsDomain &&
			// If assets domain URL starts with protocol, checks if it's not current location origin.
			assetsDomain.match(/^(http|https):\/\//gi) &&
			!assetsDomain.startsWith(window.location.origin)
		) {
			return false;
		}

		return true;
	}
}

<dmo-toast-message
	*ngFor="let toast of toasts; let first = first"
	#toasts
	@toastEnter
	[@shrinkToast]="toast._shrinkToastAnimation"
	(@shrinkToast.done)="onToastShrinkedDone($event, toast._toastIndex)"
	[@toastVisible]="!toast._hidden"
	(@toastVisible.done)="onToastVisibilityAnimationDone($event, toast._toastIndex)"
	[attr.data-testid]="'toast-message-' + toast._toastIndex"
	[attr.aria-hidden]="toast._hidden"
	[type]="toast.type"
	[text]="toast.text"
	[title]="toast.title"
	[buttonPosition]="toast.buttonPosition ? toast.buttonPosition : UiToastMessageButtonPosition.RIGHT"
	[buttonLabel]="toast.buttonLabel"
	[withoutPadding]="true"
	(closeClicked)="closeToast.emit(toast._toastIndex)"
	(buttonClicked)="toastButtonClicked.emit(toast._toastIndex)"
></dmo-toast-message>

import { generateActionObject } from '../internal/internal';
import { EntityState } from '../internal/entity-state';
import { Type } from '@angular/core';
import { EntityActionType } from './type-alias';
import { StateToken } from '@ngxs/store';

export class Reset {
	/**
	 * Resets the targeted store to the default state: no entities, loading is false, error is undefined,
	 * active is undefined.
	 * @param target The targeted state class
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see defaultEntityState
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, context: string) {
		return generateActionObject(EntityActionType.Reset, target).setContext(context);
	}
}

<imt-ui-modal-template [hideHeader]="false" [hideBody]="false" [hideFooter]="false" *transloco="let t">
	<imt-ui-modal-header (dissmised)="onDismissed($event)">{{ title }}</imt-ui-modal-header>
	<imt-ui-modal-body>
		<ng-container *ngIf="bulletListConfig && !deleteEntity">
			<p [innerHTML]="t(bulletListConfig.messageAboveBullets)"></p>

			<ul class="styled-list ml-3">
				<li *ngFor="let bullet of bulletListConfig.bullets">{{ bullet }}</li>
			</ul>

			<p [innerHTML]="t(bulletListConfig.messageBelowBullets)"></p>
		</ng-container>
		<ng-container *ngIf="!bulletListConfig && !deleteEntity">
			<ul
				*ngFor="let meta of metadata"
				class="entity-content"
				[ngClass]="{ 'no-list': metadata.length === 1, 'pl-0 mt-0 pt-0 border-0': meta?.normalTextFormat }"
			>
				<li class="underline" [class.list-unstyled]="meta?.normalTextFormat">
					<p [innerHTML]="text(meta.type, meta.textdata || { data: meta.data })"></p>
					<div *ngIf="meta.mappedData" [class.dmo-overflow-y-auto]="meta.mappedData.length <= 3">
						<a
							*ngFor="let item of meta.mappedData; let i = index"
							[style.display]="i > 2 && !expandedEntities ? 'none' : 'flex'"
							target="_blank"
							class="list-group-item dmo-pl-0 dmo-pr-0"
							[routerLink]="item.link"
							[imtUiRouterLinkDomain]="item.zoneDomain"
						>
							<i class="list-group-icon" [ngClass]="getEntityIcon(item.entity)"></i>
							<div class="list-group-title">{{ item.name }}</div>
						</a>
						<button
							*ngIf="meta.mappedData.length > 3 && !expandedEntities"
							mk-ui-button
							[type]="MkUiButtonType.Clear"
							(click)="expandEntities()"
							class="dmo-text-primary dmo-mt-16 hover:dmo-underline"
						>
							{{ t('prompts.showMore') }}
						</button>
					</div>
					<div *ngIf="meta?.checkbox?.length">
						<div
							class="list-group-item form-check form-check-inline align-items-start"
							*ngFor="let checkbox of meta.checkbox"
						>
							<input
								class="form-check-input mr-3 mt-3"
								type="checkbox"
								[name]="checkbox.name"
								[id]="checkbox.name"
								[(ngModel)]="checkboxValues[checkbox.name]"
							/>
							<label
								class="form-check-label"
								[for]="checkbox.name"
								[innerHTML]="text(checkbox.description)"
							></label>
						</div>
					</div>
					<div *ngIf="meta?.binaryRadio">
						<div class="custom-control custom-radio">
							<input
								class="custom-control-input dmo-mt-6"
								type="radio"
								[name]="meta.binaryRadio.name"
								[id]="meta.binaryRadio.name + '_false'"
								[(ngModel)]="checkboxValues[meta.binaryRadio.name]"
								[value]="false"
								(ngModelChange)="onInputChange($event)"
							/>
							<label
								class="custom-control-label dmo-pl-8"
								[for]="meta.binaryRadio.name + '_false'"
								[innerHTML]="text(meta.binaryRadio.descriptionFalse)"
							></label>
						</div>
						<div class="custom-control custom-radio dmo-mt-20">
							<input
								class="custom-control-input dmo-mt-6"
								type="radio"
								[name]="meta.binaryRadio.name"
								[id]="meta.binaryRadio.name + '_true'"
								[(ngModel)]="checkboxValues[meta.binaryRadio.name]"
								[value]="true"
								(ngModelChange)="onInputChange($event)"
							/>
							<label
								class="custom-control-label dmo-pl-8"
								[for]="meta.binaryRadio.name + '_true'"
								[innerHTML]="text(meta.binaryRadio.descriptionTrue)"
							></label>
						</div>
					</div>
					<p class="mt-3" *ngIf="footerText(meta.type)" [innerHTML]="footerText(meta.type) | transloco"></p>
				</li>
			</ul>
		</ng-container>
		<ng-container *ngIf="deleteEntity">
			<dmo-static-message
				type="danger"
				[title]="
					t(
						deleteEntity?.type === EntityTypes.TEAM
							? 'prompts.deleteEntity.teamAlertTitle'
							: 'prompts.deleteEntity.orgAlertTitle'
					)
				"
			>
				{{ t('prompts.deleteEntity.cannotUndo') }}
				<ng-container *ngIf="deleteEntity?.type === EntityTypes.ORGANIZATION">
					<div class="dmo-mt-16">{{ t('prompts.deleteEntity.orgAlertText') }}</div>
					<ul class="dmo-list-disc dmo-pl-16 dmo-mb-0">
						<ng-container
							*ngIf="metadata.length === 3 && metadata?.[0].type === 'deleteOrganizationWithActiveScenarios'"
						>
							<li
								[innerHTML]="t('prompts.deleteEntity.orgAlertTextActiveScenarios', { number:  metadata?.[0].data})"
							></li>
							<li
								[innerHTML]="t('prompts.deleteEntity.orgAlertTextActiveSubscription', { name:  metadata?.[1].data?.['activeSubscription']?.['name'] })"
							></li>
							<li>
								{{ t('prompts.deleteEntity.orgAlertTextOrgDeleted') }}
							</li>
						</ng-container>
						<ng-container
							*ngIf="metadata.length === 2 && metadata?.[0].type === 'deleteOrganizationWithActiveScenarios'"
						>
							<li
								[innerHTML]="t('prompts.deleteEntity.orgAlertTextActiveScenarios', { number:  metadata?.[0].data})"
							></li>
							<li>
								{{ t('prompts.deleteEntity.orgAlertTextOrgDeleted') }}
							</li>
						</ng-container>
						<ng-container
							*ngIf="metadata.length === 2 && metadata?.[0].type === 'deleteOrganizationWithActiveSubscription'"
						>
							<li
								[innerHTML]="t('prompts.deleteEntity.orgAlertTextActiveSubscription', { name:  metadata?.[0].data?.['activeSubscription']?.['name'] })"
							></li>
							<li>
								{{ t('prompts.deleteEntity.orgAlertTextOrgDeleted') }}
							</li>
						</ng-container>
						<li *ngIf="metadata.length === 1 && metadata?.[0].type === 'deleteOrganization'">
							{{ t('prompts.deleteEntity.orgAlertTextOrgDeleted') }}
						</li>
					</ul>
				</ng-container>
			</dmo-static-message>
			<div class="dmo-mt-16 delete-entity-text">
				<div [innerHTML]="t('prompts.deleteEntity.confirmText', { entityName: deleteEntity.name })"></div>
				<input
					type="text"
					class="dmo-mt-8 form-control"
					[(ngModel)]="entityInput"
					(ngModelChange)="onInputChange($event)"
				/>
			</div>
			<div *ngIf="deleteEntity?.type === EntityTypes.TEAM && metadata?.length > 1" class="dmo-mt-12">
				<div class="dmo-mb-12 dmo-text-sm">{{ t('prompts.deleteEntity.teamFooterText') }}</div>
				<ul class="dmo-mb-0">
					<ng-container *ngFor="let meta of metadata">
						<li *ngIf="meta?.type !== 'deleteTeam'" class="underline">
							<div *ngIf="meta.mappedData" class="delete-entity-scenarios-list dmo-overflow-y-auto">
								<a
									*ngFor="let item of meta.mappedData"
									target="_blank"
									class="list-group-item list-group-item-scenario dmo-pl-0"
									[routerLink]="item.link"
									[imtUiRouterLinkDomain]="item.zoneDomain"
								>
									<i
										class="list-group-icon dmo-no-underline"
										[ngClass]="getEntityIcon(item.entity)"
									></i>
									<div class="list-group-title dmo-text-sm">{{ item.name }}</div>
								</a>
							</div>
						</li>
					</ng-container>
				</ul>
			</div>
		</ng-container>
	</imt-ui-modal-body>
	<imt-ui-modal-footer
		[hideBtnPrimary]="hideConfirm"
		[hideBtnSecondary]="false"
		[disableBtnPrimary]="disableBtnPrimary"
		[disableBtnSecondary]="false"
		[btnPrimaryText]="btnPrimaryText"
		[btnPrimaryClass]="btnPrimaryClass"
		[btnSecondaryText]="btnSecondaryText"
		(submited)="onClose($event)"
		(dismissed)="onDismissed($event)"
	></imt-ui-modal-footer>
</imt-ui-modal-template>

import { Pg } from '@imt-web-zone/shared/util';

export class DlqApiModel {
	constructor(
		public id: string,
		public scenarioId: number,
		public reason: string,
		public created: Date,
		public size: number,
		public resolved: boolean,
		public retry: boolean,
		public attempts: number,
		public index: number,
		public executionId: string,
	) {}
}

/**
 * ListDlqsPayload
 */
export interface ListDlqsPayload {
	query: {
		scenarioId?: number;
		pg?: Pg<DlqApiModel, null>;
	};
}

/**
 * ListDlqsResponse
 */
export interface ListDlqsResponse {
	dlqs: Array<{
		id: string;
		reason: string;
		created: Date;
		size: number;
		resolved: boolean;
		retry: boolean;
	}>;
}

/**
 * DeleteDlqPayload
 */
export interface DeleteDlqPayload {
	query: {
		scenarioId: string;
	};
	payload: {
		ids?: Array<string>;
		exceptIds?: Array<string>;
		all: boolean;
	};
}

/**
 * DeleteDlqResponse
 */
export interface DeleteDlqResponse {
	dlqs: Array<string>;
	error: {
		message: string;
	};
}

/**
 * ItemDlqPayload
 */

export interface ItemDlqPayload {
	params: {
		dlqId: string;
	};
}

/**
 * ItemDlqResponse
 */

export interface ItemDlqResponse {
	dlq: {
		id: string;
		scenarioId: number;
		scenarioName: string;
		companyId: number;
		companyName: string;
		resolved: boolean;
		deleted: boolean;
		index: number;
		created: Date;
		executionId: string;
		retry: boolean;
		attempts: number;
		size: number;
	};
}

import { Subject } from 'rxjs';

export class ActionCompleter {
	public cancelUncompleted$ = new Subject<void>();

	public cancelPreviousAction(): void {
		this.cancelUncompleted$.next();
	}
}

export function createActionCompleter(cancelUncompleted?: boolean): ActionCompleter | null {
	return cancelUncompleted ? new ActionCompleter() : null;
}

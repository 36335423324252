export function isEqual(valueA: any, valueB: any): boolean {
	// Check if the values are primitives or null/undefined
	if (valueA === valueB) {
		return true;
	}

	// Check if both values are objects
	if (typeof valueA === 'object' && typeof valueB === 'object' && valueA !== null && valueB !== null) {
		const keysA = Object.keys(valueA);
		const keysB = Object.keys(valueB);

		// Check if both objects have the same number of keys
		if (keysA.length !== keysB.length) {
			return false;
		}

		// Check if each key in valueA exists in valueB and has the same value
		for (const key of keysA) {
			if (!valueB.hasOwnProperty(key) || !isEqual(valueA[key], valueB[key])) {
				return false;
			}
		}

		return true;
	}

	// Values are not equal
	return false;
}

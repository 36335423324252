import { Injectable, Provider } from '@angular/core';
import { of } from 'rxjs';
import { IMT_UI_AUTH_USER_DATA } from '@imt-web-zone/shared/ui/components';
import { IMT_UI_TRANSLATE_SERVICE_TOKEN, ImtUiLangService } from '@imt-web-zone/shared/util-imt-translate';

export interface ImtUiConfig {
	langService: Provider;
	authUserData: Provider;
}

export const DEFAULT_IMT_UI_LANG_SERVICE: ImtUiLangService = new (function () {
	this.get = (key: string | Array<string>) => {
		return of(this.translate(key));
	};
	this.translate = (key: string | Array<string>) => {
		return typeof key === 'string'
			? key
			: key.reduce((val, k) => {
					val[k] = k;
					return val;
			  }, {});
	};
})();

export const DEFAULT_CONFIG: ImtUiConfig = {
	langService: {
		provide: IMT_UI_TRANSLATE_SERVICE_TOKEN,
		useValue: DEFAULT_IMT_UI_LANG_SERVICE,
	},
	authUserData: {
		provide: IMT_UI_AUTH_USER_DATA,
		useValue: of({ locale: 'en-us', timezoneOffset: '+0000' }),
	},
};

@Injectable({
	providedIn: 'root',
})
export class ImtUiService {
	public config: ImtUiConfig = DEFAULT_CONFIG;
}

import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '@imt-web-zone/shared/environments';

@Injectable({ providedIn: 'root' })
export class LocalstorageService {
	private storageEvent$: Observable<StorageEvent>;

	constructor() {
		this.storageEvent$ = fromEvent<StorageEvent>(window, 'storage');
	}

	public get userIdChanged$(): Observable<string> {
		return this.storageEvent$.pipe(
			filter((e) => e.key === environment.localStorage?.userIdKey && e.newValue !== e.oldValue),
			map((e) => e.newValue || ''),
		);
	}
}

import { AuthUserModel } from './auth-user.model';
import { removeUndefined, toString, toNumber } from '@imt-web-zone/shared/util-store';
import { AuthUserApiModel } from './auth.interface';

export class AuthUserAdapter {
	public static toStore(item: Partial<AuthUserApiModel>): AuthUserModel {
		return removeUndefined(
			new AuthUserModel(
				item.avatar,
				item.email,
				item.features,
				toString(item.id),
				item.locale,
				item.name,
				item.timezone,
				toString(item.timezoneId),
				toString(item.countryId),
				toString(item.localeId),
				toString(item.usersAdminsRoleId),
				item.hasPassword,
				item.forceSetPassword,
				item.language,
				item.tfaEnabled,
				item.tfaCodes,
				item.isAffiliatePartner,
				item.created,
			),
		);
	}

	public static toApi(item: Partial<AuthUserModel>): AuthUserApiModel {
		return removeUndefined(
			new AuthUserApiModel(
				item.avatar,
				item.email,
				item.features,
				toNumber(item.id),
				item.locale,
				item.name,
				item.timezone,
				toNumber(item.timezoneId),
				toNumber(item.countryId),
				toNumber(item.localeId),
				toNumber(item.usersAdminsRoleId),
				item.hasPassword,
				item.forceSetPassword,
				item.language,
				item.tfaEnabled,
				item.tfaCodes,
				item.isAffiliatePartner,
				item.created,
			),
		);
	}
}

import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { environment } from '@imt-web-zone/shared/environments';
import { ImtUiFormulaTranslateService } from '@imt-web-zone/zone/data-access-formula';
import { tap } from 'rxjs/operators';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';

@Injectable()
export class CashierInterceptor implements HttpInterceptor {
	private formulaTranslate = inject(ImtUiFormulaTranslateService);

	protected envBaseUrl?: string = environment.cashier?.baseUrl;
	protected envHeaders?: { [key: string]: string } = environment.cashier?.headers;
	protected apiConfigFacade = inject(ApiConfigFacade);

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			url: this.baseUrl(request.url),
			headers: this.addDefaultHeaders(request.headers),
		});

		return next.handle(request).pipe(
			tap((ev: HttpEvent<any>) => {
				if (ev instanceof HttpResponse) {
					this.formulaTranslate.interceptResponse(ev);
				}
			}),
		);
	}

	protected addDefaultHeaders(headers: HttpHeaders): HttpHeaders {
		for (const header in this.envHeaders) {
			if (this.envHeaders.hasOwnProperty(header)) {
				if (!headers.get(header)) {
					headers = headers.set(header, this.envHeaders[header]);
				}
			}
		}
		return headers;
	}

	protected baseUrl(url: string) {
		return `${this.envBaseUrl}${url}`;
	}
}

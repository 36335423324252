import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImtRoutes } from '@imt-web-zone/shared/util-store';
import { maintenanceModeRedirect } from '@imt-web-zone/zone/feature-maintenance-mode/redirect';
import { CodeownersTeamsEnum } from '@imt-web-zone/core/data-access-codeowners';
import { ApiConfigGuard } from '@imt-web-zone/zone/state-api-config';
import { authGuard } from '@imt-web-zone/zone/state-auth';
import { LocalesGuard } from '@imt-web-zone/zone/data-access-enums';

const routes: ImtRoutes = [
	{
		path: '',
		canActivate: [ApiConfigGuard, maintenanceModeRedirect()],
		children: [
			{
				path: 'login',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () => import('@imt-web-zone/zone/feature-public/login').then((m) => m.LoginModule),
			},
			{
				path: 'logout',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () => import('@imt-web-zone/zone/feature-public/logout').then((m) => m.LogoutModule),
			},
			{
				path: 'admin/login',
				data: {
					ddScope: CodeownersTeamsEnum.POSEIDON,
					theme: 'indigo',
				},
				loadChildren: () => import('@imt-web-zone/zone/feature-public/login').then((m) => m.LoginModule),
			},
			{
				path: 'change-password',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-public/force-password-change').then(
						(m) => m.ForcePasswordChangeModule,
					),
				canActivate: [authGuard, LocalesGuard],
				canActivateChild: [authGuard, LocalesGuard],
			},
			{
				path: 'accept/:hash',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-organization-invitation').then(
						(m) => m.OrganizationInvitationModule,
					),
				canActivate: [authGuard, LocalesGuard],
			},
			{
				path: 'create-connection/:teamId/:appId',
				data: { ddScope: 'atlas' },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-create-connection').then((m) => m.CreateConnectionModule),
				canActivate: [authGuard, LocalesGuard],
			},
			{
				path: 'zone-install',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () => import('@imt-web-zone/zone/feature-zone-install').then((m) => m.ZoneInstallModule),
				canActivate: [LocalesGuard],
			},
			{
				path: 'maintenance-mode',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-maintenance-mode').then((m) => m.MaintenanceModeModule),
				canActivate: [LocalesGuard],
			},
			{
				path: 'reset/:hash',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-public/reset-password').then((m) => m.ResetPasswordModule),
			},
			{
				path: 'sso',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () => import('@imt-web-zone/zone/feature-public/sso').then((m) => m.SsoModule),
			},
			{
				path: 'install',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-public/install-guide').then((m) => m.InstallGuideModule),
			},
			{
				path: 'app',
				data: { ddScope: CodeownersTeamsEnum.APPS_PLATFORM },
				loadChildren: () =>
					import('@imt-web-zone/zone/feature-apps/sdk-app-invite').then((m) => m.SdkAppInviteModule),
			},
			{
				path: '',
				loadChildren: () => import('@imt-web-zone/zone/feature-restricted').then((m) => m.ZoneModule),
			},
			{
				path: ':lang/kb',
				data: { ddScope: CodeownersTeamsEnum.POSEIDON },
				children: [
					{
						path: '**',
						loadComponent: () =>
							import('@imt-web-zone/zone/feature-public/help').then((m) => m.HelpPageComponent),
					},
				],
			},
			{
				path: 'error',
				loadComponent: () =>
					import('@imt-web-zone/zone/feature-public/error-pages').then((m) => m.Error5xxComponent),
				data: {
					customClasses: ['error-page'],
				},
			},
			{
				path: '**',
				loadComponent: () =>
					import('@imt-web-zone/zone/feature-public/error-pages').then((m) => m.Error404Component),
				data: {
					customClasses: ['error-page', 'gradient'],
				},
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

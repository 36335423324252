import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

export type UserActivityEvent = 'mousemove' | 'keyup';
export type ActivityEvent = 'initial-sanity-check' | 'dialog-prolong-button' | UserActivityEvent;

export interface SessionChecks {
	subscription?: Subscription;
	makeRequest?: boolean;
	timer: ReturnType<typeof setTimeout> | null;
	callBackFn: () => void;
}

export interface ActivityLogEvent {
	timestamp: string;
	userActivity: ActivityEvent;
	useActivityCorrelationId: string;
	type: string;
}

export class ActivityLogEventEntity implements ActivityLogEvent {
	public timestamp: string;

	constructor(public type: string, public useActivityCorrelationId: string, public userActivity: ActivityEvent) {
		this.timestamp = new Date().toISOString();
	}
}

@Injectable({
	providedIn: 'root',
})
export class SessionChecksService {
	public userSession = {
		interval: 2 * 60000, // 2 mins,
		modalShown: false,
	};
	public userSessionExpiresAt!: Date;

	private readonly userActivityEventsKey = 'userActivityEvents';

	private getLatestSessionCheckAndSetTimeout(
		sessionCheck: SessionChecks,
		sessionType: string,
		interval: number,
		sessionChecksLS: Record<string, any>,
	) {
		const timeSinceCheck = this.calculateTimeSinceCheck(sessionChecksLS, sessionType);
		// if there was less time since the last session check compared to interval -
		// set the timer with the difference, otherwise proceed with setting the intervals
		if (timeSinceCheck < interval) {
			if (!sessionCheck.timer) {
				sessionCheck.timer = setTimeout(() => {
					sessionCheck.timer = null;
					sessionCheck.makeRequest = true;
					this.getStorageAndStartInterval(sessionCheck);
				}, interval - timeSinceCheck);
			}
			sessionCheck.makeRequest = false;
		} else {
			sessionCheck.makeRequest = true;
		}
	}

	public getStorageAndStartInterval(
		sessionCheck: SessionChecks,
		sessionType?: string,
		checkForLastRequestTime?: boolean,
		interval?: number,
	) {
		const sessionChecksLS = this.getOrSetLocalStorage();

		if (sessionType && interval && sessionChecksLS && checkForLastRequestTime) {
			this.getLatestSessionCheckAndSetTimeout(sessionCheck, sessionType, interval, sessionChecksLS);
		}

		const subscription = sessionCheck.subscription;
		if ((!subscription || subscription?.closed) && sessionCheck.makeRequest) {
			sessionCheck.callBackFn();
		}
	}

	public calculateTimeSinceCheck(sessionChecksLS: Record<string, any>, sessionType: string) {
		const currentTime = new Date().getTime();
		return Math.abs(currentTime - new Date(sessionChecksLS[sessionType])?.getTime());
	}

	public getOrSetLocalStorage(key?: string, date?: Date | null, toggle?: boolean) {
		let sessionChecksLS;
		try {
			sessionChecksLS = JSON.parse(localStorage.getItem('sessionChecks') as string);
		} catch {
			return;
		}

		if (!key) {
			return sessionChecksLS;
		}
		sessionChecksLS[key] = date ? date : toggle ? toggle : new Date();
		this.setLocalStorage(sessionChecksLS);
	}

	public getSessionCheckItem(key: string) {
		return this.getSessionCheckItems()[key];
	}

	public setSessionCheckItem(key: string, value: any) {
		const items = this.getSessionCheckItems();
		items[key] = value;
		this.setLocalStorage(items);
	}

	private getSessionCheckItems() {
		let sessionChecksLS;
		try {
			sessionChecksLS = JSON.parse(localStorage.getItem('sessionChecks') as string);
		} catch {
			return {};
		}
		return sessionChecksLS;
	}

	public removeSessionCheckItem(key: string) {
		const sessionChecksLS = JSON.parse(localStorage.getItem('sessionChecks') as string);
		if (sessionChecksLS?.[key]) {
			delete sessionChecksLS[key];
		}
		this.setLocalStorage(sessionChecksLS);
	}

	public setLocalStorage(sessionCheck: object) {
		localStorage.setItem('sessionChecks', JSON.stringify(sessionCheck));
	}

	public storeUserActivityEvents(newEvent: ActivityLogEvent) {
		localStorage.setItem(
			this.userActivityEventsKey,
			JSON.stringify(this.filterAndSortEvents([...this.getUserActivityEventsFromLocalStorage(), newEvent])),
		);
	}

	public emitUserActivityEvents() {
		console.error(
			`This user event happened in last 30 minute:${JSON.stringify(
				this.filterAndSortEvents(this.getUserActivityEventsFromLocalStorage()),
			)} `,
		);
	}

	private getUserActivityEventsFromLocalStorage() {
		// Retrieve existing events from local storage
		const rawItems = localStorage.getItem(this.userActivityEventsKey);

		// Parse the events if they exist, otherwise initialize an empty array
		return rawItems ? (JSON.parse(rawItems) as ActivityLogEvent[]) : [];
	}

	private filterAndSortEvents(items: ActivityLogEvent[]) {
		// Get the current time and calculate the cutoff time (30 minutes ago)
		const now = new Date();
		const cutoffTime = new Date(now.getTime() - 30 * 60 * 1000);

		// Filter out events older than 30 minutes
		const filteredItems = items.filter((item) => new Date(item.timestamp) >= cutoffTime);

		// Sort the items based on timestamp from newest to oldest
		filteredItems.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
		return filteredItems;
	}
}

import { generateActionObject } from '../internal/internal';
import { EntityState } from '../internal/entity-state';
import { Type } from '@angular/core';
import { EntityActionType } from './type-alias';
import { StateToken } from '@ngxs/store';

export interface EntitySetErrorAction {
	payload: Error;
}

export class SetError {
	/**
	 * Generates an action that will set the error state for the given state.
	 * Put undefined to clear the error state.
	 * @param target The targeted state class
	 * @param error The error that describes the error state
	 * @param context Text that will be appended to the action type in redux devtools
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, error: Error | undefined, context: string) {
		return generateActionObject(EntityActionType.SetError, target, error).setContext(context);
	}
}

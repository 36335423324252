import {
	Component,
	ChangeDetectionStrategy,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	EventEmitter,
	Output,
	inject,
} from '@angular/core';
import { Params } from '@angular/router';
import { ImtUiModalActive } from '@imt-web-zone/shared/ui-modal';
import { UiPanelMenuService } from '@imt-web-zone/shared/ui-panel-menu';
import { UiCountdownChange, UiCountdownState } from '@imt-web-zone/shared/ui-countdown';

@Component({
	templateUrl: './imt-ui-confirm-dialog.component.html',
	styleUrls: ['./imt-ui-confirm-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImtUiConfirmDialogComponent {
	public activeModal = inject(ImtUiModalActive);
	private cdr = inject(ChangeDetectorRef);
	private panelMenu = inject(UiPanelMenuService);

	public title = '';
	public confirmMessageHTML: string | null = null;
	public confirmMessage = '';
	public titleData: Params = {};
	public confirmMessageData: Params = {};
	public btnPrimaryText = '';
	public btnSecondaryText = '';
	public error: any;
	public hideBtnPrimary = false;
	public hasAdvancedParameters = false;
	public hasForman = false;
	public hideOptionMenu = false;
	public userSession = false;
	public spacingBetweenButtons = false;
	public countdownMessage: string | null = null;
	public countdownDate: Date | null = null;

	public closeAction?: () => void;
	public dismissAction?: () => void;
	public submitAction?: () => void;
	public countdownAction?: () => void;
	public get errorBulletPoints() {
		if (!this.error) {
			return undefined;
		}

		return (this.error.suberrors || []).map((e: any) => e.message);
	}

	private advancedParameters = false;

	@Output() public showAdvancedParameters = new EventEmitter<boolean>();
	@ViewChild('modalBody') public modalBody!: ElementRef;

	public detectChanges(): void {
		this.cdr.detectChanges();
	}

	public openPanelMenu(relative: Element) {
		this.panelMenu.open(relative);
	}

	public toggleAdvacedParameters() {
		this.advancedParameters = !this.advancedParameters;
		this.showAdvancedParameters.next(this.advancedParameters);
	}

	public countdownChange() {
		return (change: UiCountdownChange) => {
			const countdownFinished = change.state === UiCountdownState.Finished && change.timestamp === 0;

			if (countdownFinished && this.countdownAction) {
				this.countdownAction();
			}
		};
	}
}

<div class="dmo-my-8 dmo-leading-none">
	<i
		class="dmo-text-base dmo-icon dmo-leading-none"
		data-testid="toast-message-icon"
		[ngClass]="{
			'dmo-fa-solid': type !== UiToastMessageType.DANGER,
			'before:dmo-fa-circle-info': type === UiToastMessageType.INFO || type === UiToastMessageType.BRAND,
			'before:dmo-fa-circle-check': type === UiToastMessageType.SUCCESS,
			'before:dmo-fa-hexagon-exclamation': type === UiToastMessageType.WARNING,
			'domino-icon-error-circle-solid': type === UiToastMessageType.DANGER,
		}"
	></i>
</div>
<div class="dmo-flex-grow dmo-text-sm dmo-mt-8 dmo-mb-4" data-testid="toast-message-body">
	<header *ngIf="title" class="dmo-font-bold" data-testid="toast-message-title">{{ title }}</header>
	<dmo-embed-content [content]="text" data-testid="toast-message-content"></dmo-embed-content>
	<div
		*ngIf="buttonLabel"
		class="dmo-mt-8"
		[ngClass]="{
			'min-[400px]:dmo-hidden': buttonPosition === UiToastMessageButtonPosition.RIGHT
		}"
	>
		<ng-container *ngTemplateOutlet="button; context: { testid: 'toast-message-cta-bottom' }"></ng-container>
	</div>
</div>
<div
	*ngIf="buttonLabel && buttonPosition === UiToastMessageButtonPosition.RIGHT"
	class="max-[400px]:dmo-hidden dmo-mt-2"
>
	<ng-container *ngTemplateOutlet="button; context: { testid: 'toast-message-cta-right' }"></ng-container>
</div>
<div class="dmo-mt-8 dmo-mr-2 dmo-leading-none">
	<button (click)="closeClicked.emit()" data-testid="toast-message-close-btn">
		<i class="dmo-far before:dmo-fa-xmark dmo-text-base dmo-icon dmo-leading-none"></i>
	</button>
</div>

<ng-template #button let-testid="testid">
	<dmo-notification-button subdued (click)="buttonClicked.emit()" [type]="buttonType" [attr.data-testid]="testid">
		{{ buttonLabel }}
	</dmo-notification-button>
</ng-template>

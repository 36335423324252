import { TranslocoService } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

export class TranslateExtension {
	constructor(private transloco: TranslocoService) {}
	public prePopulate(field: FormlyFieldConfig) {
		const to = field.templateOptions || {};
		if (!to.translate || to._translated) {
			return;
		}

		to._translated = true;
		field.expressionProperties = {
			'templateOptions.placeholder': this.transloco.selectTranslate(to.placeholder),
			'templateOptions.label': this.transloco.selectTranslate(to.label),
		};
	}
}

export function registerTranslateExtension(translate: TranslocoService) {
	return {
		extensions: [
			{
				name: 'translate',
				extension: new TranslateExtension(translate),
			},
		],
	};
}

import {
	createAsyncLoadAction,
	ActionType,
	createAction,
	props,
	createAsyncAction,
} from '@imt-web-zone/shared/util-store';
import {
	DisableOrganizationSsoPayload,
	DisableOrganizationSsoResponse,
	LoadOrganizationDataStoresMetadataPayload, LoadOrganizationDataStoresMetadataResponse
} from '.';
import {
	DeleteOrganizationPayload,
	DeleteOrganizationResponse,
	DetailOrganizationPayload,
	DetailOrganizationResponse,
	EnableDisableOrganizationFeaturePayload,
	EnableDisableOrganizationFeatureResponse,
	ListAdminOrganizationsPayload,
	ListAdminOrganizationsResponse,
	ListOrganizationsPayload,
	ListOrganizationsResponse,
} from './organizations.interface';
import { OrganizationModel } from './organizations.model';

// tslint:disable:max-line-length

export const listOrganizationsRequest = createAsyncLoadAction<ListOrganizationsPayload, ListOrganizationsResponse>(
	'[Organizations API] GET list organizations',
);
export type ListOrganizationsRequest = ActionType<typeof listOrganizationsRequest>;

export const detailOrganizationRequest = createAsyncLoadAction<
	DetailOrganizationPayload,
	DetailOrganizationResponse,
	{ activateOrganization?: boolean }
>('[Organizations API] GET detail organization');
export type DetailOrganizationRequest = ActionType<typeof detailOrganizationRequest>;

export const setActiveOrganization = createAction(
	'[Organizations] set active',
	props<{
		payload: { name?: string; id: string | null, license?: OrganizationModel['license'] };
	}>(),
);
export type SetActiveOrganization = ActionType<typeof setActiveOrganization>;

export const addOrganizationWithRedirect = createAction(
	'[Organizations] add organization',
	props<{
		payload: OrganizationModel;
		redirect: boolean;
	}>(),
);
export type AddOrganizationWithRedirect = ActionType<typeof addOrganizationWithRedirect>;

export const deleteOrganizationRequest = createAsyncAction<DeleteOrganizationPayload, DeleteOrganizationResponse>(
	'[Organizations API] Delete',
);
export type DeleteOrganizationRequest = ActionType<typeof deleteOrganizationRequest>;

export const disableOrganizationSsoRequest= createAsyncAction<
	DisableOrganizationSsoPayload, DisableOrganizationSsoResponse
>(
	'[Organizations] SSO disable',
);
export type DisableOrganizationSsoRequest = ActionType<typeof disableOrganizationSsoRequest>;

export const listAdminOrganizationsRequest = createAsyncLoadAction<
	ListAdminOrganizationsPayload,
	ListAdminOrganizationsResponse
>('[Organizations API] GET list admin organizations');
export type ListAdminOrganizationsRequest = ActionType<typeof listAdminOrganizationsRequest>;

export const enableDisableOrganizationFeatureRequest = createAsyncAction<
	EnableDisableOrganizationFeaturePayload,
	EnableDisableOrganizationFeatureResponse
>('[Organizations API] POST enable admin organization feature');
export type EnableDisableOrganizationFeatureRequest = ActionType<typeof enableDisableOrganizationFeatureRequest>;

export const loadOrganizationDataStoresMetadata = createAsyncLoadAction<LoadOrganizationDataStoresMetadataPayload, LoadOrganizationDataStoresMetadataResponse>('[Organizations API] load organization data stores metadata');
export type LoadOrganizationDataStoresMetadata = ActionType<typeof loadOrganizationDataStoresMetadata>;

export const changedActiveOrganization = createAction('[Organizations API] changed active organization');
export type ChangedActiveOrganization = ActionType<typeof changedActiveOrganization>;

export function Getter() {
	return function (target: any, key: string) {
		Object.defineProperty(target, key, {
			configurable: false,
			get: () => {
				return target[`__${key}`]();
			},
			set: (value: any) => {
				if (typeof value !== 'function') {
					throw new Error(`Value of "${key}" must be a function!`);
				}
				target[`__${key}`] = value;
			},
		});
	};
}

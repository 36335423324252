import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { StateBaseFacadeAbstractClass } from '@imt-web-zone/core/util-state-facade';
import { UpdateRouteParams } from './router.actions';
import { RouterSelectors } from '@imt-web-zone/shared/util-store';

@Injectable({ providedIn: 'root' })
export class RouterFacade extends StateBaseFacadeAbstractClass {
	public urlState$ = this.store.select$(RouterSelectors.urlState);
	public data$ = this.store.select$(RouterSelectors.data);
	public params$ = this.store.select$(RouterSelectors.params);
	public queryParams$ = this.store.select$(RouterSelectors.queryParams);
	public url$ = this.store.select$(RouterSelectors.url);

	public urlStateRxSnapshot = this.rxSnapshotFn(RouterSelectors.urlState);
	public urlRxSnapshot = this.rxSnapshotFn(RouterSelectors.url);
	public dataRxSnapshot = this.rxSnapshotFn(RouterSelectors.data);
	public paramsRxSnapshot = this.rxSnapshotFn(RouterSelectors.params);
	public queryParamsRxSnapshot = this.rxSnapshotFn(RouterSelectors.queryParams);

	public urlStateSignal = this.store.selectSignal(RouterSelectors.urlState);
	public urlSignal = this.store.selectSignal(RouterSelectors.url);
	public dataSignal = this.store.selectSignal(RouterSelectors.data);
	public paramsSignal = this.store.selectSignal(RouterSelectors.params);
	public queryParamsSignal = this.store.selectSignal(RouterSelectors.queryParams);

	public updateRouteParams$(params: Params, replace = false) {
		return this.store.dispatch$(new UpdateRouteParams(params, replace));
	}
}

import { Selector, Store } from '@ngxs/store';
import { environment } from '@imt-web-zone/shared/environments';
import { debounceTime } from 'rxjs';
import { COMMON_STATE_TOKEN } from './common.symbols';
import { CommonModel } from './common.model';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApiConfigData, ApiConfigSelectors, ModeEnum } from '@imt-web-zone/zone/state-api-config';

export class CommonSelectors {
	public static hasPendingRequests$(store: Store) {
		return store.select(this.hasPendingRequests).pipe(debounceTime(10));
	}

	@Selector([COMMON_STATE_TOKEN])
	private static hasPendingRequests(state: CommonModel) {
		return state && state.pendingRequests > 0;
	}

	@Selector([COMMON_STATE_TOKEN])
	public static initialUrl(state: CommonModel) {
		return state && state.initialUrl;
	}

	@Selector([COMMON_STATE_TOKEN])
	public static showLogoIndicator(state: CommonModel) {
		return state && state.isLogoIndicatorVisible;
	}

	@Selector([COMMON_STATE_TOKEN, ApiConfigSelectors.apiConfig])
	public static getLogoUrl(state: CommonModel, apiConfig: ApiConfigData) {
		if (apiConfig.mode === ModeEnum.SLAVE) {
			return `https://${apiConfig.slaveDomains.cdn}/img/make/make_app_white_logo.png`;
		}
		return environment?.appConfig?.logo + '?t=' + state?.lastLogoChange;
	}

	@Selector([COMMON_STATE_TOKEN, ApiConfigSelectors.apiConfig])
	public static getLogoInvertedUrl(state: CommonModel, apiConfig: ApiConfigData) {
		if (apiConfig.mode === ModeEnum.SLAVE) {
			return `https://${apiConfig.slaveDomains.cdn}/img/make/_m_color.png`;
		}
		return environment?.appConfig?.logoInverted + '?t=' + state?.lastLogoChange;
	}
}

import { ImtUiTheme } from '@imt-web-zone/shared/model';
import { mergeRecord } from '@imt-web-zone/shared/util';
import { EnvironmentInterface } from './environment.interface';
import { environmentBase } from './environment-base';

const environmentZoneBase: EnvironmentInterface = mergeRecord(
	{
		serviceWorker: {
			enable: false,
			repeatVersionNotificationInterval: 1000 * 60 * 10, // 10 min
			serviceWorkerCheckInterval: 1000 * 60, // 60 sec
		},
		appConfig: {
			logo: '/logo.png',
			logoInverted: '/logo_inverted.png',
			favicon: '/favicon.ico',
			defaultTheme: 'violet',
			defaultLang: 'en',
		},
		adminUiTheme: ImtUiTheme.INDIGO,
		versions: {
			app: process.env.VERSION as string,
			forman: process.env.FORMAN_VERSION,
			inspector: process.env.INSPECTOR_VERSION,
			dominoElements: process.env.DOMINO_ELEMENTS_VERSION,
			tailwindThemes: process.env.TAILWIND_THEMES_VERSION,
			iml: process.env.IML_VERSION,
		},
		nativeApps: {
			connectionToInstallationTimeoutTime: 1000 * 60 * 5, // 5 minutes
			installationTimeoutTime: 1000 * 60 * 15, // 15 minutes
			checkInstallationPeriod: 500, // 500 ms
			installationCompleted: 1500, // 500 ms
		},
		notifications: {
			checkUnreadPeriod: 1000 * 60 * 5, // 5 minutes
		},
		admin: {
			hqSyncPeriod: 1000 * 60 * 10, // 10 minutes,
		},
		loadLocalesTimeout: 1000 * 5, // 5 seconds
	},
	environmentBase,
);

export { environmentZoneBase };

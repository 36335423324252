import { generateActionObject } from '../internal/internal';
import { EntityActionType, EntitySelector, Payload } from './type-alias';
import { EntityState, EntityType } from '../internal/entity-state';
import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';

export type EntityRemoveAction<T> = Payload<EntitySelector<T>>;

export class Remove<T extends Record<string, any>> {
	/**
	 * Generates an action that will remove the given entities from the state.
	 * Put null if all entities should be removed.
	 * @param target The targeted state class
	 * @param payload An EntitySelector payload
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see EntitySelector
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		payload: EntitySelector<T extends CollectionStateModel<any> ? EntityType<T> : T>,
		context: string,
	) {
		return generateActionObject(EntityActionType.Remove, target, payload).setContext(context);
	}
}

<div class="modal-header modal-confirm" [class.modal-forman]="hasForman">
	<imt-ui-modal-header (dissmised)="closeAction ? closeAction() : activeModal.close(false)">
		{{ title | transloco : titleData }}

		<button
			*ngIf="!hideOptionMenu"
			class="panel-menu"
			type="button"
			imt-ui-modal-header-button
			#panelMenu
			(click)="openPanelMenu(panelMenu)"
		>
			<i class="fas fa-ellipsis-v"></i>
		</button>
	</imt-ui-modal-header>
</div>
<div #modalBody class="modal-body modal-confirm" [class.modal-user-session]="userSession">
	<dmo-static-message
		*ngIf="error"
		[bulletPoints]="errorBulletPoints"
		[title]="error.message"
		type="danger"
		class="dmo-mb-10"
	>
		<ng-container *ngIf="error.detail">{{ error.detail }}</ng-container>
	</dmo-static-message>
	<imt-ui-modal-body *ngIf="confirmMessageHTML || confirmMessage">
		<p class="underline" [innerHTML]="confirmMessageHTML || (confirmMessage | transloco : confirmMessageData)"></p>
		<ng-container *ngIf="countdownMessage">
			<div class="dmo-flex dmo-justify-center font-weight-bold dmo-mt-0 dmo-pb-16">
				<span>{{ countdownMessage }}</span>
				<ng-container *ngIf="countdownDate">
					<span
						class="dmo-ml-4"
						*imtUiCountdown="countdownDate; timestamp as t; state as s; change: countdownChange()"
						[innerHTML]="t | date : 'mm:ss'"
					>
					</span
					>.
				</ng-container>
			</div>
		</ng-container>
	</imt-ui-modal-body>
</div>
<div
	class="modal-footer modal-confirm"
	[class.justify-content-between]="hasAdvancedParameters"
	[class.modal-user-session]="userSession"
>
	<div *ngIf="hasAdvancedParameters" class="d-flex justify-content-center align-items-center">
		<imt-switch id="modal-switch" class="xs" (input)="toggleAdvacedParameters()"></imt-switch>
		<label class="show-advanced-label m-0 pl-2" for="modal-switch">{{ 'common.showAdvanced' | transloco }}</label>
	</div>

	<imt-ui-modal-footer
		[hideBtnPrimary]="hideBtnPrimary"
		[spacingBetweenButtons]="spacingBetweenButtons"
		[hideBtnSecondary]="false"
		[disableBtnPrimary]="false"
		[disableBtnSecondary]="false"
		[btnPrimaryText]="btnPrimaryText"
		[btnSecondaryText]="btnSecondaryText"
		(submited)="submitAction ? submitAction() : activeModal.close(true)"
		(dismissed)="dismissAction ? dismissAction() : activeModal.close(false)"
	>
	</imt-ui-modal-footer>
</div>

import { Type } from '@angular/core';

import { Actions, ofActionSuccessful, StateToken, Store } from '@ngxs/store';
import { defer, filter, ignoreElements, map, merge, mergeMap, Observable, pipe, race, take } from 'rxjs';

import { effectError, effectFromCache, effectSuccess } from '@imt-web-zone/core/util-state-effect';
import { HttpBuilder } from '@imt-web-zone/shared/util';
import {
	Add,
	ClearActive,
	CollectionState,
	CreateOrReplace,
	EffectAction,
	EntityStateModel,
	HashMap,
	Remove,
	RemoveActive,
	Reset,
	RunEffectAction,
	SetActive,
	SetError,
	SetLoading,
	Update,
	UpdateActive,
	Upsert,
} from '@imt-web-zone/shared/util-store';

import { GenericTypeFromStateToken, StateFacadeAbstract } from './state-facade.abstract';
import { FacadeStore } from './state-facade.store';
import { EffectMetadata, EffectSuccessfulOrCachedData, isFacadeStore } from './internals';

type GenericActionParams<T extends Type<any>> = RemoveFirstFromTuple<ConstructorParameters<T>>;
type RemoveFirstFromTuple<T extends unknown[]> = T extends [unknown, ...infer R] ? R : T;

function createEffectMetadata(action: EffectAction): EffectMetadata {
	return {
		fromCache: action.fromCache || false,
		response: action.response,
	};
}

export abstract class CollectionFacadeAbstract<
	T extends StateToken<any>,
	S extends HttpBuilder,
> extends StateFacadeAbstract<T, S> {
	/**
	 * Generates an action that will add the given entities to the state.
	 * The entities given by the payload will be added.
	 * For certain ID strategies this might fail, if it provides an existing ID.
	 * In all other cases it will overwrite the ID value in the entity with the calculated ID.
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link Add} for implementation details.
	 */
	public add$ = this.getCollectionFacadeAction(Add<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that will add the given entities to the state.
	 * If an entity with the ID already exists, it will be overridden.
	 * In all cases it will overwrite the ID value in the entity with the calculated ID.
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link CreateOrReplace} for implementation details.
	 */
	public createOrReplace$ = this.getCollectionFacadeAction(CreateOrReplace<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that will add the given entities to the state.
	 * If an entity with the ID already exists, it will be Patch current state.
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link Upsert} for implementation details.
	 */
	public upsert$ = this.getCollectionFacadeAction(Upsert<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that will update the current active entity.
	 * If no entity is active a runtime error will be thrown.
	 * @param id An EntitySelector that determines the entities to update
	 * @param data An Updater that will be applied to the selected entities
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link Update} for implementation details.
	 */
	public update$ = this.getCollectionFacadeAction(Update<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that will update the current active entity.
	 * If no entity is active a runtime error will be thrown.
	 * @param payload An Updater payload
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link UpdateActive} for implementation details.
	 */
	public updateActive$ = this.getCollectionFacadeAction(UpdateActive<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that will remove the given entities from the state.
	 * Put null if all entities should be removed.
	 * @param payload An EntitySelector payload
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link Remove} for implementation details.
	 */
	public remove$ = this.getCollectionFacadeAction(Remove<GenericTypeFromStateToken<T>>);

	/**
	 * Generates an action that removes the active entity from the state and clears the active ID.
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link RemoveActive} for implementation details.
	 */
	public removeActive$ = this.getCollectionFacadeAction(RemoveActive);

	/**
	 * Generates an action that will set the loading state for the given state.
	 * @param loading The loading state
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link SetLoading} for implementation details.
	 */
	public setLoading$ = this.getCollectionFacadeAction(SetLoading);

	/**
	 * Generates an action that will set the error state for the given state.
	 * Put undefined to clear the error state.
	 * @param error The error that describes the error state
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link SetError} for implementation details.
	 */
	public setError$ = this.getCollectionFacadeAction(SetError);

	/**
	 * Generates an action that sets an ID that identifies the active entity
	 * @param id The ID that identifies the active entity
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link SetActive} for implementation details.
	 */
	public setActive$ = this.getCollectionFacadeAction(SetActive);

	/**
	 * Generates an action that clears the active entity in the given state
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link ClearActive} for implementation details.
	 */
	public clearActive$ = this.getCollectionFacadeAction(ClearActive);

	/**
	 * Resets the targeted store to the default state: no entities, loading is false, error is undefined,
	 * active is undefined.
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see {@link Reset} for implementation details.
	 */
	public reset$ = this.getCollectionFacadeAction(Reset);

	/**
	 * Reactive View Snapshots
	 *
	 * select data as snapshot - each time the selector emits, it performs change detection
	 * @deprecated use activeSignal instead
	 */
	public activeRxSnapshot = this.rxSnapshotFn(CollectionState.getActive(this.stateToken));
	/**
	 * @deprecated use activeIdSignal instead
	 */
	public activeIdRxSnapshot = this.rxSnapshotFn(CollectionState.getActiveId(this.stateToken));
	/**
	 * @deprecated use entitiesSignal instead
	 */
	public entitiesRxSnapshot = this.rxSnapshotFn(CollectionState.getEntities(this.stateToken));
	/**
	 * @deprecated use idsSignal instead
	 */
	public idsRxSnapshot = this.rxSnapshotFn(CollectionState.getKeys(this.stateToken));
	/**
	 * @deprecated use entityMapSignal instead
	 */
	public entityMapRxSnapshot = this.rxSnapshotFn(CollectionState.getEntitiesMap(this.stateToken));
	/**
	 * @deprecated use loadingSignal instead
	 */
	public loadingRxSnapshot = this.rxSnapshotFn(CollectionState.getLoading(this.stateToken));
	/**
	 * @deprecated use errorSignal instead
	 */
	public errorRxSnapshot = this.rxSnapshotFn(CollectionState.getError(this.stateToken));
	/**
	 * @deprecated use entityByIdSignal instead
	 */
	public entityByIdRxSnapshot(id: string) {
		return this.rxSnapshotFn(CollectionState.getById(this.stateToken, id));
	}

	/**
	 * select as signal
	 */
	public activeSignal = this.store.selectSignal(CollectionState.getActive(this.stateToken));
	public activeIdSignal = this.store.selectSignal(CollectionState.getActiveId(this.stateToken));
	public entitiesSignal = this.store.selectSignal(CollectionState.getEntities(this.stateToken));
	public idsSignal = this.store.selectSignal(CollectionState.getKeys(this.stateToken));
	public entityMapSignal = this.store.selectSignal(CollectionState.getEntitiesMap(this.stateToken));
	public loadingSignal = this.store.selectSignal(CollectionState.getLoading(this.stateToken));
	public errorSignal = this.store.selectSignal(CollectionState.getError(this.stateToken));
	public entityByIdSignal(id: string) {
		return this.store.selectSignal(CollectionState.getById(this.stateToken, id));
	}

	/**
	 * select as observable
	 */
	public active$ = this.store.select$(CollectionState.getActive(this.stateToken));
	public activeId$ = this.store.select$(CollectionState.getActiveId(this.stateToken));
	public entities$ = this.store.select$(CollectionState.getEntities(this.stateToken));
	public ids$ = this.store.select$(CollectionState.getKeys(this.stateToken));
	public entityMap$ = this.store.select$(CollectionState.getEntitiesMap(this.stateToken));
	public entityById$(id: string) {
		return this.store.select$(CollectionState.getById(this.stateToken, id));
	}
	public loading$ = this.store.select$(CollectionState.getLoading(this.stateToken));
	public error$ = this.store.select$(CollectionState.getError(this.stateToken));

	/**
	 * function that formats result from #effectSuccessfulOrCachedSource$()
	 *
	 * and returns data from the state for related entity
	 *
	 */
	public static staticEffectSuccessfulOrCached$<M, S extends HttpBuilder>(
		method: keyof S,
		store: Store | FacadeStore,
		actions$: Actions,
		stateToken: StateToken,
		predicate?: (action: EffectAction) => boolean,
	): Observable<EffectSuccessfulOrCachedData<M>> {
		return CollectionFacadeAbstract.effectSuccessfulOrCachedSource$(method, actions$, stateToken, predicate).pipe(
			take(1),
			mergeMap((action) => {
				const selector = CollectionState.getEntitiesMap<EntityStateModel<M>>(stateToken);

				const mapEntities = () =>
					pipe(
						map((entities: HashMap<any>) => {
							const data = store.selectSnapshot(
								CollectionState.getCacheData(stateToken, action.prevAction.cacheKey || ''),
							);
							entities = data?.ids.reduce(
								(acc, currVal) => ({ ...acc, [currVal]: entities[currVal] }),
								{},
							);
							return {
								entities: entities || {},
								ids: data?.ids || [],
								metadata: data?.metadata,
								...createEffectMetadata(action),
							} as EffectSuccessfulOrCachedData<M> & EffectMetadata;
						}),
					);

				// Since `Store` can be provided outside of this class, we have to make sure,
				// that `store.select$` exists, otherwise original `store.select` is used as a fallback.
				if (isFacadeStore(store)) {
					return store.select$(selector).pipe(mapEntities());
				}
				return store.select(selector).pipe(mapEntities());
			}),
		);
	}

	/**
	 * Listens for "Success", "FromCache" or "Error" actions dispatched from the inserted action.
	 *
	 * Observable completes on first emitted action.
	 *
	 * @private
	 */
	private static effectSuccessfulOrCachedSource$<S extends HttpBuilder>(
		method: keyof S,
		actions$: Actions,
		stateToken: StateToken,
		predicate?: (action: EffectAction) => boolean,
	) {
		let source$ = race(
			actions$.pipe(ofActionSuccessful(effectFromCache<S>(stateToken, method))),
			actions$.pipe(ofActionSuccessful(effectSuccess<S>(stateToken, method))),
			actions$.pipe(ofActionSuccessful(effectError<S>(stateToken, method))),
		);

		if (predicate) {
			source$ = source$.pipe(filter(predicate));
		}

		return source$.pipe(
			map((action) => {
				if (action.error) {
					throw action;
				}
				return action;
			}),
		);
	}

	/**
	 * select data as snapshots
	 */
	public get activeSnapshot() {
		return this.store.selectSnapshot(CollectionState.getActive(this.stateToken));
	}
	public get activeIdSnapshot() {
		return this.store.selectSnapshot(CollectionState.getActiveId(this.stateToken));
	}
	public get entitiesSnapshot() {
		return this.store.selectSnapshot(CollectionState.getEntities(this.stateToken));
	}
	public get idsSnapshot() {
		return this.store.selectSnapshot(CollectionState.getKeys(this.stateToken));
	}
	public get entityMapSnapshot() {
		return this.store.selectSnapshot(CollectionState.getEntitiesMap(this.stateToken));
	}
	public get loadingSnapshot() {
		return this.store.selectSnapshot(CollectionState.getLoading(this.stateToken));
	}

	public get errorSnapshot() {
		return this.store.selectSnapshot(CollectionState.getError(this.stateToken));
	}

	public entityByIdSnapshot(id: string) {
		return this.store.selectSnapshot(CollectionState.getById(this.stateToken, id));
	}

	protected constructor(protected override stateToken: StateToken<GenericTypeFromStateToken<T>>) {
		super(stateToken);
	}

	/**
	 * @deprecated better use dispatchEffect$ - this method will be protected one day
	 *
	 * It waits for the completion one of the "effectFromCache" or "effectSuccess" action and returns map of entities
	 * and array of ids from the store which were set stored based on that effect.
	 *
	 * Takes the first value, then completes.
	 *
	 * In case of "effectError" action, it throws an error.
	 */
	public onceEffectSuccessfulOrCached$(method: keyof S, predicate?: (action: EffectAction) => boolean) {
		return CollectionFacadeAbstract.staticEffectSuccessfulOrCached$<any, S>(
			method,
			this.store,
			this.actions$,
			this.stateToken,
			predicate,
		).pipe(take(1));
	}

	/**
	 * function that formats result from #effectSuccessfulOrCachedSource$(), read description there
	 *
	 */
	protected dispatchEffect$(action: RunEffectAction): Observable<EffectMetadata> {
		const effectSuccessfulOrCachedSource$ = CollectionFacadeAbstract.effectSuccessfulOrCachedSource$(
			action.serviceMethodName as keyof S,
			this.actions$,
			this.stateToken,
			(listenedAction) => listenedAction.prevAction.uid === action.uid,
		);
		return merge(
			effectSuccessfulOrCachedSource$.pipe(map(createEffectMetadata)),
			defer(() => this.store.dispatch$(action).pipe(ignoreElements())),
		);
	}

	/**
	 * Creates a function that dispatches generic action by it's type, respecting it's params.
	 */
	private getCollectionFacadeAction<A extends Type<any>>(action: A) {
		return (...args: GenericActionParams<A>) => {
			return this.store.dispatch$(new action(this.stateToken, ...args));
		};
	}
}

import { Component, Input, Output, EventEmitter, ViewEncapsulation, inject } from '@angular/core';
import { ImtUiModalComponent } from '../imt-ui-modal.component';
import { DismissReason } from '../imt-ui-modal-base.component';

/**
 * Example of usage:
 * <example-url>/demo/modal</example-url>
 */

@Component({
	selector: 'imt-ui-modal-header',
	templateUrl: './imt-ui-modal-header.component.html',
	styleUrls: ['./imt-ui-modal-header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ImtUiModalHeaderComponent {
	private parent = inject(ImtUiModalComponent, { host: true, optional: true });

	@Input() public hideCrossBtn = false;
	/**
	 * Modal dissmiss reason
	 */
	@Input() public reason: DismissReason = DismissReason.CrossClick;

	@Output() public dissmised: EventEmitter<DismissReason> = new EventEmitter();

	public onDismiss(reason?: DismissReason): void {
		if (this.parent) {
			this.parent.onDismiss(reason);
		}
		if (this.dissmised) {
			this.dissmised.emit(reason);
		}
	}
}

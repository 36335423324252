import { Type } from '@angular/core';
import { NgxsOnInit, StateContext, StateToken } from '@ngxs/store';
import { CollectionStateModel } from './collection.model';
import { Observable } from 'rxjs';
import { CacheFunctions, CacheStateSelector } from '../cache/cache.functions';
import { CacheMap, CacheModelState } from '../cache/cache.model';
import { EntityState, ArrayOptions, SortingOptions } from './internal/entity-state';
import { IdStrategy } from './internal/id-strategy';
import { Action } from '../async-action/action-models';
import { AsyncAction } from '../async-action/async-action';
import { adaptToApi, adaptToStore } from '@imt-web-zone/core/util-state-model-adapter';
import { effectFactory, RunEffectAction } from '../effect';

// @dynamic
export class CollectionState<T extends Record<string, any>> extends EntityState<T> implements NgxsOnInit {
	public static getCache(stateToken: StateToken): CacheStateSelector<CacheMap> {
		return CacheFunctions.getCacheSelector(stateToken);
	}

	public static getCacheData(stateToken: StateToken, urlKey: string) {
		return CacheFunctions.getCacheDataSelector(stateToken, urlKey);
	}

	public static getCacheExists(stateToken: StateToken): CacheStateSelector<boolean> {
		return CacheFunctions.getCacheExistsSelector(stateToken);
	}

	constructor(
		storeClass: Type<EntityState<T>>,
		_idKey: keyof T,
		idStrategy: Type<IdStrategy.IdGenerator<T>>,
		sortingOptions?: SortingOptions<T>,
	) {
		super(storeClass, _idKey, idStrategy, sortingOptions);
	}

	public ngxsOnInit(ctx: StateContext<T>) {
		const state = ctx.getState();
		ctx.patchState({ ...state, ...{ idKey: this.idKey, storeKey: this.storePath } });
	}

	public clearCache(ctx: StateContext<CacheModelState>, url?: string) {
		CacheFunctions.clearCache(ctx, url);
	}

	/**
	 *
	 */
	public createEffect$<R extends Record<string, any>>(
		ctx: StateContext<CollectionStateModel<T>>,
		action: AsyncAction<any, R, any> & Action,
		httpRequest$: Observable<R>,
		responseEntities: ((res: R) => { [key: string]: any } | Array<{ [key: string]: any }>) | keyof R,
		successCb: (res: R) => void,
	) {
		return effectFactory(ctx, action, httpRequest$, responseEntities, successCb);
	}

	public toStore(data: Record<string, any>): T {
		const adapter = (this as any)['modelAdapter'];
		if (!adapter) {
			throw new Error(
				'Invalid context of the function! Must have context of StateClass. Call it with .bind(this)',
			);
		}
		return adaptToStore(adapter, data);
	}

	public toApi(model: T) {
		const adapter = (this as any)['modelAdapter'];
		if (!adapter) {
			throw new Error(
				'Invalid context of the function! Must have context of StateClass. Call it with .bind(this)',
			);
		}
		return adaptToApi(adapter, model);
	}

	public effectStateUpdater(ctx: StateContext<CollectionStateModel<T>>, action: RunEffectAction) {
		if (!action.effectOptions?.responseEntityPath) {
			return;
		}
		const entityData: unknown = action.response[action.effectOptions.responseEntityPath];
		if (!entityData) {
			return;
		}
		let payload: T | T[] | string;
		switch (action.method) {
			case 'GET':
				payload = this.convertPayload(['array', 'object'], entityData) as T | T[];
				return this.upsert(ctx, { payload });

			case 'PUT':
			case 'PATCH':
				payload = this.convertPayload(['object'], entityData);
				return this.update(ctx, {
					payload: {
						id: this.idGenerator.mustGetIdOf(payload),
						data: payload as Partial<T>,
					},
				});

			case 'POST':
				payload = this.convertPayload(['array', 'object'], entityData) as T | T[];
				return this.add(ctx, { payload });

			case 'DELETE':
				payload = this.convertPayload(['string', 'number'], entityData) as string;
				return this.remove(ctx, { payload });

			default:
				throw new Error('State has not been udpated. Please use "customUpdater" and update state manually.');
		}
	}

	private convertPayload(allowedTypes: Array<string>, data: Record<string, any>) {
		const type = this.typeCheck(data);
		if (!allowedTypes.includes(type)) {
			throw new Error('invalid type!');
		}

		switch (type) {
			case 'string':
			case 'number':
				return data.toString();
			case 'array':
				return (data as Array<any>).map((d) => this.toStore(d));
			case 'object':
				return this.toStore(data);

			default:
				throw new Error(`invalid type "${type}"`);
		}
	}

	// todo move to global utils
	private typeCheck(value: any) {
		const return_value = Object.prototype.toString.call(value);
		const type = return_value.substring(return_value.indexOf(' ') + 1, return_value.indexOf(']'));
		return type.toLowerCase();
	}
}

export { IdStrategy, ArrayOptions, SortingOptions };

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

export type UiTranslatableTerm =
	| string
	| {
			scope: string;
			key: string;
	  };

@Component({
	selector: 'dmo-translatable-term',
	standalone: true,
	imports: [NgIf, TranslocoModule],
	template: `
		<ng-container *ngIf="term['scope']; else plainTerm">
			<ng-container *transloco="let t; read: term['scope']">
				{{ t(term['key']) }}
			</ng-container>
		</ng-container>
		<ng-template #plainTerm>
			{{ term }}
		</ng-template>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UiTranslatableTermComponent {
	@Input({ required: true }) public term!: UiTranslatableTerm;
}

import { DestroyRef, inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	UI_TOAST_MESSAGE_DEFAULT_OPTIONS,
	UI_TOAST_MESSAGE_OVERLAY_PANEL_CLASS,
	UiToastMessagePosition,
} from './ui-toast-message';
import { Overlay } from '@angular/cdk/overlay';
import { UiToastMessageContainerComponent } from './ui-toast-message-container/ui-toast-message-container.component';
import { Subscription, timer } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	UiToastMessageRef,
	UI_TOAST_MESSAGE_PERMANENT,
	UiToastMessageForType,
	UiToastMessageType,
	UiToastMessageUpdateContent,
	UiToastMessage,
} from './ui-toast-message.interface';

const SHARED_INSTANCE_KEY = '__zone_toast_service_shared_instance';

declare global {
	interface Window {
		[SHARED_INSTANCE_KEY]?: UiToastMessageService;
	}
}

type UiToastMessageRefInternal = UiToastMessageRef & {
	/**
	 * @internal
	 */
	_timerSubscription: Subscription;
	/**
	 * @internal
	 */
	_toastIndex: number;
};

/**
 * Service used to open and control toast messages.
 */
@Injectable({ providedIn: 'platform' })
export class UiToastMessageService implements OnDestroy {
	public defaultOptions = inject(UI_TOAST_MESSAGE_DEFAULT_OPTIONS);

	private containerInstance?: UiToastMessageContainerComponent;
	private toastRefs: Array<UiToastMessageRefInternal> = [];
	private containerSubscription?: Subscription;
	private overlay = inject(Overlay);
	private destroyRef = inject(DestroyRef);
	private injector = inject(Injector);
	private document = inject(DOCUMENT);
	private overlayPanelClass = inject(UI_TOAST_MESSAGE_OVERLAY_PANEL_CLASS, { optional: true });

	public ngOnDestroy() {
		this.window[SHARED_INSTANCE_KEY] = undefined;
	}

	private get container() {
		if (!this.containerInstance) {
			this.createContainer();
		}

		if (!this.containerInstance) {
			throw `Unable to create container for toasts.`;
		}

		return this.containerInstance;
	}

	private get firstNonPermanentToast() {
		return this.toastRefs.find((toastRef) => toastRef.options.expiration !== UI_TOAST_MESSAGE_PERMANENT);
	}

	private get window() {
		const window = this.document.defaultView;
		if (!window) {
			throw new Error(`Toasts can't work outside browser environment.`);
		}
		return window;
	}

	private get sharedInstance() {
		if (!this.window[SHARED_INSTANCE_KEY]) {
			this.window[SHARED_INSTANCE_KEY] = this;
		}

		return this.window[SHARED_INSTANCE_KEY];
	}

	/**
	 * Show a toast message with the default type `UiToastMessageType.BRAND` if `type` is not specified.
	 */
	public show(options: UiToastMessageForType) {
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
		return (this.sharedInstance as any).internalShow(options);
	}

	/**
	 * Open a toast message with type `UiToastMessageType.DANGER`.
	 */
	public showDanger(config: UiToastMessageForType) {
		return this.sharedInstance.show({
			expiration: this.defaultOptions.errorMessageExpiration,
			...config,
			type: UiToastMessageType.DANGER,
		});
	}

	/**
	 * Open a toast message with type `UiToastMessageType.BRAND`.
	 */
	public showBrand(config: UiToastMessageForType) {
		return this.sharedInstance.show({ ...config, type: UiToastMessageType.BRAND });
	}

	/**
	 * Open a toast message with type `UiToastMessageType.SUCCESS`.
	 */
	public showSuccess(config: UiToastMessageForType) {
		return this.sharedInstance.show({ ...config, type: UiToastMessageType.SUCCESS });
	}

	/**
	 * Open a toast message with type `UiToastMessageType.WARNING`.
	 */
	public showWarning(config: UiToastMessageForType) {
		return this.sharedInstance.show({ ...config, type: UiToastMessageType.WARNING });
	}

	/**
	 * Open a toast message with type `UiToastMessageType.INFO`.
	 */
	public showInfo(config: UiToastMessageForType) {
		return this.sharedInstance.show({ ...config, type: UiToastMessageType.INFO });
	}

	protected internalShow(options: UiToastMessageForType): UiToastMessageRef {
		if (options.ignoreRedundant) {
			const found = this.toastRefs.find(
				(ref) => ref.options.text === options.text && ref.options.title === options.title,
			);

			if (found) {
				return found;
			}
		}

		if (this.toastRefs.length === this.defaultOptions.maxToasts) {
			this.firstNonPermanentToast?.close();
		}
		const fullToastOptions = {
			...options,
			type: options.type || this.defaultOptions.defaultType,
		};
		const toastIndex = this.container.addToast(fullToastOptions);

		const closeToast = () => {
			// Do nothing if toast has been already closed.
			if (!this.container.hideToast(toastIndex)) {
				return;
			}

			const subIndex = this.toastRefs.findIndex((ref) => ref.options === fullToastOptions);
			this.toastRefs.splice(subIndex, 1);

			fullToastOptions.onClose?.();
			timerSubscription.unsubscribe();
		};

		const timerSubscription = this.createCloseTimerSubscription(options.expiration, () => closeToast());

		const toastRef: UiToastMessageRefInternal = {
			_timerSubscription: timerSubscription,
			_toastIndex: toastIndex,
			close: () => closeToast(),
			isVisible: () => this.container.isToastVisible(toastIndex),
			options: fullToastOptions,
			updateContent: (updatedContent: UiToastMessageUpdateContent) =>
				this.container.updateToast(toastIndex, updatedContent),
		};

		this.toastRefs.push(toastRef);

		return toastRef;
	}

	protected createContainer() {
		const overlayRef = this.overlay.create({
			panelClass: this.overlayPanelClass || undefined,
			positionStrategy: this.getPosition(),
		});
		const portal = new ComponentPortal(UiToastMessageContainerComponent, null, this.injector);
		const componentRef = overlayRef.attach(portal);

		// We need to call this manually for custom elements even though in Angular apps it's not necessary.
		overlayRef.updatePosition();
		this.containerInstance = componentRef.instance;

		this.containerSubscription?.unsubscribe();
		this.containerSubscription = new Subscription();

		this.containerSubscription.add(
			this.containerInstance.closeToast
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((toastIndex) => this.findToastRef(toastIndex)?.close()),
		);

		this.containerSubscription.add(
			this.containerInstance.toastButtonClicked
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((toastIndex) => {
					this.findToastRef(toastIndex)?.options.onButtonClick?.();
				}),
		);

		/**
		 * When a toast is hovered we cancel all expiration timeouts and start them again when they're not hovered.
		 */
		this.containerSubscription.add(
			this.containerInstance.hovered.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hovered) => {
				if (hovered) {
					this.toastRefs.forEach((ref) => ref._timerSubscription.unsubscribe());
				} else {
					this.toastRefs.forEach(
						(ref) =>
							(ref._timerSubscription = this.createCloseTimerSubscription(ref.options.expiration, () =>
								ref.close(),
							)),
					);
				}
			}),
		);
	}

	private findToastRef(_toastIndex: number) {
		return this.toastRefs.find((ref) => ref._toastIndex === _toastIndex);
	}

	private getPosition() {
		const position = this.defaultOptions.position;
		const positionOffset = this.defaultOptions.positionOffset;
		let overlay = this.overlay.position().global();
		// set horizontal position
		switch (position) {
			case UiToastMessagePosition.TOP_LEFT:
			case UiToastMessagePosition.CENTER_LEFT:
			case UiToastMessagePosition.BOTTOM_LEFT:
				overlay = overlay.left(positionOffset);
				break;

			case UiToastMessagePosition.TOP_CENTER:
			case UiToastMessagePosition.CENTER:
			case UiToastMessagePosition.BOTTOM_CENTER:
				overlay = overlay.centerHorizontally();
				break;

			case UiToastMessagePosition.TOP_RIGHT:
			case UiToastMessagePosition.CENTER_RIGHT:
			case UiToastMessagePosition.BOTTOM_RIGHT:
				overlay = overlay.right(positionOffset);
				break;
		}

		// set vertical position
		switch (position) {
			case UiToastMessagePosition.TOP_LEFT:
			case UiToastMessagePosition.TOP_CENTER:
			case UiToastMessagePosition.TOP_RIGHT:
				overlay = overlay.top(positionOffset);
				break;

			case UiToastMessagePosition.BOTTOM_LEFT:
			case UiToastMessagePosition.BOTTOM_CENTER:
			case UiToastMessagePosition.BOTTOM_RIGHT:
				overlay = overlay.bottom(positionOffset);
				break;

			case UiToastMessagePosition.CENTER_LEFT:
			case UiToastMessagePosition.CENTER:
			case UiToastMessagePosition.CENTER_RIGHT:
				overlay = overlay.centerVertically();
				break;
		}
		return overlay;
	}

	private createCloseTimerSubscription(expiration: UiToastMessage['expiration'], completeHandler: () => void) {
		if (expiration === UI_TOAST_MESSAGE_PERMANENT) {
			return Subscription.EMPTY;
		}
		const hideDelay = expiration || this.defaultOptions.expiration;

		return timer(hideDelay)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				complete: () => completeHandler(),
			});
	}
}

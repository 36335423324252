import { Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class CookiesService {
	private document: Document;

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(protected injector: Injector) {
		this.document = injector.get(DOCUMENT);
	}

	public readCookie(cookieName: string) {
		const nameEQ = cookieName + '=';
		const cookies = document.cookie.split(';');
		for (let cookie of cookies) {
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1, cookie.length);
			}
			if (cookie.indexOf(nameEQ) === 0) {
				const value = cookie.substring(nameEQ.length, cookie.length);
				return value ? decodeURIComponent(value) : value;
			}
		}
		return null;
	}

	public setCookie(name: string, value: string, domain?: string, expireInDays?: number) {
		let expiration: Date | undefined = undefined;
		if (expireInDays) {
			expiration = new Date();
			expiration.setDate(expiration.getDate() + expireInDays);
		}
		document.cookie =
			'' +
			name +
			'=' +
			escape(value) +
			(!expiration ? '' : '; expires=' + expiration.toUTCString()) +
			(!domain ? '' : '; domain=' + domain) +
			'; path=/';
	}

	public removeCookie(name: string, domain?: string) {
		this.document.cookie =
			`${name}=` +
			(!domain ? '' : '; domain=' + domain) +
			`; expires=${new Date(new Date(0).getTime()).toUTCString()}; path=/`;
	}
}

import { inject, Injectable, Injector } from '@angular/core';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { CookiesService } from '@imt-web-zone/shared/data-access-cookies';

interface ZoneCookie {
	orgId: number;
	zoneId: number;
	userId: number;
	visitedUrl: string;
}

@Injectable({ providedIn: 'root' })
export class ZoneUserCookiesService extends CookiesService {
	protected override injector: Injector;

	private apiConfigFacade = inject(ApiConfigFacade);
	constructor() {
		const injector = inject(Injector);

		super(injector);
		this.injector = injector;
	}

	public setVisitedCookie(toDelete = false) {
		if (toDelete) {
			this.setCookie('visited', '', this.webCrossDomain, -2);
		} else if (!this.readCookie('visited')) {
			this.setCookie('visited', window.location.href, this.webCrossDomain, 1);
		}
	}

	public readZoneCookie(userId: number) {
		return this.readCookie(`zone:${userId}`);
	}

	public readUserCookie() {
		return this.readCookie('userId');
	}

	public setZoneCookie(userId: number, zoneId: number, orgId?: number) {
		if (window.location.pathname.startsWith('/admin')) {
			return;
		}

		let cookie = {} as ZoneCookie;
		try {
			cookie = JSON.parse(this.readZoneCookie(userId) || '') as ZoneCookie;
		} catch {
			/**/
		}

		cookie.userId = userId;
		cookie.zoneId = zoneId;
		if (orgId) {
			cookie.orgId = orgId;
		}

		this.setCookie(`zone:${userId}`, JSON.stringify(cookie), this.webCrossDomain);
	}

	public override readCookie(cookieName: string): string | null {
		return super.readCookie(cookieName);
	}

	public setSSOErrorCookie(value: { status: number; message: string; stack: string }) {
		this.setCookie('ssoe', JSON.stringify(value), this.webCrossDomain);
	}

	private get webCrossDomain() {
		return this.apiConfigFacade.configSnapshot?.domains['*'].replace('*', '');
	}
}

import { Injectable, Renderer2, inject as inject_1 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BodyTagDef, ImtAssetsInjector, ImtUiAssetsInjectorResult } from './imt-assets-injector';

interface AssetsContext {
	renderer2: Renderer2;
	document: Document;
	tags: Array<BodyTagDef>;
	tagsMap: Map<string, true>;
	// initializeScripts: () => void;
}

@Injectable()
export class ImtUiAssetsInjector implements ImtAssetsInjector {
	public get cache() {
		return this.initializedAssetsMap;
	}

	private initializedAssetsMap: Map<string, true> = new Map();
	private _document?: Document;

	constructor() {
		const document = inject_1(DOCUMENT);

		this._document = document as Document;
	}

	/**
	 *
	 * injects link or script element to the
	 *
	 */
	public inject(
		renderer2: Renderer2,
		tags: BodyTagDef | Array<BodyTagDef>,
	): Promise<Array<{ el: HTMLElement; result: 'success' | 'error' }>> {
		tags = tags instanceof Array ? tags : [tags];
		tags = tags.filter((asset) => !this.initializedAssetsMap.get(asset.src));
		return this.initializeAssets.call({
			renderer2,
			tags,
			document: this._document,
			tagsMap: this.initializedAssetsMap,
		});
	}

	/**
	 *
	 *  create element and append id to body
	 */
	private initializeAssets(this: AssetsContext): Promise<ImtUiAssetsInjectorResult[]> {
		const promises: Array<Promise<ImtUiAssetsInjectorResult>> = [];
		for (const tag of this.tags) {
			promises.push(
				new Promise((resolve) => {
					const el = this.renderer2.createElement(tag.tag);
					const runCustomCallback = (type: 'onLoad' | 'onError') => {
						if (tag[type] && typeof tag[type] === 'function') {
							tag[type]();
						}
					};

					if (tag.body) {
						runCustomCallback('onLoad');
						resolve({ el, result: 'success' });
					} else {
						el.onload = () => {
							runCustomCallback('onLoad');
							resolve({ el, result: 'success' });
						};
					}

					el.onerror = () => {
						runCustomCallback('onError');
						resolve({ el, result: 'error' });
					};

					if (tag.tag === 'link') {
						el.rel = 'stylesheet';
						el.href = tag.src;
					} else if (tag.type !== null) {
						if (!tag.type) {
							el.type = 'text/javascript';
						} else {
							el.type = tag.type;
						}
						if (tag.src) {
							el.src = tag.src;
						}
						if (tag.body) {
							el.innerHTML = tag.body;
						}
					}

					if (tag.attributes instanceof Array) {
						for (const attr of tag.attributes) {
							el.setAttribute(attr.attr, attr.value || '');
						}
					}
					this.renderer2.appendChild(document[tag.position || 'body'], el);
					if (tag.cache !== false) {
						this.tagsMap.set(tag.src, true);
					}
				}),
			);
		}
		return Promise.all(promises);
	}
}

import { ASSETS_VERSION_PLACEHOLDER } from '@imt-web-zone/core/util-assets';

export enum ModeEnum {
	MASTER = 'master',
	SLAVE = 'slave',
}

export enum RemotePagesEnum {
	ORGANIZATION = 'organization',
	TEAM = 'team',
	SCENARIOS = 'scenarios',
	TEMPLATES = 'templates',
	OEM_TEMPLATES = 'oem-templates',
	CONNECTIONS = 'connections',
	WEBHOOKS = 'webhooks',
	KEYS = 'keys',
	DEVICES = 'devices',
	DATA_STORES = 'datastores',
	DATA_STRUCTURES = 'datastructures',
	VARIABLES = 'variables',
	MY_APPS = 'myapps',
	NOTIFICATIONS = 'notifications',
	FUNCTIONS = 'functions',
	USER = 'user',
}

export class ApiConfigData {
	public zoneId!: number;
	public zone!: string;
	public mode!: ModeEnum;
	public domains!: {
		'*': string;
		web: string;
		static: string;
		hook: string;
		oauth: string;
		master: string;
		hq: string;
		admin?: string; // available only in hq admin
		base?: string;
		cdn?: {
			[cdnDomain: string]:
				| `${string}/${typeof ASSETS_VERSION_PLACEHOLDER}/${string}`
				| `${string}/${typeof ASSETS_VERSION_PLACEHOLDER}`;
		};
	};
	public slaveDomains?: {
		hq: string;
		cdn: string;
		admin: string;
		hqadmin: string;
	};
	public brand!: {
		title?: string;
		theme?: string | null;
	};
	public tokens!: {
		googleAnalytics?: string;
		candu?: string;
		userflow?: string;
		rudderstackToken?: string;
		rudderstackDataUrl?: string;
		stripePublishableKey?: string;
		datadogToken?: string;
		chameleonToken?: string;
	};
	public generalSettings!: {
		academyRoot: string;
		communityRoot: string;
		helpRoot: string;
		devicesEnabled: boolean;
		sdkAppsEnabled: boolean;
		variablesEnabled: boolean;
		scenarioInputsEnabled: boolean;
		customFunctionsEnabled: boolean;
		formanEnabled: boolean;
		sdkAppsHelpRoot: string;
		openTicketRoot: string;
		supportEmail: string;
		supportedLanguages: Array<string>;
		defaultLanguage: string;
		twoFactorAuthenticationEnabled: boolean;
		usersCanCreateOrg: boolean;
		remoteFormanUrl: string;
		remoteFormanAdapterUrl: string;
		onPremAgentEnabled: boolean;
		onPremAgentLink: string;
		maintenanceModeEnabled: boolean;
		maintenanceModeText: string;
		userCreateStartDate: Date;
		dynamicConnectionsEnabled: boolean;
		personalConnectionsEnabled: boolean;
		slothEnabled: boolean;
		hqSyncPanicMode: boolean;
		scenarioRelationTreeEnabled: boolean;
	};
	public build!: number;
	public server!: string;
	public dataStoresAllowed!: boolean;
	public version!: string;
	public showInstallGuide!: boolean;
	public ssoType!: SsoTypes;
	public remotePages?: Array<RemotePagesEnum>;
	public codeInjection?: { header?: string; footer?: string };
}

export enum SsoTypes {
	NONE = 'none',
	OAUTH2 = 'oauth2',
	SAML2 = 'saml2',
}

export type GetZoneEnvResponse = ApiConfigData;

import { removeUndefined, toString, toNumber } from '@imt-web-zone/shared/util-store';
import { ScenarioLogsApiModel } from './scenario-logs.interface';
import { ScenarioLogModel } from './scenario-logs.model';

export class ScenarioLogsAdapter {
	public static toStore(item: Partial<ScenarioLogsApiModel>, scenarioId: number): ScenarioLogModel {
		return removeUndefined(
			new ScenarioLogModel(
				toString(item.id),
				item.imtId,
				item.type,
				new Date(item.timestamp),
				toString(item.authorId),
				item.status,
				item.instant,
				item.duration,
				item.operations,
				item.transfer,
				item.detail,
				toString(item.scenarioId || scenarioId),
				toString(item.teamId),
				item.reason,
				item.parent,
			),
		);
	}

	public static toApi(item: Partial<ScenarioLogModel>): ScenarioLogsApiModel {
		return removeUndefined(
			new ScenarioLogsApiModel(
				item.id,
				item.imtId,
				item.type,
				item.timestamp,
				toNumber(item.authorId),
				item.status,
				item.instant,
				item.duration,
				item.operations,
				item.transfer,
				item.detail,
				toNumber(item.scenarioId),
				toNumber(item.teamId),
				item.reason,
				item.parent,
			),
		);
	}
}

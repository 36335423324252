import { Injectable } from '@angular/core';

import { StateFacadeAbstract } from '@imt-web-zone/core/util-state-facade';

import { COMMON_STATE_TOKEN } from './common.symbols';
import { changeLogoIndicatorVisibility } from './common.actions';

@Injectable({ providedIn: 'root' })
export class CommonBaseFacade extends StateFacadeAbstract<typeof COMMON_STATE_TOKEN, unknown> {
	// `CommonBaseState` doesn't have related service.
	protected service: unknown;

	constructor() {
		super(COMMON_STATE_TOKEN);
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public changeLogoIndicatorVisibility$(visible: boolean) {
		return this.store.dispatch(changeLogoIndicatorVisibility({ visible }));
	}
}

import { Injectable, inject } from '@angular/core';
import {
	Body,
	Query,
	GET,
	DELETE,
	Path,
	HttpBuilder,
	Pg,
	QueryType,
	QueryData,
	Pagination,
	BodyType,
} from '@imt-web-zone/shared/util';
import { Observable } from 'rxjs';
import {
	DlqApiModel,
	ListDlqsPayload,
	ListDlqsResponse,
	DeleteDlqResponse,
	DeleteDlqPayload,
	ItemDlqPayload,
	ItemDlqResponse,
} from './dlqs.interface';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';

@Injectable({ providedIn: 'root' })
export class DlqsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('dlqs')
	public listDlqs$(
		@QueryData queryData: QueryType<ListDlqsPayload>,
		@Pagination pg?: Pg<DlqApiModel, null>,
	): Observable<ListDlqsResponse> {
		return undefined;
	}

	@GET('dlqs/{dlqId}')
	public itemDlq$(@Path('dlqId') dlqId: ItemDlqPayload['params']['dlqId']): Observable<ItemDlqResponse> {
		return undefined;
	}

	@DELETE('dlqs')
	public deleteDlq$(
		@Body data: BodyType<DeleteDlqPayload>,
		@Query('scenarioId') scenarioId: string,
	): Observable<DeleteDlqResponse> {
		return undefined;
	}
}

import { InjectionToken, ClassProvider, Type } from '@angular/core';

export interface ClassTokenProvider<C, T = unknown> {
	injectionToken: InjectionToken<T>;
	classToProvide: Type<C>;
}

export interface ClassTokenProvidersConfig<C extends T, T = unknown> {
	classProviders?: Array<ClassTokenProvider<C, T>>;
}

/** Processes single token provider config and returns it in a right structure. */
export const getClassTokenProvider = <C>(providerConfig: ClassTokenProvider<C>): ClassProvider | null => {
	const { injectionToken: token, classToProvide } = providerConfig;

	const provider =
		token != undefined && classToProvide != null
			? {
					provide: token,
					useClass: classToProvide,
			  }
			: null;

	return provider;
};

/**
 * Picks a provided tokens and classes from the `config.classProviders` array if exists
 * and returns it as a `ClassProvider` array with registered classes from `classToProvide` (e.g. service)
 * under `injectionToken` token.
 */
export const getClassTokenProviders = <T extends ClassTokenProvidersConfig<unknown>>(
	config: T,
): Array<ClassProvider> => {
	if (!Array.isArray(config.classProviders)) {
		return [];
	}

	return config.classProviders.map(getClassTokenProvider).filter((provider) => provider !== null) as ClassProvider[];
};

import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { NativeFetchInterceptor } from './native-fetch.interceptor';

type DepsInit = (...deps: unknown[]) => Promise<{
	csrfDomains: string[];
}>;

export const nativeFetchInterceptorInit = (depsInit: DepsInit, ...deps: unknown[]): Provider => {
	return {
		provide: APP_INITIALIZER,
		useFactory: (...deps: unknown[]) => {
			const nativeFetchInterceptorService = inject(NativeFetchInterceptor);
			return async () => {
				const initializedDeps = await depsInit(...deps);
				nativeFetchInterceptorService.initialize(initializedDeps.csrfDomains);
			};
		},
		multi: true,
		deps,
	};
};

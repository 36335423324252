import { Injectable, inject } from '@angular/core';
import {
	GET,
	Path,
	HttpBuilder,
	ParamType,
	QueryData,
	QueryType,
	Pagination,
	PaginationType,
} from '@imt-web-zone/shared/util';
import { Observable } from 'rxjs';
import {
	ListScenariosLogsResponse,
	ListScenariosLogsPayload,
	ListAdminScenariosLogsPayload,
	ScenarioExecutionLogPayload,
	ScenarioExecutionLogResponse,
} from './scenario-logs.interface';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';

@Injectable({ providedIn: 'root' })
export class ScenarioLogsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('scenarios/{scenarioId}/logs')
	public listScenariosLogs$(
		@Path('scenarioId')
		scenarioId: ParamType<ListScenariosLogsPayload, 'scenarioId'>,
		@QueryData queryData: QueryType<ListScenariosLogsPayload>,
		@Pagination pg: PaginationType<ListScenariosLogsPayload>,
	): Observable<ListScenariosLogsResponse> {
		return undefined;
	}

	@GET('scenarios/{scenarioId}/logs/{executionId}')
	public executionLog$(
		@Path('scenarioId')
		scenarioId: ParamType<ScenarioExecutionLogPayload, 'scenarioId'>,
		@Path('executionId')
		executionId: ParamType<ScenarioExecutionLogPayload, 'executionId'>,
	): Observable<ScenarioExecutionLogResponse> {
		return undefined;
	}

	@GET('admin/scenarios/logs')
	public listAdminScenariosLogs$(
		@QueryData queryData: QueryType<ListAdminScenariosLogsPayload>,
		@Pagination pg: PaginationType<ListAdminScenariosLogsPayload>,
	): Observable<ListScenariosLogsResponse> {
		return undefined;
	}
}

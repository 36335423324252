import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { ImtUiLangService } from './imt-lang-service';

@Injectable()
export class ImtUiTranslateWrapperService implements ImtUiLangService {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private transloco: TranslocoService) {}

	public selectTranslate(key: string | Array<string>, interpolateParams?: any): Observable<any> {
		return this.transloco.selectTranslate(key, interpolateParams);
	}

	public translate(key: string | Array<string>, interpolateParams?: any): any {
		return this.transloco.translate(key, interpolateParams);
	}
}

export function ImtUiTranslateWrapperServiceFactory(translate: TranslocoService) {
	return new ImtUiTranslateWrapperService(translate);
}

import { ApiConfigData } from './api-config.interface';

type ThemeType = string | null;

export class SetApiConfig {
	public static readonly type: string = '[Config] set config';
	constructor(public config: Partial<ApiConfigData>) {}
}

export class FinishInstallGuide {
	public static readonly type: string = '[Config] resolve install guide';
}

export class ChangeTheme {
	public static readonly type: string = '[Config] change theme';
	constructor(public theme: ThemeType, public prevTheme?: ThemeType) {}
}
export class ChangeThemeFailed {
	public static readonly type: string = '[Config] change theme failed and set fallback theme';
	constructor(public theme: ThemeType, public prevTheme?: ThemeType) {}
}

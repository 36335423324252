import { Cols, Pg } from '@imt-web-zone/shared/util';

export enum OrganizationDomainStatus {
	UNVERIFIED = 'unverified',
	IN_PROGRESS = 'in_progress',
	VERIFIED = 'verified',
	FAILED = 'failed',
	SUSPENDED = 'suspended',
}

export enum VerificationStatusDescription {
	DNS_SERVER_UNAVAILABLE = 'dns_server_unavailable',
	DNS_RECORD_NOT_EXISTS = 'dns_record_not_exists',
	DNS_RECORD_INCORRECT_VALUE = 'dns_record_incorrect_value',
	DOMAIN_VERIFIED_BY_ANOTHER_ORG = 'domain_verified_by_another_org',
}

type StatusDescriptionTranslationMap = {
	[k in VerificationStatusDescription]: string;
};

export const DomainStatusDescriptionTranslation: StatusDescriptionTranslationMap = {
	dns_server_unavailable: 'dnsServerUnavailable',
	dns_record_not_exists: 'dnsRecordNotExists',
	dns_record_incorrect_value: 'dnsRecordIncorrectValue',
	domain_verified_by_another_org: 'domainVerifiedByAnotherOrg',
};

export interface OrganizationDomainApiModel {
	id: number;
	organizationId: string;
	domain: string;
	verificationMethod: string;
	verificationCode: string;
	status: OrganizationDomainStatus;
	statusDescription?: VerificationStatusDescription;
	created: Date;
	verified?: Date;
	verifying?: boolean;
}

type OrganizationDomainCols = Cols<OrganizationDomainApiModel, 'id'>;

export interface ListOrganizationDomainsPayload {
	params: {
		organizationId: number;
	};
	query: {
		id?: number;
		cols?: OrganizationDomainCols;
		pg?: Pg<OrganizationDomainApiModel, 'id'>;
	};
}

export interface ListOrganizationDomainsResponse {
	organizationDomains: Array<OrganizationDomainApiModel>;
}

export interface CreateOrganizationDomainResponse {
	organizationDomain: OrganizationDomainApiModel;
}

export interface DeleteOrganizationDomainPayload {
	params: {
		organizationId: string;
		organizationDomainId: string;
	};
	query: {
		confirmed?: boolean;
	};
}

export interface DeleteOrganizationDomainResponse {
	organizationDomain: number;
}

export interface VerifyOrganizationDomainPayload {
	params: {
		organizationId: string;
		organizationDomainId: string;
	};
}

export interface VerifyOrganizationDomainResponse {
	organizationDomain: OrganizationDomainApiModel;
}

export interface DetailOrganizationDomainPayload {
	params: {
		id: number;
	};
}

export interface DetailOrganizationDomainResponse {
	organizationDomain: OrganizationDomainApiModel;
}

import type { UiDropdownMenuItemDirective } from './ui-dropdown-menu-item/ui-dropdown-menu-item.directive';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

/**
 * Object that provides connection between a dropdown component and its child menu items.
 */
export class UiDropdownRef {
	private _itemClicked$ = new Subject<UiDropdownMenuItemDirective>();
	public itemClicked$: Observable<UiDropdownMenuItemDirective> = this._itemClicked$;

	private _menuItems$ = new BehaviorSubject<UiDropdownMenuItemDirective[]>([]);
	public menuItems$: Observable<UiDropdownMenuItemDirective[]> = this._menuItems$;

	private _selectedItem$ = new BehaviorSubject<UiDropdownMenuItemDirective | null>(null);
	public selectedItem$: Observable<UiDropdownMenuItemDirective | null> = this._selectedItem$;

	private _focusedItem$ = new BehaviorSubject<UiDropdownMenuItemDirective | null>(null);
	public focusedItem$: Observable<UiDropdownMenuItemDirective | null> = this._focusedItem$;

	private _disabled$ = new BehaviorSubject<boolean>(false);
	public disabled$: Observable<boolean> = this._disabled$;

	private _changeVisibility$ = new Subject<boolean>();
	public changeVisibility$: Observable<boolean> = this._changeVisibility$;

	public clickItem(item: UiDropdownMenuItemDirective) {
		this._itemClicked$.next(item);
	}

	/**
	 * Register menu item to be iterable by the dropdown controls.
	 *
	 * @param item Menu item to be added
	 */
	public addMenuItem(item: UiDropdownMenuItemDirective) {
		this._menuItems$.next([...this._menuItems$.value, item]);
	}

	/**
	 * Unregister menu item from the dropdown controls.
	 *
	 * @param item Menu item to be removed
	 */
	public removeMenuItem(item: UiDropdownMenuItemDirective) {
		this._menuItems$.next(this._menuItems$.value.filter((i) => i !== item));
	}

	/**
	 * Change the selected item in the menu.
	 *
	 * @param item
	 */
	public selectItem(item: UiDropdownMenuItemDirective | null) {
		this._selectedItem$.next(item);
	}

	/**
	 * Change the focused item in the menu.
	 *
	 * @param item
	 */
	public focusItem(item: UiDropdownMenuItemDirective | null) {
		this._focusedItem$.next(item);
	}

	/**
	 * Set disabled state for the dropdown. This won't change disabled state for its anchor element.
	 *
	 * @param disabled
	 */
	public setDisabled(disabled: boolean) {
		this._disabled$.next(disabled);
	}

	/**
	 * Emit that the dropdown should open now.
	 */
	public showDropdown() {
		this._changeVisibility$.next(true);
	}

	/**
	 * Emit that the dropdown should hide now.
	 */
	public hideDropdown() {
		this._changeVisibility$.next(false);
	}
}

export interface CacheModelState {
	cache: CacheMap;
	storeKey: string | undefined;
}

export interface CacheData {
	timestamp: number;
	ids: Array<string>;
	metadata?: Record<string, any>;
}

export interface CacheMap {
	[url: string]: CacheData;
}

export function initialCache(): CacheModelState {
	return {
		storeKey: undefined,
		cache: {},
	};
}

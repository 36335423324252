import { HttpErrorInterceptor } from './http-error.interceptor';
import {
	HTTP_INTERCEPTORS_API,
	HTTP_INTERCEPTORS_CASHIER,
	HTTP_INTERCEPTORS_STREAMER,
} from '@imt-web-zone/shared/data-access-http-clients';
import { PendingRequestsInterceptor } from './pending-requests.interceptor';
import { ApiInterceptor } from './api.interceptor';
import { StreamerInterceptor } from './streamer.interceptor';
import { CashierInterceptor } from './cashier.interceptor';

export const ZONE_INTERCEPTORS: Array<any> = [
	// API INTERCEPTORS
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: ApiInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: HttpErrorInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: PendingRequestsInterceptor,
		multi: true,
	},

	{
		provide: HTTP_INTERCEPTORS_STREAMER,
		useClass: StreamerInterceptor,
		multi: true,
	},
];

export const HQ_ADMIN_INTERCEPTORS: Array<any> = [
	// API INTERCEPTORS
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: ApiInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: HttpErrorInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_API,
		useClass: PendingRequestsInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_CASHIER,
		useClass: CashierInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS_CASHIER,
		useClass: HttpErrorInterceptor,
		multi: true,
	},
];

import { HttpResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import { ActionMessageMetadata, ActionMetadata } from '../async-action/actions.interface';
import { CacheFunctions } from '../cache/cache.functions';

export interface EffectFactoryOptions<T> {
	type?: string;
	responseEntityPath: keyof T | undefined;
}

interface RequestParams {
	params?: Params;
	query?: Params;
	payload?: Params;
	headers?: Params;
}

/**
 *
 * @typeParam T - request payload interface
 * @typeParam R - response interface
 */
export class RunEffectAction<T extends RequestParams = Record<string, any>, R = any> {
	public type: string;
	public hideFromDevtools = false;
	public cacheKey?: string;
	public uid = Date.now().toString(36) + Math.random().toString(36).substring(2);

	// properties bellow just for backward compatibility
	// todo remove
	public request = true;
	public response?: R;
	public metadata: ActionMetadata = {};

	public static getType(serviceName: string, methodName: string) {
		return `RUN_EFFECT ${serviceName}.${methodName}`;
	}
	constructor(
		public effectOptions: EffectFactoryOptions<any>,
		public request$: HttpResponse<any>['body'],
		public method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
		public params: T,
		public serviceMethodName: string,
		serviceName: string,
	) {
		this.type = RunEffectAction.getType(serviceName, this.serviceMethodName);
		if (!this.effectOptions.type) {
			this.effectOptions.type = this.serviceMethodName;
		}

		if (this.method === 'GET') {
			this.cacheKey = CacheFunctions.serializeParams(this, this.effectOptions?.type || '');
		}
	}

	public setMetaData(meta: ActionMessageMetadata) {
		this.metadata = meta;
		return this;
	}
}

/**
 *
 * @typeParam T - request function returning RunEffectAction
 */
export type EffectStart<T extends (...args: any) => RunEffectAction> = ReturnType<T>;

/**
 * make all properties required
 */
type Required<T> = { [P in keyof T]-?: T[P] };

/**
 *
 * @typeParam T - request function returning RunEffectAction
 */
export interface EffectAction<T extends (...args: any) => any = any> {
	type: string;
	prevAction: RunEffectAction;
	response?: ReturnType<T>['response'];
	error?: Error;
	fromCache?: boolean;
}

export type EffectSuccess<T extends (...args: any) => any = any> = Required<Omit<EffectAction<T>, 'error'>>;

export type EffectError<T extends (...args: any) => any = any> = Required<Omit<EffectAction<T>, 'response'>>;

import { Injectable, Injector, inject } from '@angular/core';
import { StateFacadeAbstract } from '@imt-web-zone/core/util-state-facade';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from './auth.symbols';
import { AuthService } from './auth.service';
import {
	AuthUserApiModel,
	Disable2faResponse,
	Enable2faResponse,
	LoadAuthUserPayload,
	LogoutPayload,
	PartialUpdateUserPayload,
	RegisterPartnerPayload,
} from './auth.interface';
import {
	changeAuthUserLanguageRequest,
	confirmForcedPasswordChange,
	disable2faSuccess,
	enable2faSuccess,
	loadAuthUser,
	logout,
	registerPartner,
	updateUserFromFormula,
} from './auth.actions';
import { AuthSelectors } from './auth.selectors';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { AuthState } from './auth.state';
import { EntityGuardOptions } from '@imt-web-zone/core/util-entity-guard';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { ActionMessageMetadata, AsyncLoadActionMeta } from '@imt-web-zone/shared/util-store';

@Injectable({ providedIn: 'root' })
export class AuthFacade extends StateFacadeAbstract<typeof AUTH_STATE_TOKEN, AuthService> {
	public static provideStore() {
		return AuthState;
	}
	public static userIdExist = (injector: Injector) => injector.get(Store).select(AuthSelectors.getAuthUserId);

	public tzRxSnapshot = this.rxSnapshotFn(AuthSelectors.getAuthUserTimezone);
	public localeRxSnapshot = this.rxSnapshotFn(AuthSelectors.getAuthUserLocale);
	public languageRxSnapshot = this.rxSnapshotFn(AuthSelectors.getAuthUserLanguage);
	public userRxSnapshot = this.rxSnapshotFn(AuthSelectors.getAuthUser);
	public userIdRxSnapshot = this.rxSnapshotFn(AuthSelectors.getAuthUserId);

	public tz$ = this.store.select$(AuthSelectors.getAuthUserTimezone);
	public locale$ = this.store.select$(AuthSelectors.getAuthUserLocale);
	public language$ = this.store.select$(AuthSelectors.getAuthUserLanguage);
	public user$ = this.store.select$(AuthSelectors.getAuthUser);
	public userId$ = this.store.select$(AuthSelectors.getAuthUserId);
	public isUserAvailable$ = this.userId$.pipe(
		filter((id) => !!id),
		map((id) => true),
	);
	public zoneNotifications$ = this.store.select$(AuthSelectors.getZoneNotifications);

	public tzSignal = this.store.selectSignal(AuthSelectors.getAuthUserTimezone);
	public localeSignal = this.store.selectSignal(AuthSelectors.getAuthUserLocale);
	public languageSignal = this.store.selectSignal(AuthSelectors.getAuthUserLanguage);
	public userSignal = this.store.selectSignal(AuthSelectors.getAuthUser);
	public userIdSignal = this.store.selectSignal(AuthSelectors.getAuthUserId);

	public get userIdSnapshot() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUserId);
	}

	public get userLocaleSnapshot() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUserLocale);
	}

	public get languageSnapshot() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUserLanguage);
	}

	public get userSnapshot() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUser);
	}

	public get tzSnapshot() {
		return this.store.selectSnapshot(AuthSelectors.getAuthUserTimezone);
	}

	protected service = inject(AuthService);
	protected router = inject(Router);

	constructor() {
		super(AUTH_STATE_TOKEN);
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	@EntityGuardOptions()
	public loadAuthUser$(
		cols: LoadAuthUserPayload['query']['cols'],
		metadata?: { fromGuard: boolean } & AsyncLoadActionMeta,
	) {
		return this.store.dispatch(loadAuthUser({ query: { cols } }, metadata));
	}

	@EntityGuardOptions({ runAfter$: AuthFacade.userIdExist })
	public loadAuthUserAffiliate$() {
		return this.loadAuthUser$(['isAffiliatePartner']);
	}

	public oauthAuthorize$(initialUrl?: string) {
		return this.service.oauthAuthorize$({ redirect: initialUrl || '/' }).pipe(
			tap((res) => {
				if (!window.location.pathname.includes('sso/oauth')) {
					window.location.href = res.authorizeUrl;
					return;
				}
			}),
			map(() => false),
			catchError((e) => of(this.router.createUrlTree(['/admin/login']))),
		);
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public updateUserFromFormula$(user: Partial<AuthUserApiModel>) {
		return this.store.dispatch(updateUserFromFormula({ payload: user }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public logout$(options: LogoutPayload['payload']) {
		return this.store.dispatch(logout({ payload: options }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public changeAuthUserLanguage$(
		userId: PartialUpdateUserPayload['params']['userId'],
		language: PartialUpdateUserPayload['payload']['language'],
	) {
		return this.store.dispatch(changeAuthUserLanguageRequest({ params: { userId }, payload: { language } }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public disable2faSuccess$(tfaEnabled: Disable2faResponse['tfaEnabled']) {
		return this.store.dispatch(disable2faSuccess({ payload: { tfaEnabled } }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public enable2faSuccess$(
		oneTimePasswords: Enable2faResponse['oneTimePasswords'],
		tfaEnabled: Enable2faResponse['tfaEnabled'],
	) {
		return this.store.dispatch(enable2faSuccess({ payload: { oneTimePasswords, tfaEnabled } }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public registerPartner$(partner: RegisterPartnerPayload['payload']) {
		return this.store.dispatch(registerPartner({ payload: partner }));
	}

	// TODO: use `this.store.dispatch$` instead of `this.store.dispatch`
	public confirmForcedPasswordChange$(messageText?: ActionMessageMetadata['messageData']['text']) {
		return this.store.dispatch(
			confirmForcedPasswordChange(
				messageText
					? {
							metadata: { messageData: { text: messageText } },
					  }
					: {},
			),
		);
	}
}

import { inject, Injectable } from '@angular/core';
import { Body, HttpBuilder, POST } from '@imt-web-zone/shared/util';
import { ORGANIZATIONS_STATE_TOKEN } from '@imt-web-zone/shared/data-access';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { firstValueFrom, Observable } from 'rxjs';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { Store } from '@ngxs/store';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { TeamsFacade } from '@imt-web-zone/zone/state-teams';

interface TrackPayload<T> {
	eventName: string;
	payload: {
		organization_id?: number;
		team_id?: number;
		user_id?: number;
	} & T;
}

@Injectable({ providedIn: 'root' })
export class TrackmanService extends HttpBuilder {
	private teamsFacade = inject(TeamsFacade);
	private authFacade = inject(AuthFacade);

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(http: HttpApiClient, private store: Store) {
		super(http);
	}

	public trace$<T>(eventName: string, payload: T, includeCommonData = true) {
		const commonData = includeCommonData ? this.commonData : {};
		return this._trace$({
			eventName: eventName,
			payload: {
				...commonData,
				...payload,
			},
		});
	}

	public async trace<T>(eventName: string, payload: T, includeCommonData = true) {
		return await firstValueFrom(this.trace$(eventName, payload, includeCommonData));
	}

	@POST('trace')
	private _trace$(@Body payload: TrackPayload<unknown>): Observable<unknown> {
		return undefined;
	}

	private get commonData() {
		return {
			organization_id: +this.store.selectSnapshot(CollectionState.getActiveId(ORGANIZATIONS_STATE_TOKEN)),
			team_id: +this.teamsFacade.activeIdSnapshot,
			user_id: +this.authFacade.userIdSnapshot,
		};
	}
}

import { createSelector, State } from '@ngxs/store';
import { FormsStateModel } from './forms.model';
import { Injectable } from '@angular/core';

export const FORMS_STATE_KEY = 'forms';
// @dynamic
@State({
	name: FORMS_STATE_KEY,
	defaults: {},
})
@Injectable()
export class FormsState {
	public static getFormState(formName: string) {
		return createSelector([FormsState], (state: FormsStateModel) => state[formName] || null);
	}
}

import {
	ChangeDetectorRef,
	DestroyRef,
	Directive,
	ElementRef,
	Injector,
	OnInit,
	TemplateRef,
	ViewContainerRef,
	inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TemplatePortal } from '@angular/cdk/portal';

import {
	EMPTY,
	Observable,
	ReplaySubject,
	Subscription,
	combineLatest,
	distinctUntilChanged,
	filter,
	merge,
	mergeMap,
	scan,
	switchAll,
	take,
	tap,
	withLatestFrom,
} from 'rxjs';

import type { UiDropdownMenuItemDirective } from './ui-dropdown-menu-item/ui-dropdown-menu-item.directive';

import { UiDropdownRef } from './ui-dropdown-ref';
import { dropdownPositions } from './dropdown-positions';
import { UI_DROPDOWN_OVERLAY_PANEL_CLASS } from './ui-dropdown';

@Directive({ standalone: true })
export abstract class UiDropdownBaseDirective implements OnInit {
	public abstract content: TemplateRef<any>;
	public abstract anchor?: ElementRef<HTMLElement> | Element | null;
	public abstract dataCy: string;
	public abstract overlayHostDataCy: string;
	public abstract overlayOffset?: number;
	public abstract keyboardControls: boolean;
	public abstract stayOnScroll: boolean;
	public abstract disabled: boolean;
	public abstract hideOnClickOutside: boolean;

	/**
	 * Returns true/false based on whether the overlay is visible right now.
	 */
	public get opened() {
		return Boolean(this.overlayRef);
	}

	public get offsetFromAnchor() {
		if (typeof this.overlayOffset === 'number') {
			return this.overlayOffset;
		}
		return typeof this.dropdownDefaultOffset === 'number' ? this.dropdownDefaultOffset : 0;
	}

	protected abstract dropdownDefaultOffset: number | null;
	protected abstract elm: Element;
	protected overlayRef?: OverlayRef | null;

	protected dropdownRef = inject(UiDropdownRef);
	protected document: Document = inject(DOCUMENT);
	protected destroyRef = inject(DestroyRef);
	protected cdr = inject(ChangeDetectorRef);

	private dropdownSubscriptions?: Subscription;
	private keyControlsSubscriptions?: Subscription;
	// Source of events that might change while the overlay is visible.
	private hideEvents$ = new ReplaySubject<Observable<unknown>>(1);

	private viewContainerRef = inject(ViewContainerRef);
	private overlay = inject(Overlay);
	private injector = inject(Injector);
	private overlayPanelClass = inject(UI_DROPDOWN_OVERLAY_PANEL_CLASS, { optional: true });

	public ngOnInit(): void {
		this.dropdownRef.changeVisibility$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((visible) => {
			if (visible && !this.opened) {
				this.showDropdown();
			} else if (!visible && this.opened) {
				this.hideDropdown();
			}
		});
	}

	/**
	 * Toggle visibility.
	 */
	public toggleDropdown() {
		if (this.opened) {
			this.hideDropdown();
		} else {
			this.showDropdown();
		}
	}

	/**
	 * Show dropdown.
	 */
	public showDropdown() {
		if (this.overlayRef) {
			return;
		}

		this.dropdownSubscriptions?.unsubscribe();
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.anchor || this.elm)
			.withPositions(dropdownPositions)
			.withFlexibleDimensions(true)
			.withGrowAfterOpen(true)
			.withDefaultOffsetY(this.offsetFromAnchor)
			.withLockedPosition(false);

		this.overlayRef = this.overlay.create({
			panelClass: this.overlayPanelClass || undefined,
			positionStrategy,
			scrollStrategy: this.createScrollStrategy(),
			disposeOnNavigation: true,
		});

		this.overlayRef.attach(new TemplatePortal(this.content, this.viewContainerRef, {}, this.injector));
		this.overlayRef.overlayElement.setAttribute('data-testid', this.dataCy);
		this.overlayRef.hostElement.setAttribute('data-testid', this.overlayHostDataCy);

		this.useClickOutsideListener();

		// Merge all events that might hide the dropdown.
		this.dropdownSubscriptions = merge(
			this.overlayRef.detachments(),
			this.hideEvents$.pipe(switchAll()),
			this.overlayRef.keydownEvents().pipe(filter((event) => event.key === 'Escape')),
		)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.hideDropdown());

		this.dropdownRef.selectItem(null);
		this.dropdownRef.focusItem(null);
		this.dropdownSubscriptions.add(
			this.dropdownRef.itemClicked$
				.pipe(
					// Don't hide dropdown when item has explicitly set `stayOnClick`.
					filter((item) => !item.stayOnClick),
				)
				.subscribe((_) => this.hideDropdown()),
		);
		this.dropdownSubscriptions.add(
			this.dropdownRef.disabled$.pipe(distinctUntilChanged()).subscribe((disabled) => (this.disabled = disabled)),
		);

		this.bindKeyboardControls();
		this.dropdownRef.showDropdown();
	}

	/**
	 * Hide dropdown
	 */
	public hideDropdown() {
		if (this.overlayRef) {
			this.dropdownSubscriptions?.unsubscribe();
			this.overlayRef.dispose();
			this.overlayRef = null;
			this.dropdownRef.hideDropdown();
			this.cdr.markForCheck(); // CDM-9072
		}
	}

	protected createScrollStrategy() {
		return this.stayOnScroll ? this.overlay.scrollStrategies.noop() : this.overlay.scrollStrategies.close();
	}

	protected bindKeyboardControls() {
		this.keyControlsSubscriptions?.unsubscribe();
		if (!this.keyboardControls || !this.overlayRef) {
			return;
		}

		const keyEvents$ = this.overlayRef.keydownEvents();

		/**
		 * Navigate among the dropdown menu items using Up/Down keys.
		 */
		this.keyControlsSubscriptions = combineLatest([keyEvents$, this.dropdownRef.menuItems$])
			.pipe(
				filter(([event]) => ['ArrowUp', 'ArrowDown'].includes(event.key)),
				tap(([event]) => event.preventDefault()),
				withLatestFrom(this.dropdownRef.selectedItem$),
				scan((selectedItem: null | UiDropdownMenuItemDirective, [[event, items], preferredSelectedItem]) => {
					const enabledMenuItems = items.filter((item) => !item.disabled);
					const menuItemsCount = enabledMenuItems.length;

					// If the expected selected item doesn't match the selected item in `DropdownRef` it means that user
					// hovered over another item than the one that should be selected by keyboard keys. In this case
					// user selected item has preference.
					if (selectedItem !== preferredSelectedItem) {
						selectedItem = preferredSelectedItem;
					}
					const selectedIndex = selectedItem ? enabledMenuItems.indexOf(selectedItem) : null;

					if (selectedIndex === null) {
						return enabledMenuItems[0];
					}

					switch (event.key) {
						// Circle through all enabled menu items.
						case 'ArrowUp':
							return selectedIndex === 0
								? enabledMenuItems[menuItemsCount - 1]
								: enabledMenuItems[selectedIndex - 1];
						case 'ArrowDown':
							return selectedIndex === menuItemsCount - 1
								? enabledMenuItems[0]
								: enabledMenuItems[selectedIndex + 1];
					}

					return selectedItem;
				}, null),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((selectedItem) => {
				this.dropdownRef.selectItem(selectedItem);
				this.dropdownRef.focusItem(selectedItem);
			});

		/**
		 * Confirm selected item by pressing "Enter" key.
		 */
		this.keyControlsSubscriptions.add(
			keyEvents$
				.pipe(
					filter((event) => event.key === 'Enter'),
					tap((event) => event.preventDefault()),
					mergeMap(() => this.dropdownRef.selectedItem$.pipe(take(1))),
					filter(Boolean),
					takeUntilDestroyed(this.destroyRef),
				)
				.subscribe((selectedItem) => {
					// Manually create a click event and dispatch it on the menu item element.
					const event = new MouseEvent('click', {
						bubbles: true,
						cancelable: true,
						view: this.document.defaultView,
					});

					event.preventDefault();
					selectedItem.elm.nativeElement.dispatchEvent(event);
					// After pressing "Enter" key, the dropdown in normal situation would hide anyway because the
					// focused element is the anchor button. But there might be special use-cases where the dropdown
					// is opened programmatically for example, so we need to be sure it hides after confirming
					// selected item.
					// Although there is one exceptional situation, when item has explicitly set `stayOnClick`, which
					// should leave dropdown open.
					if (!selectedItem.stayOnClick) {
						this.hideDropdown();
					}
				}),
		);
	}

	/**
	 * By default, the overlay hides by clicking outside its DOM structure but by using hideOnClickOutside input we can
	 * disable this behavior.
	 */
	protected useClickOutsideListener() {
		if (this.hideOnClickOutside) {
			if (this.overlayRef) {
				this.hideEvents$.next(
					this.overlayRef.outsidePointerEvents().pipe(filter((event) => event.target !== this.elm)),
				);
			}
		} else {
			this.hideEvents$.next(EMPTY);
		}
	}
}

import { createSelector } from '@ngxs/store';
import { SESSION_STATE_TOKEN, SessionModel } from '@imt-web-zone/zone/state-session';
import { AuthSelectors } from '@imt-web-zone/zone/state-auth';

export class SessionSelectors {
	public static getUserData() {
		return createSelector(
			[SESSION_STATE_TOKEN, AuthSelectors.getAuthUserId],
			(sessionState: SessionModel, userId: number) => {
				if (!userId) {
					return null;
				}

				if (!sessionState[userId]) {
					return { organizationId: null, teamId: null };
				}
				// used when leaving admin (zone)
				if (window.location.pathname.startsWith('/leave/admin')) {
					return {
						organizationId: sessionState[userId].adminOrganizationId,
						teamId: sessionState[userId].adminTeamId,
					};
				}
				return {
					organizationId: sessionState[userId].organizationId,
					teamId: sessionState[userId].teamId,
				};
			},
		);
	}
}

import { InjectionToken } from '@angular/core';
import { DevtoolsOption, CacheExpiration, TitleOptions } from '@imt-web-zone/shared/model';
import { NgxsStoragePluginOptions } from '@ngxs/storage-plugin/internals';

export interface NgxsSetupStorageOptions extends NgxsStoragePluginOptions {
	saveForms?: boolean;
	key?: Array<string>;
}

export interface NgxsSetupOptions {
	ngxsStorage?: NgxsSetupStorageOptions;
	devtools?: DevtoolsOption;
	cacheExpiration?: {
		default: CacheExpiration;
		states?: {
			[key: string]: CacheExpiration;
		};
	};
	title?: TitleOptions;
}

export interface NgxsSetupOptions {
	ngxsStorage?: NgxsSetupStorageOptions;
	devtools?: DevtoolsOption;
	cacheExpiration?: {
		default: CacheExpiration;
		states?: {
			[key: string]: CacheExpiration;
		};
	};
	title?: TitleOptions;
}

type CacheExpirationConfig = Pick<NgxsSetupOptions, 'cacheExpiration'>;

export const NGXS_CACHE_OPTIONS: InjectionToken<CacheExpirationConfig> = new InjectionToken('NGXS_CACHE_OPTIONS');

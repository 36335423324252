import { inject, Injectable } from '@angular/core';
import { map, filter, take, firstValueFrom, switchMap } from 'rxjs';
import { TitleService as CoreTitleService } from '@imt-web-zone/shared/util';
import { TranslocoService } from '@jsverse/transloco';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { TeamsFacade } from '@imt-web-zone/zone/state-teams';

@Injectable({ providedIn: 'root' })
export class TitleTranslateProvider {
	private apiConfigFacade = inject(ApiConfigFacade);
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private transloco: TranslocoService) {}

	public async getValue(value: string): Promise<string> {
		if (!value) {
			return value;
		}

		return firstValueFrom(
			value === 'app.name'
				? this.apiConfigFacade.config$.pipe(
						map((config) => config?.brand?.title),
						take(1),
				  )
				: this.transloco.selectTranslate(value).pipe(take(1)),
		);
	}
}

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	private teamsFacade = inject(TeamsFacade);
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private coreTitleService: CoreTitleService) {
		this.teamsFacade.active$
			.pipe(
				filter((x) => !!x),
				switchMap((x) => this.coreTitleService.setOptions({ defaultTitle: x.name })),
			)
			.subscribe();
	}
}

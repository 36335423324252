import { EnvironmentBase } from '@imt-web-zone/shared/model';

export interface EnvironmentSharedBase extends EnvironmentBase {
	api: {
		baseUrl: string;
		staticUrl: string;
		sanityCheckInterval: number;
		newSanityCheckInterval: number;
		sessionLogoutExpiration: number;
		featureRefreshInterval: number;
		headers?: {
			[name: string]: string;
		};
	};
	cashier?: {
		baseUrl: string;
		headers: {
			'imt-web-hq-admin': string;
		};
	};
	streamer?: {
		baseUrl: string;
		socketUrlPrefix?: string;
		headers?: {
			[name: string]: string;
		};
	};
	/**
	 * staticUrlFolder is primarily used in widget for overloading config.domains.static
	 */
	staticFolderUrl?: string;
	appConfig?: {
		defaultTheme: string;
		defaultLang: string;
		[key: string]: unknown;
	};
	build: number;
	defaultLocale: string;
	localStorage?: {
		userIdKey: 'userId';
	};
}

const environmentBase: EnvironmentSharedBase = {
	production: false,
	env: 'local',
	hmr: false,
	serviceWorker: {
		enable: false,
		repeatVersionNotificationInterval: 1000 * 60 * 10, // 10 min
		//serviceWorkerCheckInterval: 1000 * 60 * 5, // 5 min
		serviceWorkerCheckInterval: 1000 * 5,
	},
	api: {
		baseUrl: 'api/v2',
		staticUrl: 'static',
		sanityCheckInterval: 1000 * 60 * 1, // 1 min
		sessionLogoutExpiration: 1000 * 60 * 2, // 2 min
		newSanityCheckInterval: 1000 * 60 * 5, // 5 min
		featureRefreshInterval: 1000 * 60,
		headers: {
			'imt-web-zone': 'local',
		},
	},
	cashier: {
		baseUrl: 'api/cashier',
		headers: {
			'imt-web-hq-admin': 'production',
		},
	},
	streamer: {
		baseUrl: 'streamer',
	},
	cacheExpiration: {
		default: 10 * 1000,
	},
	appConfig: {
		defaultTheme: 'violet',
		defaultLang: 'en',
		inspectorCdn: '/files/inspector/branch/master',
	},
	disableReduxDevtools: false,
	versions: {
		app: process.env.VERSION,
		iml: process.env.IML_VERSION,
	},
	build: +process.env.BUILD,
	defaultLocale: 'en-US',
	localStorage: {
		userIdKey: 'userId',
	},
	helpLinkSlave: 'https://www.make.com/en/help/',
	helpLink: undefined,
};

export { environmentBase };

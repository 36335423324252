import { StateContext, StateToken } from '@ngxs/store';
import { createEffectActionType } from './effect-action-utils';
import { HttpBuilder } from '@imt-web-zone/shared/util';
import { MODEL_META } from '@imt-web-zone/core/util-state-model-adapter';
import {
	EFFECT_ACTION_HANDLER,
	EFFECT_REQUEST_ADAPTER,
	EFFECT_STATE_UPDATER,
	EFFECT_INIT,
	EffectHandlerOptions,
	effectSymbol,
	effectSymbolPostfix,
	StateEffectError,
} from './internals';
import { effectFactory, RunEffectAction } from '@imt-web-zone/shared/util-store';
import { debugLogger } from '@imt-web-zone/core/util-core';
import { ɵensureStoreMetadata } from '@ngxs/store/internals';

const debug = debugLogger.create('state-effect-handler');

export const MODEL_ADAPTER = 'modelAdapter';

export interface EffectStateUpdater<T = any> {
	effectStateUpdater?(ctx: StateContext<any>, action: RunEffectAction): void;
	toStore?(data: Record<string, any>): T;
	toApi?(model: T): Record<string, any>;
}

export interface EffectRequestAdapter {
	effectRequestAdapter?(ctx: StateContext<any>, action: RunEffectAction): void;
}

export function getEffectOptions(target: any, propertyKey: string) {
	return (target as any)[effectSymbol(propertyKey)];
}

function customUpdaterSymbol(propertyKey: string) {
	return `${EFFECT_STATE_UPDATER}_${propertyKey}`;
}

function registerActionHandlers(target: any, options: EffectHandlerOptions) {
	// get assured that target[NGXS_META] exists
	ɵensureStoreMetadata(target);

	for (const property of Object.getOwnPropertyNames(options?.service.prototype)) {
		if (property.endsWith(effectSymbolPostfix)) {
			const method = property.replace(effectSymbolPostfix, '');
			const effectActionType = RunEffectAction.getType(options?.service.name, method);
			target['NGXS_META'].actions[effectActionType] = [
				{
					fn: EFFECT_ACTION_HANDLER,
					options: {},
					type: effectActionType,
				},
			];
		}
	}
}

/**
 * Decorator creating Action Handler for RunEffectAction. Must be used together with ngxs @State decorator
 *
 * @constructor
 */
export function EffectHandler(options: EffectHandlerOptions) {
	return function (target: any) {
		if (!options.adapter.prototype[MODEL_META]) {
			throw new StateEffectError(
				`Model "${options.adapter.prototype.constructor.name}" must use "@ModelAdapter()" decorator!`,
			);
		}

		if (!options?.service.prototype[EFFECT_INIT]) {
			throw new StateEffectError(
				`Service "${options.service.prototype.constructor.name}" must use "@EffectInit()" decorator!`,
			);
		}

		target.prototype[MODEL_ADAPTER] = options.adapter;

		registerActionHandlers(target, options);

		if (options?.requestAdapter) {
			target.prototype[EFFECT_REQUEST_ADAPTER] = options.requestAdapter;
		}

		if (options?.storeUpdater) {
			target.prototype[EFFECT_STATE_UPDATER] = options.storeUpdater;
		}

		debug(`EffectHandler state "${options.adapter.prototype.constructor.name}" initialized`, options);

		target.prototype[EFFECT_ACTION_HANDLER] = function (
			this: Record<string, any> & EffectStateUpdater<any> & EffectRequestAdapter,
			ctx: StateContext<any>,
			action: RunEffectAction,
		) {
			action.effectOptions = { ...action.effectOptions };
			const stateToken = target['NGXS_OPTIONS_META']['name'] as StateToken;
			const type = action?.effectOptions?.type;
			const debugHandler = debugLogger.create('state-effect-handler:' + stateToken.getName());
			if (this.effectRequestAdapter) {
				this.effectRequestAdapter(ctx, action);
			}

			if (!type) {
				throw new StateEffectError(`"effectOptions.type" must not be empty!`);
			}

			action.effectOptions.type = createEffectActionType(stateToken, type);
			debugHandler(`effectFactory called with: `, action);
			return effectFactory(
				ctx,
				action,
				action.request$,
				action.effectOptions.responseEntityPath as string,
				(res) => {
					// check if request uses customUpdater
					const customStoreUpdater = this[customUpdaterSymbol(action.serviceMethodName as string)];
					if (customStoreUpdater) {
						if (typeof this[customStoreUpdater] !== 'function') {
							throw new Error(
								`customStoreUpdater function "${customStoreUpdater}" does not exist in "${stateToken.getName()}" state`,
							);
						}
						this[customStoreUpdater](ctx, action);
						debugHandler(`effectFactory resolve with customStoreUpdater: `, customStoreUpdater);
					} else if (this.effectStateUpdater) {
						this.effectStateUpdater(ctx, action);
						debugHandler(`effectFactory resolve with effectStateUpdater`);
					} else {
						throw new StateEffectError(`Action "${action.type} wasn't processed with any updater function`);
					}
				},
			);
		};
	};
}

/**
 * Mark function as CustomUpdater linked to [requestProperty]
 *
 */
export function EffectCustomUpdater<T extends HttpBuilder>(requestProperty: keyof T) {
	return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
		target[customUpdaterSymbol(requestProperty as string)] = propertyKey;
	};
}

import { ActionType, createAction, props } from '@imt-web-zone/shared/util-store';

export const setDataStorePageSize = createAction('[Session] set datastore page size', props<{ size: number }>());
export type SetDataStorePageSize = ActionType<typeof setDataStorePageSize>;


export const setActiveOrganizationTeam = createAction(
	'[Session] set active organization team',
	props<{ isOrganization: boolean, id: string | null }>(),
);
export type SetActiveOrganizationTeam = ActionType<typeof setActiveOrganizationTeam>;

import { inject, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';

import { changeLogoIndicatorVisibility, CommonBaseFacade } from '@imt-web-zone/zone/state-common';
import { AppNames, APP_NAME } from '@imt-web-zone/shared/core';
import { ApiConfigFacade, SsoTypes } from '@imt-web-zone/zone/state-api-config';

import { LoadAuthUserPayload } from './auth.interface';
import { AuthorizationType } from './auth.model';
import { AuthFacade } from './auth.facade';
import { ZoneUserCookiesService } from '@imt-web-zone/zone/data-access-storages';
import { entityGuard } from '@imt-web-zone/core/util-entity-guard';
import { Store } from '@ngxs/store';

export const getUserColumns = (appNames: AppNames): LoadAuthUserPayload['query']['cols'] => {
	return appNames === AppNames.ZONE_HQ_ADMIN
		? [
				'usersAdminsRoleId',
				'avatar',
				'countryId',
				'email',
				'features',
				'id',
				'locale',
				'localeId',
				'timezone',
				'name',
				'timezoneId',
				'forceSetPassword',
				'language',
				'tfaEnabled',
		  ]
		: [
				'usersAdminsRoleId',
				'avatar',
				'countryId',
				'email',
				'features',
				'id',
				'locale',
				'localeId',
				'timezone',
				'name',
				'timezoneId',
				'userOrganizationRoles',
				'userTeamRoles',
				'forceSetPassword',
				'language',
				'tfaEnabled',
				'hasPassword',
				'created',
		  ];
};

let previousUrl: string | undefined;

export const authGuard = entityGuard(
	AuthFacade,
	({ facade, state, router, route }) => {
		const apiConfigFacade = inject(ApiConfigFacade);
		const cookiesService = inject(ZoneUserCookiesService);
		const AppNames = inject(APP_NAME);
		const injector = inject(Injector);

		const authState = facade.stateSnapshot;
		const apiConfig = apiConfigFacade.stateSnapshot;
		const isJWT = authState?.type === AuthorizationType.JWT;
		const hasCookie = !!cookiesService.readCookie('userId');

		// ignore activating guard after logout redirect
		if (authState?.signedOut) {
			return of(true);

			// not logged in
		} else if (!(isJWT || hasCookie) && previousUrl !== state.url) {
			// sso enabled
			if (apiConfig.ssoType === SsoTypes.OAUTH2 || apiConfig.ssoType === SsoTypes.SAML2) {
				return ssoHandler(injector, router, route, state);
			}
			return of(router.createUrlTree(['/login']));
		} else {
			// logged in, load user
			previousUrl = state.url;

			return facade.loadAuthUser$(getUserColumns(AppNames), {
				fromGuard: true,
				cacheExpiration: 1000 * 60 * 60 * 2, // 2 hours,
			});
		}
	},
	({ injector }) => {
		injector.get(AuthFacade).logout$({ redirect: true }).subscribe();
		return of(false);
	},
);

const ssoHandler = (injector: Injector, router: Router, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const authFacade = injector.get(AuthFacade);
	const apiConfigFacade = injector.get(ApiConfigFacade);
	const cookiesService = injector.get(ZoneUserCookiesService);
	const store = injector.get(Store);
	const commonFacade = injector.get(CommonBaseFacade);

	if (/\/admin\/login\/?/.test(state.url) || /\/admin$/.test(state.url)) {
		return of(router.createUrlTree(['/admin/login']));
	}

	if (state.url === '/login' && window.location.pathname === '/admin/login') {
		return of(false);
	}
	store.dispatch(changeLogoIndicatorVisibility({ visible: true }));

	if (apiConfigFacade.isSlave) {
		cookiesService.setVisitedCookie();
	}

	return authFacade.oauthAuthorize$(commonFacade.stateSnapshot.initialUrl || '/');
};

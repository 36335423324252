export class AuthUserModel {
	public loginAsUser?: boolean;

	constructor(
		public avatar: string,
		public email: string,
		public features: {
			allow_apps?: boolean;
			allow_apps_js?: boolean;
			private_modules?: boolean;
			public_templates?: boolean;
			allow_apps_commit?: boolean;
		},
		public id: string,
		public locale: string,
		public name: string,
		public timezone: string,
		public timezoneId: string,
		public countryId: string,
		public localeId: string,
		public usersAdminsRoleId?: string,
		public hasPassword?: boolean,
		public forceSetPassword?: boolean,
		public language?: string,
		public tfaEnabled?: boolean,
		public tfaCodes?: Array<string>,
		public isAffiliatePartner?: boolean,
		public created?: Date,
	) {}
}

export function getInitialAuthUserModel(): AuthUserModel | null {
	return null;
}

import {
	ApplicationRef,
	ErrorHandler,
	Injector,
	ModuleWithProviders,
	NgModule,
	Provider,
	inject,
	InjectionToken,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Observable, combineLatest, distinctUntilChanged, filter, firstValueFrom, map, merge } from 'rxjs';
import { FORMLY_CONFIG } from '@ngx-formly/core';
import {
	DefaultFallbackStrategy,
	DefaultInterceptor,
	DefaultMissingHandler,
	provideTranslocoFallbackStrategy,
	provideTranslocoInterceptor,
	provideTranslocoMissingHandler,
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	TranslocoModule,
	TranslocoService,
} from '@jsverse/transloco';
import { CustomUrlSerializer, TitleService } from '@imt-web-zone/shared/util';
import {
	APP_NAME,
	APP_ROOT_SELECTOR,
	AppNames,
	BASE_HREF_PROVIDER,
	FormlyValidationsService,
	IMT_APP_INITIALIZER_SELECTORS,
	IMT_EVENTS,
	IMT_MODAL_SERVICE,
	imtEventsFactory,
} from '@imt-web-zone/shared/core';
import { ORGANIZATIONS_STATE_TOKEN, WINDOW, windowFactory } from '@imt-web-zone/shared/data-access';
import { ImtUiModule } from '@imt-web-zone/shared/ui';
import {
	getTranslocoConfig,
	TRANSLOCO_AVAILABLE_LANGS,
	translocoInit,
	translocoNestedParamsTranspiler,
} from '@imt-web-zone/core/util-transloco';
import { environment } from '@imt-web-zone/shared/environments';
import { AppLoader, registerTranslateExtension } from '@imt-web-zone/zone/util';
import {
	AngularFormatterService,
	AppStatusService,
	ErrorHandlerService,
	MultiTranslateHttpLoaderResource,
	RudderStackService,
	TrackmanService,
	translateLoader,
	TRANSLATIONS_RESOURCES,
	ZoneToastService,
} from '@imt-web-zone/zone/data-access';
import { ImtUiModalModule, ImtUiModalService } from '@imt-web-zone/shared/ui-modal';
import { ImtModalTypeEnum } from '@imt-web-zone/shared/model';
import { ImtUiConfirmDialogComponent } from '@imt-web-zone/shared/ui-confirm-dialog';
import { PromptComponent } from '@imt-web-zone/zone/ui-prompt';
import { InspectorBaseInit } from '@imt-web-zone/zone/data-access-inspector';
import { ImtUiFormulaTranslateService } from '@imt-web-zone/zone/data-access-formula';
import { DEFAULT_TOOLTIP_CONFIG, UiTooltipConfig } from '@imt-web-zone/make-design-system/ui-tooltip';
import { DROPDOWN_DEFAULT_OFFSET } from '@imt-web-zone/make-design-system/ui-dropdown';
import { imtUiAuthUserDataProvider } from '@imt-web-zone/shared/ui/components';
import {
	IMT_UI_TRANSLATE_SERVICE_TOKEN,
	ImtUiTranslateWrapperServiceFactory,
} from '@imt-web-zone/shared/util-imt-translate';
import { DatadogService } from '@imt-web-zone/core/data-access-datadog';
import { baseDependencyInit, initializeApp, serviceInit } from '@imt-web-zone/core/util-core';
import { TEAMS_STATE_TOKEN, TeamsFacade } from '@imt-web-zone/zone/state-teams';
import { OrganizationsFacade } from '@imt-web-zone/zone/data-access-state/organizations';
import { SKIP_ADMIN_USER_ROLES } from '@imt-web-zone/zone-feature-permissions';
import { FeatureFlag, GrowthbookService, Context } from '@imt-web-zone/shared/data-access-growthbook';
import { GoogleAnalyticsService } from '@imt-web-zone/zone/data-access-google-analytics';
import {
	ComponentThemes,
	ThemeService,
	ThemesVariant,
	ZoneTheme,
} from '@imt-web-zone/make-design-system/util-theme-service';
import { UiToastMessageModule } from '@imt-web-zone/make-design-system/ui-toast-message';
import { AuthFacade, AuthSelectors } from '@imt-web-zone/zone/state-auth';
import { FormsState } from '@imt-web-zone/shared/util-store';
import { CommonState } from '@imt-web-zone/zone/data-access-common';
import { SessionState } from '@imt-web-zone/zone/data-access-session';
import { RouterFacade } from '@imt-web-zone/zone/state-router';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { RouterSelectors } from '@imt-web-zone/shared/util-store';
import { nativeFetchInterceptorInit } from '@imt-web-zone/zone/data-access-native-fetch-interceptor';
import {
	HQ_ADMIN_INTERCEPTORS,
	httpErrorFormatterData,
	HttpErrorFormatterService,
	ZONE_INTERCEPTORS,
} from '@imt-web-zone/zone/data-access-interceptors';
import { ApiConfigFacade, ApiConfigProvider, ApiConfigSelectors, ModeEnum } from '@imt-web-zone/zone/state-api-config';
import { ChameleonService, IdentifyArgs } from '@imt-web-zone/zone/data-access-chameleon';
import { AppActivityTrackerService } from '@imt-web-zone/zone/app-activity-tracker';
import { ZoneAssetsService, ZoneAssetsDomain } from '@imt-web-zone/zone/util-zone-assets';
import { provideAssetsPublicPath, provideAssetsService } from '@imt-web-zone/core/util-assets';
import { INTEGROMAT_ROOT_ELEMENT } from '../constants';
import { StoreModule } from '@imt-web-zone/zone/state-store';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CookiesService } from '@imt-web-zone/shared/data-access-cookies';
import { EnumsState, EnumNames } from '@imt-web-zone/zone/state-enums';
import { UiFirstLevelNavigationService } from '@imt-web-zone/make-design-system/ui-first-level-navigation';
import { CommonModel, updateCommonState } from '@imt-web-zone/zone/state-common';
import { Store } from '@ngxs/store';

function appInitializerSelectorsFactory(): Array<any> {
	return [
		AuthSelectors.getAuthState,
		CollectionState.getActive(TEAMS_STATE_TOKEN),
		CollectionState.getActive(ORGANIZATIONS_STATE_TOKEN),
		AuthSelectors.getAuthUserTimezone,
		ApiConfigSelectors.apiConfig,
		EnumsState.getEnumMap(EnumNames.TIMEZONES),
		RouterSelectors.url,
	];
}

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		TranslocoModule,
		StoreModule.forRoot(
			[
				ApiConfigFacade.provideStore(),
				AuthFacade.provideStore(),
				CommonState,
				EnumsState,
				SessionState,
				FormsState,
			],
			{},
		),
		ImtUiModalModule.forRoot({
			modalComponents: {
				[ImtModalTypeEnum.Confirm]: ImtUiConfirmDialogComponent,
				[ImtModalTypeEnum.Prompt]: PromptComponent,
			},
			classProviders: [
				{
					injectionToken: IMT_MODAL_SERVICE,
					classToProvide: ImtUiModalService,
				},
			],
		}),
		ImtUiModule.forRoot({
			langService: {
				provide: IMT_UI_TRANSLATE_SERVICE_TOKEN,
				useFactory: ImtUiTranslateWrapperServiceFactory,
				deps: [TranslocoService],
			},
			authUserData: imtUiAuthUserDataProvider(
				(authFacade: AuthFacade) =>
					combineLatest({
						locale: authFacade.locale$,
						timezoneOffset: authFacade.tz$.pipe(map((timezone) => timezone.offset)),
					}),
				AuthFacade,
			),
		}),
		UiToastMessageModule,
	],
})
export class ZoneCoreModule {
	public appRef = inject(ApplicationRef);
	public titleService = inject(TitleService);
	public appStatusService = inject(AppStatusService);
	public formulaTranslateService = inject(ImtUiFormulaTranslateService);
	public formlyValidations = inject(FormlyValidationsService);

	public static forRoot(
		appName: AppNames,
		translationsResources: Array<MultiTranslateHttpLoaderResource> = null,
		providers: Array<Provider> = [],
	): ModuleWithProviders<ZoneCoreModule> {
		const INTERCEPTORS = appName === AppNames.ZONE_HQ_ADMIN ? HQ_ADMIN_INTERCEPTORS : ZONE_INTERCEPTORS;
		return {
			ngModule: ZoneCoreModule,
			providers: [
				{ provide: ErrorHandler, useClass: ErrorHandlerService },
				{
					provide: APP_ROOT_SELECTOR,
					useValue: INTEGROMAT_ROOT_ELEMENT,
				},
				BASE_HREF_PROVIDER,
				provideAssetsPublicPath<ZoneAssetsDomain>(ZoneAssetsDomain.Zone, localStorage.getItem('publicPath')),
				provideAssetsService(ZoneAssetsService),
				{ provide: TRANSLATIONS_RESOURCES, useValue: translationsResources },
				{ provide: TRANSLOCO_LOADER, useFactory: translateLoader, deps: [Injector] },
				{ provide: TRANSLOCO_AVAILABLE_LANGS, useValue: ['en', 'cs'] },
				{
					provide: TRANSLOCO_CONFIG,
					useFactory: getTranslocoConfig({
						defaultLang: 'en',
						reRenderOnLangChange: true,
						prodMode: environment.production,
					}),
				},
				provideTranslocoMissingHandler(DefaultMissingHandler),
				provideTranslocoInterceptor(DefaultInterceptor),
				provideTranslocoFallbackStrategy(DefaultFallbackStrategy),
				provideHttpClient(withInterceptorsFromDi()),
				translocoNestedParamsTranspiler,
				...INTERCEPTORS,
				{ provide: APP_NAME, useValue: appName },
				{
					provide: UrlSerializer,
					useClass: CustomUrlSerializer,
				},
				{ provide: WINDOW, useFactory: windowFactory },
				{ provide: IMT_EVENTS, useFactory: imtEventsFactory },
				{
					provide: IMT_APP_INITIALIZER_SELECTORS,
					useFactory: appInitializerSelectorsFactory,
				},

				/**
				 *  APP_INITIALIZER
				 */
				initializeApp(),

				/**
				 * SEQUENTIAL dependencies bellow
				 */
				baseDependencyInit(AppLoader),
				baseDependencyInit(ApiConfigProvider),
				baseDependencyInit(
					ZoneAssetsService,
					async (apiConfigFacade) => {
						const cdnConfig = apiConfigFacade.configSnapshot.domains.cdn;

						if (!cdnConfig) {
							return {};
						}

						const assetsPaths = {
							[ZoneAssetsDomain.DominoElements]: ZoneAssetsService.replaceVersionInURL(
								cdnConfig.dominoElements,
								environment.versions.dominoElements,
							),
							[ZoneAssetsDomain.TailwindThemes]: ZoneAssetsService.replaceVersionInURL(
								cdnConfig.tailwindThemes,
								environment.versions.tailwindThemes,
							),

							// [ZoneAssetsDomain.Forman]: resolved via `InspectorBaseInit`
							// [ZoneAssetsDomain.Inspector]: resolved via `InspectorBaseInit`

							// [ZoneAssetsDomain.Zone] resolved via `provideAssetsPublicPath`, DO NOT try to update it
							// here or anywhere else as it's closely related to the public path of the application
						};
						return assetsPaths;
					},
					ApiConfigFacade,
				),
				baseDependencyInit(
					GrowthbookService,
					async (apiConfigFacade, authFacade, organizationFacade, trackmanService, cookiesService) => {
						const apiConfigData = apiConfigFacade.configSnapshot;
						const firstTimeUser = cookiesService.readCookie('first_time_user') === 'true';
						let crossDomain: string;
						let zone: string;

						if (apiConfigData) {
							crossDomain = apiConfigData.domains['*'].replace('*', '');
							zone = apiConfigData.zone;
						}

						const onContextChange$: Observable<Context> = combineLatest([
							authFacade.user$,
							organizationFacade.activeId$,
						]).pipe(
							filter(([user, orgId]) => !!user),
							distinctUntilChanged(([prevUser, prevOrg], [currUser, currOrg]) => {
								const userIsEqual = !['id', 'email', 'countryId', 'localeId', 'timezoneId'].some(
									(field) => {
										return prevUser[field] !== currUser[field];
									},
								);
								return userIsEqual && prevOrg === currOrg;
							}),
							map(([userContext, orgContext]) => ({
								email: userContext.email,
								userId: userContext.id,
								countryId: userContext.countryId,
								localeId: userContext.localeId,
								timezoneId: userContext.timezoneId,
								createdAt: new Date(userContext.created).getTime(),
								org_id: +orgContext,
								firstTimeUser,
								zone,
							})),
						);

						return {
							refreshInterval: environment.api.featureRefreshInterval,
							initialContext: { zone },
							projectName: 'web-zone',
							eventTrack: (eventName, data, includeCommonData) => {
								if (authFacade.userIdSnapshot) {
									trackmanService.trace$(eventName, data, includeCommonData).subscribe();
									GoogleAnalyticsService.pushToDataLayer({
										event: 'experiment',
										userId: data['user_id'],
										...data,
									});
								}
							},
							onContextChange$,
							crossDomain,
							mobileMaxWidth: 575,
						};
					},
					ApiConfigFacade,
					AuthFacade,
					OrganizationsFacade,
					TrackmanService,
					CookiesService,
				),
				baseDependencyInit(
					InspectorBaseInit,
					async (authFacade, apiConfigFacade) => {
						return {
							lang$: combineLatest([authFacade.language$, apiConfigFacade.state$]).pipe(
								map(([langugage, apiConfigData]) => {
									return langugage || apiConfigData?.generalSettings?.defaultLanguage;
								}),
							),
							user$: authFacade.user$,
							inspectorUrl: apiConfigFacade.configSnapshot.domains.cdn?.inspector,
							inspectorFallbackVersion: environment.versions.inspector,
							formanUrl: apiConfigFacade.configSnapshot.domains.cdn?.forman,
							formanFallbackVersion: environment.versions.forman,
						};
					},
					AuthFacade,
					ApiConfigFacade,
				),
				baseDependencyInit(
					new InjectionToken('ZONE_VERSIONS'),
					async (store, growthbook) => {
						const versions = (): Pick<CommonModel, 'version' | 'forman' | 'inspector' | 'iml'> => ({
							forman: window.IMT_FORMAN_VERSION,
							inspector: window.IMT_INSPECTOR?.version,
							iml: environment.versions.iml,
							version: environment.versions.app,
						});
						await firstValueFrom(store.dispatch(updateCommonState({ payload: versions() })));

						if (growthbook.isOn('enable-zone-versions')) {
							Object.defineProperty(window, 'ZONE_VERSIONS', {
								get: versions,
							});
						}
					},
					// todo remove
					Store,
					GrowthbookService,
				),

				/**
				 * initialization of providers
				 */
				serviceInit(
					DatadogService,
					async (apiConfigFacade, authFacade, teamFacade, organizationFacade, growthBookService) => {
						const apiConfig = apiConfigFacade.stateSnapshot;
						const datadogToken = apiConfig?.tokens?.datadogToken || undefined;
						return {
							config: {
								env: environment.env,
								service: 'web-zone',
								clientToken: datadogToken,
							},
							featureFlag: 'enable-datadog' as FeatureFlag,
							userId$: authFacade.userId$,
							contextChanged$: merge(
								teamFacade.activeId$.pipe(map((id) => ['teamId', id] as [string, any])),
								organizationFacade.activeId$.pipe(map((id) => ['organization', id] as [string, any])),
							),
							// reload$: apiConfigFacade.apiConfigData$.pipe(
							// 	map((config) => config?.tokens.datadogToken),
							// 	filter((token) => !!token),
							// ),
						};
					},
					ApiConfigFacade,
					AuthFacade,
					TeamsFacade,
					OrganizationsFacade,
					GrowthbookService,
				),
				serviceInit(
					async (apiConfigFacade) => {
						if (apiConfigFacade.configSnapshot.mode === ModeEnum.MASTER) {
							return null;
						}
						return import('@imt-web-zone/zone/data-access-stripe').then((m) => m.StripeService);
					},
					async (apiConfigFacade) => {
						return {
							token$: apiConfigFacade.tokens$.pipe(
								filter((tokens) => !!tokens?.stripePublishableKey),
								map((tokens) => tokens.stripePublishableKey),
								distinctUntilChanged(),
							),
						};
					},
					ApiConfigFacade,
				),
				serviceInit(
					async (_, apiConfigFacade) => {
						if (apiConfigFacade.configSnapshot.mode === ModeEnum.MASTER) {
							return null;
						}

						return import('@imt-web-zone/zone/data-access-session-checks').then((m) => m.HqSessionService);
					},
					async (authFacade, apiConfigFacade) => {
						if (apiConfigFacade.configSnapshot.mode === ModeEnum.MASTER) {
							return null;
						}
						return {
							userId$: authFacade.userId$,
							hqDomain$: apiConfigFacade.config$.pipe(map((config) => config.slaveDomains?.hq)),
						};
					},
					AuthFacade,
					ApiConfigFacade,
				),
				serviceInit(
					AngularFormatterService,
					async (authFacade) => {
						return {
							getLocale: () => authFacade.userLocaleSnapshot,
							getTimezone: () => authFacade.tzSnapshot,
						};
					},
					AuthFacade,
				),
				serviceInit(
					ChameleonService,
					async (authFacade, apiConfigFacade, orgsFacade) => {
						return {
							identify$: combineLatest([
								authFacade.user$,
								orgsFacade.active$.pipe(filter((org) => !!org)),
								apiConfigFacade.config$,
							]).pipe(
								map(([user, org, config]) => {
									return [
										user?.id,
										{
											email: user?.email,
											name: user?.name,
											created: user?.created,
											timezone: user?.timezone,
											company: {
												uid: org?.id,
												name: org?.name,
												orgzone: config?.zone,
											},
										},
									] as IdentifyArgs;
								}),
							),
							token$: apiConfigFacade.tokens$.pipe(map((tokens) => tokens.chameleonToken)),
						};
					},
					AuthFacade,
					ApiConfigFacade,
					OrganizationsFacade,
				),
				serviceInit(
					GoogleAnalyticsService,
					async (apiConfigFacade, authFacade) => {
						const apiConfigData = apiConfigFacade.configSnapshot;
						let gaToken: string;
						let crossDomain: string;
						if (apiConfigData) {
							gaToken = apiConfigData.tokens.googleAnalytics;
							crossDomain = apiConfigData.domains['*'].replace('*', '');
						}
						return {
							token: gaToken,
							userId$: authFacade.userId$.pipe(filter((id) => !!id)),
							datalayer$: authFacade.userId$.pipe(
								filter((id) => !!id),
								map((id) => ({
									event: 'login',
									userId: id,
								})),
							),
							crossDomain,
						};
					},
					ApiConfigFacade,
					AuthFacade,
				),
				serviceInit(
					RudderStackService,
					async (apiConfigFacade, authUserFacade, routerFacade) => {
						return {
							tokens$: apiConfigFacade.tokens$.pipe(
								map((tokens) => ({
									rudderstackToken: tokens?.rudderstackToken,
									rudderstackDataUrl: tokens?.rudderstackDataUrl,
								})),
							),
							userData$: authUserFacade.user$.pipe(
								distinctUntilChanged((a, b) => a && b && a.id === b.id),
								filter((res) => !!res),
								map((user) => ({
									id: user.id,
									data: { email: user.email, locale: user.locale },
								})),
							),
							routerUrl$: routerFacade.url$,
						};
					},
					ApiConfigFacade,
					AuthFacade,
					RouterFacade,
				),
				serviceInit(ThemeService, () => {
					return Promise.resolve({
						activeTheme: 'integromat' as ZoneTheme,
						variant: 'violet' as ThemesVariant,
						componentThemes: [
							'integromat-table',
							'integromat-dropdown',
							'integromat-tooltip',
						] as Array<ComponentThemes>,
						localStorageKey: 'zone-theme',
					});
				}),

				serviceInit(
					ZoneToastService,
					(apiConfigFacade) => {
						return Promise.resolve(apiConfigFacade.isMaster);
					},
					ApiConfigFacade,
				),

				translocoInit(
					async (
						apiConfigFacade: ApiConfigFacade,
						apiConfigProvider: ApiConfigProvider,
						transloco: TranslocoService,
					) => {
						const apiConfigResult = await firstValueFrom(apiConfigProvider.resolved$);

						let language: string;
						if (apiConfigResult) {
							const apiConfigData = apiConfigFacade.configSnapshot;
							language = apiConfigData.generalSettings?.defaultLanguage;
						}
						language = language || environment.appConfig?.defaultLang;

						// These few lines below look a bit weird and they were taken originally
						// from constructor of `app.component` (untouched).
						transloco.setDefaultLang(language);
						transloco.setActiveLang(language);
						transloco.setActiveLang(environment.appConfig.defaultLang);
						return transloco.getActiveLang();
					},
					ApiConfigFacade,
					ApiConfigProvider,
					TranslocoService,
				),
				serviceInit(
					AppActivityTrackerService,
					async (
						growthBookService: GrowthbookService,
						authFacade: AuthFacade,
						apiConfigFacade: ApiConfigFacade,
					) => {
						return {
							featureFlagIsOn$: growthBookService.isOn$('secure_session_logout_web_zone'),
							authUser$: authFacade.user$,
							isMaster: apiConfigFacade.isMaster,
							apiConfig: apiConfigFacade.configSnapshot,
						};
					},
					GrowthbookService,
					AuthFacade,
					ApiConfigFacade,
				),
				nativeFetchInterceptorInit(
					async (apiConfigFacade: ApiConfigFacade, apiConfigProvider: ApiConfigProvider) => {
						const apiConfigResult = await firstValueFrom(apiConfigProvider.resolved$);
						const csrfDomains: string[] = [];
						if (apiConfigResult) {
							const apiConfigData = apiConfigFacade.configSnapshot;
							csrfDomains.push(apiConfigData.domains.web);
							if (apiConfigData.domains.hq) {
								csrfDomains.push(apiConfigData.domains.hq);
							}
							if (apiConfigData.domains.base && !csrfDomains.includes(apiConfigData.domains.base)) {
								csrfDomains.push(apiConfigData.domains.base);
							}
						}
						return {
							csrfDomains: csrfDomains,
						};
					},
					ApiConfigFacade,
					ApiConfigProvider,
				),
				{
					provide: FORMLY_CONFIG,
					multi: true,
					useFactory: registerTranslateExtension,
					deps: [TranslocoService],
				},
				{ provide: APP_NAME, useValue: appName },
				{
					provide: SKIP_ADMIN_USER_ROLES,
					useValue: () => {
						if (!window.location.pathname.startsWith('/admin')) {
							return true;
						}
						return false;
					},
				},
				HttpErrorFormatterService,
				httpErrorFormatterData((authFacade: AuthFacade) => {
					return () => ({
						locale: authFacade.userLocaleSnapshot,
						timezoneOffset: authFacade.tzSnapshot?.offset,
					});
				}, AuthFacade),
				// Design System
				{
					provide: DEFAULT_TOOLTIP_CONFIG,
					useValue: {
						offset: {
							vertical: 2,
							horizontal: 2,
						},
					} as UiTooltipConfig,
				},
				{
					provide: DROPDOWN_DEFAULT_OFFSET,
					useValue: 2,
				},
				{
					provide: UiFirstLevelNavigationService,
					useFactory: (growthbookService: GrowthbookService) =>
						growthbookService.isOn('second-level-navigation') ? new UiFirstLevelNavigationService() : null,
					deps: [GrowthbookService],
				},
				...providers,
			],
		};
	}

	constructor() {
		const parentModule = inject(ZoneCoreModule, { optional: true, skipSelf: true });

		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}
}

import { ActionType, createAction, createAsyncLoadAction, props } from '@imt-web-zone/shared/util-store';
import { ScenarioLogModel } from './scenario-logs.model';
import { ListScenariosLogsPayload, ListScenariosLogsResponse, ScenarioExecutionLogPayload, ScenarioExecutionLogResponse, ScenariosLogStatus } from './scenario-logs.interface';
import { ActionMessageMetadata } from '@imt-web-zone/shared/util-store';

// tslint:disable:max-line-length

export const listScenarioLogs = createAsyncLoadAction<ListScenariosLogsPayload, ListScenariosLogsResponse, ActionMessageMetadata & { ignoreRunning?: boolean; clearActive?: boolean, clearStore?: boolean }>('[ScenariosLogs API] GET list logs');
export type ListScenarioLogs = ActionType<typeof listScenarioLogs>;

export const scenarioExecutionLog = createAsyncLoadAction<ScenarioExecutionLogPayload, ScenarioExecutionLogResponse>('[ScenariosLogs API] GET execution log')
export type ScenarioExecutionLog = ActionType<typeof scenarioExecutionLog>;

export const addScenarioLog = createAction('[Log] ADD scenario log', props<{ payload: ScenarioLogModel }>());
export type AddScenarioLog = ActionType<typeof addScenarioLog>;

export const updateScenarioLog = createAction('[Log] Update scenario log', props<{
	payload: {
		id: string;
		imtId: string;
		data: {
			duration: number;
			transfer: number;
			operations: number;
			status: ScenariosLogStatus;
			instant: boolean;
		};
	},
}>());
export type UpdateScenarioLog = ActionType<typeof updateScenarioLog>;

export const dropExecution = createAction('[Log] Drop execution', props<{ payload: string }>())
export type DropExecution = ActionType<typeof dropExecution>;

import { InjectionToken, Provider } from '@angular/core';

import { HttpErrorFormatterData } from './http-error-formatter.model';

export const HTTP_ERROR_FORMATTER_DATA = new InjectionToken<HttpErrorFormatterData>('HTTP_ERROR_FORMATTER_DATA');

/**
 * Helps providing data provider in place where `HttpErrorFormatterService` is used
 */
export const httpErrorFormatterData = (
	factoryFn: (...deps: Array<unknown>) => () => HttpErrorFormatterData,
	...deps: Array<unknown>
): Provider => {
	return {
		provide: HTTP_ERROR_FORMATTER_DATA,
		useFactory: factoryFn,
		deps,
	};
};

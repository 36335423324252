<ng-template #template>
	<div class="modal-header" [ngClass]="theme + ' gradient'" *ngIf="!hideHeader">
		<ng-container *ngIf="headerText">
			<h4 class="modal-title">{{ headerText }}</h4>
			<button type="button" class="close" aria-label="Close" (click)="onDismiss(reason)">
				<span aria-hidden="true">&times;</span>
			</button>
		</ng-container>

		<ng-container *ngIf="!headerText">
			<ng-content select="imt-ui-modal-header"></ng-content>
		</ng-container>
	</div>
	<div class="modal-body" *ngIf="!hideBody">
		<ng-container *ngIf="bodyText">
			<p>{{ bodyText }}</p>
		</ng-container>
		<ng-container *ngIf="!bodyText">
			<ng-content select="imt-ui-modal-body"></ng-content>
			<ng-content></ng-content>
		</ng-container>
	</div>
	<div class="modal-footer" *ngIf="!hideFooter">
		<ng-content select="imt-ui-modal-footer"></ng-content>
	</div>
</ng-template>

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, map, mergeMap, skip, startWith, take } from 'rxjs/operators';
import { fromEvent, interval, merge, Observable, of, timer } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { GET, HttpBuilder } from '@imt-web-zone/shared/util';
import { environment } from '@imt-web-zone/shared/environments';
import { COMMON_STATE_TOKEN } from '@imt-web-zone/zone/state-common';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import { Store } from '@ngxs/store';
import {
	UI_TOAST_MESSAGE_PERMANENT,
	UiToastMessageRef,
	UiToastMessageService,
} from '@imt-web-zone/make-design-system/ui-toast-message';

@Injectable({
	providedIn: 'root',
})
export class AppStatusService extends HttpBuilder {
	private openedModal: UiToastMessageRef;
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(
		private appRef: ApplicationRef,
		private updates: SwUpdate,
		private toastService: UiToastMessageService,
		private transloco: TranslocoService,
		protected http: HttpApiClient,
		private store: Store,
	) {
		super(http);
	}

	public init() {
		if (environment.serviceWorker.enable && !this.updates.isEnabled) {
			this.checkVersionWithoutSW();
			return void 0;
		}
		if (!environment.serviceWorker.enable) {
			const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
			appIsStable$.pipe(take(1)).subscribe(() => {
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.getRegistrations().then((registrations) => {
						for (const registration of registrations) {
							// as we do not use caching let unregister service worker
							// once we want to start using it, implement mechanism how to unregister it
							// - finish todo: unregister sw in update-ngsw.ts
							// eslint-disable-next-line no-restricted-syntax
							console.info('serviceWorker unregistered');
							this.clearCache();
							registration.unregister();
						}
					});
				}
			});
			return void 0;
		}

		this.updates.versionUpdates.subscribe((evt) => {
			switch (evt.type) {
				case 'VERSION_DETECTED':
					console.log(`Downloading new app version: ${evt.version.hash}`);
					break;
				case 'VERSION_READY':
					console.log(`Current app version: ${evt.currentVersion.hash}`);
					console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
					break;
				case 'VERSION_INSTALLATION_FAILED':
					console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
					break;
			}
		});

		interval(environment.serviceWorker.serviceWorkerCheckInterval)
			.pipe(
				startWith(0),
				mergeMap(() => this.updates.checkForUpdate()),
				skip(1),
			)
			.subscribe((updateFound) => {
				if (environment.env === 'development') {
					console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
				}
				if (updateFound) {
					this.showVersionNotification();
				}
			});
	}

	@GET('ping', { 'Content-Type': 'text/html' }, 'text')
	public ping$(): Observable<'pong'> {
		return undefined;
	}

	public get isOffline$() {
		return merge(
			of(!navigator.onLine),
			fromEvent(window, 'online').pipe(map(() => false)),
			fromEvent(window, 'offline').pipe(map(() => true)),
		);
	}

	private showVersionNotification() {
		if (this.openedModal || window['hide-version-notification']) {
			return void 0;
		}
		this.openedModal = this.toastService.showWarning({
			title: this.transloco.translate('versionupdate.title'),
			text: this.transloco.translate('versionupdate.description'),
			expiration: UI_TOAST_MESSAGE_PERMANENT,
			// @todo: Ask UX what should go here.
			buttonLabel: 'Reload',
			onClose: () => {
				setTimeout(() => {
					this.showVersionNotification();
				}, environment.serviceWorker.repeatVersionNotificationInterval);
				this.openedModal = null;
			},
			onButtonClick: this.reload.bind(this),
		});
	}

	private checkVersionWithoutSW() {
		const delay = environment.serviceWorker.serviceWorkerCheckInterval;
		timer(0, delay)
			.pipe(mergeMap(() => this.http.get<any>('/ngsw.json')))
			.subscribe((ngsw) => {
				const version = this.store.selectSnapshot(COMMON_STATE_TOKEN)?.version;
				if (version && ngsw.appData.version !== version) {
					this.showVersionNotification();
				}
			});
	}

	private async reload() {
		await this.updates.activateUpdate();
		// await this.clearCache();
		document.location.reload();
	}

	private async clearCache() {
		const names = await caches.keys();
		const deletedCache: Array<Promise<boolean>> = [];
		for (const name of names) {
			deletedCache.push(caches.delete(name));
		}
		return await Promise.all(deletedCache);
	}
}

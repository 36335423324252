import { inject } from '@angular/core';

import { StateToken } from '@ngxs/store';
import { produce } from 'immer';

import { NGXS_TESTING_ENV } from '@imt-web-zone/core/util-state-facade/testing';

import { StateFacadeAbstractError } from './internals';
import { StateBaseFacadeAbstractClass } from './state-base-facade.abstract';

export type GenericTypeFromStateToken<T> = T extends StateToken<infer X> ? X : never;

export abstract class StateFacadeAbstract<T extends StateToken, S> extends StateBaseFacadeAbstractClass {
	protected abstract service: S;

	/**
	 * @deprecated use stateSignal instead
	 */
	public stateRxSnapshot = this.rxSnapshotFn(this.stateToken);
	public stateSignal = this.store.selectSignal(this.stateToken);
	public state$ = this.store.select$(this.stateToken);
	public get stateSnapshot() {
		return this.store.selectSnapshot(this.stateToken);
	}

	private readonly ngxsTestingEnv = inject(NGXS_TESTING_ENV, { optional: true });

	protected constructor(protected stateToken: StateToken<GenericTypeFromStateToken<T>>) {
		super();
		this.checkExistenceOfState();
	}

	/**
	 * Resets the state to a specific point.
	 * This method is useful for those who need to modify the state directly during unit testing.
	 */
	public resetState(state: GenericTypeFromStateToken<T>) {
		if (this.ngxsTestingEnv !== true) {
			throw new StateFacadeAbstractError(`
				#resetState method is intended to be used only in testing!

				To use #resetState in test, use provideTestingStore().

				Example:

				describe('HooksFacade', () => {
					const createService = createServiceFactory({
						service: HooksFacade,
						providers: [provideTestingStore([HooksState])],
						...
					});
					...
				});
			`);
		}

		// Get the whole state.
		const baseState = this.store.snapshot();
		// Get the identifier of the current state, that needs to be set.
		const stateTokenName = this.stateToken.getName();

		// Create a new state with given changes.
		const nextState = produce(baseState, (draft: any) => {
			draft[stateTokenName] = structuredClone(state);
		});

		// Reset state into desired point.
		return this.store.reset(nextState);
	}

	private checkExistenceOfState() {
		const state = this.store.selectSnapshot(this.stateToken);
		if (typeof state === 'undefined') {
			// todo resolve - this not working when facade is used in ServiceInit()
			// throw new Error(
			// 	`State "${this.stateToken.getName()}" not initialized!` +
			// 		`\nPlease include ${this.stateToken.getName()} State to the NgxsModule.forFeature()\n`,
			// );
		}
	}
}

import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyModule } from '@ngx-formly/core';

import { ImtModalTypeComponentMap, ImtModalService } from '@imt-web-zone/shared/model';
import { ClassTokenProvidersConfig, getClassTokenProviders } from '@imt-web-zone/shared/util';

import { ImtUiModalComponent } from './imt-ui-modal.component';
import { ImtUiModalBodyComponent } from './body/imt-ui-modal-body.component';
import { ImtUiModalHeaderComponent } from './header/imt-ui-modal-header.component';
import { ImtUiModalFooterComponent } from './footer/imt-ui-modal-footer.component';
import { ImtUiModalAccessDeniedComponent, ImtUiModalTemplateComponent } from './templates/imt-ui-modal-templates';
import { IMT_MODAL_COMPONENT_MAP } from './imt-ui-modal.symbol';
import { ImtUiModalService } from './imt-ui-modal.service';
import { ImtUiModalActive } from './imt-ui-modal-active';
import { DismissReason } from './imt-ui-modal-base.component';

export interface ImtUiModalModuleOptions<T> {
	/** Components which will be used as a possible content for the modal */
	modalComponents: ImtModalTypeComponentMap<T>;
}

@NgModule({
	declarations: [
		ImtUiModalComponent,
		ImtUiModalBodyComponent,
		ImtUiModalHeaderComponent,
		ImtUiModalFooterComponent,
		ImtUiModalTemplateComponent,
		ImtUiModalAccessDeniedComponent,
	],
	imports: [CommonModule, NgbModalModule, FormsModule, ReactiveFormsModule, FormlyModule.forChild()],
	exports: [
		ImtUiModalComponent,
		ImtUiModalBodyComponent,
		ImtUiModalHeaderComponent,
		ImtUiModalFooterComponent,
		ImtUiModalTemplateComponent,
		ImtUiModalAccessDeniedComponent,
	],
	providers: [NgbActiveModal, ImtUiModalService, ImtUiModalActive],
})
export class ImtUiModalModule {
	public static forRoot<T>(
		options: ImtUiModalModuleOptions<T> & ClassTokenProvidersConfig<ImtUiModalService, ImtModalService>,
	): ModuleWithProviders<ImtUiModalModule> {
		return {
			ngModule: ImtUiModalModule,
			providers: [
				{
					provide: IMT_MODAL_COMPONENT_MAP,
					useValue: options.modalComponents,
				},
				// provide an option to expose `ImtUiModalService` via token, so then it can be used
				// in lib types which are not allowed to use this "type:ui" lib
				...getClassTokenProviders(options),
			],
		};
	}
}

export {
	ImtUiModalComponent,
	ImtUiModalBodyComponent,
	ImtUiModalHeaderComponent,
	ImtUiModalFooterComponent,
	ImtUiModalTemplateComponent,
	ImtUiModalAccessDeniedComponent,
	ImtUiModalService,
	ImtUiModalActive,
	DismissReason,
};

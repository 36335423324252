import {
	ActionType,
	createAction,
	createAsyncAction,
	createAsyncLoadAction,
	props,
} from '@imt-web-zone/shared/util-store';
import {
	DetailOrganizationsUserOrganizationRolePayload,
	DetailOrganizationsUserOrganizationRoleResponse,
	DetailUsersUserOrganizationRolePayload,
	DetailUsersUserOrganizationRoleResponse,
	ListOrganizationsUserOrganizationRolesPayload,
	ListOrganizationsUserOrganizationRolesResponse,
	ListUsersUserOrganizationRolesPayload,
	ListUsersUserOrganizationRolesResponse,
	SetUsersUserOrganizationRolePayload,
	SetUsersUserOrganizationRoleResponse,
} from './user-organization-roles.interface';
import { UserOrganizationRoleModel } from './user-organization-roles.model';
import { ActionMessageMetadata } from '@imt-web-zone/shared/util-store';

export type UserOrganizationRolesMetadata = ActionMessageMetadata & {
	fromAdmin?: boolean;
};

export const listOrganizationsUserRolesRequest = createAsyncLoadAction<
	ListOrganizationsUserOrganizationRolesPayload,
	ListOrganizationsUserOrganizationRolesResponse
>('[UserOrganizationRoles API]GET list organizations user roles');
export type ListOrganizationsUserRolesRequest = ActionType<typeof listOrganizationsUserRolesRequest>;

export const detailOrganizationsUserRoleRequest = createAsyncLoadAction<
	DetailOrganizationsUserOrganizationRolePayload,
	DetailOrganizationsUserOrganizationRoleResponse
>('[UserOrganizationRoles API] GET detail organizations user role');
export type DetailOrganizationsUserRoleRequest = ActionType<typeof detailOrganizationsUserRoleRequest>;

export const listUsersOrganizationRolesRequest = createAsyncLoadAction<
	ListUsersUserOrganizationRolesPayload,
	ListUsersUserOrganizationRolesResponse,
	UserOrganizationRolesMetadata
>('[UserOrganizationRoles API] GET list users organization roles');
export type ListUsersOrganizationRolesRequest = ActionType<typeof listUsersOrganizationRolesRequest>;

export const detailUsersOrganizationRoleRequest = createAsyncLoadAction<
	DetailUsersUserOrganizationRolePayload,
	DetailUsersUserOrganizationRoleResponse,
	UserOrganizationRolesMetadata
>('[UserOrganizationRoles API] GET detail users organization role');
export type DetailUsersOrganizationRoleRequest = ActionType<typeof detailUsersOrganizationRoleRequest>;

export const setUsersOrganizationRoleRequest = createAsyncAction<
	SetUsersUserOrganizationRolePayload,
	SetUsersUserOrganizationRoleResponse,
	UserOrganizationRolesMetadata
>('[UserOrganizationRoles API] POST set users organization role');
export type SetUsersOrganizationRoleRequest = ActionType<typeof setUsersOrganizationRoleRequest>;

export const setUsersOrganizationRole = createAction(
	'[UserOrganizationRoles] set users organization role',
	props<{ payload: UserOrganizationRoleModel }>(),
);

export type SetUsersOrganizationRole = ActionType<typeof setUsersOrganizationRole>;

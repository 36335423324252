import { State, Action, StateContext, StateToken } from '@ngxs/store';
import { RouterNavigation } from '@ngxs/router-plugin';
import {
	CollectionState,
	CollectionStateModel,
	EffectStart,
	EffectSuccess,
	IdStrategy,
} from '@imt-web-zone/shared/util-store';
import { RouterUrlStateModel } from '@imt-web-zone/shared/util-store';
import { Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { EnableDisableUserOauthScopeResponse, UserApiModel } from './users.interface';
import { getInitialUsersStateModel, UserListModel, usersSortConfig } from './users.model';
import { UsersService } from './users.service';
import { EffectCustomUpdater, EffectHandler, effectSuccess } from '@imt-web-zone/core/util-state-effect';
import { AuthFacade, disable2faSuccess } from '@imt-web-zone/zone/state-auth';

type UserListStateModel = CollectionStateModel<UserListModel>;

export const USERS_STATE_TOKEN = new StateToken<UserListStateModel>('users');

@State({
	name: USERS_STATE_TOKEN,
	defaults: getInitialUsersStateModel(),
})
@EffectHandler({ service: UsersService, adapter: UserListModel<UserApiModel> })
@Injectable()
export class UsersState extends CollectionState<UserListModel> {
	private router = inject(Router);

	private authFacade = inject(AuthFacade);

	constructor() {
		super(UsersState, 'id', IdStrategy.EntityIdGenerator, usersSortConfig);
	}

	@Action([RouterNavigation])
	public setActiveGroupByRouter(
		ctx: StateContext<UserListStateModel>,
		action: RouterNavigation<RouterUrlStateModel>,
	): void {
		if (action.routerState && action.routerState.params.userId) {
			this.setActive(ctx, { payload: action.routerState.params.userId });
		} else if (ctx.getState().active) {
			this.clearActive(ctx);
		}
	}

	@EffectCustomUpdater<UsersService>('enableFeature$')
	protected enableFeature(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['enableFeature$']>,
	) {
		const params = action.params.params;
		this.enableDisableFeature(ctx, params.userId, params.featureName, action.response.enabled);
	}

	@EffectCustomUpdater<UsersService>('disableFeature$')
	protected disableFeature(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['disableFeature$']>,
	) {
		const params = action.params.params;
		this.enableDisableFeature(ctx, params.userId, params.featureName, action.response.enabled);
	}

	@EffectCustomUpdater<UsersService>('deleteUser$')
	protected deleteUser(ctx: StateContext<UserListStateModel>, action: EffectSuccess<UsersService['deleteUser$']>) {
		this.remove(ctx, { payload: action.response.user.toString() });
	}

	@EffectCustomUpdater<UsersService>('partialUpdateAdminUser$')
	protected partialUpdateAdminUser(
		ctx: StateContext<UserListStateModel>,
		action: EffectSuccess<UsersService['partialUpdateAdminUser$']>,
	) {
		const user = action.response.user;
		this.update(ctx, {
			payload: {
				id: user.id.toString(),
				data: {
					usersAdminsRoleId: user.usersAdminsRoleId,
				},
			},
		});
	}

	@EffectCustomUpdater<UsersService>('loginAsAdminUser$')
	protected loginAsAdminUser(
		ctx: StateContext<UserListStateModel>,
		action: EffectSuccess<UsersService['loginAsAdminUser$']>,
	) {
		this.router.navigate(['/leave/admin']);
	}

	@EffectCustomUpdater<UsersService>('disableAdminUser2fa$')
	protected disableAdminUser2fa(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['disableAdminUser2fa$']>,
	) {
		this.update(ctx, {
			payload: {
				id: action.params.params.userId.toString(),
				data: {
					tfaEnabled: action.response.tfaEnabled,
				},
			},
		});
	}

	@EffectCustomUpdater<UsersService>('enableUserOauthScope$')
	protected enableUserOauthScope(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['enableUserOauthScope$']>,
	) {
		this.enableDisableUserOauthScope(ctx, action.response, action.params.params.userId);
	}

	@EffectCustomUpdater<UsersService>('disableUserOauthScope$')
	protected disableUserOauthScope(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['disableUserOauthScope$']>,
	) {
		this.enableDisableUserOauthScope(ctx, action.response, action.params.params.userId);
	}

	@EffectCustomUpdater<UsersService>('unpairUserSso$')
	protected unpairUserSso(
		ctx: StateContext<UserListStateModel>,
		action: EffectStart<UsersService['unpairUserSso$']>,
	) {
		const params = action.params.params;
		const user = ctx.getState().entities[params.userId];
		const socialSso = user?.socialSso?.filter((x) => x !== params.ssoProvider) || [];
		this.update(ctx, {
			payload: {
				id: action.params.params.userId.toString(),
				data: {
					socialSso,
				},
			},
		});
	}

	@Action(effectSuccess<UsersService>(USERS_STATE_TOKEN, 'disableAdminUser2fa$'))
	protected disableAdminUser2fa2(
		ctx: StateContext<UserListStateModel>,
		action: EffectSuccess<UsersService['disableAdminUser2fa$']>,
	) {
		if (action.prevAction.params.params.userId.toString() === this.authFacade.userIdSnapshot) {
			ctx.dispatch(disable2faSuccess({ payload: action.response }));
		}
	}

	private enableDisableFeature(
		ctx: StateContext<CollectionStateModel<UserListModel>>,
		userId: string,
		featureName: string,
		enabled: boolean,
	) {
		const features = ctx.getState().entities[userId].features;
		this.update(ctx, {
			payload: {
				id: userId,
				data: {
					features: {
						...features,
						[featureName]: enabled,
					},
				},
			},
		});
	}

	private enableDisableUserOauthScope(
		ctx: StateContext<UserListStateModel>,
		{ oauthScope }: EnableDisableUserOauthScopeResponse,
		userId: string,
	) {
		const oauthScopes = [...ctx.getState().entities[userId].oauthScopes];

		if (oauthScope.enabled) {
			oauthScopes.push(oauthScope.id);
		} else {
			const index = oauthScopes.indexOf(oauthScope.id);
			if (index !== -1) {
				oauthScopes.splice(index, 1);
			}
		}

		this.update(ctx, {
			payload: {
				id: userId,
				data: {
					oauthScopes,
				},
			},
		});
	}
}

import { Params } from '@angular/router';
import { Selector } from '@ngxs/store';
import { CacheFunctions, HashMap } from '@imt-web-zone/shared/util-store';
import { RouterSelectors } from '@imt-web-zone/shared/util-store';
import { environment } from '@imt-web-zone/shared/environments';
import { Pg } from '@imt-web-zone/shared/util';
import {
	EnumNames,
	EnumsState,
	ImtZoneModel,
	LocalesEnumModel,
	TimezonesEnumModel,
} from '@imt-web-zone/zone/state-enums';

import { ApiConfigData, ApiConfigSelectors, ModeEnum } from '@imt-web-zone/zone/state-api-config';
import { UserZoneUnreadNotification } from './auth.interface';
import { AUTH_STATE_TOKEN } from './auth.symbols';
import { AuthUserModel } from './auth-user.model';
import { AuthStateModel } from './auth.model';

export interface ZoneNotification extends UserZoneUnreadNotification {
	zoneDomain: string;
	zoneName: string;
}

export class AuthSelectors {
	public static get getCache() {
		return CacheFunctions.getCacheSelector(AUTH_STATE_TOKEN);
	}

	@Selector([AUTH_STATE_TOKEN])
	public static getAuthState(state: AuthStateModel): AuthStateModel {
		return state;
	}

	@Selector([AUTH_STATE_TOKEN, EnumsState.getEnumMap(EnumNames.IMT_ZONES), ApiConfigSelectors.apiConfig])
	public static getZoneNotifications(
		state: AuthStateModel,
		zones: HashMap<ImtZoneModel>,
		apiConfig: ApiConfigData,
	): Array<ZoneNotification> {
		if (state && state.userZoneUnreadNotifications && zones) {
			return state.userZoneUnreadNotifications
				.map((n) => ({
					...n,
					imtZoneId: apiConfig.zoneId === n.imtZoneId ? null : n.imtZoneId,
					zoneName: zones[n.imtZoneId].name,
					zoneDomain: zones[n.imtZoneId].domain,
				}))
				.sort((a, b) => (a.imtZoneId === apiConfig.zoneId ? -1 : 1)) as Array<ZoneNotification>;
		}
		return [];
	}

	/**
	 * Calculates length of unread notifications depending on mode (slave/master).
	 * If mode is `SLAVE` then returns unread notifications for the current zone.
	 */
	@Selector([
		AuthSelectors.getZoneNotifications,
		ApiConfigSelectors.apiConfig,
		RouterSelectors.queryParams,
		AuthSelectors.getUserUnreadNotifications,
	])
	public static getUnreadNotificationsLength(
		zoneNotifications: Array<ZoneNotification>,
		apiConfig: ApiConfigData,
		queryParams: Params & { pg?: Pg<any, any> },
		userUnreadNotifications: number,
	) {
		if (apiConfig.mode === ModeEnum.SLAVE) {
			const zoneId = queryParams['imtZoneId'] ? +queryParams['imtZoneId'] : null;
			const zoneNotification = zoneNotifications.find(({ imtZoneId }) => imtZoneId === zoneId);
			return zoneNotification?.unreadNotifications;
		}

		if (apiConfig.mode === ModeEnum.MASTER) {
			return userUnreadNotifications;
		}

		return 0;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static tfaEnabled(state: AuthStateModel) {
		return state?.tfaEnabled;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static getUserUnreadNotifications(state: AuthStateModel) {
		if (state) {
			return state.userUnreadNotifications;
		}
		return 0;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static getAuthUserId(state: AuthStateModel): string | null {
		if (state && state.user) {
			return state.user.id;
		}
		return null;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static getAuthUserCreated(state: AuthStateModel) {
		if (state && state.user) {
			return state.user.created;
		}
		return null;
	}

	@Selector([AUTH_STATE_TOKEN, EnumsState.getEnumMap(EnumNames.TIMEZONES)])
	public static getAuthUser(state: AuthStateModel, timezones: HashMap<TimezonesEnumModel>): AuthUserModel | null {
		if (state && state.user) {
			return {
				...state.user,
				timezone: timezones[state.user.timezoneId]
					? timezones[state.user.timezoneId].name
					: state.user.timezone,
			};
		}
		return null;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static isLoggedAsDifferentUser(state: AuthStateModel) {
		if (state && state.user) {
			return state.user.loginAsUser;
		}
		return false;
	}

	@Selector([AUTH_STATE_TOKEN, EnumsState.getEnumMap(EnumNames.TIMEZONES)])
	public static getAuthUserTimezone(
		state: AuthStateModel,
		timezones: HashMap<TimezonesEnumModel>,
	): TimezonesEnumModel {
		if (state && state.user) {
			return timezones[state.user.timezoneId] || ({} as TimezonesEnumModel);
		}
		return {} as TimezonesEnumModel;
	}

	@Selector([AUTH_STATE_TOKEN])
	public static getAuthUserLanguage(state: AuthStateModel) {
		if (state && state.user) {
			return state.user.language;
		}
		return null;
	}

	@Selector([AUTH_STATE_TOKEN, EnumsState.getEnumMap(EnumNames.LOCALES)])
	public static getAuthUserLocale(state: AuthStateModel, locales: HashMap<LocalesEnumModel>) {
		if (state && state.user && state.user.localeId && locales && locales[state.user.localeId]) {
			return locales[state.user.localeId].angularCode || environment.defaultLocale;
		}
		return environment.defaultLocale;
	}
}

import { HttpClient } from '@angular/common/http';
import { TranslocoLoader } from '@jsverse/transloco';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';

export type MultiTranslateHttpLoaderResource = {
	prefix: string;
	suffix: string;
};

export class MultiTranslateHttpLoader implements TranslocoLoader {
	constructor(
		private http: HttpClient,
		private apiConfigFacade: ApiConfigFacade,
		private zoneAssetsService: ZoneAssetsService,
		public resources: Array<MultiTranslateHttpLoaderResource> = [
			{
				prefix: '/assets/i18n/',
				suffix: '.json',
			},
		],
	) {}

	public getTranslation(lang: string): Observable<any> {
		const request$ = (config) => {
			const path = this.zoneAssetsService.zoneAssetPath(`${config.prefix}${lang}${config.suffix}`);

			return this.http.get(path).pipe(
				catchError((e) => {
					console.error('Could not find translation file:', path);
					return of({});
				}),
			);
		};

		const resources: Array<MultiTranslateHttpLoaderResource> = [];
		for (const config of this.resources) {
			if (config.prefix.includes('ems') && (lang !== 'en' || !this.apiConfigFacade.isEms)) {
				continue;
			}
			resources.push(config);
		}

		return forkJoin(resources.map((config) => request$(config))).pipe(
			map((responses) => responses.reduce((a, b) => this.deepMerge(a, b))),
		);
	}

	private deepMerge(target: Record<string, any>, source: Record<string, any>) {
		// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source`
		// properties
		for (const key of Object.keys(source)) {
			if (source[key] instanceof Object) {
				Object.assign(source[key] || {}, this.deepMerge(target[key] || {}, source[key] || {}));
			}
		}
		Object.assign(target || {}, source);
		return target;
	}
}

import { CollectionStateModel, getInitialCollectionState } from '@imt-web-zone/shared/util-store';

export class DlqModel {
	constructor(
		public id: string,
		public scenarioId: number,
		public reason: string,
		public created: Date,
		public size: number,
		public resolved: boolean,
		public retry: boolean,
		public attempts: number,
		public index: number,
		public executionId: string,
	) {}
}

export function getInitialDlqsStateModel(): CollectionStateModel<DlqModel> {
	return getInitialCollectionState<DlqModel>();
}

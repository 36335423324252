import createDebug, { Debugger } from 'debug';

/**
 * Wrapper Class for https://www.npmjs.com/package/debug with better terminal formatting and remote logging
 *
 *
 * read: ../../README.md
 */
class DebugLogger {
	private readonly origFormatArgsFn = createDebug.formatArgs;
	public remoteDebugFn?: (...args: Array<any>) => void;
	private enabledNamespaces: Array<string> = [];
	private enabledDebugPatternRegs = new Map<string, { reg: RegExp; val: string }>();
	private ignoreDebugPatternRegs = new Map<string, RegExp>();
	private namespaceEnabledCache = new Map<string, boolean>();

	constructor() {
		createDebug.log = console.log.bind(console);
		createDebug.formatArgs = this.formatTerminalArgs();
		this.filterOutDebugNamespaces();
	}

	/**
	 * creates a debug functions that are supposed to be used instead of console.log
	 *
	 * @param namespace
	 */
	public create(namespace: string) {
		const terminalDebug = this.createDebug(namespace);
		return (...args: Array<any>) => {
			terminalDebug('', ':', ...args);

			if (this.isRemoteDebugEnabled(namespace)) {
				this.logRemoteDebug(namespace, ...args);
			}
		};
	}

	/**
	 *
	 * set namespaces for remote Debugger
	 *
	 */
	public setRemoteDebug(namespacePattern: string) {
		this.clearCache();

		namespacePattern.split(',').map((pattern) => {
			const val = pattern.trim();
			const normalized: string = val.replace(/:?\*:?/g, '.*?');
			if (normalized.startsWith('-')) {
				this.ignoreDebugPatternRegs.set(val, new RegExp(normalized.slice(1)));
			} else {
				this.enabledDebugPatternRegs.set(pattern, {
					reg: new RegExp(normalized),
					val,
				});
			}
		});
	}

	/**
	 * sets all memoized data - performed on each setRemoteDebug()
	 */
	private clearCache() {
		this.enabledDebugPatternRegs.clear();
		this.ignoreDebugPatternRegs.clear();
		this.namespaceEnabledCache.clear();
	}

	/**
	 * filter out all debug:{namespace} from the enabled debugger namespaces
	 * @private
	 */
	private filterOutDebugNamespaces() {
		const ls: string = localStorage['debug'];
		if (ls?.length) {
			this.enabledNamespaces = ls.split(',');
		}

		createDebug.enable(this.enabledNamespaces.join(','));
	}
	/**
	 * create debug fn for remote logging
	 *
	 * @private
	 */
	private logRemoteDebug(namespace: string, ...args: Array<string>) {
		// override log function with own implementation for remote logging
		const remoteDebugFn = this.remoteDebugFn;
		if (remoteDebugFn) {
			remoteDebugFn(namespace, ...args);
		}
	}

	/**
	 * Update the output of the console logs - set ccs styling
	 *
	 */
	private formatTerminalArgs() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		return function (this: Debugger, args: Array<string>) {
			self.origFormatArgsFn.call(this, args);
			args[0] = args[0].replace(this.namespace + ' ', `[${this.namespace}]`);
			args[1] = `background-${args[1]}; color: ${self.invertColor(this.color)}; font-size: 16px`;
		};
	}

	/**
	 * Determine whether color's opposite is black or white
	 * @param hex
	 * @param bw
	 */
	private invertColor(hex: string, bw = true) {
		if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r: string | number = parseInt(hex.slice(0, 2), 16);
		let g: string | number = parseInt(hex.slice(2, 4), 16);
		let b: string | number = parseInt(hex.slice(4, 6), 16);
		if (bw) {
			// https://stackoverflow.com/a/3943023/112731
			return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
		}
		// invert color components
		r = (255 - r).toString(16);
		g = (255 - g).toString(16);
		b = (255 - b).toString(16);
		// pad each with zeros and return
		return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
	}

	private padZero(str: string, len?: number) {
		len = len || 2;
		const zeros = new Array(len).join('0');
		return (zeros + str).slice(-len);
	}

	private isRemoteDebugEnabled(namespace: string) {
		const resolve = () => {
			for (const pattern of this.ignoreDebugPatternRegs.values()) {
				if (pattern.test(namespace)) {
					return false;
				}
			}

			for (const data of this.enabledDebugPatternRegs.values()) {
				if (!data.val) {
					return false;
				}
				if (data.reg.test(namespace)) {
					return true;
				}
			}
			return false;
		};

		// check previous result in cache
		let result = this.namespaceEnabledCache.get(namespace);
		if (result === undefined) {
			result = resolve();
			// cache previous result
			this.namespaceEnabledCache.set(namespace, result);
			return result;
		} else {
			return result;
		}
	}

	private createDebug(namespace: string) {
		const debug = createDebug(namespace);
		return debug;
	}
}

export const debugLogger = new DebugLogger();

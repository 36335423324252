import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';

import { ImtUiAssetsInjectorResult, ImtUiAssetsInjector, BodyTagDef } from '@imt-web-zone/shared/util-assets-injector';

interface CanduCallbacks {
	[callbackName: string]: () => void;
}

@Injectable()
export class CanduService {
	private renderer2: Renderer2;
	private els: Array<ImtUiAssetsInjectorResult>;

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private assetInjector: ImtUiAssetsInjector, rendererFactory: RendererFactory2) {
		this.renderer2 = rendererFactory.createRenderer(null, null);
	}

	// https://docs.candu.ai/en/articles/5918176-making-buttons-or-cards-interactive
	public init(userId$: Observable<string>, canduToken$: Observable<string>, callbacks: CanduCallbacks = {}) {
		combineLatest([userId$, canduToken$]).subscribe(([userId, token]) => {
			this.initCandu(userId, token, callbacks);
		});
	}

	/**
	 * Installs Candu by adding script with CDN source
	 * https://docs.candu.ai/en/articles/5908307-installing-candu
	 *
	 * Using JS SDK `@candulabs/js-sdk` is deprecated by Candu
	 * https://docs.candu.ai/en/articles/5918533-javascript-to-cdn-migration
	 */
	private async initCandu(userId: string, clientToken: string, callbacks: CanduCallbacks) {
		// Candu client token and User ID are both mandatory
		if (!(userId && clientToken)) {
			return;
		}

		try {
			if (this.els) {
				this.els.forEach((el) => el.el.remove());
			}

			const canduScript: BodyTagDef = {
				tag: 'script',
				position: 'head',
				src: `https://cdn.candu.ai/sdk/latest/candu.umd.js?token=${clientToken}`,
				attributes: [{ attr: 'async' }],
				onLoad: () => {
					window['Candu']?.init({ userId, clientToken, callbacks });
				},
			};

			this.els = await this.assetInjector.inject(this.renderer2, canduScript);
		} catch (error) {
			console.error('Error appending Candu');
			console.error(error);
		}
	}
}

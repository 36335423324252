import { PgLast } from '@imt-web-zone/shared/util';

export enum ScenarioLogsType {
	MANUAL = 'manual',
	MODIFY = 'modify',
	SCHEDULE = 'schedule',
	AUTO = 'auto',
	WARNING = 'warning',
	INVALID = 'invalid',
	START = 'start',
	STOP = 'stop',
	BACKOFF = 'backoff',
	DEPLETED = 'depleted',
	PUBLISH = 'publish',
	LASTRUN = 'lastrun',
}

export enum ScenariosLogStatus {
	/**
	 * For FE purposes only, this value doesn't actually exist and should not be sent do BE
	 */
	ALL = -1,
	RUNNING = 0,
	SUCCESS = 1,
	WARNING = 2,
	ERROR = 3,
}

export interface ScenarioLogsBase {
	type: ScenarioLogsType;
	timestamp: Date;
	author_id?: number | null;
	imtId: string;
}
export interface ScenarioLogsAuthor {
	author: { name: string; staff: boolean };
}
export interface ScenarioLogsReason {
	reason: string;
}
export interface ScenarioLogsDelay {
	delay: number;
}

export type ScenarioLogsExecution = ScenarioLogsBase & {
	type: ScenarioLogsType.MANUAL | ScenarioLogsType.AUTO;
	id: string;
	status: ScenariosLogStatus;
	instant?: boolean;
	duration: number;
	operations: number;
	transfer: number;
	reason: string;
};

export type ScenarioLogsSchedule = ScenarioLogsBase & {
	type: ScenarioLogsType.SCHEDULE;
	detail: ScenarioLogsAuthor;
	id: number;
};

export type ScenarioLogsModify = ScenarioLogsBase & {
	type: ScenarioLogsType.MODIFY;
	detail: ScenarioLogsAuthor;
	id: number;
};

export type ScenarioLogsPublish = ScenarioLogsBase & {
	type: ScenarioLogsType.PUBLISH;
	detail: ScenarioLogsAuthor;
	id: number;
};

export type ScenarioLogsStart = ScenarioLogsBase & {
	type: ScenarioLogsType.START;
	detail: ScenarioLogsAuthor;
	id: number;
};

export type ScenarioLogsStop = ScenarioLogsBase & {
	type: ScenarioLogsType.STOP;
	detail: ScenarioLogsAuthor;
	id: number;
};

export type ScenarioLogsWarning = ScenarioLogsBase & {
	type: ScenarioLogsType.WARNING;
	detail: ScenarioLogsReason;
};

export type ScenarioLogsInvalidated = ScenarioLogsBase & {
	type: ScenarioLogsType.INVALID;
	detail: ScenarioLogsReason;
	id: number;
};

export type ScenarioLogsBackoff = ScenarioLogsBase & {
	type: ScenarioLogsType.BACKOFF;
	detail: { delay: number };
};

export type ScenarioLogsDepleted = ScenarioLogsBase & {
	type: ScenarioLogsType.DEPLETED;
};

export type ScenarioLogsLastrun = ScenarioLogsBase & {
	type: ScenarioLogsType.LASTRUN;
};

export type ScenarioLog =
	| ScenarioLogsSchedule
	| ScenarioLogsModify
	| ScenarioLogsPublish
	| ScenarioLogsStart
	| ScenarioLogsStop
	| ScenarioLogsWarning
	| ScenarioLogsInvalidated
	| ScenarioLogsBackoff
	| ScenarioLogsDepleted
	| ScenarioLogsLastrun;

export class ScenarioLogsApiModel {
	constructor(
		public id: string | number,
		public imtId: string,
		public type: ScenarioLogsType,
		public timestamp: Date,
		public authorId: number | null,
		public status?: ScenariosLogStatus,
		public instant?: boolean,
		public duration?: number,
		public operations?: number,
		public transfer?: number,
		public detail?: ScenarioLogsAuthor | ScenarioLogsReason | ScenarioLogsDelay,
		public teamId?: number,
		public scenarioId?: number,
		public reason?: string,
		public parent?: { id: number; name: string; chainingRole: 'bridge' | 'parent' },
	) {}
}

/**
 * ListScenariosLogsPayload
 */

export interface ListScenariosLogsPayload {
	params: {
		scenarioId?: number;
	};
	query: {
		from?: number;
		to?: number;
		status?: ScenariosLogStatus;
		showCheckRuns?: boolean;
		pg?: PgLast<ScenarioLogsApiModel, 'imtId'>;
	};
}

/**
 * ListScenariosLogsResponse
 */

export interface ListScenariosLogsResponse {
	scenarioLogs: Array<
		| ScenarioLogsSchedule
		| ScenarioLogsModify
		| ScenarioLogsStart
		| ScenarioLogsStop
		| ScenarioLogsWarning
		| ScenarioLogsInvalidated
		| ScenarioLogsBackoff
		| ScenarioLogsDepleted
		| ScenarioLogsLastrun
		| ScenarioLogsPublish
	>;
	pg: {
		sortBy: string;
		sortDir: string;
		limit: number;
		offset: number;
	};
}

/**
 * ListAdminScenariosLogsPayload
 */

export interface ListAdminScenariosLogsPayload {
	query: {
		from?: number;
		to?: number;
		status?: ScenariosLogStatus;
		pg?: PgLast<ScenarioLogsApiModel, 'imtId'>;
	};
}

export interface ScenarioExecutionLogPayload {
	params: {
		scenarioId?: number;
		executionId?: string;
	};
}

export interface ScenarioExecutionLogResponse {
	scenarioLog: ScenarioLogsExecution;
}

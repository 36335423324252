import { DestroyRef, inject, Injectable } from '@angular/core';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import type rudderanalytics from 'rudder-sdk-js';
import { ServiceInit } from '@imt-web-zone/core/util-core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface Config {
	routerUrl$: Observable<string>;
	userData$: Observable<{ id: string; data: Record<string, any> }>;
	tokens$: Observable<{ rudderstackToken: string; rudderstackDataUrl: string }>;
}

@Injectable()
export class RudderStackService implements ServiceInit {
	private initialized: boolean;
	private subs: Array<Subscription> = [];
	private previousUrl: string;
	private sdk: typeof rudderanalytics;
	private config: Config;
	private userId: string;
	private destroyRef = inject(DestroyRef);

	public async initialize(config: Config) {
		this.config = config;

		this.config.tokens$
			.pipe(
				distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
				tap((res) => {
					this._reset();
				}),
				filter((res) => !!res.rudderstackDataUrl && !!res.rudderstackToken),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((res) => {
				this._initialize(res.rudderstackToken, res.rudderstackDataUrl);
			});
	}

	private async _initialize(token: string, dataUrl: string) {
		this.sdk = await import('rudder-sdk-js');
		this.sdk.ready(() => {
			this.initialized = true;
			this.trackPages();
		});
		this.sdk.load(token, dataUrl);

		const identifySub = this.config.userData$.subscribe(({ id, data }) => {
			this.userId = id;
			this.sdk.reset();
			this.sdk.identify(id, data);
		});

		this.subs.push(identifySub);
	}

	private _reset() {
		if (this.initialized) {
			this.sdk.reset();
			for (const sub of this.subs) {
				sub.unsubscribe();
			}
		}
	}

	private trackPages() {
		const sub = this.config.routerUrl$
			.pipe(
				filter((res) => !!res),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((res) => {
				this.sdk.page('Page Navigation', {
					userId: this.userId,
					path: res,
					referrer: this.previousUrl || document.referrer,
				});
				this.previousUrl = res;
			});

		this.subs.push(sub);
	}
}

import { UpdateRouteParams } from './router.actions';
import produce from 'immer';

export function routerMetaReducer(
	state: any,
	action: unknown,
	next: (state: any, action: unknown) => any /* todo monorepo: resolve RootState */,
) {
	if (action instanceof UpdateRouteParams) {
		state = produce(state, (draftState) => {
			draftState.router.state.params = action.replace
				? action.params
				: { ...draftState.router.state.params, ...action.params };
		});
	}

	return next(state, action);
}

import { Injectable, InjectionToken, Injector, inject } from '@angular/core';
import { NGXS_DEVTOOLS_OPTIONS, NgxsDevtoolsAction, NgxsDevtoolsExtension } from '@ngxs/devtools-plugin';
import { catchError, tap } from 'rxjs/operators';
import { getActionTypeFromInstance, NgxsPlugin, Store } from '@ngxs/store';
import type { DevtoolsOption } from '@imt-web-zone/shared/model';

export interface DevtoolSanitizer {
	sanitize(action: unknown): unknown;
}

export const DEVTOOLS_OPTIONS = new InjectionToken<DevtoolsOption>('imt-options');

/**
 * monkey patched  https://github.com/ngxs/store/blob/master/packages/devtools-plugin/src/devtools.plugin.ts
 */
@Injectable()
export class DevtoolsPlugin implements NgxsPlugin {
	private _options = inject<DevtoolsOption>(NGXS_DEVTOOLS_OPTIONS);
	private _injector = inject(Injector);

	private readonly _devtoolsExtension!: NgxsDevtoolsExtension;
	private readonly _windowObj: any = typeof window !== 'undefined' ? window : {};
	private readonly originalActionSanitizer: DevtoolsOption['actionSanitizer'];
	private readonly sanitizedActions = new Map<string, true>();
	constructor() {
		this.originalActionSanitizer = this._options.actionSanitizer;
		if (this._options.sanitizedActions) {
			for (const actionType of Object.values(this._options.sanitizedActions)) {
				this.sanitizedActions.set(actionType, true);
			}
		}

		const globalDevtools = this._windowObj.__REDUX_DEVTOOLS_EXTENSION__ || this._windowObj.devToolsExtension;
		if (globalDevtools) {
			this._devtoolsExtension = globalDevtools.connect(this._options) as NgxsDevtoolsExtension;
			this._devtoolsExtension.subscribe((a) => this.dispatched(a));
		}
	}

	public handle(state: any, action: any, next: (state: any, mutation: any) => any): any {
		const isDisabled = this._options && this._options.disabled;
		if (!this._devtoolsExtension || isDisabled) {
			return next(state, action);
		}
		return next(state, action).pipe(
			catchError((error) => {
				const newState = this.store.snapshot();
				this._sendToDevTools(state, action, newState);
				throw error;
			}),
			tap((newState) => {
				this._sendToDevTools(state, action, newState);
			}),
		);
	}

	private get store(): Store {
		return this._injector.get<Store>(Store);
	}

	private _sendToDevTools(state: any, action: any, newState: any) {
		const type = getActionTypeFromInstance(action);

		// if init action, send initial state to dev api-generator-clear
		const isInitAction = type === '@@INIT';
		// tslint:disable:no-non-null-assertion
		const blacklist = this._options.actionBlacklist || [];
		const blacklistPredicate = this._options.actionBlacklistPredicate;
		if (action.hideFromDevtools) {
			return;
		}
		for (const _type of blacklist) {
			if (!type || type.includes(_type)) {
				return;
			}
		}

		if (blacklistPredicate) {
			if (blacklistPredicate(action)) {
				return;
			}
		}

		if (isInitAction) {
			this._devtoolsExtension.init(state);
		} else {
			action = this.sanitizeSelf(action, type);
			const _type = action.typeContext ? `${type} - ${action.typeContext}` : type;
			this._devtoolsExtension.send({ ...action, type: _type }, newState);
		}
	}

	/**
	 * if action implements #sanitize(), use it. Otherwise set default ActionSanitizer
	 *
	 */
	private sanitizeSelf(action: any, type?: string) {
		if (type && this.sanitizedActions.has(type)) {
			action = structuredClone(action);
		}
		if (action.sanitize) {
			this._options.actionSanitizer = action.sanitize;
		} else if (this._options.actionSanitizer) {
			this._options.actionSanitizer = this.originalActionSanitizer;
		}
		return action;
	}

	/**
	 * Handle the action from the dev api-generator-clear subscription
	 */
	private dispatched(action: NgxsDevtoolsAction) {
		if (action.type === 'DISPATCH') {
			if (action.payload.type === 'JUMP_TO_ACTION' || action.payload.type === 'JUMP_TO_STATE') {
				const prevState = JSON.parse(action.state);
				this.store.reset(prevState);
			} else if (action.payload.type === 'TOGGLE_ACTION') {
				console.warn('Skip is not supported at this time.');
			} else if (action.payload.type === 'IMPORT_STATE') {
				const { actionsById, computedStates, currentStateIndex } = action.payload.nextLiftedState;
				this._devtoolsExtension.init(computedStates[0].state);
				Object.keys(actionsById)
					.filter((actionId) => actionId !== '0')
					.forEach((actionId) =>
						this._devtoolsExtension.send(actionsById[actionId], computedStates[actionId].state),
					);
				this.store.reset(computedStates[currentStateIndex].state);
			}
		} else if (action.type === 'ACTION') {
			const actionPayload = JSON.parse(action.payload);
			this.store.dispatch(actionPayload);
		}
	}
}

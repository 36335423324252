import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Dispatch } from '@imt-web-zone/shared/util-dispatch';
import { Injectable } from '@angular/core';
import { changePendingRequests } from '@imt-web-zone/zone/state-common';

const excludedUrlSlugs = ['/users/unread-notifications', 'admin/install/apps', '/imt/hq/sanity-check'];

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (excludedUrlSlugs.some((slug) => request.url.includes(slug))) {
			return next.handle(request);
		}

		return next.handle(request).pipe(
			tap((r) => {
				if (r.type === HttpEventType.Sent) {
					this.changePendingRequests(1);
				}
			}),
			finalize(() => {
				this.changePendingRequests(-1);
			}),
		);
	}

	@Dispatch()
	private changePendingRequests(change: number) {
		return changePendingRequests({ change });
	}
}

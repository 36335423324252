import { generateActionObject } from '../internal/internal';
import { EntityState } from '../internal/entity-state';
import { Type } from '@angular/core';
import { EntityActionType } from './type-alias';
import { StateToken } from '@ngxs/store';

export interface EntitySetLoadingAction {
	payload: boolean;
}

export class SetLoading {
	/**
	 * Generates an action that will set the loading state for the given state.
	 * @param target The targeted state class
	 * @param loading The loading state
	 * @param context Text that will be appended to the action type in redux devtools
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, loading: boolean, context: string) {
		return generateActionObject(EntityActionType.SetLoading, target, loading).setContext(context);
	}
}

import { Action, State, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';
import { inject, Injectable } from '@angular/core';
import { ActionSuccess, AsyncActionSuccess, EffectSuccess } from '@imt-web-zone/shared/util-store';
import { RouterNavigated } from '@ngxs/router-plugin';
import { DeleteOrganizationRequest, deleteOrganizationRequest } from '@imt-web-zone/shared/data-access';
import { setUsersOrganizationRoleRequest, SetUsersOrganizationRoleRequest } from '@imt-web-zone/zone/state-permissions';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { effectSuccess } from '@imt-web-zone/core/util-state-effect';
import { TEAMS_STATE_TOKEN, TeamsService } from '@imt-web-zone/zone/state-teams';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { RouterUrlStateModel } from '@imt-web-zone/shared/util-store';
import {
	SESSION_STATE_TOKEN,
	SessionModel,
	setActiveOrganizationTeam,
	SetActiveOrganizationTeam,
	setDataStorePageSize,
	SetDataStorePageSize,
} from '@imt-web-zone/zone/state-session';

@State({
	name: SESSION_STATE_TOKEN,
	defaults: {},
})
@Injectable()
export class SessionState {
	private apiConfigFacade = inject(ApiConfigFacade);
	private authFacade = inject(AuthFacade);

	public get apiConfig() {
		return this.apiConfigFacade.configSnapshot;
	}

	public get userId() {
		return this.authFacade.userIdSnapshot;
	}

	@Action([RouterNavigated])
	public setActiveGroupByRouter(ctx: StateContext<SessionModel>, action: RouterNavigated<RouterUrlStateModel>) {
		const organizationId = action?.routerState?.params?.organizationId;
		const teamId = action?.routerState?.params?.teamId;

		if (organizationId) {
			this.setActiveOrganizationTeam(ctx, { isOrganization: true, id: organizationId });
		} else if (teamId) {
			this.setActiveOrganizationTeam(ctx, { isOrganization: false, id: teamId });
		}
	}

	@Action(setActiveOrganizationTeam)
	@ImmutableContext()
	public setActiveOrganizationTeam(
		ctx: StateContext<SessionModel>,
		action: SetActiveOrganizationTeam | { isOrganization: boolean; id: string },
	) {
		const userId = this.userId;
		if (!userId) {
			return;
		}
		ctx.setState((state: SessionModel) => {
			if (!state[userId]) {
				state[userId] = {};
			}

			state[userId].zone = this.apiConfig.zone;
			if (action.isOrganization) {
				state[userId]['organizationId'] = action.id;
			} else {
				state[userId]['teamId'] = action.id;
			}

			return state;
		});
	}

	// TODO: move to component level once session facade is created - DashboardTeams
	@Action(effectSuccess<TeamsService>(TEAMS_STATE_TOKEN, 'deleteTeam$'))
	protected deleteTeamSuccess(ctx: StateContext<SessionModel>, action: EffectSuccess<TeamsService['deleteTeam$']>) {
		ctx.setState((state: SessionModel) => {
			Object.keys(state).forEach((userId) => {
				const affectedId = action.response.team.toString();
				if (state[userId]?.adminTeamId === affectedId) {
					delete state[userId].adminTeamId;
				}

				if (state[userId]?.teamId === affectedId) {
					delete state[userId].teamId;
				}
			});

			return state;
		});
	}

	@AsyncActionSuccess(deleteOrganizationRequest)
	@ImmutableContext()
	public deleteOrganizationSuccess(
		ctx: StateContext<SessionModel>,
		action: ActionSuccess<DeleteOrganizationRequest>,
	) {
		ctx.setState((state: SessionModel) => {
			const affectedId = action.response.organization.toString();
			Object.keys(state).forEach((userId) => {
				if (state[userId].organizationId === affectedId) {
					delete state[userId].adminOrganizationId;
				}

				if (state[userId].organizationId === affectedId) {
					delete state[userId].organizationId;
				}
			});
			return state;
		});
	}

	@AsyncActionSuccess(setUsersOrganizationRoleRequest)
	@ImmutableContext()
	public leaveOrganizationSuccess(
		ctx: StateContext<SessionModel>,
		action: ActionSuccess<SetUsersOrganizationRoleRequest>,
	) {
		if (action.prevAction.params.params.isLeave) {
			ctx.setState((state: SessionModel) => {
				const affectedId = action.response.userOrganizationRole.organizationId.toString();
				Object.keys(state).forEach((userId) => {
					if (state[userId].organizationId === affectedId) {
						delete state[userId].adminOrganizationId;
					}

					if (state[userId].organizationId === affectedId) {
						delete state[userId].organizationId;
					}
				});
				return state;
			});
		}
	}

	@Action(setDataStorePageSize)
	@ImmutableContext()
	public setDataStorePageSIze(ctx: StateContext<SessionModel>, action: SetDataStorePageSize) {
		ctx.setState((state: SessionModel) => {
			Object.keys(state).forEach((userId) => {
				state[userId].dataStorePageSize = action.size;
			});
			return state;
		});
	}
}

import { ComponentType } from '@angular/cdk/portal';

import { NgbModalOptions, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum ImtModalTypeEnum {
	Confirm = 'Confirm',
	Prompt = 'Prompt',
}

export type ImtModalActive = NgbActiveModal;
export type ImtModalOptions = NgbModalOptions;
export type ImtModalRef = NgbModalRef;

export type ImtModalType<T> = T extends string ? T : ImtModalTypeEnum;

export type ImtModalTypeComponentMap<T> = {
	[key in ImtModalType<T>]: ComponentType<any>;
};

import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NGXS_DEVTOOLS_OPTIONS, NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { DEVTOOLS_OPTIONS, DevtoolsPlugin } from './devtools.plugin';
import { UpdateFormDirty, UpdateFormErrors, UpdateFormStatus } from '@ngxs/form-plugin';
import { RouterDataResolved } from '@ngxs/router-plugin';
import { DevtoolsOption } from '@imt-web-zone/shared/model';

@NgModule({
	imports: [NgxsModule],
})
export class DevtoolsExtensionModule {
	public static forRoot(): ModuleWithProviders<NgxsReduxDevtoolsPluginModule> {
		return {
			ngModule: NgxsReduxDevtoolsPluginModule,
			providers: [
				{
					provide: NGXS_PLUGINS,
					useClass: DevtoolsPlugin,
					multi: true,
				},
				{
					provide: NGXS_DEVTOOLS_OPTIONS,
					useFactory: DevtoolsExtensionModule.devtoolsOptionsFactory,
					deps: [DEVTOOLS_OPTIONS],
				},
			],
		};
	}

	private static devtoolsOptionsFactory(options: DevtoolsOption = {}) {
		DevtoolsExtensionModule.setDefaultOptions(options);
		return {
			name: 'NGXS',
			...options,
		};
	}

	private static setDefaultOptions(options: DevtoolsOption) {
		if (!options.actionBlacklistPredicate) {
			options.actionBlacklistPredicate = DevtoolsExtensionModule.defaultActionBlacklistPredicate;
		} else {
			const originalPredicate = options.actionBlacklistPredicate;
			options.actionBlacklistPredicate = (action: any) => {
				const defaultFilter = DevtoolsExtensionModule.defaultActionBlacklistPredicate(action);
				if (defaultFilter) {
					return true;
				}
				return originalPredicate(action);
			};
		}

		if (!options.actionBlacklist) {
			options.actionBlacklist = [];
		}

		options.actionBlacklist = options.actionBlacklist.concat(DevtoolsExtensionModule.defaultActionBlacklist());
	}
	/**
	 *
	 *
	 * By default filter actions with typeAsync property
	 *
	 */
	private static defaultActionBlacklistPredicate(action: any) {
		if (action.constructor && action.constructor.typeAsync) {
			return true;
		}
		return false;
	}

	/**
	 *
	 *
	 *
	 * By default filter actions in the Array
	 *
	 */
	private static defaultActionBlacklist(): Array<string> {
		return [UpdateFormStatus.type, UpdateFormDirty.type, UpdateFormErrors.type, RouterDataResolved.type, 'noop'];
	}
}

import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import { TranslocoService } from '@jsverse/transloco';

import { HTTP_ERROR_FORMATTER_DATA } from './http-error-formatter.data';
import { HttpErrorFormatterData } from './http-error-formatter.model';

interface ErrorMetadata {
	type: string; // Typically self-describing unique identification of the error message
	data: ErrorMetadataData[]; // Variables which will be formatted and inserted to the message translation
}

type ErrorMetadataDataType = 'date' | 'number' | 'text';
type ErrorMetadataDataDateFormat = 'date' | 'dateTime';

interface ErrorMetadataData {
	key: string;
	type: ErrorMetadataDataType;
	value: string | number;
	format?: ErrorMetadataDataDateFormat;
}

const DEFAULT_DATE_FORMAT = 'mediumDate';
const dateFormatMap = new Map<ErrorMetadataDataDateFormat, string>([
	['date', DEFAULT_DATE_FORMAT],
	['dateTime', 'medium'],
]);

const dataExist = (obj: unknown): obj is HttpErrorFormatterData =>
	!!obj && typeof obj === 'object' && !!obj['locale'] && !!obj['timezoneOffset'];

/**
 * TODO (@ivan-rozhon)
 * Think about possibility to encapsulate this logic into standalone independent library which would have
 * included also translations for exceptions and other related parts.
 */
@Injectable()
export class HttpErrorFormatterService {
	private transloco = inject(TranslocoService);
	private locale = inject(LOCALE_ID);
	private dataFn = inject(HTTP_ERROR_FORMATTER_DATA) as unknown as () => HttpErrorFormatterData;

	private datePipe: DatePipe;
	private decimalPipe: DecimalPipe;

	constructor() {
		this.datePipe = new DatePipe(this.locale);
		this.decimalPipe = new DecimalPipe(this.locale);
	}

	/** Takes all error messages, translates them and passes formatted variables into. */
	public formatAndTranslate(metadata: ErrorMetadata[]): string {
		const formatterData = typeof this.dataFn === 'function' && this.dataFn();

		if (!dataExist(formatterData)) {
			throw new Error('HttpErrorFormatterData not provided');
		}

		if (!(metadata && Array.isArray(metadata) && metadata.length)) {
			return '';
		}

		return (
			metadata
				.map(({ type, data }) =>
					this.transloco.translate(
						// all exceptions types are under `exceptions` translation key
						`exceptions.${type}`,
						// transform array of variables to map of interpolate params
						data.reduce(
							(prev, curr) => ({
								...prev,
								[curr.key]: this.formatValue(curr.value, formatterData, curr.type, curr.format),
							}),
							{},
						),
					),
				)
				// concat all together and divide single messages with new line
				.reduce((prev, curr) => (prev && curr ? `${prev} <br> ${curr}` : prev + curr), '')
		);
	}

	/** Formats value accordingly to its type and eventually given format */
	private formatValue(
		value: string | number,
		formatterData: HttpErrorFormatterData,
		type: ErrorMetadataDataType,
		format?: ErrorMetadataDataDateFormat,
	): string | null {
		switch (type) {
			case 'date':
				return this.datePipe.transform(
					value,
					dateFormatMap.get(format) || DEFAULT_DATE_FORMAT,
					formatterData.timezoneOffset,
					formatterData.locale,
				);

			case 'number':
				return this.decimalPipe.transform(value, undefined, formatterData.locale);

			default:
				return value == null ? '' : `${value}`;
		}
	}
}

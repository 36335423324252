import { CollectionStateModel, getInitialCollectionState, SortingOptions } from '@imt-web-zone/shared/util-store';
import { Alias, Field, ModelAdapter, ModelBase, Type } from '@imt-web-zone/core/util-state-model-adapter';
import { UserApiModel } from './users.interface';

export const usersSortConfig: SortingOptions<UserListModel> = {
	sortByKey: 'id',
	sortCondition: (a, b) => {
		return +b.id - +a.id;
	},
};

@ModelAdapter()
export class UserListModel<T extends UserApiModel = UserApiModel> extends ModelBase<T> {
	@Type(Number) public id!: string;
	@Field public email!: string;
	@Field public name!: string;
	@Field public avatar?: string;
	@Field public roleName?: string;
	@Field public owner?: boolean;
	@Field public roleId?: string;
	@Field public timezoneId?: string;
	@Field public countryId?: string;
	@Field public features?: any;
	@Alias<T>('organizations') public organizationsCount?: number;
	@Alias<T>('scenarios') public scenariosCount?: number;
	@Alias<T>('activeScenarios') public activeScenariosCount?: number;
	@Field public lastLogin?: Date;
	@Field public service?: string;
	@Field public deleted?: boolean;
	@Field public created?: Date;
	@Field public localeId?: string;
	@Field public usersAdminsRoleId?: number;
	@Field public language?: string;
	@Field public tfaEnabled?: boolean;
	/**
	 * Available only in hq admin context
	 */
	@Field public oauthScopes?: Array<number>;
	@Field public partnerCode?: string;
	@Field public partnerUserId?: string;
	/**
	 * Available in hq admin and zone admin
	 */
	@Field public verificationCode?: string;
	@Field public resetPasswordCode?: string;
	@Field public socialSso?: Array<string>;
}

export function getInitialUsersStateModel(): CollectionStateModel<UserListModel> {
	return getInitialCollectionState<UserListModel>();
}

interface Action {
	type: string;
}

export enum EffectActionsTypes {
	START = 'start',
	SUCCESS = 'success',
	ERROR = 'error',
	FROM_CACHE = 'from cache',
}

export function createActionType(type: string, state: EffectActionsTypes) {
	let startActionType;
	const namespaceExist = /\[.+\]/.exec(type);
	if (namespaceExist && namespaceExist[0]) {
		startActionType = type.replace(namespaceExist[0], namespaceExist[0] + ` [${state}]`);
	} else {
		startActionType = `[${state}] ` + type;
	}
	return startActionType;
}

/**
 * get start type from request type
 */
export function startActionOf(action: Action & { [key: string]: any }) {
	return { type: createActionType((action as any).type, EffectActionsTypes.START) };
}

/**
 * get success type from request type
 */
export function successActionOf(action: Action & { [key: string]: any }) {
	return { type: createActionType((action as any).type, EffectActionsTypes.SUCCESS) };
}

/**
 * get error type from request type
 */
export function errorActionOf(action: Action & { [key: string]: any }) {
	return { type: createActionType(action.type, EffectActionsTypes.ERROR) };
}

/**
 * get from Cache type from request type
 */
export function fromCacheActionOf(action: Action & { [key: string]: any }) {
	return { type: createActionType((action as any).type, EffectActionsTypes.FROM_CACHE) };
}

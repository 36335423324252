import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';
import { Injectable } from '@angular/core';
import {
	changeLogo,
	COMMON_STATE_TOKEN,
	CommonBaseState,
	CommonModel,
	getInitialCommonState,
	SetInitialUrlRequests,
	setInitialUrlRequests,
} from '@imt-web-zone/zone/state-common';
import { AsyncActionSuccess } from '@imt-web-zone/shared/util-store';
import { login } from '@imt-web-zone/zone/state-auth';

@State({
	name: COMMON_STATE_TOKEN,
	defaults: getInitialCommonState(true),
})
@Injectable()
export class CommonState extends CommonBaseState {
	constructor() {
		super();
	}

	// TODO: refactor this
	// @Action([SwitchTeamSuccess, SwitchOrganizationSuccess])
	// public async redirectFromSwitch(
	// 	ctx: StateContext<CommonModel>,
	// 	action: SwitchTeamSuccess | SwitchOrganizationSuccess
	// ) {
	// 	const teamId = action.payload;
	// 	// organization changed => redirect to org dashboard
	// 	if (action instanceof SwitchOrganizationSuccess) {
	// 		const path = ['organization', action.payload, 'dashboard'];
	// 		this.redirect(path);
	// 		return;
	// 	}
	// 	const tree = this.router.createUrlTree([], { relativeTo: this.route });
	// 	if (tree && tree.root.children.primary) {
	// 		const result = tree.root.children.primary.segments.map(
	// 			(x) => x.path
	// 		);
	// 		if (teamId !== result[0] && this.skipRedirect(result)) {
	// 			result[0] = teamId;
	// 			this.redirect(result);
	// 		} else if (teamId) {
	// 			const inAdmin = location.pathname.startsWith('/admin');
	// 			const path = inAdmin
	// 				? ['admin', teamId, 'team', 'dashboard']
	// 				: [teamId, 'team', 'dashboard'];
	// 			this.redirect(path);
	// 		} else {
	// 			this.redirect(['/']);
	// 		}
	// 	}
	// }

	@AsyncActionSuccess(login)
	@ImmutableContext()
	public clearInitialUrl(ctx: StateContext<CommonModel>) {
		ctx.setState((state: CommonModel) => {
			delete state.initialUrl;
			return state;
		});
	}

	@Action([setInitialUrlRequests])
	@ImmutableContext()
	public setInitialUrl(ctx: StateContext<CommonModel>, action: SetInitialUrlRequests) {
		ctx.setState((state: CommonModel) => {
			state.initialUrl = action.url;
			return state;
		});
	}

	@Action([changeLogo])
	@ImmutableContext()
	public changeLogo(ctx: StateContext<CommonModel>) {
		ctx.setState((state: CommonModel) => {
			state.lastLogoChange = +new Date();
			return state;
		});
	}
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CommonModel, getInitialCommonState } from './common.model';
import { COMMON_STATE_TOKEN } from './common.symbols';
import {
	changeLogoIndicatorVisibility,
	ChangeLogoIndicatorVisibility,
	changePendingRequests,
	ChangePendingRequests,
	updateCommonState,
	UpdateCommonState,
} from './common.actions';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';

@State({
	name: COMMON_STATE_TOKEN,
	defaults: getInitialCommonState(true),
})
@Injectable()
export class CommonBaseState {
	@Action(changePendingRequests)
	@ImmutableContext()
	public changePendingRequests(ctx: StateContext<CommonModel>, action: ChangePendingRequests) {
		ctx.setState((state: CommonModel) => {
			const pendingReqeustCount = state.pendingRequests + action.change;
			state.pendingRequests = pendingReqeustCount > 0 ? pendingReqeustCount : 0;
			return state;
		});
	}

	@Action(changeLogoIndicatorVisibility)
	@ImmutableContext()
	public loadZone(ctx: StateContext<CommonModel>, action: ChangeLogoIndicatorVisibility) {
		ctx.setState((state: CommonModel) => {
			state.isLogoIndicatorVisible = action.visible;
			return state;
		});
	}

	@Action([updateCommonState])
	@ImmutableContext()
	public updateCommonStateData(ctx: StateContext<CommonModel>, action: UpdateCommonState) {
		ctx.setState((state: CommonModel) => {
			state = { ...state, ...action.payload };
			return state;
		});
	}
}

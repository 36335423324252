import { getActionTypeFromInstance } from '@ngxs/store';
import { ClearFormValue } from './forms.actions';
import produce from 'immer';

export function formsMetaReducer(
	state: Record<string, unknown>,
	action: unknown,
	next: (state: Record<string, unknown>, action: unknown) => any /* todo monorepo: fix for RootState type */,
) {
	switch (getActionTypeFromInstance(action)) {
		case ClearFormValue.type: {
			state = produce(state, (draftState) => {
				delete draftState.forms[(action as ClearFormValue).payload.path];
			});
			break;
		}
	}

	return next(state, action);
}

import { Action, State, StateContext } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { IS_CUSTOM_ELEMENT } from '@imt-web-zone/shared/core';
import { API_CONFIG_STATE_TOKEN, ApiConfigStateBase } from './api-config-base.state';
import { FinishInstallGuide } from './api-config.actions';
import { ApiConfigData } from './api-config.interface';

@State({
	name: API_CONFIG_STATE_TOKEN,
	defaults: {} as ApiConfigData,
})
@Injectable()
export class ApiConfigState extends ApiConfigStateBase {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(injector: Injector, @Optional() @Inject(IS_CUSTOM_ELEMENT) isCustomElement: boolean) {
		super(injector);
	}

	@Action(FinishInstallGuide)
	public finishInstallGuide(ctx: StateContext<ApiConfigData>, action: FinishInstallGuide) {
		const state = ctx.getState();
		ctx.patchState({ ...state, showInstallGuide: false });
		ctx.dispatch(new Navigate(['']));
	}
}

import { mergeRecord } from '@imt-web-zone/shared/util';
import { EnvironmentInterface } from './environment.interface';
import { environmentZoneBase } from './environment-zone-base';

const environment: EnvironmentInterface = mergeRecord(environmentZoneBase, {
	env: 'staging',
	serviceWorker: {
		enable: true,
	},
	appConfig: {
		inspectorCdn: '/files/inspector/latest', // latest points to the last released version
	},
	production: false,
	api: {
		headers: {
			'imt-web-zone': 'staging',
		},
	},
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';
// Included with Angular CLI.

export { environment };

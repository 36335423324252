import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LangDefinition } from '@jsverse/transloco/lib/types';

/**
 * @todo(martinsik): We shouldn't need this method and just provide translations like any other component.
 */
export const appendScopedTranslations = (componentDir: string, scope: string) => {
	const transloco = inject(TranslocoService);
	return Promise.all(
		transloco.getAvailableLangs().map(async (langOrDef: string | LangDefinition) => {
			const lang = typeof langOrDef === 'string' ? langOrDef : langOrDef.id;

			const m = await import(`../../../${componentDir}/src/lib/+i18n/${lang}.json`);
			transloco.setTranslation(m.default, `${scope}/${lang}`);
		}),
	);
};

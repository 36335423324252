/* eslint-disable */
import { RouterStateModel } from '@ngxs/router-plugin';
import {
	CollectionStateModel,
	getInitialCollectionState,
	CacheModelState,
	initialCache,
	RouterUrlStateModel,
	FormsState,
} from '@imt-web-zone/shared/util-store';
import { EnumsModel } from '@imt-web-zone/zone/state-enums';
import {
	getInitialOrganizationsStateModel,
	getInitialTemplatesStateModel,
	//HookModel,
	//HooksIncomingModel,
	OrganizationModel,
	TemplateModel,
} from '@imt-web-zone/shared/data-access';
import { SessionModel } from '@imt-web-zone/zone/state-session';
import { getInitialImtInspectorModel, ImtInspectorModel } from '@imt-web-zone/inspector/data-access-state';
import { AuthStateModel, getInitialAuthStateModel } from '@imt-web-zone/zone/state-auth';
import { getInitialTeamsStateModel, TeamModel } from '@imt-web-zone/zone/state-teams';
import { CommonModel, getInitialCommonState } from '@imt-web-zone/zone/state-common';
// TODO remove this file, reset state dynamically
// eslint-disable @typescript-eslint/explicit-member-accessibility
class RootStateBase {
	auth: AuthStateModel = getInitialAuthStateModel();
	apiConfig: Record<string, any> = {};
	enums: EnumsModel = {};
	teams: CollectionStateModel<TeamModel> = getInitialTeamsStateModel();
	scenarios: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	//scenarios: CollectionStateModel<ScenarioModel> = getInitialScenariosStateModel();
	scenariosFolders: CollectionStateModel<Record<string, unknown>> =
		getInitialCollectionState<Record<string, unknown>>();
	templates: CollectionStateModel<TemplateModel> = getInitialTemplatesStateModel();
	inspector: ImtInspectorModel = getInitialImtInspectorModel();
	scenarioLogs: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	userApiTokens: CollectionStateModel<any> = getInitialCollectionState();

	// todo resolve
	dataStructures: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	dataStores: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	dataStoresData: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();

	dlqs: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	dlqsLogs: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	oauth: Record<string, any> = null;
	organizations: CollectionStateModel<OrganizationModel> = getInitialOrganizationsStateModel();
	userMailhubPreferences: CollectionStateModel<any> = getInitialCollectionState();
	commissionInfo: CacheModelState = initialCache();
	usersRoles: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	users: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState<Record<string, unknown>>();
	hooks: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	hooksIncomings: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	functions: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState<Record<string, unknown>>();
	//getInitialFunctionsStateModel();
	connections: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState<Record<string, unknown>>();
	userSessions: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	usersAdminsRoles: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	// eslint-disable-next-line max-len
	usersAdminsRolesPermissions: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	userTeamRoles: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	userOrganizationRoles: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	common: CommonModel = getInitialCommonState();
	// eslint-disable-next-line max-len
	usersRolesPermissions: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	notifications: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	sdkApps: CollectionStateModel<any> = getInitialCollectionState();
	sdkAppsConnections: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsWebhooks: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsChanges: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsModules: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsRpcs: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsFunctions: CollectionStateModel<Record<string, any>> = getInitialCollectionState();
	sdkAppsInvites: CollectionStateModel<Record<string, any>> = getInitialCollectionState();

	nativeApps: CollectionStateModel<Record<string, any>> = getInitialCollectionState<Record<string, unknown>>();
	metaApps: CollectionStateModel<Record<string, any>> = getInitialCollectionState<Record<string, unknown>>();
	keys: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState<Record<string, unknown>>();
	scenariosConsumptions: CollectionStateModel<Record<string, unknown>> =
		getInitialCollectionState<Record<string, unknown>>();
	incomingMessages: CollectionStateModel<Record<string, unknown>> =
		getInitialCollectionState<Record<string, unknown>>();
	incomingMessagesLog: CollectionStateModel<Record<string, unknown>> =
		getInitialCollectionState<Record<string, unknown>>();
	// eslint-disable-next-line max-len
	userOrganizationInvitations: CollectionStateModel<Record<string, unknown>> =
		getInitialCollectionState<Record<string, unknown>>();
	devices: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	devicesIncomings: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	userTeamNotifications: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	// eslint-disable-next-line max-len
	organizationInstalledApps: CollectionStateModel<Record<string, unknown>> = getInitialCollectionState();
	session: SessionModel;
	router: RouterStateModel<RouterUrlStateModel>;
	forms: FormsState = {};
}

export type RootState = RootStateBase;

export function initialRootState(): RootState {
	return new RootStateBase();
}

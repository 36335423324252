import { Component, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImtUiModalBase } from '../imt-ui-modal-base.component';
import { ImtUiModalService } from './../imt-ui-modal.service';

@Component({
	selector: 'imt-ui-modal-template',
	template: ` <div class="modal-header" *ngIf="!hideHeader">
			<ng-container *ngIf="headerText">
				<h4 class="modal-title">{{ headerText }}</h4>
				<button type="button" class="close" aria-label="Close" (click)="onDismiss(reason)">
					<span aria-hidden="true">&times;</span>
				</button>
			</ng-container>

			<ng-container *ngIf="!headerText">
				<ng-content select="imt-ui-modal-header"></ng-content>
			</ng-container>
		</div>
		<div class="modal-body" *ngIf="!hideBody">
			<ng-container *ngIf="bodyText">
				<p>{{ bodyText }}</p>
			</ng-container>
			<ng-container *ngIf="!bodyText">
				<ng-content select="imt-ui-modal-body"></ng-content>
				<ng-content></ng-content>
			</ng-container>
		</div>
		<div class="modal-footer" *ngIf="!hideFooter">
			<ng-content select="imt-ui-modal-footer"></ng-content>
		</div>`,
	styleUrls: ['../imt-ui-modal.component.scss'],
})
export class ImtUiModalTemplateComponent extends ImtUiModalBase {
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(@Optional() protected modalRef: NgbActiveModal, protected modalService: ImtUiModalService) {
		super(modalService);
	}
}

/**
 *
 */

@Component({
	template: ` <imt-ui-modal-template #template>
		<imt-ui-modal-header (dissmised)="template.onDismiss($event)">{{
			'imt_ui_common.integromat'
		}}</imt-ui-modal-header>
		<imt-ui-modal-body>{{ 'imt_ui_modal.access_denied' }}</imt-ui-modal-body>
		<imt-ui-modal-footer
			[hideBtnPrimary]="true"
			(dismissed)="template.onDismiss($event)"
			(submited)="template.onClose($event)"
		></imt-ui-modal-footer>
	</imt-ui-modal-template>`,
})
export class ImtUiModalAccessDeniedComponent {}

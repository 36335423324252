import { Type } from '@angular/core';
import { createSelector, StateContext, StateToken } from '@ngxs/store';
import {
	EntityActionType,
	EntityAddAction,
	EntityCreateOrReplaceAction,
	EntityRemoveAction,
	EntitySetActiveAction,
	EntitySetErrorAction,
	EntitySetLoadingAction,
	EntityUpdateAction,
	EntityUpdateActiveAction,
} from '../actions';
import { InvalidIdError, NoSuchEntityError, UpdateFailedError } from './errors';
import { IdStrategy } from './id-strategy';
import { asArray, elvis, getActive, getById, HashMap, mustGetActive, NGXS_META_KEY } from './internal';
import { EntityStateModel, StateSelector } from './models';
import IdGenerator = IdStrategy.IdGenerator;
import { UpsertAction } from '../actions';

/* eslint @typescript-eslint/no-this-alias: 0 */
/**
 * Returns a new object which serves as the default state.
 * No entities, loading is false, error is undefined, active is undefined.
 * pageSize is 10 and pageIndex is 0.
 */
export function defaultEntityState<T>(defaults: Partial<EntityStateModel<T>> = {}): EntityStateModel<T> {
	return {
		entities: {},
		ids: [],
		loading: false,
		error: undefined,
		active: undefined,
		lastUpdated: Date.now(),
		idKey: undefined,
		storeKey: undefined,
		...defaults,
	};
}

export interface SortingOptions<T> {
	/**
	 * sorts array by property name
	 *
	 */
	sortByKey?: keyof T;
	sortOrder?: 'asc' | 'desc';
	sortCondition?: (itemA: T, itemB: T) => number;
}

export interface ArrayOptions<M> extends SortingOptions<M> {
	predicate?: (entity: M) => boolean;
}

export type EntityType<T extends EntityStateModel<any>> = T['entities'][0];

function sortCb<F extends Record<string, any>>(options: SortingOptions<F>) {
	return (itemA: F, itemB: F) => {
		if (!options.sortByKey) {
			if (options.sortCondition) {
				const conditionResult = options.sortCondition(itemA, itemB);
				if (conditionResult !== null) {
					return conditionResult;
				}
			}

			if (options.sortOrder === 'desc') {
				return -1;
			}
			return 0;
		}

		const a = itemA[options.sortByKey as any];
		const b = itemB[options.sortByKey as any];

		try {
			if (typeof a === 'string' && typeof b === 'string') {
				let sortResult = a.localeCompare(b);
				if (options.sortCondition) {
					const conditionResult = options.sortCondition(itemA, itemB);
					if (conditionResult !== null) {
						sortResult = conditionResult;
					}
				}
				if (options.sortOrder === 'desc') {
					return sortResult * -1;
				}
				return sortResult;
			}
		} catch (err) {
			return 0;
		}

		let result: number | null = null;

		if (a < b) {
			result = -1;
		}
		if (a > b) {
			result = 1;
		}

		if (a === b) {
			result = 0;
		}

		if (options.sortCondition) {
			const conditionResult = options.sortCondition(itemA, itemB);
			if (conditionResult !== null) {
				result = conditionResult;
			}
		}
		if (result !== null && options.sortOrder === 'desc') {
			return result * -1;
		}

		return result as number;
	};
}

export const EntityIdKeysMap: { [key: string]: string } = {};
export const AdaptersMap: { [key: string]: IdGenerator<any> } = {};

// @dynamic
export class EntityState<T extends Record<string, any>> {
	protected readonly idGenerator: IdGenerator<T>;
	protected readonly idKey: string;
	protected readonly storePath: string;
	private readonly sortingOptions: SortingOptions<T> | null;

	// ------------------- SELECTORS -------------------

	public static getEntitiesWhere<T extends EntityStateModel<any>>(
		stateToken: StateToken<T>,
		options: ArrayOptions<EntityType<T>>,
	): StateSelector<Array<EntityType<any>>, T> {
		return createSelector([stateToken], (state) => {
			return this._getEntitiesWhere(state as any, options);
		});
	}

	/**
	 * Returns whole state
	 */
	public static getState<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state: T) => state);
	}

	/**
	 * Returns a selector for all entities matching predicate, sorted by insertion order
	 *
	 * @deprecated use CollectionState.getEntitiesWhere(stateToken, options)
	 */
	public static entitiesWhere<T>(options: ArrayOptions<T>): StateSelector<Array<any>> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getEntitiesWhere(subState, options);
		};
	}

	private static _getEntitiesWhere<T extends EntityStateModel<any>>(
		state: T,
		options: ArrayOptions<EntityType<T>>,
	): Array<T> {
		let entities = state.ids.reduce((arr, id) => {
			const entity = state.entities[id];
			if (options.predicate) {
				if (options.predicate(entity)) {
					arr.push(entity);
				}
			} else {
				arr.push(entity);
			}
			return arr;
		}, [] as Array<EntityType<T>>);

		if (options.sortOrder || options.sortByKey || options.sortCondition) {
			entities = entities.sort(sortCb(options));
		}

		return entities;
	}

	/**
	 * Returns a selector for the activeId
	 *
	 */
	public static getActiveId<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state) => {
			return this._getActiveId(state) || null;
		});
	}

	/**
	 * Returns a selector for the activeId
	 * @deprecated use CollectionState.getActiveId(stateToken)
	 */
	public static get activeId(): StateSelector<string | null> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getActiveId(subState) || null;
		};
	}

	private static _getActiveId(state: EntityStateModel<unknown>): string | null | undefined {
		return state?.active;
	}

	/**
	 * Returns a selector for the active entity
	 */
	public static getActive<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state: T) => getActive<EntityType<T>>(state));
	}

	/**
	 * Returns a selector for the active entity
	 */
	public static getById<T extends EntityStateModel<any>>(stateToken: StateToken<T>, id: any) {
		return createSelector([stateToken], (state: T) => getById<EntityType<T>>(state, id));
	}

	/**
	 * Returns a selector for the active entity
	 * @deprecated use CollectionState.getActive(stateToken)
	 */
	public static get active(): StateSelector<any> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return getActive(subState);
		};
	}

	/**
	 * Returns a selector for the keys of all entities
	 *
	 */
	public static getKeys(stateToken: StateToken<any>): StateSelector<Array<string>> {
		return createSelector([stateToken], (state) => this._getKeys(state));
	}

	/**
	 * Returns a selector for the keys of all entities
	 * @deprecated use CollectionState.getKeys(stateToken)
	 */
	public static get keys(): StateSelector<Array<string>> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getKeys(subState);
		};
	}

	private static _getKeys(state: EntityStateModel<any>): Array<string> {
		return Object.keys(state?.entities || {});
	}

	/**
	 * Returns a selector for all entities, sorted by insertion order
	 */
	public static getEntities<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state: T) => this._getEntities<EntityType<T>>(state));
	}

	/**
	 * Returns a selector for all entities, sorted by insertion order
	 * @deprecated use CollectionState.getEntities()
	 */
	public static get entities(): StateSelector<Array<any>> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getEntities(subState);
		};
	}

	private static _getEntities<T>(state: EntityStateModel<any>): Array<T> {
		return state?.ids.map((id) => state?.entities[id]);
	}

	/**
	 * Returns a selector for the nth entity, sorted by insertion order
	 */
	public static getNthEntity<T extends EntityStateModel<any>>(stateToken: StateToken<T>, index: number) {
		return createSelector([stateToken], (state: T) => this._getNthEntity<EntityType<T>>(state, index));
	}

	/**
	 * Returns a selector for the nth entity, sorted by insertion order
	 * @deprecated use CollectionState.getNthEntity()
	 */
	public static nthEntity(index: number): StateSelector<any> {
		// tslint:disable-line:member-ordering
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getNthEntity(subState, index);
		};
	}

	private static _getNthEntity<T>(state: EntityStateModel<any>, index: number): T {
		const id = state?.ids[index];
		return state?.entities[id];
	}

	/**
	 * Returns a selector for the map of entities
	 */
	public static getEntitiesMap<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state) => this._getEntitiesMap<EntityType<T>>(state));
	}

	/**
	 * @deprecated use CollectionState.getEntitiesMap(stateToken)
	 */
	public static get entitiesMap(): StateSelector<HashMap<any>> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getEntitiesMap(subState);
		};
	}

	private static _getEntitiesMap<T>(state: EntityStateModel<any>): EntityStateModel<T>['entities'] {
		return state?.entities;
	}

	/**
	 * Returns a selector for the size of the entity map
	 */
	public static getSize(stateToken: StateToken<any>): StateSelector<number> {
		return createSelector([stateToken], (state) => this._getSize(state));
	}

	/**
	 * @deprecated use CollectionState.getSize(stateToken)
	 */
	public static get size(): StateSelector<number> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getSize(subState);
		};
	}

	private static _getSize(state: EntityStateModel<any>): number {
		return Object.keys(state?.entities || {}).length;
	}

	/**
	 * Returns a selector for the error
	 */
	public static getError(stateToken: StateToken<any>): StateSelector<Error | undefined | null> {
		return createSelector([stateToken], (state) => this._getError(state));
	}

	/**
	 * @deprecated use CollectionState.getError(stateToken)
	 */
	public static get error(): StateSelector<Error | undefined> {
		const that = this;
		return (state) => {
			const name = that.staticStorePath;
			return elvis(state, name).error;
		};
	}

	private static _getError(state: EntityStateModel<any>): Error | undefined | null {
		return state?.error;
	}

	/**
	 * Returns a selector for the loading state
	 */
	public static getLoading(stateToken: StateToken<any>): StateSelector<boolean> {
		return createSelector([stateToken], (state) => this._getLoading(state));
	}

	/**
	 * Returns a selector for the loading state
	 * @deprecated use CollectionState.getLoading()
	 */
	public static get loading(): StateSelector<boolean> {
		const that = this;
		return (state) => {
			const name = that.staticStorePath;
			return elvis(state, name)?.loading;
		};
	}

	private static _getLoading(state: EntityStateModel<any>): boolean {
		return state?.loading;
	}

	/**
	 * Returns a selector for the latest added entity
	 */
	public static getLatest<T extends EntityStateModel<any>>(stateToken: StateToken<T>) {
		return createSelector([stateToken], (state: T) => this._getLatest<EntityType<T>>(state));
	}

	/**
	 * @deprecated use CollectionState.getLatest(stateToken)
	 */
	public static get latest(): StateSelector<any> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			const latestId = subState?.ids[subState.ids.length - 1];
			return subState?.entities[latestId];
		};
	}

	private static _getLatest<T>(state: EntityStateModel<any>): T {
		const latestId = state?.ids[state.ids.length - 1];
		return state?.entities[latestId];
	}

	/**
	 * Returns a selector for the latest added entity id
	 */
	public static getLatestId(stateToken: StateToken<any>): StateSelector<string | undefined> {
		return createSelector([stateToken], (state) => this._getLatestId(state));
	}

	/**
	 * @deprecated use CollectionState.getLatestId(stateToken)
	 */
	public static get latestId(): StateSelector<string | undefined> {
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return subState?.ids[subState.ids.length - 1];
		};
	}

	private static _getLatestId(state: EntityStateModel<any>): string | undefined {
		return state?.ids[state.ids.length - 1];
	}

	/**
	 * Returns a selector for the update timestamp
	 */
	public static getLastUpdated(stateToken: StateToken<any>): StateSelector<Date> {
		return createSelector([stateToken], (state) => this._getLastUpdated(state));
	}

	/**
	 * @deprecated use CollectionState.getLastUpdated(stateToken)
	 */
	public static get lastUpdated(): StateSelector<Date> {
		// tslint:disable-line:member-ordering
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return new Date(subState?.lastUpdated);
		};
	}

	private static _getLastUpdated(state: EntityStateModel<any>): Date {
		return new Date(state?.lastUpdated);
	}

	/**
	 * Returns a selector for age, based on the update timestamp
	 */
	public static getAge(stateToken: StateToken<any>): StateSelector<number> {
		return createSelector([stateToken], (state) => this._getAge(state));
	}

	/**
	 * @deprecated use CollectionState.getAge(stateToken)
	 */
	public static get age(): StateSelector<number> {
		// tslint:disable-line:member-ordering
		const that = this;
		return (state) => {
			const subState = elvis(state, that.staticStorePath) as EntityStateModel<any>;
			return this._getAge(subState);
		};
	}

	private static _getAge(state: EntityStateModel<unknown>): number {
		return Date.now() - state.lastUpdated;
	}

	protected constructor(
		storeClass: Type<EntityState<T>>,
		_idKey: keyof T,
		idStrategy: Type<IdGenerator<T>>,
		sortingOptions: SortingOptions<T> | null = null,
	) {
		this.idKey = _idKey as string;
		this.storePath = (storeClass as any)[NGXS_META_KEY].path;
		this.idGenerator = new idStrategy(_idKey);
		this.sortingOptions = sortingOptions;

		AdaptersMap[this.storePath] = this.idGenerator;
		EntityIdKeysMap[this.storePath] = this.idKey;

		this.setup(storeClass, Object.values(EntityActionType));
	}

	private static get staticStorePath(): string {
		const that: any = this;
		return that[NGXS_META_KEY].path;
	}

	/**
	 * This function is called every time an entity is updated.
	 * It receives the current entity and a partial entity that was either passed directly or generated with a function.
	 * The default implementation uses the spread operator to create a new entity.
	 * You must override this method if your entity type does not support the spread operator.
	 * @see Updater
	 * @param current The current entity, readonly
	 * @param updated The new data as a partial entity
	 * @example
	 * // default behavior
	 * onUpdate(current: Readonly<T updated: Partial<T>): T {
	 * return {...current, ...updated};
	 * }
	 */
	public onUpdate(current: Readonly<T>, updated: Partial<T>): T {
		return { ...current, ...updated } as T;
	}

	// ------------------- ACTION HANDLERS -------------------

	/**
	 * The entities given by the payload will be added.
	 * For certain ID strategies this might fail, if it provides an existing ID.
	 * In all cases it will overwrite the ID value in the entity with the calculated ID.
	 */
	public add<R extends EntityStateModel<T>>(
		{ getState, patchState }: StateContext<R>,
		{ payload }: EntityAddAction<T>,
	) {
		const updated = this._addOrReplace(
			getState(),
			payload,
			// for automated ID strategies this mostly shouldn't throw an UnableToGenerateIdError error
			// for EntityIdGenerator it will throw an error if no ID is present
			(p, state) => this.idGenerator.generateId(p, state),
		);
		patchState({ ...updated, lastUpdated: Date.now() } as Partial<R>);
	}

	/**
	 * The entities given by the payload will be added.
	 * It first checks if the ID provided by each entity does exist.
	 * If it does the current entity will be replaced.
	 * In all cases it will overwrite the ID value in the entity with the calculated ID.
	 */
	public createOrReplace<R extends EntityStateModel<T>>(
		{ getState, patchState }: StateContext<R>,
		{ payload }: EntityCreateOrReplaceAction<T>,
	) {
		const updated = this._addOrReplace(getState(), payload, (p, state) =>
			this.idGenerator.getPresentIdOrGenerate(p, state),
		);
		patchState({ ...updated, lastUpdated: Date.now() } as Partial<R>);
	}

	public upsert<R extends EntityStateModel<T>>(
		{ getState, patchState }: StateContext<R>,
		{ payload }: UpsertAction<T>,
	) {
		const updated = this._upsert(getState(), payload, (p, state) =>
			this.idGenerator.getPresentIdOrGenerate(p, state),
		);
		patchState({ ...updated, lastUpdated: Date.now() } as Partial<R>);
	}

	public update<R extends EntityStateModel<T>>(
		{ getState, patchState }: StateContext<R>,
		{ payload }: EntityUpdateAction<T>,
	) {
		const entities = { ...getState().entities }; // create copy
		let affected: Array<T>;

		if (payload.id === null) {
			affected = Object.values(entities);
		} else if (typeof payload.id === 'function') {
			// eslint-disable-next-line @typescript-eslint/ban-types
			affected = Object.values(entities).filter((e) => (payload.id as Function)(e));
		} else {
			affected = Object.values(entities).filter((e) => asArray(payload.id).includes(this.idOf(e) as string));
		}

		const ids = getState().ids;
		let result = {
			entities,
			ids,
		};
		if (typeof payload.data === 'function') {
			affected.forEach((e) => {
				// eslint-disable-next-line @typescript-eslint/ban-types
				result = this._update(result.entities, (payload.data as Function)(e), this.idOf(e), result.ids);
			});
		} else {
			affected.forEach((e) => {
				result = this._update(result.entities, payload.data as Partial<T>, this.idOf(e), result.ids);
			});
		}

		patchState({ ...result, lastUpdated: Date.now() } as Partial<R>);
	}

	public updateActive<R extends EntityStateModel<T>>(
		{ getState, patchState }: StateContext<R>,
		{ payload }: EntityUpdateActiveAction<T>,
	) {
		const state = getState();
		const { id, active } = mustGetActive(state);
		const { entities } = state;

		let result: { entities: HashMap<T>; ids: Array<string> };
		if (typeof payload === 'function') {
			result = this._update(entities, payload(active), id as string, getState().ids);
		} else {
			result = this._update(entities, payload, id as string, getState().ids);
		}
		patchState({ ...result, lastUpdated: Date.now() } as Partial<R>);
	}

	public removeActive<R extends EntityStateModel<T>>({ getState, patchState }: StateContext<R>) {
		const { active, ids } = getState();
		const entities = { ...getState().entities };

		delete entities[active as string];
		patchState({
			entities: { ...entities },
			ids: ids.filter((id) => id !== active),
			active: undefined,
			lastUpdated: Date.now(),
		} as Partial<R>);
	}

	public remove<R extends EntityStateModel<T>>(
		{ getState, patchState, setState }: StateContext<R>,
		{ payload }: EntityRemoveAction<T>,
	) {
		const { active, ids } = getState();
		const entities = { ...getState().entities };

		if (payload === null) {
			const data: Partial<R> = {
				entities: {},
				ids: [],
				active: undefined,
				lastUpdated: Date.now(),
			} as any;
			patchState(data);
		} else {
			const deleteIds: Array<string> =
				typeof payload === 'function'
					? Object.values(entities)
							.filter((e) => payload(e))
							.map((e) => this.idOf(e) as string)
					: asArray(payload);

			const wasActive = deleteIds.includes(active as string);
			deleteIds.forEach((id) => delete entities[id]);
			patchState({
				entities: { ...entities },
				ids: ids.filter((id) => !deleteIds.includes(id.toString())),
				active: wasActive ? undefined : active,
				lastUpdated: Date.now(),
			} as Partial<R>);
		}
	}

	public reset<R extends EntityStateModel<T>>({ setState }: StateContext<R>) {
		setState(defaultEntityState({ idKey: this.idKey, storeKey: this.storePath }) as R);
	}

	public setLoading<R extends EntityStateModel<T>>(
		{ patchState }: StateContext<R>,
		{ payload }: EntitySetLoadingAction,
	) {
		patchState({ loading: payload } as Partial<R>);
	}

	public setActive<R extends EntityStateModel<T>>(
		{ patchState }: StateContext<R>,
		{ payload }: EntitySetActiveAction,
	) {
		patchState({ active: payload } as Partial<R>);
	}

	public clearActive<R extends EntityStateModel<T>>({ patchState }: StateContext<R>) {
		patchState({ active: undefined } as Partial<R>);
	}

	public setError<R extends EntityStateModel<T>>({ patchState }: StateContext<R>, { payload }: EntitySetErrorAction) {
		patchState({ error: payload } as Partial<R>);
	}

	// ------------------- UTILITY -------------------

	/**
	 * Returns the id of the given entity, based on the defined idKey.
	 * This methods allows Partial entities and thus might return undefined.
	 * Other methods calling this one have to handle this case themselves.
	 * @param data a partial entity
	 */
	protected idOf(data: Partial<T>): string | undefined {
		return (data as Record<string, string>)[this.idKey];
	}

	/**
	 * Returns ID index of sorted array items
	 * @param entities of items
	 * @param id of item
	 * @param options of sorting
	 */
	private getSortedIndex(entities: { [key: string]: T }, id: string, options: SortingOptions<T>): number {
		const values = Object.values(entities);
		return values.sort(sortCb(options)).indexOf(entities[id]);
	}

	/**
	 * A utility function to update the given state with the given entities.
	 * It returns a state model with the new entities map and IDs.
	 * For each given entity an ID will be generated. The generated ID will overwrite the current value:
	 * <code>entity[this.idKey] = generatedId(entity, state);</code>
	 * If the ID wasn't present, it will be added to the state's IDs array.
	 * @param state The current state to act on
	 * @param payload One or multiple partial entities
	 * @param generateId A function to generate an ID for each given entity
	 */
	private _addOrReplace(
		state: EntityStateModel<T>,
		payload: T | Array<T>,
		generateId: (payload: Partial<T>, state: EntityStateModel<T>) => string,
	): { entities: HashMap<T>; ids: Array<string> } {
		state = { ...state };
		const entities = { ...state.entities };
		let ids = [...state.ids];

		for (let entity of asArray(payload)) {
			const id = generateId(entity, state);
			entity = { ...entity };
			const existsId = !!entities[id];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			entity[this.idKey] = id;
			entities[id] = entity;

			if (!existsId) {
				ids.push(id);
			}
		}

		if (this.sortingOptions) {
			ids = Object.values(entities)
				.sort(sortCb(this.sortingOptions))
				.map((value) => (value as Record<string, string>)[this.idKey]);
		}

		return {
			entities: { ...entities },
			ids: [...ids],
		};
	}

	/**
	 * A utility function to update the given state with the given entities.
	 * It returns a state model with the new entities map and IDs.
	 * For each given entity an ID will be generated. The generated ID will patch the current value:
	 * <code>entity[this.idKey] = generatedId(entity, state);</code>
	 * If the ID wasn't present, it will be added to the state's IDs array.
	 * @param state The current state to act on
	 * @param payload One or multiple partial entities
	 * @param generateId A function to generate an ID for each given entity
	 */
	private _upsert(
		state: EntityStateModel<T>,
		payload: Partial<T> | Array<Partial<T>>,
		generateId: (payload: Partial<T>, state: EntityStateModel<T>) => string,
	): { entities: HashMap<T>; ids: Array<string> } {
		const entities = { ...state.entities };
		let ids = [...state.ids];

		for (let entity of asArray(payload)) {
			const id = generateId(entity, state);
			const existsId = !!entities[id];

			if (!existsId) {
				entity = { ...entity };
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				entity[this.idKey] = id;
			} else {
				entity = { ...entities[id], ...entity };
			}
			entities[id] = entity as any;
			if (!existsId) {
				ids.push(id);
			}
		}

		if (this.sortingOptions) {
			ids = Object.values(entities)
				.sort(sortCb(this.sortingOptions))
				.map((value) => (value as Record<string, string>)[this.idKey]);
		}

		return {
			entities: { ...entities },
			ids: [...ids],
		};
	}

	/**
	 * A utility function to update the given entities map with the provided partial entity.
	 * After checking if an entity with the given ID is present, the #onUpdate method is called.
	 * @param entities The current entity map
	 * @param entity The partial entity to update with
	 * @param id The ID to find the current entity in the map
	 */
	private _update(
		entities: HashMap<T>,
		entity: Partial<T>,
		id: string = this.idOf(entity) as string,
		ids: Array<string>,
	): { entities: HashMap<T>; ids: Array<string>; idKey: string; storeKey: string } {
		if (id === undefined) {
			throw new UpdateFailedError(new InvalidIdError(id));
		}
		const current = entities[id];
		if (current === undefined) {
			throw new UpdateFailedError(new NoSuchEntityError(id));
		}

		const index = ids.indexOf(id);
		const updated = this.onUpdate(current, entity);
		entities = { ...entities, [id]: updated };

		if (this.sortingOptions) {
			const sortedIndex = this.getSortedIndex(entities, id, this.sortingOptions);
			if (sortedIndex !== index) {
				ids.splice(index, 1);
				ids.splice(sortedIndex, 0, id);
			}
		}
		return { entities, ids, idKey: this.idKey, storeKey: this.storePath };
	}

	private setup(storeClass: Type<EntityState<T>>, actions: Array<string>) {
		// validation if a matching action handler exists has moved to reflection-validation tests
		actions.forEach((fn) => {
			const actionName = `[${this.storePath}] ${fn}`;
			(storeClass as any)[NGXS_META_KEY].actions[actionName] = [
				{
					fn,
					options: {},
					type: actionName,
				},
			];
		});
	}
}

import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AsyncLoadAction } from '@imt-web-zone/shared/util-store';
import { Observable } from 'rxjs';

export type TruthySelector = (...args: any[]) => string | number | boolean | null;
export type EntityGuardRunAfter = (injector: Injector) => Observable<string | number | boolean | null>;
export const getGuardOptionsSymbol = (key: string) => ('__' + key + '_options').toUpperCase();

export type EntityGuardArgs<T> = {
	errorAction: AsyncLoadAction<any, T>;
	router: Router;
	route: ActivatedRouteSnapshot;
	state: RouterStateSnapshot;
	injector: Injector;
};
export type EntityGuardErrorFn<T> = ({
	errorAction,
	router,
	route,
	state,
	injector,
}: EntityGuardArgs<T>) => Observable<UrlTree | boolean> | Promise<boolean>;

export class EntityGuardError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'EntityGuardError';
		Object.setPrototypeOf(this, EntityGuardError.prototype);
	}
}

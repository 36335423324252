export interface CountriesEnumModel {
	id: string;
	name: string;
}

export interface LocalesEnumModel {
	id: string;
	name: string;
	code: string;
	angularCode: string;
}

export interface LanguageEnumModel {
	code: string;
	name: string;
}

export interface TimezonesEnumModel {
	id: string;
	name: string;
	code: string;
	offset: string;
}

export interface UserFeatureEnumModel {
	title: string;
	name: string;
}

export interface UserEmailNotificationsEnumModel {
	id: string;
	name: string;
	description: string;
}

export interface ModuleTypeEnumModel {
	id: string;
	name: string;
}

export interface UserApiTokenScopesModel {
	name: string;
}

export interface ImtZoneModel {
	id: string;
	domain: string;
	name: string;
}

export interface OrganizationFeatureEnumModel {
	title: string;
	name: string;
}

export interface AppReviewStatus {
	value: number;
	label: string;
}

export interface UserOauthScopesEnumModel {
	id: number;
	name: string;
}

export interface VariableTypesEnumModel {
	id: number;
	name: string;
	teamOnly: boolean;
}

export enum VariableTypes {
	NUMBER = 1,
	TEXT = 2,
	BOOLEAN = 3,
	DATE = 4,
}

export interface ConnectedSystemAppEnumModel {
	name: string;
	label: string;
	icon: string;
}

export enum EnumNames {
	COUNTRIES = 'countries',
	LOCALES = 'locales',
	LANGUAGES = 'languages',
	TIMEZONES = 'timezones',
	USER_FEATURES = 'userFeatures',
	USER_EMAIL_NOTIFICATIONS = 'userEmailNotifications',
	MODULE_TYPES = 'moduleTypes',
	USER_API_TOKEN_SCOPES = 'userApiTokenScopes',
	ORGANIZATION_FEATURES = 'organizationFeatures',
	IMT_ZONES = 'imtZones',
	APP_REVIEW_STATUSES = 'appsReviewStatuses',
	USER_OAUTH_SCOPES = 'userOauthScopes',
	VARIABLE_TYPES = 'variableTypes',
	CONNECTED_SYSTEM_APPS = 'connectedSystemApps',
}

export interface EnumsModel {
	[EnumNames.COUNTRIES]?: EnumEntities<CountriesEnumModel>;
	[EnumNames.LOCALES]?: EnumEntities<LocalesEnumModel>;
	[EnumNames.LANGUAGES]?: EnumEntities<LanguageEnumModel>;
	[EnumNames.TIMEZONES]?: EnumEntities<TimezonesEnumModel>;
	[EnumNames.USER_FEATURES]?: EnumEntities<UserFeatureEnumModel>;
	[EnumNames.USER_EMAIL_NOTIFICATIONS]?: EnumEntities<UserEmailNotificationsEnumModel>;
	[EnumNames.MODULE_TYPES]?: EnumEntities<ModuleTypeEnumModel>;
	[EnumNames.USER_API_TOKEN_SCOPES]?: EnumEntities<UserApiTokenScopesModel>;
	[EnumNames.ORGANIZATION_FEATURES]?: EnumEntities<OrganizationFeatureEnumModel>;
	[EnumNames.IMT_ZONES]?: EnumEntities<ImtZoneModel>;
	[EnumNames.APP_REVIEW_STATUSES]?: EnumEntities<AppReviewStatus>;
	[EnumNames.USER_OAUTH_SCOPES]?: EnumEntities<UserOauthScopesEnumModel>;
	[EnumNames.VARIABLE_TYPES]?: EnumEntities<VariableTypesEnumModel>;
	[EnumNames.CONNECTED_SYSTEM_APPS]?: EnumEntities<ConnectedSystemAppEnumModel>;
}

export interface EnumsModelMap {
	[EnumNames.COUNTRIES]?: CountriesEnumModel;
	[EnumNames.LOCALES]?: LocalesEnumModel;
	[EnumNames.LANGUAGES]?: LanguageEnumModel;
	[EnumNames.TIMEZONES]?: TimezonesEnumModel;
	[EnumNames.USER_FEATURES]?: UserFeatureEnumModel;
	[EnumNames.USER_EMAIL_NOTIFICATIONS]?: UserEmailNotificationsEnumModel;
	[EnumNames.MODULE_TYPES]?: ModuleTypeEnumModel;
	[EnumNames.USER_API_TOKEN_SCOPES]?: UserApiTokenScopesModel;
	[EnumNames.ORGANIZATION_FEATURES]?: OrganizationFeatureEnumModel;
	[EnumNames.IMT_ZONES]?: ImtZoneModel;
	[EnumNames.APP_REVIEW_STATUSES]?: AppReviewStatus;
	[EnumNames.USER_OAUTH_SCOPES]?: UserOauthScopesEnumModel;
	[EnumNames.VARIABLE_TYPES]?: VariableTypesEnumModel;
	[EnumNames.CONNECTED_SYSTEM_APPS]?: ConnectedSystemAppEnumModel;
}

export interface EnumEntities<T> {
	entities: { [key: string]: T };
	ids: Array<string>;
}

export enum UiButtonType {
	Primary = 'primary',
	Brand = 'brand',
	Info = 'info',
	Success = 'success',
	Warning = 'warning',
	Danger = 'danger',

	Outlined = 'outlined',

	Clear = 'clear',
	Secondary = 'secondary',
}

export enum UiButtonSize {
	/**
	 * @deprecated
	 */
	VerySmall = 'very-small',

	Small = 'small',
	Normal = 'normal',
	Large = 'large',
}

import { InjectionToken } from '@angular/core';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { FeatureFlag } from '@imt-web-zone/shared/data-access-growthbook';
import { Observable } from 'rxjs';

export const DATADOG_CLIENT = new InjectionToken<typeof datadogLogs>('DATADOG_CLIENT');

export interface DatadogConfig {
	config: LogsInitConfiguration;
	userId$: Observable<string>;
	reload$?: Observable<string>;
	contextChanged$?: Observable<[string, any]>;
	featureFlag?: FeatureFlag;
}

import { CollectionStateModel, getInitialCollectionState } from '@imt-web-zone/shared/util-store';
import {
	ScenarioLogsAuthor,
	ScenarioLogsDelay,
	ScenarioLogsReason,
	ScenarioLogsType,
	ScenariosLogStatus,
} from './scenario-logs.interface';

export class ScenarioLogModel {
	constructor(
		public id: string,
		public imtId: string,
		public type: ScenarioLogsType,
		public timestamp: Date,
		public authorId: string | null,
		public status?: ScenariosLogStatus,
		public instant?: boolean,
		public duration?: number,
		public operations?: number,
		public transfer?: number,
		public detail?: ScenarioLogsAuthor | ScenarioLogsReason | ScenarioLogsDelay,
		public scenarioId?: string,
		public teamId?: string,
		public reason?: string,
		public parent?: { id: number; name: string; chainingRole: 'bridge' | 'parent' },
	) {}
}

export function getInitialScenariosLogsStateModel(): CollectionStateModel<ScenarioLogModel> {
	return getInitialCollectionState<ScenarioLogModel>();
}

import { filter, take } from 'rxjs/operators';
import { Subject } from 'rxjs';

export enum ImtEventsEnum {
	// [file_name]_[action_name] = [file_name].[action_name]
	INSPECT_PANEL_RESOLVE_BTN = 'inspector.inspect_panel.resolve_btn',

	INSPECTOR_MODE_CHANGE = 'inspector.mode_change',
	INSPECTOR_ACTIVE_EXECUTION_CHANGE = 'inspector.active_execution_change',
	INSPECTOR_MODE_TRANSITION = 'inspector.mode_transition',

	LOADER_ERROR = 'loader.error',

	DEBUGGER_LOAD_LOG = 'debugger.load_log',
	DEBUGGER_LOAD_LOG_FAILED = 'debugger.load_log_failed',

	REPOSITORY_LOAD_APP = 'repository.load_app',
	REPOSITORY_LOAD_PACKAGE = 'repository.load_package',

	MODULEBROWSERPANEL_LOAD_APPS = 'modulebrowserpanel.load_apps',

	SCENARIO_PATCH_SCENARIO = 'scenario.patch_scenario',
	SCENARIO_CREATE_SCENARIO = 'scenario.create_scenario',
	SCENARIO_LOAD_SCENARIO_BLUEPRINT = 'scenario.load_scenario_blueprint',
	SCENARIO_LOAD_DLQS_BLUEPRINT = 'scenario.load_dlqs_blueprint',
	SCENARIO_REFRESH = 'scenario.refresh',
	SCENARIO_BUILT = 'scenario.built',
	SCENARIO_ADD_MODULE_BUTTON_CLICKED = 'scenario.add_module_button_clicked',
	SCENARIO_TOOLS_BUTTON_CLICKED = 'scenario.tools_button_clicked',
	SCENARIO_MODULE_ADDED = 'scenario.module_added',
	SCENARIO_MODULE_SAVED = 'scenario.module_saved',
	SCENARIO_APP_SELECTED = 'scenario.app_selected',
	SCENARIO_APP_SEARCHED_AND_NOT_FOUND = 'scenario.app_searched_and_not_found',
	SCENARIO_MODULE_SEARCHED_AND_NOT_FOUND = 'scenario.module_searched_and_not_found',
	SCENARIO_FILTER_ADDED = 'scenario.filter_added',
	SCENARIO_SCHEDULE_UPDATED = 'scenario.schedule_updated',
	SCENARIO_AI_BUTTON_CLICKED = 'scenario.ai_button_clicked',
	SCENARIO_FIRST_DATA_MAPPED = 'scenario.first_data_mapped',
	SCENARIO_CREATE_APP_USING_AI_CLICKED = 'scenario.create_app_using_ai_clicked',
	SCENARIO_TOOLBAR_EXPANDED = 'scenario.toolbar_expanded',
	SCENARIO_TOOLBAR_COLLAPSED = 'scenario.toolbar_collapsed',
	SCENARIO_LOGS_OPENED = 'scenario.logs_opened',
	SCENARIO_SCHEDULING_OPENED = 'scenario.scheduling_opened',

	SCENARIO_LOG_REQUEST_EXECUTION_STREAM = 'scenario.log_request_execution_stream',
	SCENARIO_LOG_EXECUTION_STREAM = 'scenario.log_execution_stream',
	SCENARIO_LOG_DETAIL = 'scenario.log_detail',

	TEMPLATE_CREATE_TEMPLATE = 'template.create_template',
	TEMPLATE_PATCH_TEMPLATE = 'template.patch_template',
	TEMPLATE_LOAD_TEMPLATE_BLUEPRINT = 'template.load_template_blueprint',
	TEMPLATE_LOAD_SCENARIO_BLUEPRINT = 'template.load_scenario_blueprint',
	TEMPLATE_SCENARIO_FROM_TEMPLATE = 'templates.scenario_from_template',

	TEMPLATER_PATCH_TEMPLATE = 'templater.patch_template',

	DESIGNER_SCENARIO_SWITCH = 'designer.scenario_switch',
	DESIGNER_SCENARIO_SWITCH_DONE = 'designer.scenario_switch_done',

	FIELDSET_ADD_DATA_STRUCTURE = 'fieldset.add_data_structure',

	FLASH_SHOW_TOAST = 'flash.show_toast',

	REDIRECT = 'app.redirect',

	DESIGNER_SET_NAME = 'designer.set_name',

	DESIGNER_GO_TO_LOG = 'desiqner.go_to_log',
	DESIGNER_GO_TO_DLQ_LOG = 'desiqner.go_to_dql_log',
	DESIGNER_GO_TO_DLQ_LOGS = 'desiqner.go_to_dql_logs',

	VISUALIZER_ADD = 'visualizer.add',
	VISUALIZER_EDIT = 'visualizer.edit',
	VISUALIZER_DELETE = 'visualizer.delete',

	DATASTORE_BROWSE = 'datastore.browse',

	TEMPLATE_WIZARD_TEAM = 'templatewizard.team',
	TEMPLATE_WIZARD_ORGANIZATION = 'templatewizard.organization',

	CREDENTIALS_REQUEST_INIT = 'credentials-request.init',
	CREDENTIALS_REQUEST_READY = 'credentials-request.ready',

	INPUTMANAGER_FOCUS_CHANGED = 'inputmanager.focus_changed',
}

export class ImtEvent<T, P = { [key: string]: any }> {
	constructor(public type: ImtEventsEnum, public data?: T, public error?: Error, public metadata?: P) {}
}

export class ImtEvents {
	public stream$: Subject<ImtEvent<any>> = new Subject();

	public dispatch<T extends { [key: string]: any }, P extends { [key: string]: any }>(
		event: ImtEventsEnum,
		data?: T,
		metadata?: P,
	) {
		this.stream$.next(new ImtEvent(event, data, undefined, metadata));
	}

	public dispatchError<P extends { [key: string]: any }>(event: ImtEventsEnum, error: Error, metadata?: P) {
		this.stream$.next(new ImtEvent(event, undefined, error, metadata));
	}

	public on$(event: ImtEventsEnum) {
		return this.stream$.pipe(filter((imtEvent) => imtEvent.type === event));
	}

	public once$(event: ImtEventsEnum) {
		return this.stream$.pipe(
			filter((imtEvent) => imtEvent.type === event),
			take(1),
		);
	}
}

// temporary
if (!(window as any).imtEvents) {
	(window as any).imtEvents = new ImtEvents();
}

import { Injectable } from '@angular/core';
import { StateFacadeAbstract } from '@imt-web-zone/core/util-state-facade';
import { setActiveOrganizationTeam } from './session.actions';
import { SESSION_STATE_TOKEN } from './session.symbols';

@Injectable({ providedIn: 'root' })
export class SessionFacade extends StateFacadeAbstract<typeof SESSION_STATE_TOKEN, unknown> {
	// `SessionState` doesn't have related service.
	protected service: unknown;

	constructor() {
		super(SESSION_STATE_TOKEN);
	}

	public setActiveOrganizationTeam$(payload: { isOrganization: boolean; id: string | null }) {
		return this.store.dispatch$(setActiveOrganizationTeam(payload));
	}
}

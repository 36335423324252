import { inject, Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, catchError, map, mergeMap, take, forkJoin, filter, finalize, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { GuardOptionsInterface } from './entity-guard-options.decorator';
import {
	EntityGuardArgs,
	EntityGuardError,
	EntityGuardErrorFn,
	getGuardOptionsSymbol,
	EntityGuardRunAfter,
	TruthySelector,
} from './internals';

function errorHandler$<T>(onError: EntityGuardErrorFn<T>, args: EntityGuardArgs<T>) {
	if (onError) {
		return onError(args);
	}
	return of(false);
}

function isAvailable$(source$: Observable<any>) {
	return source$.pipe(
		filter((res) => !!res),
		map(() => true),
		take(1),
	);
}

/**
 *
 * creates proxy of the facade instance and its methods
 */
function createProxyFacade<T>(facade: T, injector: Injector) {
	return new Proxy(facade as object, {
		get(target, property, receiver) {
			let prop;
			if (typeof target[property] === 'function') {
				prop = createMethodProxy(target, property, injector);
			} else {
				prop = Reflect.get(target, property, receiver);
			}
			return prop;
		},
	}) as T;
}

/**
 *
 * create proxy of facade method decorated with @EntityGuardOptions(GuardOptionsInterface)
 * and add additional behaviour defined by the passed config by the decorator
 *
 * options.runAfter - the original method is resolved only after all passed selectors return truthy value
 */
function createMethodProxy(target: object, property: string | symbol, injector: Injector) {
	const origMethod = target[property];
	const store = injector.get(Store);

	return new Proxy(origMethod, {
		apply: (target, thisArg, argumentsList) => {
			const methodOptions: GuardOptionsInterface = thisArg[getGuardOptionsSymbol(String(property))];
			if (typeof methodOptions === 'undefined') {
				return origMethod.apply(thisArg, argumentsList);
			}

			const runAfter = methodOptions?.runAfter$ || methodOptions?.runAfter;

			if (runAfter) {
				const selectors = runAfter instanceof Array ? runAfter : [runAfter];
				let runAfterObservables$: Array<Observable<boolean>>;

				if (methodOptions?.runAfter$) {
					runAfterObservables$ = selectors.map((cb: EntityGuardRunAfter) => isAvailable$(cb(injector)));
				} else {
					runAfterObservables$ = selectors.map((selector: TruthySelector) =>
						isAvailable$(store.select(selector)),
					);
				}
				return forkJoin(runAfterObservables$).pipe(
					mergeMap(() => {
						return origMethod.apply(thisArg, argumentsList);
					}),
				);
			}
			return origMethod.apply(thisArg, argumentsList);
		},
	});
}

/**
 *
 * @param Facade
 * @param guardFn canActivate fn
 * @param onError callback performed on error
 */
export const entityGuard = <T, K>(
	Facade: Type<T>,
	guardFn: ({
		facade,
		route,
		state,
	}: {
		facade: T;
		route: ActivatedRouteSnapshot;
		state: RouterStateSnapshot;
		router: Router;
	}) => Observable<unknown | UrlTree>,
	onError?: EntityGuardErrorFn<K>,
) => {
	return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
		const facade = inject(Facade);
		const router = inject(Router);
		const injector = inject(Injector);
		let proxyFacade = createProxyFacade(facade, injector);
		return guardFn({ facade: proxyFacade, route, state, router }).pipe(
			map((res) => {
				if (res instanceof UrlTree) {
					return res;
				}
				return !!res;
			}),
			catchError((e) => {
				// do not ignore internal errors
				if (e instanceof EntityGuardError) {
					return throwError(() => e);
				}
				return errorHandler$(onError, { errorAction: e, router, route, state, injector });
			}),
			finalize(() => {
				proxyFacade = null;
			}),
		);
	};
};

// todo check this: https://dev.to/dzinxed/a-new-way-of-ordering-guards-in-angular-24do
// NG0203 error
// export function orderedAsyncGuards(
// 	guards: Array<ReturnType<typeof entityGuard>>
// ) {
// 	return (route, state) => {
// 		// Convert an array into an observable.
// 		return from(guards).pipe(
// 			// For each guard, fire canActivate and wait for it
// 			// to complete.
// 			concatMap((guard) => guard(route, state)),
// 			// Don't execute the next guard if the current guard's
// 			// result is not true.
// 			takeWhile((value) => value === true, /* inclusive */ true),
// 			// Return the last guard's result.
// 			last()
// 		);
// 	};
// }

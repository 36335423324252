import { isDevMode } from '@angular/core';
import { Store as NgxsStore } from '@ngxs/store';
import { defer } from 'rxjs';

/**
 * Interface that extends `Store` from `'@ngxs/store'` with the
 * `dispatch$`, `select$` and `selectOnce$` methods and deprecates
 * the three without `$` postfix.
 */
export interface FacadeStore extends NgxsStore {
	/**
	 * @deprecated
	 * Use `dispatch$` instead.
	 *
	 * Example:
	 *
	 * ```ts
	 * this.store.dispatch$(...)
	 * ```
	 */
	dispatch: NgxsStore['dispatch'];
	/**
	 * @deprecated
	 * Use `select$` instead.
	 *
	 * Example:
	 *
	 * ```ts
	 * this.store.select$(...)
	 * ```
	 */
	select: NgxsStore['select'];
	/**
	 * @deprecated
	 * Use `selectOnce$` instead.
	 *
	 * Example:
	 *
	 * ```ts
	 * this.store.selectOnce$(...)
	 * ```
	 */
	selectOnce: NgxsStore['selectOnce'];

	/**
	 * Dispatches event(s).
	 */
	dispatch$: NgxsStore['dispatch'];
	/**
	 * Selects a slice of data from the store.
	 */
	select$: NgxsStore['select'];
	/**
	 * Select one slice of data from the store.
	 */
	selectOnce$: NgxsStore['selectOnce'];
}

/**
 * Creates proxy of the `NgxsStore` and returns it as a `FacadeStore` which introduces 3 new methods:
 *
 * 1/ `dispatch$` - Unlike original `dispatch`, it must be subscribed to dispatch the action.
 *
 * 2/ `select$` - Works same as original `select`, but since it returns Observable it makes sense to add `$` postfix.
 *
 * 3/ `selectOnce$` - Same reason as for the new `select$` method.
 */
export const createFacadeStoreProxy = (ngxsStore: NgxsStore) => {
	return new Proxy(ngxsStore, {
		get: (target: NgxsStore, property: keyof FacadeStore, receiver) => {
			if (['dispatch$', 'select$', 'selectOnce$'].includes(property)) {
				const originalPropertyName = property.replace(/\$$/s, '');

				if (property === 'dispatch$') {
					return new Proxy(target[originalPropertyName as 'dispatch'], {
						apply: (target, thisArg, argumentsList) => {
							// Defer the dispatch method, so it doesn't dispatch the action if it's not subscribed.
							return defer(() => target.apply(thisArg, [argumentsList[0]]));
						},
					});
				}

				// Preserve original behavior of the `select` and `selectOnce` methods.
				return Reflect.get(target, originalPropertyName, receiver);
			}

			// Throw warning for deprecated properties with the `$` postfix.
			if (isDevMode() && ['dispatch', 'select', 'selectOnce'].includes(property)) {
				const additionalWarnMessage =
					property === 'dispatch'
						? `Difference between the two is that Store#dispatch$() must be subscribed to dispatch the action.

							!!!IMPORTANT NOTE!!!

							Do not forget to subscribe method using Store#dispatch$().

							EXAMPLE:

							// hooks.facade.ts
							export class HooksFacade extends CollectionFacadeAbstract<typeof HOOKS_STATE_TOKEN, HooksService> {
								...

								public enableDisableHook$(hookId: string, value: boolean) {
									return this.store.dispatch$(this.service.enableDisableHook$(hookId, value ? 'enable' : 'disable'));
								}
							}

							// hooks.component.ts
							export class HooksComponent {
								...

								public onEnabledChange(value: boolean, hook: HookModel) {
									this.hooksFacade.enableDisableHook$(hook.id, value).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
								}
							}
						`
						: 'The dollar sign ($) indicates that the function returns an Observable, so it must be subscribed.';

				console.warn(`
                    !!!WARNING!!!DEPRECATION!!!WARNING!!!
                    =====================================
                    [StateFacadeAbstract]
                    
                    Please do not use Store#${property}() within the facade!

                    Use Store#${property}$() instead!

                    EXAMPLE:

                    this.store.${property}$(...)

                    ${additionalWarnMessage}
                    =====================================
                    !!!WARNING!!!DEPRECATION!!!WARNING!!!
                `);

				return Reflect.get(target, property, receiver);
			}

			return Reflect.get(target, property, receiver);
		},
	}) as FacadeStore;
};

import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';
import { EntityState, EntityType } from '../internal/entity-state';
import { generateActionObject } from '../internal/internal';
import { EntityActionType, Payload } from './type-alias';

export type EntityCreateOrReplaceAction<T> = Payload<T | Array<T>>;

export class CreateOrReplace<T extends Record<string, any>> {
	/**
	 * Generates an action that will add the given entities to the state.
	 * If an entity with the ID already exists, it will be overridden.
	 * In all cases it will overwrite the ID value in the entity with the calculated ID.
	 * @param target The targeted state class
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see CreateOrReplace#constructor
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		payload: T extends CollectionStateModel<any> ? EntityType<T> | Array<EntityType<T>> : T | Array<T>,
		context: string,
	) {
		return generateActionObject(EntityActionType.CreateOrReplace, target, payload).setContext(context);
	}
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApiConfigData, ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';

import { map } from 'rxjs';

/** Guard function that handles redirecting to the maintenance page when conditions are met. */
export const maintenanceModeRedirect = () => {
	// when config.generalSettings.maintenanceModeEnabled is `true` redirect to the maintenance page
	return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		const router = inject(Router);
		const apiConfigFacade = inject(ApiConfigFacade);

		return apiConfigFacade.config$.pipe(
			map((apiConfig: ApiConfigData) => {
				const allowedUrls = [
					'/admin',
					'/admin/login',
					'/login',
					'/logout',
					'leave/admin',
					'/sso',
					'/maintenance-mode',
				].some((url) => state.url.startsWith(url));
				if (
					apiConfig.generalSettings?.maintenanceModeEnabled &&
					route.params['navigate'] !== '/logout' &&
					!allowedUrls
				) {
					return router.createUrlTree(['maintenance-mode']);
				}

				return true;
			}),
		);
	};
};

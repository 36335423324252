import { Provider, Type, inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { BehaviorSubject, Observable, filter, map, of, startWith, switchMap, take } from 'rxjs';

import { ServiceInit, debugLogger } from '@imt-web-zone/core/util-core';
import { UtilURL } from '@imt-web-zone/shared/util';

import {
	AssetsDomain,
	AssetsDomainsConfig,
	AssetPathOptions,
	AssetsServiceInterface,
	ASSETS_PUBLIC_PATH,
	ASSETS_SERVICE,
	ASSETS_VERSION_PLACEHOLDER,
} from './assets';

const debug = debugLogger.create('assetsService');

/**
 * Uses given and existing assets service (that extends abstract `AssetsService`)
 * as a provider for `ASSETS_SERVICE` injection token.
 */
export const provideAssetsService = <D extends AssetsDomain, T extends AssetsService<D>>(
	service: Type<T>,
): Provider => {
	return {
		provide: ASSETS_SERVICE,
		useExisting: service,
	};
};

/**
 * Holds an information about all the domains for the static assets and serves as a prescription for the services
 * (which are extending this service) that will be a single source for getting assets, either application assets or
 * assets for the dependencies (libraries, etc.).
 *
 * All the services that extend `AssetsService` service should also respect `AssetsServiceInterface<T>`
 * and should provide their own, exact set of assets domains (generic type `T` in AssetsServiceInterface<T>).
 */
export abstract class AssetsService<T extends AssetsDomain> implements ServiceInit, AssetsServiceInterface<T> {
	/**
	 * Current configuration of the assets domains updated either by `provideAssetsPublicPath()` or during
	 * service initialization.
	 */
	protected get assetsDomains(): AssetsDomainsConfig {
		return this._assetsDomains;
	}
	private _assetsDomains: AssetsDomainsConfig = {};

	private appBaseHref = inject(APP_BASE_HREF, { optional: true });
	private assetsPublicPath = inject(ASSETS_PUBLIC_PATH, { optional: true });

	/** Whether service was already initialized */
	private initialized$ = new BehaviorSubject<boolean>(false);

	constructor() {
		// When class is instantiated, take provided public path and assign it under given assets domain. This will
		// ensure that assets on the public path will be available even before service initialization, which is crucial
		// to have working app (loading of theme files, etc.) even if initialization fails.
		if (this.assetsPublicPath) {
			const { assetsPublicPath, assetsPublicPathDomain } = this.assetsPublicPath;

			this.updateAssetsDomains({ [assetsPublicPathDomain]: assetsPublicPath });
		}
	}

	/**
	 * Initializes service by updating assets domains which will be used for getting assets paths on the particular
	 * domain by calling either `assetPath()` or `assetPath$()`.
	 *
	 * Domain that is also a public path (holds application assets) have to be provided via `provideAssetsPublicPath()`,
	 * because it's too late to provide public path here in order to have functional application.
	 */
	public initialize(assetsDomains: AssetsDomainsConfig = {}): Promise<void> {
		debug(`Initializing...`);

		// No need to update assets domains if object is empty.
		if (!assetsDomains || !Object.keys(assetsDomains).length) {
			return this.finishInitialization();
		}

		this.updateAssetsDomains(assetsDomains);

		return this.finishInitialization();
	}

	/**
	 * Updates configuration for assets domain with preserving existing assets domains.
	 */
	public updateAssetsDomains(assetsDomains: AssetsDomainsConfig): AssetsDomainsConfig {
		// Merge current configuration with provided one.
		this._assetsDomains = { ...this._assetsDomains, ...assetsDomains };

		debug(`Updating... assets domains`, this.assetsDomains);

		return this._assetsDomains;
	}

	/**
	 * Returns correct path for the asset on the given `relativePath` and `domain`. Domain have to be provided either
	 * during initialization in `AssetsDomainsConfig` or as a public path via `provideAssetsPublicPath()`.
	 *
	 * 1/ Providing assets domain during initialization:
	 * ```ts
	 * baseDependencyInit(
	 * 	ZoneAssetsService,
	 * 	async () => {
	 * 		return {
	 * 			[ZoneAssetsDomain.DominoElements]: 'https://cdn.make.com/file/domino-elements/1.2.3/',
	 * 		};
	 * 	},
	 * ),
	 * ```
	 *
	 * 2/ Providing assets domain via `provideAssetsPublicPath()`:
	 *
	 * ```ts
	 * provideAssetsPublicPath<ZoneAssetsDomain>(ZoneAssetsDomain.Zone
	 * ```
	 */
	public assetPath({ domain, relativePath }: AssetPathOptions<T>): string {
		return this.resolveAssetPath({ domain, relativePath });
	}

	/**
	 * Emits correct path for the asset on the given `relativePath` and `domain` right after service is fully
	 * initialized. This will ensure, that requested asset path will be correctly resolved even if this method
	 * is called before initialization (before assets domains are set).
	 */
	public assetPath$({ domain, relativePath }: AssetPathOptions<T>): Observable<string> {
		const resolveAssetPathFn = () => of(this.resolveAssetPath({ domain, relativePath }));

		// If requested domain is public path, do not wait for initialization. It was set when
		// service was instantiated.
		return domain === this.assetsPublicPath?.assetsPublicPathDomain
			? resolveAssetPathFn()
			: this.whenInitialized$().pipe(switchMap(() => resolveAssetPathFn()));
	}

	/**
	 * Replaces `ASSETS_VERSION_PLACEHOLDER` (`{{version}}` as default) version string `placeholder`
	 * in the given `url` (for example in `/path/{{version}}`) with the given `version`.
	 *
	 * Automatically trims slashes out of the `version` string.
	 *
	 * Return original URL if `placeholder` is missing.
	 */
	public static replaceVersionInURL(url: string, version: string, placeholder = ASSETS_VERSION_PLACEHOLDER): string {
		if (!url?.includes(placeholder)) {
			return url;
		}

		return url.replace(new RegExp(placeholder, 'gi'), version.replace(/^\/|\/$/g, ''));
	}

	/**
	 * Emits single emission once `AssetsService` is initialized.
	 * If `AssetsService` is already initialized, emission is immediately emitted.
	 */
	protected whenInitialized$(): Observable<void> {
		return this.initialized$.asObservable().pipe(
			startWith(this.initialized$.value),
			filter((initialized) => initialized === true),
			map(() => void 0),
			take(1),
		);
	}

	/**
	 * Resolves final asset path based on the current assets domain configuration and application base href.
	 */
	private resolveAssetPath({ domain, relativePath }: AssetPathOptions<T>): string {
		// Get the domain URL from the assets domains configuration.
		const domainUrl = domain ? this._assetsDomains[domain] : '';

		// If asset domain is not in the configuration, use current `window.location.origin` and `appBaseHref`.
		if (!domainUrl) {
			return UtilURL.urlJoin(window.location.origin, this.appBaseHref || '', relativePath || '');
		}

		// Note that `appBaseHref` is not used here because `domainUrl` should be an absolute and final path of
		// the assets on the given `domain`.
		return UtilURL.urlJoin(domainUrl, relativePath || '');
	}

	/**
	 * Marks service as initialized, which triggers all calls of the `assetPath$()` method.
	 */
	private async finishInitialization(): Promise<void> {
		debug(`Initialized...`);

		this.initialized$.next(true);
		return Promise.resolve();
	}
}

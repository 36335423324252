import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { getClassTokenProviders, ClassTokenProvidersConfig } from '@imt-web-zone/shared/util';
import { ImtUiService, ImtUiConfig, DEFAULT_CONFIG } from './imt-ui.service';
import { ImtUiAssetsInjector, ImtAssetsInjector } from '@imt-web-zone/shared/util-assets-injector';

export const IMT_UI_CONFIG_TOKEN = new InjectionToken<ImtUiConfig>('IMT_UI_CONFIG_TOKEN');

export function imtUiServiceProvider(options?: ImtUiConfig): ImtUiService {
	const service = new ImtUiService();
	if (options) {
		service.config = options;
	}

	return service;
}

type ImtUiOptions = ImtUiConfig & ClassTokenProvidersConfig<ImtUiAssetsInjector, ImtAssetsInjector>;

@NgModule({
	providers: [ImtUiService, ImtUiAssetsInjector],
})
export class ImtUiModule {
	public static forRoot(options: ImtUiOptions = DEFAULT_CONFIG): ModuleWithProviders<ImtUiModule> {
		return {
			ngModule: ImtUiModule,
			providers: [
				options.langService,
				options.authUserData,
				{
					provide: IMT_UI_CONFIG_TOKEN,
					useValue: options,
				},
				{
					provide: ImtUiService,
					useFactory: imtUiServiceProvider,
					deps: [IMT_UI_CONFIG_TOKEN],
				},
				// provide an option to expose `ImtUiAssetsInjector` via token, so then it can be used
				// in lib types which are not allowed to use this "type:ui" lib
				...getClassTokenProviders(options),
			],
		};
	}
}

import { Injectable, inject } from '@angular/core';
import { FormlyConfig } from '@ngx-formly/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class FormlyValidationsService {
	private formlyConfig = inject(FormlyConfig);
	private transloco = inject(TranslocoService);

	constructor() {
		this.init();
	}

	public init() {
		this.transloco.langChanges$.subscribe(() => {
			this.registerMessages();
		});

		this.registerMessages();
		this.registerValidators();
	}

	private registerMessages() {
		this.formlyConfig.addValidatorMessage('required', (err, field) =>
			this.transloco.selectTranslate('validations.required', { field: field.templateOptions?.label }),
		);

		this.formlyConfig.addValidatorMessage('min', (err, field) =>
			this.transloco.selectTranslate('validations.numberhigher', { value: err.min }),
		);

		this.formlyConfig.addValidatorMessage('max', (err, field) =>
			this.transloco.selectTranslate('validations.numberlower', { value: err.max }),
		);

		this.formlyConfig.addValidatorMessage('minlength', (err, field) =>
			this.transloco.selectTranslate('validations.lengthleast', { value: err.requiredLength }),
		);

		this.formlyConfig.addValidatorMessage('maxlength', (err, field) =>
			this.transloco.selectTranslate('validations.lengthmost', { value: err.requiredLength }),
		);

		this.formlyConfig.addValidatorMessage('pwdlower', (err, field) =>
			this.transloco.selectTranslate('validations.pwdlower'),
		);
		this.formlyConfig.addValidatorMessage('pwdupper', (err, field) =>
			this.transloco.selectTranslate('validations.pwdupper'),
		);
		this.formlyConfig.addValidatorMessage('pwdlength', (err, field) =>
			this.transloco.selectTranslate('validations.pwdlength'),
		);
		this.formlyConfig.addValidatorMessage('pwdnumber', (err, field) =>
			this.transloco.selectTranslate('validations.pwdnumber'),
		);
		this.formlyConfig.addValidatorMessage('pwdspecial', (err, field) =>
			this.transloco.selectTranslate('validations.pwdspecial'),
		);
		this.formlyConfig.addValidatorMessage('pwdspecial', (err, field) =>
			this.transloco.selectTranslate('validations.pwdspecial'),
		);
	}

	private registerValidators() {
		this.formlyConfig.setValidator({
			name: 'pwdlower',
			validation: (control, field) => (/[a-z]/.test(control.value) ? null : { pwdlower: true }),
		});

		this.formlyConfig.setValidator({
			name: 'pwdupper',
			validation: (control, field) => (/[A-Z]/.test(control.value) ? null : { pwdupper: true }),
		});

		this.formlyConfig.setValidator({
			name: 'pwdnumber',
			validation: (control, field) => (/[0-9]/.test(control.value) ? null : { pwdnumber: true }),
		});

		this.formlyConfig.setValidator({
			name: 'pwdspecial',
			validation: (control, field) => (/[^A-Za-z0-9]/.test(control.value) ? null : { pwdspecial: true }),
		});

		this.formlyConfig.setValidator({
			name: 'pwdlength',
			validation: (control, field) => (control.value.length >= 9 ? null : { pwdlength: true }),
		});
	}
}

export enum UiStaticMessageType {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
	BRAND = 'brand',
}

export enum UiStaticMessageButtonPosition {
	RIGHT = 'right',
	BOTTOM = 'bottom',
}

export enum UiNotificationButtonType {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
	BRAND = 'brand',
}

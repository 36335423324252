import { CollectionStateModel, getInitialCollectionState, SortingOptions } from '@imt-web-zone/shared/util-store';
import { AppNames } from '@imt-web-zone/shared/core';

export const getTemplatesSortConfig = (appName?: AppNames): SortingOptions<TemplateModel> => {
	const sortByKey = appName === AppNames.ZONE_HQ_ADMIN ? 'templateId' : 'id';

	return {
		sortByKey,
		sortCondition: (a, b) => +b[sortByKey] - +a[sortByKey],
	};
};

export class TemplateModel {
	public isPublic: boolean;
	constructor(
		public id: string,
		public templateId: string,
		public approvedId: string,
		public description: string,
		public name: string,
		public publishedId: string,
		isPublic: boolean,
		public published: Date,
		public teamId: string,
		public usedApps: Array<string>,
		public isDeleted: boolean,
		public teamName: string,
		public publicUrl: string,
		public requestedApproval: boolean,
		public approved: Date,
		public publishedName: string,
		public approvedName: string,
		public zoneId: string,
		public urlTemplateId: string,
	) {
		this.isPublic = isPublic;
	}
}

export function getInitialTemplatesStateModel(): CollectionStateModel<TemplateModel> {
	return getInitialCollectionState<TemplateModel>();
}

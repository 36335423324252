import { HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CsrfProtectionService {
	private tokenExtractor = inject(HttpXsrfTokenExtractor);

	// This is x-xsrf-token header, but since we don't want an attacker to
	// associate this header with csrf protection, we gave it a misleading name.
	public readonly xsrfHeaderName = 'x-android-device';
	public readonly cookieName = 'XSRF-TOKEN';

	public apply(request: HttpRequest<any>): HttpRequest<any> {
		const hashedToken = this.getHashedToken();

		if (!hashedToken) {
			return request;
		}

		return request.clone({
			headers: request.headers.set(this.xsrfHeaderName, hashedToken),
		});
	}

	public getHashedToken(): string | null {
		const token = this.tokenExtractor.getToken();

		if (!token) {
			return null;
		}

		return this.hashString(token);
	}

	private hashString(stringForHash: string): string {
		let h = 0;
		for (let i = 0; i < stringForHash.length; i++) {
			h = (Math.imul(31, h) + stringForHash.charCodeAt(i)) | 0;
		}
		return h.toString(16);
	}
}

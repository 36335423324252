import { Selector } from '@ngxs/store';
import { CollectionState, CollectionStateModel } from '@imt-web-zone/shared/util-store';
import { Params } from '@angular/router';
import { DLQS_STATE_TOKEN } from '@imt-web-zone/zone/state-dlqs';
import { DLQS_LOGS_STATE_TOKEN, DlqsLogModel } from '@imt-web-zone/zone/state-dlqs-logs';

// todo will be resolved by moving logic to facade layer
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SCENARIOS_STATE_TOKEN } from '@imt-web-zone/zone/state-scenarios';
import { RouterSelectors } from '@imt-web-zone/shared/util-store';
import {
	SCENARIO_LOGS_STATE_TOKEN,
	ScenarioLogModel,
	ListScenariosLogsPayload,
	ScenarioLogsType,
} from '@imt-web-zone/zone/state-scenario-logs';

export class LogsSelectors {
	@Selector([SCENARIO_LOGS_STATE_TOKEN, CollectionState.getActiveId(SCENARIOS_STATE_TOKEN)])
	public static currentlyRunningLogs(state: CollectionStateModel<ScenarioLogModel>, scenarioId: string) {
		return mapLogs(state, (l) => l.scenarioId === scenarioId && !l.status && isExecution(l));
	}

	@Selector([DLQS_LOGS_STATE_TOKEN, RouterSelectors.queryParams, CollectionState.getActiveId(DLQS_STATE_TOKEN)])
	public static dlqLogs(
		state: CollectionStateModel<DlqsLogModel>,
		queryParams: ListScenariosLogsPayload['params'],
		dlqId: string,
	) {
		if (state && state.ids && state.entities) {
			return mapLogs<DlqsLogModel>(
				state,
				(entity) => filterLogs(entity, queryParams) && entity.dlqId === dlqId,
				sortLogs,
			);
		}
		return null;
	}

	@Selector([SCENARIO_LOGS_STATE_TOKEN, CollectionState.getActiveId(SCENARIOS_STATE_TOKEN)])
	public static executions(state: CollectionStateModel<ScenarioLogModel>, scenarioId: string) {
		if (state && state.ids && state.entities) {
			return mapLogs<ScenarioLogModel>(
				state,
				(entity) => entity.scenarioId === scenarioId && (entity.status > 0 || !isExecution(entity)),
				sortLogs,
			);
		}
		return null;
	}
}

function mapLogs<T = ScenarioLogModel | DlqsLogModel>(
	{ entities, ids }: CollectionStateModel<T>,
	filterPredicate: (log: T) => boolean,
	sortFn?: (a: T, b: T) => 1 | 0 | -1,
) {
	let result = ids.filter((id) => filterPredicate(entities[id]));

	if (sortFn) {
		result = result.sort((id1, id2) => sortFn(entities[id1], entities[id2]));
	}

	return result.map((id) => entities[id]);
}

function filterLogs(log: ScenarioLogModel | DlqsLogModel, queryParams: Params) {
	const isSuccessExecution = log.status === 1;

	if (!queryParams) {
		queryParams = {};
	}
	return (
		(!queryParams.status || log.status === +queryParams.status) &&
		(!queryParams.from || log.timestamp.getTime() >= +queryParams.from) &&
		(!queryParams.to || log.timestamp.getTime() <= +queryParams.to) &&
		(queryParams.showCheckRuns ||
			!isSuccessExecution ||
			(isSuccessExecution && (log.operations !== 1 || log.transfer !== 0)))
	);
}

function sortLogs(a: ScenarioLogModel | DlqsLogModel, b: ScenarioLogModel | DlqsLogModel) {
	return a.timestamp < b.timestamp ? 1 : a.timestamp === b.timestamp ? 0 : -1;
}

function isExecution(log: ScenarioLogModel) {
	return log.type === ScenarioLogsType.MANUAL || log.type === ScenarioLogsType.AUTO;
}

import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

export type AssetsDomain<T extends string = string> = T;

export interface AssetsDomainsConfig {
	[domain: AssetsDomain]: string;
}

export interface AssetPathOptions<T extends string = string> {
	domain?: AssetsDomain<T>;
	relativePath: string;
}

export interface AssetsServiceInterface<T extends AssetsDomain> {
	assetPath({ domain, relativePath }: AssetPathOptions<T>): string;
	assetPath$({ domain, relativePath }: AssetPathOptions<T>): Observable<string>;
}

export interface AssetsPublicPath<T extends AssetsDomain> {
	assetsPublicPathDomain: T;
	assetsPublicPath: string;
}

export const ASSETS_PUBLIC_PATH_DATA_ATTR_NAME = 'data-public-path';
export const ASSETS_PUBLIC_PATH_DATA_ATTR_PLACEHOLDER = '{PUBLIC_PATH}';

export const ASSETS_VERSION_PLACEHOLDER = '{{version}}';

export const ASSETS_SERVICE = new InjectionToken<AssetsServiceInterface<AssetsDomain>>('ASSETS_SERVICE');

export const ASSETS_PUBLIC_PATH = new InjectionToken<AssetsPublicPath<AssetsDomain>>('ASSETS_PUBLIC_PATH');

import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	ElementRef,
	Injector,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import {
	CdkPortalOutlet,
	ComponentPortal,
	ComponentType,
	DomPortal,
	Portal,
	PortalModule,
	TemplatePortal,
} from '@angular/cdk/portal';
import { NgIf, NgTemplateOutlet } from '@angular/common';

export type UiEmbedContentType<C = any> = string | HTMLElement | ElementRef | TemplateRef<any> | ComponentType<C>;

@Component({
	selector: 'dmo-embed-content',
	standalone: true,
	imports: [PortalModule, NgIf, NgTemplateOutlet],
	templateUrl: './ui-embed-content.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class UiEmbedContentComponent<C = any> implements OnChanges {
	private viewContainerRef = inject(ViewContainerRef);
	private cdr = inject(ChangeDetectorRef);
	private injector = inject(Injector);

	@ViewChild('textContent') public textContent!: ElementRef;
	@ViewChild('portalOutlet', { read: CdkPortalOutlet }) public portalOutlet!: CdkPortalOutlet;

	@Input()
	public set content(content: UiEmbedContentType | undefined) {
		this._content = content;
		this.isContentPlainString = typeof content === 'string';
		this.updatePortal();
	}

	public get content() {
		return this._content;
	}

	public get componentInstance() {
		return this.componentRef?.instance;
	}

	public get componentRef() {
		if (this.portal instanceof ComponentPortal) {
			const attachedRef = this.portalOutlet?.attachedRef;
			if (attachedRef instanceof ComponentRef) {
				return attachedRef;
			}
		}

		return undefined;
	}

	private _content?: UiEmbedContentType | undefined;

	@Input() public contentTplContext?: C;

	/**
	 * @ignore
	 */
	public portal?: Portal<any>;
	public isContentPlainString = false;

	public ngOnChanges(changes: SimpleChanges) {
		if (changes['contentTplContext']) {
			const attached = this.portalOutlet?.attachedRef;
			if (this.portal instanceof TemplatePortal) {
				/**
				 * This doesn't seem to have any effect and the context needs to be updated in
				 * `CdkPortalOutlet.attachedRef`.
				 */
				this.portal.context = this.contentTplContext;

				if (attached && !(attached instanceof ComponentRef)) {
					attached.context = this.contentTplContext;
				}
			}

			if (this.portal instanceof ComponentPortal && attached instanceof ComponentRef) {
				this.updateComponentInputs();
			}
		}
	}

	private updatePortal() {
		if (this.portal?.isAttached) {
			this.portal.detach();
		}

		const content = this._content;
		if (!content) {
			return;
		}

		if (this.isContentPlainString) {
			if (!this.textContent) {
				return;
			}
			this.portal = new DomPortal(this.textContent);
		} else {
			if (content instanceof TemplateRef) {
				this.portal = new TemplatePortal(content, this.viewContainerRef, this.contentTplContext);
			} else if (content instanceof ElementRef || content instanceof HTMLElement) {
				this.portal = new DomPortal(content);
			} else {
				this.portal = new ComponentPortal(content as ComponentType<any>, this.viewContainerRef, this.injector);
				this.updateComponentInputs();
			}
			this.cdr.detectChanges();
		}
	}

	private updateComponentInputs() {
		const attachedRef = this.portalOutlet?.attachedRef;
		if (attachedRef instanceof ComponentRef) {
			for (const inputName in this.contentTplContext) {
				attachedRef.setInput(inputName, this.contentTplContext[inputName]);
			}
		}
	}
}

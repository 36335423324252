import { Directive, ElementRef, HostListener, InjectionToken, Input, TemplateRef, inject } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

import { SHOW_DROPDOWN_ARROW } from '@imt-web-zone/make-design-system/ui-button';

import { UiDropdownBaseDirective } from './ui-dropdown-base.directive';
import { UiDropdownRef } from './ui-dropdown-ref';

export const DROPDOWN_DEFAULT_OFFSET = new InjectionToken<number>('dropdown-default-offset');

/**
 * Directive that after clicking its anchor element shows content in a dropdown overlay. Typically used with
 * `UiDropdownMenuComponent` as its content.
 */
@Directive({
	selector: '[dmoDropdown]',
	standalone: true,
	exportAs: 'dmoDropdown',
	providers: [
		{
			provide: UiDropdownRef,
			useClass: UiDropdownRef,
		},
		{
			provide: SHOW_DROPDOWN_ARROW,
			useValue: true,
		},
	],
})
export class UiDropdownDirective extends UiDropdownBaseDirective {
	/**
	 * Content for the dropdown menu. In most use-cases it should contain `UiDropdownMenuComponent`.
	 */
	@Input('dmoDropdown') public content!: TemplateRef<any>;
	/**
	 * Anchor element different from the one where this directive is applied to.
	 */
	@Input('dmoDropdownAnchor') public anchor?: ElementRef<HTMLElement>;
	/**
	 * Custom `data-testid` attribute for the dropdown element created using CDK's Overlay.
	 */
	@Input('dmoDropdownDataCy') public dataCy = 'dmo-dropdown-content';
	/**
	 * Custom `data-testid` attribute for CDK's overlay element behind the dropdown.
	 */
	@Input('dmoDropdownOverlayHostDataCy') public overlayHostDataCy = 'cdk-overlay-host-via-dmo-dropdown';
	/**
	 * Overlay offset from the anchor element (from the edge where the overlay is touching the anchor element).
	 */
	@Input('dmoDropdownOverlayOffset') public overlayOffset?: number;

	/**
	 * Enable or disable selecting a menu item from the dropdown using Up/Down/Enter keys.
	 */
	@Input('dmoDropdownKeyboardControls') public set keyboardControls(keyboardControls: BooleanInput) {
		this._keyboardControls = coerceBooleanProperty(keyboardControls);
		if (this.overlayRef) {
			this.bindKeyboardControls();
		}
	}
	public get keyboardControls(): boolean {
		return this._keyboardControls;
	}

	/**
	 * By default, the dropdown hides after scrolling any parent `cdk-scrollable` container. Setting to `true` will keep
	 * the dropdown opened regardless.
	 */
	@Input('dmoDropdownStayOnScroll') public set stayOnScroll(stayOnScroll: BooleanInput) {
		this._stayOnScroll = coerceBooleanProperty(stayOnScroll);
		if (this.overlayRef) {
			this.overlayRef.updateScrollStrategy(this.createScrollStrategy());
		}
	}
	public get stayOnScroll(): boolean {
		return this._stayOnScroll;
	}

	/**
	 * Disable or enable dropdown (clicking the anchor element will not show the dropdown). This, however, won't change
	 * state of the anchor element.
	 */
	@Input('dmoDropdownDisabled') public set disabled(disabled: BooleanInput) {
		this._disabled = coerceBooleanProperty(disabled);
		if (this._disabled) {
			this.hideDropdown();
		}
		this.dropdownRef.setDisabled(this._disabled);
	}
	public get disabled(): boolean {
		return this._disabled;
	}

	/**
	 * Set whether the overlay should disappear after clicking outside the overlay element.
	 */
	@Input('dmoDropdownHideOnClickOutside') public set hideOnClickOutside(hideOnClickOutside: BooleanInput) {
		this._hideOnClickOutside = coerceBooleanProperty(hideOnClickOutside);
		this.useClickOutsideListener();
	}
	public get hideOnClickOutside(): boolean {
		return this._hideOnClickOutside;
	}

	/**
	 * @ignore
	 */
	@HostListener('click', ['$event']) public onClick(e: MouseEvent) {
		if (!this.disabled) {
			this.toggleDropdown();
		}
	}

	private _hideOnClickOutside = true;
	private _disabled = false;
	private _stayOnScroll = false;
	private _keyboardControls = true;

	protected dropdownDefaultOffset = inject(DROPDOWN_DEFAULT_OFFSET, { optional: true });
	protected elm = inject(ElementRef).nativeElement;
}

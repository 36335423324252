import { ChangeDetectionStrategy, Component, HostBinding, InjectionToken, OnInit, inject } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { IMT_UI_TRANSLATE_SERVICE_TOKEN, ImtUiLangService } from '@imt-web-zone/shared/util-imt-translate';

export const CONNECTED_TO_TOKEN = new InjectionToken('connectedTo');
export const PANEL_MENU_REF_TOKEN = new InjectionToken('panelMenuRef');

export interface UiPanelMenuRef {
	close: () => void;
}

@Component({
	template: `
		<button *ngIf="collapsable" class="panel-menu-option px-3" type="button" (click)="expandAll($event)">
			<i class="far fa-expand mr-2"></i>
			<span>{{ resources.expand }}</span>
		</button>
		<button *ngIf="collapsable" class="panel-menu-option px-3" type="button" (click)="collapseAll($event)">
			<i class="far fa-compress mr-2"></i>
			<span>{{ resources.collapse }}</span>
		</button>
		<button class="panel-menu-option px-3" type="button" (click)="refresh($event)">
			<i class="far fa-sync mr-2"></i>
			<span>{{ resources.refresh }}</span>
		</button>
	`,
	selector: 'ui-panel-menu',
	styleUrls: ['./ui-panel-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('openClose', [
			transition(':enter', [
				style({ 'max-height': '0px' }),
				animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ 'max-height': '200px' })),
			]),
			transition(':leave', [
				style({ 'max-height': '250px' }),
				animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ 'max-height': '0px' })),
			]),
		]),
	],
})
export class UiPanelMenuComponent implements OnInit {
	private connectedTo = inject<Element>(CONNECTED_TO_TOKEN);
	private ref = inject<UiPanelMenuRef>(PANEL_MENU_REF_TOKEN);
	private langService = inject<ImtUiLangService>(IMT_UI_TRANSLATE_SERVICE_TOKEN);

	public resources: {
		refresh?: string;
		collapse?: string;
		expand?: string;
	} = {};

	public collapsable = true;

	private forman?: any | null = null;

	@HostBinding('@openClose') protected _openCloseBinding: unknown;

	public ngOnInit() {
		this.forman = this.connectedTo.closest('.i-panel, imt-panel, ngb-modal-window')?.querySelector('imt-forman');
		this.resources = {
			collapse: this.langService.translate('imt_ui_panel_menu.collapseAll'),
			expand: this.langService.translate('imt_ui_panel_menu.expandAll'),
			refresh: this.langService.translate('imt_ui_panel_menu.refreshForm'),
		};
		this.collapsable = this.forman?.tree || false;
	}

	public expandAll(event: MouseEvent) {
		this.forman?.expand();
		this.ref.close();
		event.stopPropagation();
	}

	public collapseAll(event: MouseEvent) {
		this.forman?.collapse();
		this.ref.close();
		event.stopPropagation();
	}

	public refresh(event: MouseEvent) {
		this.forman?.rebuild();
		this.ref.close();
		event.stopPropagation();
	}
}

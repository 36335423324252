import { EntityState } from './entity-state';
import { Type } from '@angular/core';
import { NoActiveEntityError } from './errors';
import { EntityStateModel } from './models';
import { StateToken } from '@ngxs/store';

/**
 * type alias for javascript object literal
 */
export interface HashMap<T> {
	[id: string]: T;
}

export const NGXS_META_KEY = 'NGXS_META';

class ReflectedAction<T> {
	public payload: T;
	public typeContext?: string;
	constructor(public data: T) {
		this.payload = data;
	}

	public setContext(text: string) {
		this.typeContext = text;
		return this;
	}
}
/**
 * This function generates a new object for the ngxs Action with the given fn name
 * @param fn The name of the Action to simulate, e.g. "Remove" or "Update"
 * @param store The class of the targeted entity state, e.g. ZooState
 * @param payload The payload for the created action object
 */
export function generateActionObject<T extends Record<string, any>>(
	fn: string,
	store: Type<EntityState<T>> | StateToken<T>,
	payload?: any,
) {
	const name = (store as any)[NGXS_META_KEY]?.path || (store as any)['getName']();
	const obj = new ReflectedAction(payload);
	//const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	Reflect.getPrototypeOf(obj).constructor['type'] = `[${name}] ${fn}`;
	return obj;
}

/**
 * Utility function that returns the active entity of the given state
 * @param state the state of an entity state
 */
export function getActive<T>(state: EntityStateModel<any>): T | null {
	if (state && state.entities && state.active) {
		return state.entities[state.active];
	}
	return null;
}

export function getById<T>(state: EntityStateModel<any>, id: any): T | null {
	if (state && state.entities) {
		return state.entities[id];
	}
	return null;
}

/**
 * Returns the active entity. If none is present an error will be thrown.
 * @param state The state to act on
 */
export function mustGetActive<T>(state: EntityStateModel<T>): {
	id: string | undefined | null;
	active: T;
} {
	const active = getActive(state as any) as any;
	if (active === undefined) {
		throw new NoActiveEntityError();
	}
	return { id: state.active, active };
}

/**
 * Undefined-safe function to access the property given by path parameter
 * @param object The object to read from
 * @param path The path to the property
 */
export function elvis(object: any, path: string): any | undefined {
	return path ? path.split('.').reduce((value, key) => value && value[key], object) : object;
}

/**
 * Returns input as an array if it isn't one already
 * @param input The input to make an array if necessary
 */
export function asArray<T>(input: T | Array<T>): Array<T> {
	return Array.isArray(input) ? input : [input];
}

/**
 * Limits a number to the given boundaries
 * @param value The input value
 * @param min The minimum value
 * @param max The maximum value
 */
function clamp(value: number, min: number, max: number): number {
	return Math.min(max, Math.max(min, value));
}

/**
 * Uses the clamp function is wrap is false.
 * Else it wrap to the max or min value respectively.
 * @param wrap Flag to indicate if value should be wrapped
 * @param value The input value
 * @param min The minimum value
 * @param max The maximum value
 */
export function wrapOrClamp(wrap: boolean, value: number, min: number, max: number): number {
	if (!wrap) {
		return clamp(value, min, max);
	} else if (value < min) {
		return max;
	} else if (value > max) {
		return min;
	} else {
		return value;
	}
}

import { Type } from '@angular/core';
import { MODEL_META } from '@imt-web-zone/core/util-state-model-adapter';

export const EFFECT_ACTION_HANDLER = '__effectActionHandler';
export const EFFECT_REQUEST_ADAPTER = 'effectRequestAdapter';
export const EFFECT_STATE_UPDATER = 'effectStateUpdater';

export const effectSymbolPostfix = '__effect';

export const EFFECT_INIT = 'EFFECT_INIT';

export function effectSymbol(propertyKey: string) {
	return `${propertyKey}${effectSymbolPostfix}`;
}
export class StateEffectError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'StateEffectError';
		Object.setPrototypeOf(this, StateEffectError.prototype);
	}
}

export type EffectHandlerOptions = {
	storeUpdater?: string;
	requestAdapter?: string;
	service: Type<any>;
	adapter: Type<any> & { [MODEL_META]?: any };
};

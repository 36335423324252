import { inject, Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HashMap } from '@imt-web-zone/shared/util-store';
import { environment } from '@imt-web-zone/shared/environments';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import moment from 'moment';
import { Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, filter, first, timeout } from 'rxjs/operators';
import { EnumsState } from './enums.state';
import { EnumNames } from './enums.model';
import { APP_NAME, AppNames } from '@imt-web-zone/shared/core';

// todo extract this service to separate lib
@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	private store = inject(Store);

	public locales$ = this.store.select(EnumsState.getEnumMap(EnumNames.LOCALES));
	private appName = inject(APP_NAME);

	private loadedLocales: HashMap<boolean> = {
		[environment.defaultLocale]: true,
	};

	private normalizeLocaleAngularCode(angularCode: string) {
		// Error: InvalidPipeArgument: 'NG0701: Missing locale data for the locale "zh-cn".' for pipe 'DatePipe'
		// Converting all the Chinese angularCode instances to zh
		// https://github.com/jhipster/generator-jhipster/issues/6863#issuecomment-469003947
		if (angularCode === 'zh-Hans' || angularCode === 'zh-Hant' || angularCode === 'zh-Hant-HK') {
			angularCode = 'zh';
		}
		return angularCode;
	}

	public async loadLocale(localeId: string) {
		// on a slow network, locales might not be loaded when this function executes and we need
		// to wait for them to load
		const locales = await this.locales$
			.pipe(
				filter((l) => !!Object.keys(l).length),
				first(),
				timeout(1000 * 30),
				catchError((e) => {
					console.error(
						"Couldn't load locales. Didnt you forget to include Locales guard with Auth guard in the route? Locales are required while loading user.",
						e,
					);
					return throwError(e);
				}),
			)
			.toPromise();
		const locale = locales[localeId];
		if (locale) {
			if (!this.loadedLocales[locale.angularCode]) {
				let angularLocalePromise = null;
				let momentLocalePromise = null;
				if (this.appName === AppNames.ZONE_HQ_ADMIN) {
					// hq-admin is built using ESBUILD, not webpack like Zone
					// As a dev server ESBUILD is using Vite
					// Vite has limitation that dynamic import must start with ./ and end with file extension
					// for more info see https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
					angularLocalePromise = import(
						`../../../../../node_modules/@angular/common/locales/${this.normalizeLocaleAngularCode(
							locale.angularCode,
						)}.mjs`
					);
					momentLocalePromise = import(`../../../../../node_modules/moment/locale/${locale.code}.js`);
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					angularLocalePromise = import(
						// [CDM-1477] See: https://issueexplorer.com/issue/angular/angular-cli/22154
						// https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
						// DO NOT REMOVE CODE BELOW
						// eslint-disable-next-line max-len
						/* webpackInclude: /(af|ar|az|be|bg|bm|bn|bo|bs|ca|cs|cy|da|de|el|eo|es|et|eu|fa|fi|fo|fr|gd|gl|gu|he|hi|hr|hu|id|is|it|ja|ka|kk|km|"kn|ko|lo|lt|lv|mk|ml|mn|mr|ms|mt|my|nb|ne|nl|nn|pa|pl|pt|ro|ru|sd|si|sk|sl|sq|sr|sv|sw|ta|te|tg|th|tr|tzm|uk|ur|uz|vi|yo|ar-DZ|ar-KW|ar-LY|ar-MA|ar-SA|ar-TN|en-US|de-AT|de-CH|en-AU|en-CA|en-GB|en-IE|en-NZ|es-DO|es-US|fr-CA|fr-CH|hy|mi|ms|nl-BE|pt|sr-Cyrl|tzm|uz-Latn|zh|zh-Hans|zh-Hant-HK|zh-Hant)\.mjs$/i */
						/* webpackExclude: /\.d\.ts$/ */
						`@/../node_modules/@angular/common/locales/${this.normalizeLocaleAngularCode(
							locale.angularCode,
						)}` // [angular-cli issue] https://github.com/angular/angular-cli/issues/22154#issuecomment-967104031
					);
					momentLocalePromise = import(`moment/locale/${locale.code}`);
				}

				const [angularLocale] = await Promise.all([angularLocalePromise, momentLocalePromise]);

				if (angularLocale && angularLocale.default) {
					registerLocaleData(angularLocale.default);
					this.loadedLocales[locale.angularCode] = true;
					moment.locale(locale.code);
					window.moment = moment;
				}
			} else {
				moment.locale(locale.code);
				window.moment = moment;
			}
		}
	}
}

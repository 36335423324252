import { Injectable, inject } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { RouterNavigation } from '@ngxs/router-plugin';
import { CollectionState, CollectionStateModel, IdStrategy } from '@imt-web-zone/shared/util-store';
import {
	DlqModel,
	DLQS_STATE_TOKEN,
	DlqsAdapter,
	DlqsService,
	getInitialDlqsStateModel,
	ListDlqs,
	listDlqs,
	deleteDlq,
	DeleteDlq,
	ItemDlq,
	itemDlq,
} from '@imt-web-zone/zone/state-dlqs';
import { RouterUrlStateModel } from '@imt-web-zone/shared/util-store';

@State({
	name: DLQS_STATE_TOKEN,
	defaults: getInitialDlqsStateModel(),
})
@Injectable()
export class DlqsState extends CollectionState<DlqModel> {
	private service = inject(DlqsService);

	constructor() {
		super(DlqsState, 'id', IdStrategy.EntityIdGenerator, {
			sortByKey: 'created',
			sortOrder: 'desc',
		});
	}

	@Action([RouterNavigation])
	public setActiveDlqByRouter(
		ctx: StateContext<CollectionStateModel<DlqModel>>,
		action: RouterNavigation<RouterUrlStateModel>,
	): void {
		if (action.routerState && action.routerState.params.dlqId) {
			this.setActive(ctx, { payload: action.routerState.params.dlqId });
		} else if (ctx.getState()?.active) {
			this.clearActive(ctx);
		}
	}

	@Action(listDlqs)
	public listDlqsRequest(ctx: StateContext<CollectionStateModel<DlqModel>>, action: ListDlqs) {
		const { query } = action.params;
		const request = this.service.listDlqs$(query, query.pg);
		return this.createEffect$(ctx, action, request, 'dlqs', (res) => {
			this.upsert(ctx, {
				payload: res.dlqs.map((dlq) => DlqsAdapter.toStore(dlq, action.params.query.scenarioId)),
			});
		});
	}

	@Action(deleteDlq)
	public deleteDlqRequest(ctx: StateContext<CollectionStateModel<DlqModel>>, action: DeleteDlq) {
		const { query, payload } = action.params;
		const request = this.service.deleteDlq$(payload, payload.all ? query.scenarioId : undefined);
		return this.createEffect$(
			ctx,
			action,
			request,
			(res) => res,
			(res) => {
				this.remove(ctx, { payload: res.dlqs });
			},
		);
	}

	@Action(itemDlq)
	public itemDlqRequest(ctx: StateContext<CollectionStateModel<DlqModel>>, action: ItemDlq) {
		const { params } = action.params;
		const request = this.service.itemDlq$(params.dlqId);
		return this.createEffect$(ctx, action, request, 'dlq', (res) => {
			this.upsert(ctx, { payload: DlqsAdapter.toStore(res.dlq) });
		});
	}
}

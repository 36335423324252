export enum InspectorTypes {
	DESIGNER = 'designer',
	DLQ = 'dlq',
	DEBUGGER = 'debugger',
	TEMPLATER = 'templater',
	TEMPLATER_PUBLIC = 'templaterPublic',
}

export interface ImtInspectorModel {
	type?: InspectorTypes;
	mode?: 'view' | 'edit' | 'test' | 'inspect';
	activeExecution?: string;
	error?: InspectorErrors;
}

export interface InspectorErrors {
	detail: string;
	message: string;
}

export function getInitialImtInspectorModel() {
	return null;
}

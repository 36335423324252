import { Component } from '@angular/core';

/**
 * Example of usage:
 * <example-url>/demo/modal</example-url>
 */

@Component({
	selector: 'imt-ui-modal-body',
	templateUrl: './imt-ui-modal-body.component.html',
})
export class ImtUiModalBodyComponent {}

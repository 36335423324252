import { InjectionToken } from '@angular/core';
export const TITLE_VALUE_PROVIDER = new InjectionToken<TitleValueProvider>('imt-title-value-provider', {
	providedIn: 'root',
	factory: () => new TitleValueProvider(),
});
export class TitleValueProvider {
	public getValue(value: string | undefined): Promise<string | undefined> {
		return new Promise((resolve) => resolve(value));
	}
}

import {
	Creator,
	ActionCreator,
	TypedAction,
	FunctionWithParametersType,
	NotAllowedCheck,
	ActionCreatorProps,
} from './action-models';
import { AsyncLoadActionMeta, LoadActionParams, PayloadAction } from './async-action.interface';
import { AsyncAction, AsyncLoadAction } from './async-action';

// Action creators taken from ts-action library and modified a bit to better
/**
 * @deprecated
 * Use CollectionFacadeAbstract generic actions: `add$`, `createOrReplace$`, `update$`, `upsert$`, `remove$`, etc.
 * or `@Effect()` from `effect-http-builder.ts` if your action is asynchronous (triggers HTTP request).
 */
export function createAction<T extends string>(type: T): ActionCreator<T, () => TypedAction<T>>;
/**
 * @deprecated
 * Use CollectionFacadeAbstract generic actions: `add$`, `createOrReplace$`, `update$`, `upsert$`, `remove$`, etc.
 * or `@Effect()` from `effect-http-builder.ts` if your action is asynchronous (triggers HTTP request).
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function createAction<T extends string, P extends {}>(
	type: T,
	config: ActionCreatorProps<P> & NotAllowedCheck<P>,
): ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>;
/**
 * @deprecated
 * Use CollectionFacadeAbstract generic actions: `add$`, `createOrReplace$`, `update$`, `upsert$`, `remove$`, etc.
 * or `@Effect()` from `effect-http-builder.ts` if your action is asynchronous (triggers HTTP request).
 */
export function createAction<T extends string, P extends Array<any>, R extends object>(
	type: T,
	creator: Creator<P, R & NotAllowedCheck<R>>,
): FunctionWithParametersType<P, R & TypedAction<T>> & TypedAction<T>;
/**
 * @deprecated
 * Use CollectionFacadeAbstract generic actions: `add$`, `createOrReplace$`, `update$`, `upsert$`, `remove$`, etc.
 * or `@Effect()` from `effect-http-builder.ts` if your action is asynchronous (triggers HTTP request).
 *
 * @description
 * Creates a configured `Creator` function that, when called, returns an object in the shape of the `Action` interface.
 *
 * Action creators reduce the explicitness of class-based action creators.
 *
 * @param type Describes the action that will be dispatched
 * @param config Additional metadata needed for the handling of the action.
 * See {@link createAction#usage-notes Usage Notes}.
 *
 * @usageNotes
 *
 * **Declaring an action creator**
 *
 * Without additional metadata:
 * ```ts
 * export const increment = createAction('[Counter] Increment');
 * ```
 * With additional metadata:
 * ```ts
 * export const loginSuccess = createAction(
 *   '[Auth/API] Login Success',
 *   props<{ user: User }>()
 * );
 * ```
 * With a function:
 * ```ts
 * export const loginSuccess = createAction(
 *   '[Auth/API] Login Success',
 *   (response: Response) => response.user
 * );
 * ```
 *
 * **Dispatching an action**
 *
 * Without additional metadata:
 * ```ts
 * store.dispatch(increment());
 * ```
 * With additional metadata:
 * ```ts
 * store.dispatch(loginSuccess({ user: newUser }));
 * ```
 *
 * **Referencing an action in a reducer**
 *
 * Using a switch statement:
 * ```ts
 * switch (action.type) {
 *   // ...
 *   case AuthApiActions.loginSuccess.type: {
 *     return {
 *       ...state,
 *       user: action.user
 *     };
 *   }
 * }
 * ```
 * Using a reducer creator:
 * ```ts
 * on(AuthApiActions.loginSuccess, (state, { user }) => ({ ...state, user }))
 * ```
 *
 *  **Referencing an action in an effect**
 * ```ts
 * effectName$ = createEffect(
 *   () => this.actions$.pipe(
 *     ofType(AuthApiActions.loginSuccess),
 *     // ...
 *   )
 * );
 * ```
 */
export function createAction<T extends string, C extends Creator>(
	type: T,
	config?: { _as: 'props' } | C,
): ActionCreator<T> {
	if (typeof config === 'function') {
		return defineType(type, (...args: Array<any>) => ({
			...config(...args),
			type,
		}));
	}
	const as = config ? config._as : 'empty';
	switch (as) {
		case 'empty':
			return defineType(type, () => ({ type }));
		case 'props':
			// eslint-disable-next-line @typescript-eslint/ban-types
			return defineType(type, (_props: object) => ({
				..._props,
				type,
			}));
		default:
			throw new Error('Unexpected config.');
	}
}

/**
 * @deprecated - use @Effect() from effect-http-builder.ts
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function createAsyncAction<Payload extends PayloadAction, Res, Meta extends object = {}>(
	type: string,
	hideFromDevtools?: true,
) {
	return createAction(type, (payload: Payload, metadata: Meta = {} as Meta) => {
		const action = new AsyncAction<Payload, Res, Meta>(payload, metadata);
		if (hideFromDevtools) {
			action.hideFromDevtools = true;
		}
		return action;
	});
}

/**
 * @deprecated - use @Effect() from effect-http-builder.ts
 */
export function createAsyncLoadAction<
	Params extends LoadActionParams,
	Res,
	Meta extends AsyncLoadActionMeta = AsyncLoadActionMeta,
>(type: string) {
	return createAction(
		type,
		(params: Params, metadata?: Meta & AsyncLoadActionMeta) =>
			new AsyncLoadAction<Params, Res, Meta>(type, params, metadata),
	);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function props<P extends object>(): ActionCreatorProps<P> {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return { _as: 'props', _p: undefined! };
}

export function union<C extends { [key: string]: ActionCreator<string, Creator> }>(
	creators: C,
): ReturnType<C[keyof C]> {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return undefined!;
}

function defineType<T extends string>(type: T, creator: Creator): ActionCreator<T> {
	return Object.defineProperty(creator, 'type', {
		value: type,
		writable: false,
	}) as ActionCreator<T>;
}

import { inject, Injectable, Injector } from '@angular/core';
import { CollectionFacadeAbstract } from '@imt-web-zone/core/util-state-facade';
import { EntityGuardOptions } from '@imt-web-zone/core/util-entity-guard';
import { TeamsService } from './teams.service';
import { TeamsSelectors } from './teams.selectors';
import { setActiveTeam } from './teams.actions';
import { Store } from '@ngxs/store';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { map, switchMap } from 'rxjs';
import { SessionFacade } from '@imt-web-zone/zone/state-session';
import { TEAMS_STATE_TOKEN } from './teams.symbols';

@Injectable({ providedIn: 'root' })
export class TeamsFacade extends CollectionFacadeAbstract<typeof TEAMS_STATE_TOKEN, TeamsService> {
	private sessionFacade = inject(SessionFacade);
	protected service = inject(TeamsService);

	public teamsBySelectedOrganization$ = this.store.select$(TeamsSelectors.teamsBySelectedOrganization);
	public teamsBySelectedOrganizationRxSnapshot = this.rxSnapshotFn(TeamsSelectors.teamsBySelectedOrganization);

	public teamsByOrgAndSortedByName$ = this.store.select$(TeamsSelectors.teamsByOrgAndSortedByName);
	public teamsByOrgAndSortedByNameRxSnapshot = this.rxSnapshotFn(TeamsSelectors.teamsByOrgAndSortedByName);

	public static teamIdExist = (injector: Injector) =>
		injector
			.get(Store)
			.select(CollectionState.getActiveId(TEAMS_STATE_TOKEN))
			.pipe(map((s) => !!s));

	constructor() {
		super(TEAMS_STATE_TOKEN);
	}

	@EntityGuardOptions({})
	public loadTeams$(organizationId: number) {
		return this.store.dispatch$(this.service.loadTeams$({ organizationId }));
	}

	public loadTeam$(teamId: number) {
		return this.store.dispatch$(this.service.loadTeam$(teamId));
	}

	public deleteTeam$(teamId: number) {
		return this.store.dispatch$(this.service.deleteTeam$(teamId));
	}

	// TODO: removal of `setActiveTeam` action and setting the state directly in this method unfortunately doesn't work
	// The dispatch$ method doesn't seem to recognize observable when using multiple actions passeed into it
	// The proposal of the usage: https://github.com/integromat/imt-web-zone/pull/3937#discussion_r1616960756
	public setActiveTeam$(teamId: string | null, name?: string) {
		return this.store
			.dispatch$(setActiveTeam({ payload: { id: teamId, name } }))
			.pipe(
				switchMap(() => this.sessionFacade.setActiveOrganizationTeam$({ isOrganization: false, id: teamId })),
			);
	}
}

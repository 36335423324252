import { ActionType, createAction, props } from '@imt-web-zone/shared/util-store';

export const setActiveTeam = createAction(
	'[Teams] set active',
	props<{
		payload: { name?: string; id: string | null };
	}>(),
);
export type SetActiveTeam = ActionType<typeof setActiveTeam>;

export const changedActiveTeam = createAction('[Teams] changed active team');
export type ChangedActiveTeam = ActionType<typeof changedActiveTeam>;

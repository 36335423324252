import { DestroyRef, inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ServiceInit } from '@imt-web-zone/core/util-core';
import { EMPTY, filter, interval, Observable, of, Subscription, switchMap, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DOCUMENT } from '@angular/common';
import {
	ActivityEvent,
	ActivityLogEvent,
	ActivityLogEventEntity,
	SessionChecksService,
	UserActivityEvent,
} from '@imt-web-zone/zone/data-access-session-checks';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthFacade, AuthService, AuthUserModel } from '@imt-web-zone/zone/state-auth';
import { v4 } from 'uuid';
import { ImtUiModalService } from '@imt-web-zone/shared/ui-modal';
import { TranslocoService } from '@jsverse/transloco';
import { ImtUiConfirmDialogComponent } from '@imt-web-zone/shared/ui-confirm-dialog';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiConfigData } from '@imt-web-zone/zone/state-api-config';
import { environment } from '@imt-web-zone/shared/environments';
import { UiToastMessageService, UiToastMessageType } from '@imt-web-zone/make-design-system/ui-toast-message';

export interface AppActivityConfig {
	featureFlagIsOn$?: Observable<boolean>;
	authUser$?: Observable<AuthUserModel>;
	isMaster?: boolean;
	apiConfig?: ApiConfigData;
}

@Injectable({
	providedIn: 'root',
})
export class AppActivityTrackerService implements ServiceInit {
	private document = inject<Document>(DOCUMENT);
	private authService = inject(AuthService);
	private authFacade = inject(AuthFacade);
	private modalService = inject(ImtUiModalService);
	private transloco = inject(TranslocoService);

	private eventListeners: {
		mousemove?: () => void;
		keyup?: () => void;
	} = {};
	private renderer: Renderer2;
	private destroyRef = inject(DestroyRef);
	private sessionChecksService = inject(SessionChecksService);
	private config!: AppActivityConfig;
	private sanityCheckInProgress = false;
	private trackingSubscription?: Subscription;
	private authUserEmail!: string;
	private featureFlagIsOn = false;
	private modalShownOnCurrentTab = false;
	private modalRef!: NgbModalRef;
	private apiConfig!: ApiConfigData;
	private loginLinkWithQueryParams!: string;
	private tabActive = false;
	private que: ActivityLogEventEntity[] = [];
	private queSubscription?: Subscription;
	private toast = inject(UiToastMessageService);

	private get userLoggedOut() {
		return !!this.sessionChecksService.getSessionCheckItem('userLoggedOut');
	}

	private set userLoggedOut(userLoggedOut) {
		this.sessionChecksService.setSessionCheckItem('userLoggedOut', userLoggedOut);
	}

	private get userSessionExpiresAt() {
		const value = this.sessionChecksService.getSessionCheckItem('userSessionExpiresAt');
		return value ? new Date(value) : undefined;
	}

	private set userSessionExpiresAt(userSessionExpiresAt) {
		this.sessionChecksService.setSessionCheckItem('userSessionExpiresAt', userSessionExpiresAt);
	}

	private get sanityCheck() {
		const value = this.sessionChecksService.getSessionCheckItem('sanityCheck');
		return value ? new Date(value) : undefined;
	}

	private set sanityCheck(sanityCheck) {
		this.sessionChecksService.setSessionCheckItem('sanityCheck', sanityCheck);
	}

	private get modalShown() {
		return !!this.sessionChecksService.getSessionCheckItem('modalShown');
	}

	private set modalShown(modalShow) {
		this.sessionChecksService.setSessionCheckItem('modalShown', modalShow);
	}

	constructor() {
		const rendererFactory = inject(RendererFactory2);
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	public async initialize(config: AppActivityConfig) {
		this.config = config;
		if (this.config.apiConfig) {
			this.apiConfig = this.config.apiConfig;
			this.loginLinkWithQueryParams = `https://${this.apiConfig.slaveDomains?.hq}/${this.apiConfig.generalSettings.defaultLanguage}/login?userSessionExpired=true`;
		}

		if (this.config?.isMaster) {
			return;
		}
		this.tabActive = !document.hidden;
		this.config.authUser$
			?.pipe(
				filter((user) => !!user),
				map((user) => user.email),
				tap((userEmail) => {
					this.authUserEmail = userEmail;
				}),
				filter(() => !this.userSessionExpiresAt),
				mergeMap(() => this.sanityCheckCall('initial-sanity-check', v4())),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(() => {
				if (!this.queSubscription) {
					this.queSubscription = this.createQueSubscription();
				}
			});

		this.config.featureFlagIsOn$?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
			this.featureFlagIsOn = value;

			if (value) {
				this.startTracking();
			} else {
				this.removeEventListenerAndUnsubscribe();
			}
		});

		interval(1000)
			.pipe(
				filter(() => this.featureFlagIsOn),
				filter(() => this.tabActive),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(() => this.showOrHideModalOnOtherTabs());

		document.addEventListener('visibilitychange', () => {
			this.tabActive = !document.hidden;
			if (this.tabActive && this.featureFlagIsOn) {
				// if user was already logged out in other window, redirect to login page
				if (this.userLoggedOut) {
					window.location.href = this.loginLinkWithQueryParams;
				} else {
					this.showOrHideModalOnOtherTabs();
					this.submitUnprocessedEvents();
				}
			}
		});
	}

	private showOrHideModalOnOtherTabs() {
		const userSessionExpiresAt = this.userSessionExpiresAt;
		if (userSessionExpiresAt) {
			const currentDate = new Date();
			const timeDifference = userSessionExpiresAt.getTime() - currentDate.getTime();
			// check if userSessionExpiresAt is less than sessionLogoutExpiration value
			if (timeDifference <= environment.api.sessionLogoutExpiration) {
				if (!this.modalShownOnCurrentTab) {
					this.modalShownOnCurrentTab = true;
					this.showModal(userSessionExpiresAt);
				}
			} else {
				this.hideModal();
			}
		}
	}

	public startTracking() {
		this.trackEvent('mousemove');
		this.trackEvent('keyup');
	}

	private continueSession() {
		this.sanityCheckCall('dialog-prolong-button', v4())
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.removeTrackingSubscription();
				this.modalShownOnCurrentTab = false;
				this.modalShown = false;
			});
	}

	private checkForLsAndSubscribe(eventType: UserActivityEvent) {
		if (this.userLoggedOut || this.modalShown) {
			return;
		}

		const useActivityCorrelationId = v4();
		this.storeEvent(new ActivityLogEventEntity('event_triggered_by_user', useActivityCorrelationId, eventType));
		const sanityCheckTimestamp = this.sanityCheck?.getTime();

		if (sanityCheckTimestamp && !this.trackingSubscription) {
			const eligibleForRequest = Date.now() - sanityCheckTimestamp >= environment.api.newSanityCheckInterval;
			if (eligibleForRequest) {
				this.trackingSubscription = this.sanityCheckSubscription(eventType, useActivityCorrelationId);
			} else {
				const event = new ActivityLogEventEntity('event_added_to_queue', useActivityCorrelationId, eventType);
				this.que.push(event);
				this.storeEvent(event);
			}
		}
	}

	public trackEvent(eventType: UserActivityEvent) {
		this.eventListeners[eventType] = this.renderer.listen(this.document.body, eventType, () => {
			this.checkForLsAndSubscribe(eventType);
		});
	}

	private logoutAction() {
		const userLoggedOut = this.userLoggedOut;

		if (userLoggedOut) {
			window.location.href = this.loginLinkWithQueryParams;
			return EMPTY;
		} else {
			this.userLoggedOut = true;
		}

		return this.authFacade
			.logout$({ redirect: true, sessionExpired: true })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}

	public sanityCheckLogout() {
		this.sessionChecksService.emitUserActivityEvents();
		return this.authFacade.logout$({ redirect: true, userEmailChanged: true });
	}

	private sanityCheckSubscription(eventType: ActivityEvent, useActivityCorrelationId: string) {
		return this.sanityCheckCall(eventType, useActivityCorrelationId)
			.pipe(
				tap(() => this.removeTrackingSubscription()),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe(() => {
				if (this.queSubscription) {
					this.queSubscription.unsubscribe();
					this.queSubscription = undefined;
				}
				this.queSubscription = this.createQueSubscription();
			});
	}

	private storeEvent(event: ActivityLogEvent) {
		this.sessionChecksService.storeUserActivityEvents(event);
	}

	private removeEventListenerAndUnsubscribe() {
		this.removeTrackingSubscription();
		this.eventListeners.keyup = undefined;
		this.eventListeners.mousemove = undefined;
	}

	private removeTrackingSubscription() {
		if (this.trackingSubscription) {
			this.trackingSubscription.unsubscribe();
			this.trackingSubscription = undefined;
		}
	}

	private hideModal() {
		if (this.modalRef) {
			this.modalRef.close();
			this.modalShownOnCurrentTab = false;
			this.modalShown = false;
		}
	}

	public showModal(date: Date) {
		this.callGetMeSessionCheck$(environment.api.sessionLogoutExpiration / 1000)
			.pipe(
				filter((value) => !!value.userSessionExpiresAt),
				tap((value) => {
					this.userSessionExpiresAt = value.userSessionExpiresAt;
				}),
				filter((value) => {
					const timeDifference = (this.userSessionExpiresAt?.getTime() ?? 0) - new Date().getTime();
					const result = timeDifference <= environment.api.sessionLogoutExpiration;
					this.modalShownOnCurrentTab = result;
					return result;
				}),
				switchMap(() =>
					this.transloco.selectTranslate([
						'session.modalTitle',
						'session.modalBody',
						'session.modalBody2',
						'session.btnSecondary',
						'session.btnPrimary',
					]),
				),
				takeUntilDestroyed(this.destroyRef),
				map(([modalTitle, modalBody, modalBody2, btnSecondary, btnPrimary]) => {
					return { modalTitle, modalBody, modalBody2, btnSecondary, btnPrimary };
				}),
				tap((t) => {
					this.modalShownOnCurrentTab = true;
					this.modalShown = true;
					const modalRef = this.modalService.open(ImtUiConfirmDialogComponent);
					this.modalRef = modalRef;
					modalRef.componentInstance.userSession = true;
					modalRef.componentInstance.title = t.modalTitle;
					modalRef.componentInstance.confirmMessageHTML = `<div class="text-center dmo-mt-16">${t.modalBody}</div>`;
					modalRef.componentInstance.countdownMessage = t.modalBody2;

					if (!!this.userSessionExpiresAt && this.userSessionExpiresAt.getTime() !== date.getTime()) {
						date = this.userSessionExpiresAt;
					}

					modalRef.componentInstance.countdownDate = new Date(date.getTime() - 250);
					modalRef.componentInstance.btnSecondaryText = t.btnSecondary;
					modalRef.componentInstance.spacingBetweenButtons = true;
					modalRef.componentInstance.btnPrimaryText = t.btnPrimary;
					modalRef.componentInstance.hideOptionMenu = true;
					modalRef.componentInstance.closeAction = () => {
						this.continueSession();
						modalRef.close();
					};
					modalRef.componentInstance.dismissAction = () => {
						this.logoutAction();
						modalRef.close();
					};
					modalRef.componentInstance.submitAction = () => {
						this.continueSession();
						modalRef.close();
					};
					modalRef.componentInstance.countdownAction = () => {
						this.contDownFinished();
						modalRef.close();
					};
				}),
			)
			.subscribe();
	}

	private sanityCheckCall(eventType: ActivityEvent, useActivityCorrelationId: string) {
		return of(EMPTY).pipe(
			filter(() => !this.sanityCheckInProgress),
			tap(() => {
				this.sanityCheckInProgress = true;
				this.sanityCheck = new Date();
			}),
			mergeMap(() => {
				return this.authService.sanityCheck().pipe(
					catchError((err) => {
						if (err.error?.status === 401) {
							this.storeEvent(
								new ActivityLogEventEntity(
									'event_unauthorized_sanity_check',
									useActivityCorrelationId,
									eventType,
								),
							);
							this.sanityCheckInProgress = false;
							this.logoutAction();
						}
						return EMPTY;
					}),
				);
			}),
			tap((res) => {
				this.sanityCheckInProgress = false;
				this.sanityCheck = new Date();
				const userSessionExpiresAt = res?.userSessionExpiresAt;
				if (userSessionExpiresAt) {
					this.userSessionExpiresAt = userSessionExpiresAt;
				}
				if (this.authUserEmail && res?.authUser?.email !== this.authUserEmail) {
					this.storeEvent(
						new ActivityLogEventEntity(
							'event_different_user_sanity_check',
							useActivityCorrelationId,
							eventType,
						),
					);
					this.sanityCheckLogout();
				} else {
					this.storeEvent(
						new ActivityLogEventEntity(
							'event_usefully_triggering_sanity_check',
							useActivityCorrelationId,
							eventType,
						),
					);
				}
			}),
		);
	}

	private createQueSubscription() {
		return interval(environment.api.newSanityCheckInterval)
			.pipe(mergeMap(() => this.checkQue()))
			.subscribe((value) => {
				this.processQue(value);
			});
	}

	private contDownFinished() {
		this.callGetMeSessionCheck$(0)
			.pipe(
				tap((value) => {
					if (value?.userSessionExpiresAt) {
						this.userSessionExpiresAt = value.userSessionExpiresAt;
					}
					const timeDifference = (this.userSessionExpiresAt?.getTime() ?? 0) - new Date().getTime();
					if (timeDifference <= 0) {
						this.logoutAction();
					} else {
						this.removeTrackingSubscription();
						this.modalShownOnCurrentTab = false;
						this.modalShown = false;
						this.toast.show({
							type: UiToastMessageType.INFO,
							text: this.transloco.translate('session.sessionExpiring'),
						});
					}
				}),
				take(1),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}
	private callGetMeSessionCheck$(dialogTime: number) {
		return this.authService.getMeSessionCheck$(dialogTime).pipe(
			catchError((err) => {
				if (err.error?.status === 401) {
					this.logoutAction();
				}
				return EMPTY;
			}),
		);
	}

	private submitUnprocessedEvents() {
		this.checkQue()
			.pipe(take(1))
			.subscribe((value) => {
				this.processQue(value);
			});
	}

	private checkQue() {
		return of(EMPTY).pipe(
			filter(() => this.que.length > 0),
			filter(() => this.featureFlagIsOn),
			map(() => {
				if (this.sanityCheck) {
					// eslint-disable-next-line max-len
					const events = this.que.filter(
						(value) => this.sanityCheck && new Date(value.timestamp).getTime() > this.sanityCheck.getTime(),
					);
					if (events.length > 1) {
						return events.pop(); //lat event;
					}
				}
				return null;
			}),
			takeUntilDestroyed(this.destroyRef),
		);
	}
	private processQue(value: ActivityLogEventEntity | null | undefined) {
		if (value && !this.trackingSubscription) {
			// eslint-disable-next-line max-len
			this.trackingSubscription = this.sanityCheckSubscription(
				value.userActivity,
				value.useActivityCorrelationId,
			);
			// eslint-disable-next-line max-len
			this.que = this.que.filter(
				(value) => this.sanityCheck && new Date(value.timestamp).getTime() > this.sanityCheck.getTime(),
			);
		}
	}
}

import { Class, ensureMetaDataObject, ModelAdapterError, ModelMetaData, setValue, snakeToCamel } from './internals';
import 'reflect-metadata';

export const MODEL_META = '__MODEL_META';

/**
 * creates instance of the Inserted model
 * @param Model
 * @param data
 */
export function adaptToStore<T extends Class>(Model: T, data: ConstructorParameters<T>[0]): InstanceType<T> {
	return data ? new Model(data, false) : data;
}

/**
 * transforms the model into an object adapted for the "API"
 * @param Model
 * @param data
 */
export function adaptToApi<T extends Class>(
	Model: T,
	data: Partial<InstanceType<T>> & Record<string, any>,
): ConstructorParameters<T>[0] {
	return new Model(data, true) as ConstructorParameters<T>[0];
}

/**
 * A class decorator which serialises all the metadata from the property decorators
 * into the metaDataMap according to which the input data is set into the model
 * For fields marked with Field decorator automatic snake to camel case conversion is performed.
 *
 * @constructor
 *
 * must be in following format:
 *
 *   constructor(inputData: Partial<T>, adapt?: boolean) {
 * 		//
 * 	 }
 */
export function ModelAdapter() {
	return function <T extends { new (inputData: Record<any, keyof T>, adapt: boolean): any }>(target: T) {
		const meta = ensureMetaDataObject(target.prototype);
		const metaDataMap = new Map<string, ModelMetaData>();
		for (const metaData of Object.values(meta) as Array<ModelMetaData>) {
			if (metaData.alias) {
				metaDataMap.set(metaData.alias, metaData);
			}
			metaDataMap.set(metaData.propertyKey, metaData);
		}

		return new Proxy(target, {
			construct(clz, [data, adapt]) {
				if (typeof data !== 'object') {
					throw new ModelAdapterError('The first parameter of the constructor must be object!');
				}
				if (typeof adapt !== 'boolean') {
					throw new ModelAdapterError('The second parameter of the constructor must be a boolean!');
				}

				const instance = Reflect.construct(clz, [data, adapt]);
				Object.setPrototypeOf(instance, target.prototype);
				// Object.setPrototypeOf(instance, prototype)
				const result = adapt ? {} : instance;
				for (const prop of Object.keys(data)) {
					const value = data[prop];
					const camelCaseProp = snakeToCamel(prop);
					if (metaDataMap.has(prop)) {
						setValue(result, metaDataMap.get(prop) as ModelMetaData, value, adapt);
					} else if (metaDataMap.has(camelCaseProp)) {
						const modelMetaData = metaDataMap.get(camelCaseProp) as ModelMetaData;
						modelMetaData.alias = modelMetaData.alias ?? prop;
						setValue(result, modelMetaData, value, adapt);
					} else if (instance[prop] === null) {
						instance[prop] = value;
					} else {
						// todo print only in dev mode
						// console.warn(`Property "${prop}" not defined in "${target.name}".`);
					}
				}
				return result;
			},
		});
	};
}

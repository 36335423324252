import { Injectable, inject } from '@angular/core';
import {
	GET,
	POST,
	DELETE,
	Path,
	HttpBuilder,
	QueryData,
	QueryType,
	Pagination,
	PaginationType,
	ParamType,
} from '@imt-web-zone/shared/util';
import { Observable, take } from 'rxjs';
import { LoadOrganizationDataStoresMetadataPayload, LoadOrganizationDataStoresMetadataResponse } from '.';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';
import type {
	DeleteOrganizationResponse,
	DetailOrganizationPayload,
	DetailOrganizationResponse,
	EnableDisableOrganizationFeaturePayload,
	EnableDisableOrganizationFeatureResponse,
	ListAdminOrganizationsPayload,
	ListAdminOrganizationsResponse,
	ListOrganizationsPayload,
	ListOrganizationsResponse,
	DisableOrganizationSsoResponse,
} from './organizations.interface';

@Injectable({ providedIn: 'root' })
export class OrganizationsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('organizations')
	public listOrganizations$(
		@QueryData queryData: QueryType<ListOrganizationsPayload>,
	): Observable<ListOrganizationsResponse> {
		return undefined;
	}

	@GET('organizations/{organizationId}')
	public detailOrganization$(
		@Path('organizationId') organizationId: ParamType<DetailOrganizationPayload, 'organizationId'>,
		@QueryData queryData: QueryType<DetailOrganizationPayload>,
	): Observable<DetailOrganizationResponse> {
		return undefined;
	}

	@GET('admin/organizations')
	public listAdminOrganizations$(
		@QueryData queryData: QueryType<ListAdminOrganizationsPayload>,
		@Pagination pg: PaginationType<ListAdminOrganizationsPayload>,
	): Observable<ListAdminOrganizationsResponse> {
		return undefined;
	}

	@DELETE('organizations/{id}')
	public deleteOrganizations$(@Path('id') id: string): Observable<DeleteOrganizationResponse> {
		return undefined;
	}

	@POST('organizations/{id}/turn-off-sso')
	public disableOrganizationsSso$(@Path('id') id: string): Observable<DisableOrganizationSsoResponse> {
		return undefined;
	}

	@POST('admin/organizations/{organizationId}/features/{featureName}')
	public enableAdminOrganizationFeature$(
		@Path('organizationId') organizationId: ParamType<EnableDisableOrganizationFeaturePayload, 'organizationId'>,
		@Path('featureName') featureName: ParamType<EnableDisableOrganizationFeaturePayload, 'featureName'>,
	): Observable<EnableDisableOrganizationFeatureResponse> {
		return undefined;
	}

	@DELETE('admin/organizations/{organizationId}/features/{featureName}')
	public disableAdminOrganizationFeature$(
		@Path('organizationId') organizationId: ParamType<EnableDisableOrganizationFeaturePayload, 'organizationId'>,
		@Path('featureName') featureName: ParamType<EnableDisableOrganizationFeaturePayload, 'featureName'>,
	): Observable<EnableDisableOrganizationFeatureResponse> {
		return undefined;
	}

	@GET('organizations/{organizationId}/data-stores')
	public loadOrganizationDataStoresMetadata$(
		@Path('organizationId')
		organizationId: ParamType<LoadOrganizationDataStoresMetadataPayload, 'organizationId'>,
	): Observable<LoadOrganizationDataStoresMetadataResponse> {
		return undefined;
	}

	public redirectToOtherZoneIfLastOrganizationRemoved(currentZone: string) {
		this.listOrganizations$({})
			.pipe(take(1))
			.subscribe(({ organizations }) => {
				if (organizations.length > 0 && !organizations.some((org) => org.zone === currentZone)) {
					window.location.href = `https://${organizations[0].zone}/organization/${organizations[0].id}`;
				}
			});
	}
}

import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';
import { EntityState, EntityType } from '../internal/entity-state';
import { generateActionObject } from '../internal/internal';
import { EntityActionType, Payload } from './type-alias';

export type UpsertAction<T> = Payload<Partial<T> | Array<Partial<T>>>;

export class Upsert<T extends Record<string, any>> {
	/**
	 * Generates an action that will add the given entities to the state.
	 * If an entity with the ID already exists, it will be Patch current state.
	 * @param target The targeted state class
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see Upsert#constructor
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		payload:
			| Partial<T extends CollectionStateModel<any> ? EntityType<T> : T>
			| Array<Partial<T extends CollectionStateModel<any> ? EntityType<T> : T>>,
		context: string,
	) {
		return generateActionObject(EntityActionType.Upsert, target, payload).setContext(context);
	}
}

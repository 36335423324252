import { StateToken } from '@ngxs/store';
import type { HttpBuilder } from '@imt-web-zone/shared/util';
import { EffectAction, EffectActionsTypes } from '@imt-web-zone/shared/util-store';

export function createEffectActionType(stateToken: StateToken | string, type: string, state?: EffectActionsTypes) {
	let stateName = stateToken instanceof StateToken ? stateToken.getName() : stateToken;
	stateName = stateName.charAt(0).toUpperCase() + stateName.slice(1);
	if (state) {
		return `[${stateName} API] [${state}] ${type}`;
	}
	return `[${stateName} API] ${type}`;
}

/**
 * get start type from request type
 */
export function effectStart<T extends HttpBuilder>(stateToken: StateToken, actionName: keyof T): EffectAction {
	return {
		type: createEffectActionType(stateToken, actionName as string, EffectActionsTypes.START),
	} as EffectAction;
}

/**
 * get success type from request type
 */
export function effectSuccess<T extends HttpBuilder>(stateToken: StateToken, actionName: keyof T): EffectAction {
	return {
		type: createEffectActionType(stateToken, actionName as string, EffectActionsTypes.SUCCESS),
	} as EffectAction;
}

/**
 * get error type from request type
 */
export function effectError<T extends HttpBuilder>(stateToken: StateToken, actionName: keyof T): EffectAction {
	return {
		type: createEffectActionType(stateToken, actionName as string, EffectActionsTypes.ERROR),
	} as EffectAction;
}

/**
 * get from Cache type from request type
 */
export function effectFromCache<T extends HttpBuilder>(stateToken: StateToken, actionName: keyof T): EffectAction {
	return {
		type: createEffectActionType(stateToken, actionName as string, EffectActionsTypes.FROM_CACHE),
	} as EffectAction;
}

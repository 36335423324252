import { CollectionStateModel, getInitialCollectionState } from '@imt-web-zone/shared/util-store';
import { LicenseParameters, OrganizationDataStoresData } from './organizations.interface';

export class OrganizationModel {
	constructor(
		public id: string,
		public name: string,
		public countryId?: string,
		public timezoneId?: string,
		public license?: LicenseParameters,
		public zoneId?: string,
		public subscriptionBilledType?: string,
		public subscriptionOperations?: number,
		public subscriptionOpsBilledType?: string,
		public teamIds?: Array<string>,
		public scenariosCount?: number,
		public activeScenarios?: number,
		public activeApps?: number,
		public isPaused?: boolean,
		public isDeleted?: boolean,
		public nextReset?: Date,
		public operations?: number,
		public operationsExt?: number,
		public unusedOperations?: string | null,
		public transfer?: number,
		public transferExt?: number,
		public unusedTransfer?: string,
		public features?: { [key: string]: any },
		public autoPurchasingActivated?: boolean,
		public taxExemptionValid?: boolean,
		public taxExemptionValidFrom?: Date,
		public taxExemptionValidTo?: Date,
		public partnerCode?: string,
		public partnerUserId?: string,
		public couponOperations?: number,
		/**
		 * Loaded via `loadOrganizationDataStoresMetadata` action, read-only
		 */
		public dataStores?: OrganizationDataStoresData,
		public productName?: string,
		public ssoSlug?: string,
		public lastReset?: Date,
	) {}
}

export function getInitialOrganizationsStateModel(): CollectionStateModel<OrganizationModel> {
	return getInitialCollectionState<OrganizationModel>();
}

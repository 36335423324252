import { generateActionObject } from '../internal/internal';
import { EntityActionType, Payload } from './type-alias';
import { EntityState, EntityType } from '../internal/entity-state';
import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';

export type EntityAddAction<T> = Payload<T | Array<T>>;

export class Add<T extends Record<string, any>> {
	/**
	 * Generates an action that will add the given entities to the state.
	 * The entities given by the payload will be added.
	 * For certain ID strategies this might fail, if it provides an existing ID.
	 * In all other cases it will overwrite the ID value in the entity with the calculated ID.
	 * @param target The targeted state class
	 * @param payload An entity or an array of entities to be added
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see Add#constructor
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		payload: T extends CollectionStateModel<any> ? EntityType<T> | Array<EntityType<T>> : T | Array<T>,
		context: string,
	) {
		return generateActionObject(EntityActionType.Add, target, payload).setContext(context);
	}
}

import { Selector } from '@ngxs/store';
import { CollectionState } from '@imt-web-zone/shared/util-store';
import { DlqModel, DLQS_STATE_TOKEN } from '@imt-web-zone/zone/state-dlqs';

// todo will be resolved by moving logic to facade layer
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SCENARIOS_STATE_TOKEN } from '@imt-web-zone/zone/state-scenarios';

export class DlqSelectors {
	@Selector([CollectionState.getEntities(DLQS_STATE_TOKEN), CollectionState.getActiveId(SCENARIOS_STATE_TOKEN)])
	public static dlqs(entities: Array<DlqModel>, scenarioId: string) {
		return entities.filter((entity) => entity.scenarioId === +scenarioId);
	}

	@Selector([CollectionState.getEntities(DLQS_STATE_TOKEN), CollectionState.getActiveId(SCENARIOS_STATE_TOKEN)])
	public static dlqsPreview(entities: Array<DlqModel>, scenarioId: string) {
		return DlqSelectors.dlqs(entities, scenarioId).slice(0, 3);
	}
}

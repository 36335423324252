import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiInterceptor } from './api.interceptor';
import { environment } from '@imt-web-zone/shared/environments';

@Injectable()
export class StreamerInterceptor extends ApiInterceptor implements HttpInterceptor {
	protected override get envBaseUrl() {
		return environment.streamer?.baseUrl;
	}
	protected override envHeaders: { [key: string]: string } = environment.streamer?.headers;

	public override intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const clonedReq = request.clone({
			url: `${this.baseUrl(request.url)}`,
			headers: this.addDefaultHeaders(request.headers),
		});
		return next.handle(clonedReq);
	}
}

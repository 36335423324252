import { MODEL_META } from './model-adapter.decorator';

export type Class = new (...args: any[]) => any;

export type AdaptionTypes = StringConstructor | BooleanConstructor | NumberConstructor | DateConstructor;
export type TransformerFn<T> = (
	value: T,
	// model: any todo
) => any;

export class ModelAdapterError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'ModelAdapterError';
		Object.setPrototypeOf(this, ModelAdapterError.prototype);
	}
}

export interface ModelMetaData {
	propertyKey: string;
	alias?: string;
	apiType?: AdaptionTypes;
	type?: AdaptionTypes;
	apiTransform?: TransformerFn<any>;
	transform?: TransformerFn<any>;
}

function typeCheck(value: any) {
	const return_value = Object.prototype.toString.call(value);
	const type = return_value.substring(return_value.indexOf(' ') + 1, return_value.indexOf(']'));
	return type.toLowerCase();
}

/**
 * resolves metadata for particular property and performs transformation of the models
 */
export function setValue(instance: any, metadata: ModelMetaData, value: unknown, adapt = false) {
	const property = adapt && metadata.alias ? metadata.alias : metadata.propertyKey;
	if (metadata.transform || metadata.apiTransform) {
		if (adapt) {
			if (metadata.apiTransform) {
				instance[property] = metadata.apiTransform(value);
			}
			return;
		}
		if (metadata.transform) {
			instance[property] = metadata.transform(value);
		}
	} else if (metadata.type && typeof value !== 'undefined' && value !== null) {
		const apiType = metadata.apiType;
		const ctorType = typeCheck(new metadata.type());
		// date constructor must be called with new
		if (ctorType === 'date') {
			instance[property] = adapt && apiType ? apiType(value) : new metadata.type(value);
		} else {
			instance[property] = adapt && apiType ? apiType(value) : metadata.type(value);
		}

		// if (apiType && typeof instance[property] !== typeof value) {
		// 	throw new ModelAdapterError(
		//
		// 		'Model interfaces not compatible:\n\n' +
		// 		typeCheck(apiType()) + '\n\n' +
		// 		`input value type:   "${value}" : ${typeof value}\n` +
		// 		`@Type() definition:   "${instance[property]}" : ${typeof instance[property]}"\n`
		// 	)
		// }
	} else {
		instance[property] = value;
	}
}

/**
 * sets model metadata to a constructor
 */
export function ensureMetaDataObject(target: any, key?: string): ModelMetaData {
	if (!target[MODEL_META]) {
		target[MODEL_META] = {};
	}

	if (!key) {
		return target[MODEL_META];
	}

	if (!target[MODEL_META][key]) {
		target[MODEL_META][key] = { propertyKey: key } as ModelMetaData;
	}
	return target[MODEL_META][key];
}

export function snakeToCamel(s: string): string {
	return s
		.split('_')
		.map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
		.join('');
}

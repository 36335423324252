import { AdaptionTypes, ensureMetaDataObject, TransformerFn } from './internals';
import 'reflect-metadata';

/**
 * Input decorators determines how constructor input data will be transformed into the storeModel/apiModel
 *
 */

/**
 *
 * It sets Class metadata which are used for the resolving (String, Boolean, Number, Date) type of the property.
 *
 * value of the @/Type() is a type of the property passed to the constructor which
 * will be cast to type of the model property
 *
 * ModelAdapter()
 * class Model {
 *     @Type(Number) public id: string;
 *     constructor(inputData: { id: number })
 * }
 *
 * const model = adaptToStore(Model, { id: 99 })
 *
 * => model.id = '99'
 *
 * const apiModel = adaptToApi(Model, model);
 *
 * => apiModel.id = 99
 *
 */
export function Type(apiType: AdaptionTypes) {
	return function (target: any, key: string) {
		const meta = ensureMetaDataObject(target, key);
		const type = Reflect.getMetadata('design:type', target, key);
		meta.apiType = apiType;
		if (type) {
			meta.type = type;
		}
	};
}

/**
 *
 * Marks a field which shared between the api model and store model without any change
 */
export function Field(target: any, key: string) {
	ensureMetaDataObject(target, key);
}
/**
 * it sets Class metadata which are used for the mapping more complex types.
 *
 */
export function Transform<T, K>(transform: TransformerFn<T>, apiTransform: TransformerFn<K>) {
	return function (target: any, key: string) {
		const meta = ensureMetaDataObject(target, key);
		meta.apiTransform = apiTransform;
		meta.transform = transform;
	};
}

/**
 * it sets Class metadata which are used for the resolving the alias.
 *
 * Alias value is the name of the InputData property to which the model property is bound.
 *
 * ModelAdapter()
 * class Model {
 *     @Alias('__id') public id: number;
 *     constructor(inputData: { __id: number })
 * }
 *
 * const model = adaptToStore(Model, { __id: 99 })
 *
 * => model.id = 99
 *
 * const apiModel = adaptToApi(Model, model);
 *
 * => apiModel.__id = 99
 */
export function Alias<T extends Record<string, any>>(property: keyof T) {
	return function (target: any, key: string) {
		const meta = ensureMetaDataObject(target, key);
		meta.alias = property as string;
	};
}

import { environment } from '@imt-web-zone/shared/environments';
import { startActionOf, successActionOf, errorActionOf, Action, ActionSuccess } from '@imt-web-zone/shared/util-store';
import { login, Login } from '@imt-web-zone/zone/state-auth';
import { UpdateFormStatus, UpdateFormDirty, UpdateFormErrors } from '@ngxs/form-plugin';
import { RouterDataResolved, RouterNavigated } from '@ngxs/router-plugin';
import { getActionTypeFromInstance } from '@ngxs/store';

const sanitizedActionsEnum = {
	loginStart: startActionOf(login).type,
	loginSuccess: successActionOf(login).type,
	loginError: errorActionOf(login).type,
};

function actionSanitizer(action: Action) {
	if (action.type === sanitizedActionsEnum.loginStart) {
		(action as Login).params.payload.password = '************';
	} else if (action.type === sanitizedActionsEnum.loginSuccess || action.type === sanitizedActionsEnum.loginError) {
		(action as ActionSuccess<Login>).prevAction.params.payload.password = '************';
	}
	return action;
}

function actionBlacklist(action: any) {
	switch (getActionTypeFromInstance(action)) {
		case UpdateFormStatus.type:
		case UpdateFormDirty.type:
		case UpdateFormErrors.type:
		case RouterDataResolved.type:
		case RouterNavigated.type:
			return true;
	}
	if (action.constructor && action.constructor.typeAsync) {
		return true;
	}
	return false;
}

export const getNgxsSetupDefaults = () => {
	return {
		devtools: {
			disabled: environment.disableReduxDevtools,
			actionBlacklist: [],
			actionBlacklistPredicate: actionBlacklist,
			name: 'imt-web-zone',
			actionSanitizer,
			sanitizedActions: sanitizedActionsEnum,
			//trace: isDev,
			//traceLimit: isDev ? 50 : undefined,
		},
		cacheExpiration: {
			default: environment.cacheExpiration?.default,
		},
		title: {
			app: 'app.name',
			separator: '|',
		},
	};
};

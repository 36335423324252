import { ErrorHandler, inject, Injectable, Injector, NgZone } from '@angular/core';
import { ImtModalTypeEnum } from '@imt-web-zone/shared/model';
import { IMT_MODAL_SERVICE } from '@imt-web-zone/shared/core';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService implements ErrorHandler {
	private apiConfigFacade = inject(ApiConfigFacade);
	private authFacade = inject(AuthFacade);
	private zone = inject(NgZone);
	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(private injector: Injector) {
		window.onerror = function (message, url, line, column, error) {
			console.error('ScriptsError:', message, url, line, column, error);
		};
	}

	public get apiConfig() {
		return this.apiConfigFacade.configSnapshot;
	}
	public get userId() {
		return this.authFacade.userIdSnapshot;
	}

	public handleError(error: any) {
		console.error('GlobalErrorHandler:', error?.originalStack || error);

		if (/Loading chunk [0-9a-zA-Z-~]+ failed/.test(error?.message)) {
			this.zone.run(async () => {
				const modalService = this.injector.get(IMT_MODAL_SERVICE);
				const ref = modalService.open(ImtModalTypeEnum.Confirm);

				ref.componentInstance.title = this.apiConfig.brand.title;
				ref.componentInstance.confirmMessage = 'common.versionoutofdate';
				const modalResult = await ref.result;

				if (modalResult) {
					window.location.reload();
				}
			});
		}
	}
}

import { InjectionToken, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader, MultiTranslateHttpLoaderResource } from './multi-translate-http-loader';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';

export const TRANSLATIONS_RESOURCES: InjectionToken<Array<MultiTranslateHttpLoaderResource>> = new InjectionToken(
	'TRANSLATIONS_RESOURCES',
);

export function translateLoader(injector: Injector) {
	const http = injector.get(HttpClient);
	const apiConfigFacade = injector.get(ApiConfigFacade);
	const zoneAssetsService = injector.get(ZoneAssetsService);
	const resources = injector.get(TRANSLATIONS_RESOURCES);
	return new MultiTranslateHttpLoader(http, apiConfigFacade, zoneAssetsService, [
		...resources,
		{ prefix: '/ng-ui/i18n/imt-ui-', suffix: '.json' },
	]);
}

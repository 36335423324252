import { InjectionToken } from '@angular/core';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';

import { UtilString } from '@imt-web-zone/shared/util';

import { ImtEvents } from './../global/index';

export const BASE_HREF_PROVIDER = {
	provide: APP_BASE_HREF,
	useFactory: (s: PlatformLocation) => UtilString.trimLastSlash(s.getBaseHrefFromDOM()),
	deps: [PlatformLocation],
};

export const IMT_APP_INITIALIZER_SELECTORS: InjectionToken<Array<any>> = new InjectionToken('INITIALIZER_SELECTORS');

export const APP_ROOT_SELECTOR: InjectionToken<string> = new InjectionToken('APP_ROOT_SELECTOR');

export const IMT_EVENTS: InjectionToken<ImtEvents> = new InjectionToken('IMT_EVENTS');
export function imtEventsFactory() {
	return (window as any).imtEvents;
}

/**
 * This identifies which application is running the library and it must be provided in every application.
 */
export const APP_NAME = new InjectionToken<AppNames>('APP_NAME');

export enum AppNames {
	ZONE = 'zone',
	ZONE_HQ_ADMIN = 'zone-hq-admin',
	INSPECTOR_WIDGET = 'inspector-widget',
	INSPECTOR_INTEGRATION = 'inspector-integration',
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'logo-loader-indicator',
	template: `
		<div class="logo-loader" [class.slider-admin]="inAdmin">
			<div class="loader">
				<div class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['./logo-loader-indicator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoLoaderIndicatorComponent {
	@Input() public inAdmin = false;
}

import { Injectable, TemplateRef, inject } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
	ImtModalService,
	ImtModalType,
	ImtModalOptions,
	ImtModalRef,
	ImtModalTypeComponentMap,
} from '@imt-web-zone/shared/model';

import { IMT_MODAL_COMPONENT_MAP } from './imt-ui-modal.symbol';

@Injectable()
export class ImtUiModalService implements ImtModalService {
	private modalService = inject(NgbModal);
	private modalComponentMap = inject<ImtModalTypeComponentMap<ImtModalType<any>>>(IMT_MODAL_COMPONENT_MAP, {
		optional: true,
	});

	/**
	 * Opens a new modal window with the provided content that could be in two different forms:
	 *
	 * a) ImtModalType<any> (defaults to `ImtModalTypeEnum`) - specific predefined type of the component
	 * that must be previously registered in a place where the `ImtUiModalModule` is used via `forRoot` config method
	 * and corresponding config, e.g.:
	 *
	 * ```
	 * this.uiModalService.open(ImtModalTypeEnum.Confirm);
	 * ```
	 *
	 * b) `ComponentType<any>` - pointer to the component, e.g.:
	 *
	 * ```
	 * this.uiModalService.open(ImtUiConfirmDialogComponent);
	 * ```
	 *
	 * c) `TemplateRef<any>` - embedded template
	 */
	public open(
		type: ImtModalType<any> | ComponentType<any> | TemplateRef<any>,
		options?: ImtModalOptions,
	): ImtModalRef {
		let content: unknown;

		// check whether type is component class ('function') or `TemplateRef` instance
		if (typeof type === 'function' || type instanceof TemplateRef) {
			content = type;
		}
		// at least it must be `ImtModalType` if not one of two up above
		else if (!(content = this.modalComponentMap?.[type])) {
			throw new Error(`There is no registered component for the '${type}' ImtModalType!`);
		}

		if (!content) {
			throw new Error(`Failed to process content for the modal service!`);
		}

		return this.modalService.open(content, options) as ImtModalRef;
	}
}

/**
 * Marks no expiration time for a toast message. This should be used only in very rare cases.
 */
export const UI_TOAST_MESSAGE_PERMANENT = -1;

/**
 * Configuration object defining toast messages.
 */
export interface UiToastMessage {
	/**
	 * Text content for the message.
	 */
	text: string;
	/**
	 * Toast message type. Uses `UiToastMessageType.INFO` when not set.
	 */
	type: UiToastMessageType;
	/**
	 * Optional highlighted title for the toast message.
	 */
	title?: string;
	/**
	 * Expiration time for a toast message. By default, uses values from `UiToastMessageServiceOptions`. Using
	 * `UI_TOAST_MESSAGE_PERMANENT` means no expiration and the toast message will stay visible until closed manually.
	 */
	expiration?: number;
	/**
	 * Position for the optional CTA button.
	 */
	buttonPosition?: UiToastMessageButtonPosition;
	/**
	 * Label for the optional CTA button. Setting label will enable the button.
	 */
	buttonLabel?: string;
	/**
	 * Tells the service to skip creating a new toast message if there's already a visible toast with
	 * the same `label` and `text`.
	 */
	ignoreRedundant?: boolean;
	/**
	 * Callback called when the toast message is closed either manually or when it expires.
	 */
	onClose?: () => void;
	/**
	 * Callback called when the CTA button is clicked.
	 */
	onButtonClick?: () => void;
}

export type UiToastMessageForType = Omit<UiToastMessage, 'type'> & {
	type?: UiToastMessage['type'];
};

/**
 * Reference object to the showed toast message.
 */
export interface UiToastMessageRef {
	/**
	 * Closes the toast message.
	 */
	close: () => void;
	/**
	 * Toast message configuration as a readonly object. Modifying this object won't update the rendered toast message.
	 */
	options: Readonly<UiToastMessage>;
	/**
	 * Returns true if the toast is visible.
	 */
	isVisible: () => boolean;
	/**
	 * @ignore
	 * @deprecated
	 *
	 * Do not use this function! It's provided only for backward compatibility.
	 */
	updateContent: (updatedContent: UiToastMessageUpdateContent) => void;
}

export interface UiToastMessageUpdateContent {
	text?: UiToastMessage['text'];
	title?: UiToastMessage['title'];
	type?: UiToastMessage['type'];
}

/**
 * Toast message type.
 */
export enum UiToastMessageType {
	BRAND = 'brand',
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	DANGER = 'danger',
}

/**
 * Position of the action button if enabled.
 */
export enum UiToastMessageButtonPosition {
	RIGHT = 'right',
	BOTTOM = 'bottom',
}

/**
 * https://easings.net/
 */

export const easeOutCubic = (x: number): number => {
	return 1 - Math.pow(1 - x, 3);
};

export const easeInCubic = (x: number): number => {
	return x * x * x;
};

export const easeOutCirc = (x: number): number => {
	return Math.sqrt(1 - Math.pow(x - 1, 2));
};

export const easeInCirc = (x: number): number => {
	return 1 - Math.sqrt(1 - Math.pow(x, 2));
};

import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { environment } from '@imt-web-zone/shared/environments';
import { tap } from 'rxjs/operators';
import { CsrfProtectionService } from '@imt-web-zone/shared/data-access';
import { AppNames, APP_NAME } from '@imt-web-zone/shared/core';
import { ImtUiFormulaTranslateService } from '@imt-web-zone/zone/data-access-formula';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthFacade, AuthorizationType, SetAuthorizationType } from '@imt-web-zone/zone/state-auth';
import {
	NativeFetchInterceptMethod,
	NativeFetchInterceptor,
} from '@imt-web-zone/zone/data-access-native-fetch-interceptor';
import { ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	private formulaTranslate = inject(ImtUiFormulaTranslateService);
	private appName = inject<AppNames>(APP_NAME);
	private fetchInterceptor = inject(NativeFetchInterceptor);
	private router = inject(Router);
	private store = inject(Store);
	private csrfProtectionService = inject(CsrfProtectionService);

	private authFacade = inject(AuthFacade);
	protected get envBaseUrl(): string | undefined {
		return environment.api.baseUrl;
	}

	protected envHeaders?: { [key: string]: string } | undefined = environment.api.headers;
	protected staticPathname?: string;
	private navigationRoute = '/';

	private zoneAssetsService = inject(ZoneAssetsService);
	private anchorElement = document.createElement<'a'>('a');

	constructor() {
		this.router.events.subscribe((e) => {
			if (e instanceof NavigationStart) {
				this.navigationRoute = e.url;
			}
		});

		this.fetchInterceptor.register(async (input: RequestInfo | URL, init?: RequestInit) => {
			let url = '';

			if (typeof input === 'string') {
				url = input.replace(this.envBaseUrl, '');
			} else if (input instanceof URL) {
				url = input.pathname.replace(this.envBaseUrl, '');
			} else {
				url = input.url.replace(this.envBaseUrl, '');
			}

			if (this.isAdminUrl(url)) {
				if (typeof input === 'string') {
					input = this.injectAdminToFullUrl(input);
				} else if (input instanceof URL) {
					input.pathname = this.injectAdminToFullUrl(input.pathname);
				} else {
					input = { ...input.clone(), url: this.injectAdminToFullUrl(input.url) };
				}
			}

			return [input, init] as [RequestInfo, RequestInit?];
		}, NativeFetchInterceptMethod.REQUEST);
	}

	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.staticPathname = this.urlPathname(this.zoneAssetsService.zoneAssetPath('/'));
		// TODO: remove in future
		if (environment.localStorage?.userIdKey) {
			localStorage.removeItem(environment.localStorage.userIdKey);
		}

		request = this.beforeRequest(request);
		request = this.csrfProtectionService.apply(request);

		request = request.clone({
			url: this.baseUrl(request.url),
			headers: this.addDefaultHeaders(request.headers),
		});

		return next.handle(request).pipe(
			tap((ev: HttpEvent<any>) => {
				if (ev instanceof HttpResponse) {
					this.setAuthorizationType(ev);
					this.formulaTranslate.interceptResponse(ev);
				}
			}),
		);
	}

	public setAuthorizationType(response: HttpResponse<any>) {
		const authType = response.headers.get('authType') as AuthorizationType;

		if (this.authFacade.stateSnapshot.type !== authType) {
			this.store.dispatch(new SetAuthorizationType(authType));
			return;
		}
	}

	protected addDefaultHeaders(headers: HttpHeaders): HttpHeaders {
		for (const header in this.envHeaders) {
			if (this.envHeaders.hasOwnProperty(header)) {
				if (!headers.get(header)) {
					headers = headers.set(header, this.envHeaders[header]);
				}
			}
		}
		return headers;
	}

	protected beforeRequest(request: HttpRequest<any>) {
		// we need to use browser's location instead of ActivatedRoute or RouterState, because requests from guards are
		// called before the store is initialized with router state and Angular provides correct ActivatedRoute only to
		// routed components
		if (this.isAdminUrl(request.url)) {
			request = request.clone({ url: '/admin' + request.url });
		}

		if (this.appName === AppNames.ZONE_HQ_ADMIN && request.url.startsWith('/admin')) {
			request = request.clone({ url: request.url.substr(6) });
		}

		return request;
	}

	protected baseUrl(url: string) {
		if (this.appName === AppNames.ZONE_HQ_ADMIN) {
			return `/api${url}`;
		} else if (!url.startsWith('.' + this.staticPathname) && !url.startsWith('/' + environment.streamer?.baseUrl)) {
			return `${this.envBaseUrl}${url}`;
		}
		return url;
	}

	protected isAdminUrl(url: string) {
		return (
			this.appName !== AppNames.ZONE_HQ_ADMIN &&
			(location.pathname.startsWith('/admin') || this.navigationRoute.startsWith('/admin')) &&
			!url.startsWith('/admin') &&
			!(
				url.includes(this.staticPathname + '/i18n') ||
				url.includes(this.staticPathname + '/ng-ui') ||
				url.startsWith('/' + environment.streamer?.baseUrl)
			)
		);
	}

	protected injectAdminToFullUrl(url: string) {
		return url.replace(this.envBaseUrl, `${this.envBaseUrl}/admin`);
	}

	protected urlPathname(url: string): string {
		this.anchorElement.href = url;
		const pathname = this.anchorElement.pathname;
		return pathname;
	}
}

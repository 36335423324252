import { generateActionObject } from '../internal/internal';
import { EntityActionType, Payload, Updater } from './type-alias';
import { EntityState, EntityType } from '../internal/entity-state';
import { Type } from '@angular/core';
import { StateToken } from '@ngxs/store';
import { CollectionStateModel } from '../collection.model';

export type EntitySetActiveAction = Payload<string>;
export type EntityUpdateActiveAction<T> = Payload<Updater<T>>;

export class SetActive {
	/**
	 * Generates an action that sets an ID that identifies the active entity
	 * @param target The targeted state class
	 * @param id The ID that identifies the active entity
	 * @param context Text that will be appended to the action type in redux devtools
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, id: string, context: string) {
		return generateActionObject(EntityActionType.SetActive, target, id).setContext(context);
	}
}

export class ClearActive {
	/**
	 * Generates an action that clears the active entity in the given state
	 * @param target The targeted state class
	 * @param context Text that will be appended to the action type in redux devtools
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, context: string) {
		return generateActionObject(EntityActionType.ClearActive, target).setContext(context);
	}
}

export class RemoveActive {
	/**
	 * Generates an action that removes the active entity from the state and clears the active ID.
	 * @param target The targeted state class
	 * @param context Text that will be appended to the action type in redux devtools
	 */
	constructor(target: Type<EntityState<any>> | StateToken<any>, context: string) {
		return generateActionObject(EntityActionType.RemoveActive, target).setContext(context);
	}
}

export class UpdateActive<T extends Record<string, any>> {
	/**
	 * Generates an action that will update the current active entity.
	 * If no entity is active a runtime error will be thrown.
	 * @param target The targeted state class
	 * @param payload An Updater payload
	 * @param context Text that will be appended to the action type in redux devtools
	 * @see Updater
	 */
	constructor(
		target: Type<EntityState<T>> | StateToken<T>,
		payload: Updater<T extends CollectionStateModel<any> ? EntityType<T> : T>,
		context: string,
	) {
		return generateActionObject(EntityActionType.UpdateActive, target, payload).setContext(context);
	}
}

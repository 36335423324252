import { Injectable, inject } from '@angular/core';
import { GET, Path, HttpBuilder, Pg, Pagination, QueryData, QueryType, ParamType } from '@imt-web-zone/shared/util';
import { Observable } from 'rxjs';
import {
	DlqsLogApiModel,
	ListDlqsLogsPayload,
	ListDlqsLogsResponse,
	DlqExecutionLogPayload,
	DlqExecutionLogResponse,
} from './dlqs-logs.interface';
import { HttpApiClient } from '@imt-web-zone/shared/data-access-http-clients';

@Injectable({ providedIn: 'root' })
export class DlqsLogsService extends HttpBuilder {
	constructor() {
		const http = inject(HttpApiClient);

		super(http);
	}

	@GET('dlqs/{dlqId}/logs')
	public listDlqsLogs$(
		@Path('dlqId') dlqId: ListDlqsLogsPayload['params']['dlqId'],
		@QueryData queryData: QueryType<ListDlqsLogsPayload>,
		@Pagination pg?: Pg<DlqsLogApiModel, 'imtId'>,
	): Observable<ListDlqsLogsResponse> {
		return undefined;
	}

	@GET('dlqs/{dlqId}/logs/{executionId}')
	public executionLog$(
		@Path('dlqId') dlqId: ParamType<DlqExecutionLogPayload, 'dlqId'>,
		@Path('executionId')
		executionId: ParamType<DlqExecutionLogPayload, 'executionId'>,
	): Observable<DlqExecutionLogResponse> {
		return undefined;
	}
}

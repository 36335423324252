import { createAsyncLoadAction, ActionType, createAsyncAction } from '@imt-web-zone/shared/util-store';
import { ListDlqsPayload, DeleteDlqPayload, ItemDlqPayload, ListDlqsResponse, DeleteDlqResponse, ItemDlqResponse } from './dlqs.interface';

// tslint:disable:max-line-length

export const listDlqs = createAsyncLoadAction<ListDlqsPayload, ListDlqsResponse>('[Dlqs API] GET list dlqs');
export type ListDlqs = ActionType<typeof listDlqs>;

export const deleteDlq = createAsyncAction<DeleteDlqPayload, DeleteDlqResponse>('[Dlqs API] DELETE delete dlq');
export type DeleteDlq = ActionType<typeof deleteDlq>;

export const itemDlq = createAsyncLoadAction<ItemDlqPayload, ItemDlqResponse>('[Dlqs API] GET item dlq');
export type ItemDlq = ActionType<typeof itemDlq>;

<button
	*ngIf="!hideBtnSecondary"
	[disabled]="disableBtnSecondary"
	type="button"
	class="btn btn-sm btn-outline-secondary"
	(click)="onDismiss(reason)"
>
	{{ btnSecondaryText }}
</button>
<button
	*ngIf="!hideBtnPrimary"
	[disabled]="disableBtnPrimary"
	type="button"
	class="btn btn-sm btn-primary"
	[ngClass]="btnPrimaryClass"
	[class.dmo-ml-16]="spacingBetweenButtons"
	(click)="onSubmit(result)"
>
	{{ btnPrimaryText }}
</button>
<ng-content></ng-content>

import {
	ActionType,
	AsyncLoadActionMeta,
	createAction,
	createAsyncAction,
	createAsyncLoadAction,
	props
} from '@imt-web-zone/shared/util-store';
import {
	LoadAuthUserResponse,
	LoginPayload,
	LoadAuthUserPayload,
	TfaLoginPayload,
	Enable2faResponse,
	Disable2faResponse,
	LoginResponse,
	TfaLoginResponse,
	LogoutResponse,
	LogoutPayload,
	AuthUserApiModel,
	UsersUnreadNotificationsResponse,
	Get2faResponse,
	RegisterPartnerPayload,
	RegisterPartnerResponse,
	PartialUpdateUserPayload,
	PartialUpdateUserResponse,
} from './auth.interface';
import { AuthStateModel } from './auth.model';
import { ActionMessageMetadata } from '@imt-web-zone/shared/util-store';

export const loadAuthUser = createAsyncLoadAction<LoadAuthUserPayload, LoadAuthUserResponse, { fromGuard: boolean } & AsyncLoadActionMeta>('[Auth API] load auth user');
export type LoadAuthUser = ActionType<typeof loadAuthUser>;

export const login = createAsyncAction<LoginPayload, LoginResponse>('[Auth API] login');
export type Login = ActionType<typeof login>;

export const tfaLogin = createAsyncAction<TfaLoginPayload, TfaLoginResponse>('[Auth API] two factor login');
export type TfaLogin = ActionType<typeof tfaLogin>;

export const logout = createAsyncAction<LogoutPayload, LogoutResponse>('[Auth API] logout user');
export type Logout = ActionType<typeof logout>;

export const updateUserFromFormula = createAction('Update user from formula', props<{ payload: Partial<AuthUserApiModel> }>());
export type UpdateUserFromFormula = ActionType<typeof updateUserFromFormula>;

export const updateUserUnreadNotificationsRequest =
	createAsyncAction<Record<string, never>, UsersUnreadNotificationsResponse>(
	'[Auth API] GET user unread notifications', true
);
export type UpdateUserUnreadNotificationsRequest = ActionType<typeof updateUserUnreadNotificationsRequest>;

export const decrementUserUnreadNotification = createAction('Decrement user unread notifications', props<{ imtZoneId?: number, count?: number }>());
export type DecrementUserUnreadNotification = ActionType<typeof decrementUserUnreadNotification>;

export const leaveAdministration = createAction('[Router] leave administration');
export type LeaveAdministration = ActionType<typeof leaveAdministration>;

export const leaveZoneInstall = createAction('[Router] leave zone install');
export type LeaveZoneInstall = ActionType<typeof leaveZoneInstall>;

export const leaveMaintenanceMode = createAction('[Router] leave maintenance mode');
export type LeaveMaintenanceMode = ActionType<typeof leaveMaintenanceMode>;

export const confirmForcedPasswordChange = createAction('[Auth] confirm forced password change', props<{ metadata?: ActionMessageMetadata }>());
export type ConfirmForcedPasswordChange = ActionType<typeof confirmForcedPasswordChange>;

export const changeAuthUserLanguageRequest = createAsyncAction<PartialUpdateUserPayload, PartialUpdateUserResponse>('[Auth API] PATCH auth user language');
export type ChangeAuthUserLanguageRequest = ActionType<typeof changeAuthUserLanguageRequest>;

export const get2faDataRequest = createAsyncLoadAction<Record<string, never>, Get2faResponse>('[Auth API] get 2fa data request');
export type Get2faDataRequest = ActionType<typeof get2faDataRequest>;

export const disable2faSuccess = createAction('[Auth API] [success] disable 2fa request', props<{payload: Disable2faResponse}>());
export type Disable2faSuccess = ActionType<typeof disable2faSuccess>;

export const enable2faSuccess = createAction('[Auth API] [success] enable 2fa request', props<{payload: Enable2faResponse}>());
export type Enable2faSuccess = ActionType<typeof enable2faSuccess>;

export class SetAuthorizationType {
	public static readonly type: string = '[Auth API] set authorization type';
	constructor(public payload: AuthStateModel['type']) { }
}

export const registerPartner = createAsyncAction<RegisterPartnerPayload, RegisterPartnerResponse>('[Users API] register partner');
export type RegisterPartner = ActionType<typeof registerPartner>;

export const patchAuthState = createAction('[Auth] patch state', props<{payload: Partial<AuthStateModel>}>())
export type PatchAuthState = ActionType<typeof patchAuthState>;

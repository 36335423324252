import { NgModule, NgModuleRef, ModuleWithProviders, inject } from '@angular/core';

import { setInjector } from './internals/static-injector';

@NgModule()
export class UtilDispatchModule {
	constructor() {
		const ngModuleRef = inject<NgModuleRef<unknown>>(NgModuleRef);

		setInjector(ngModuleRef.injector);
		ngModuleRef.onDestroy(() => {
			setInjector(null);
		});
	}

	public static forRoot(): ModuleWithProviders<UtilDispatchModule> {
		return {
			ngModule: UtilDispatchModule,
		};
	}
}
export { Dispatch } from './decorators/dispatch.decorator';

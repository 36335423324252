import { Injectable } from '@angular/core';
import {
	animationFrames,
	BehaviorSubject,
	combineLatestWith,
	debounceTime,
	defer,
	EMPTY,
	map,
	Observable,
	shareReplay,
	startWith,
	switchMap,
	takeWhile,
	withLatestFrom,
} from 'rxjs';
import { easeInCirc, easeOutCirc } from '@imt-web-zone/make-design-system/util';

export enum UiFirstLevelNavigationState {
	SLIM = 78,
	WIDE = 275,
}

const NAV_ANIMATION_DURATION = 200;

@Injectable()
export class UiFirstLevelNavigationService {
	private state$ = new BehaviorSubject(UiFirstLevelNavigationState.SLIM);
	private secondLvlNavHovered$ = new BehaviorSubject(false);

	public width$: Observable<number> = this.state$.pipe(
		combineLatestWith(this.secondLvlNavHovered$),
		withLatestFrom(defer(() => this.width$)),
		debounceTime(10),
		switchMap(([[newState, secondLvlNavHovered], startWidth]) => {
			if (startWidth > UiFirstLevelNavigationState.SLIM && secondLvlNavHovered) {
				newState = UiFirstLevelNavigationState.WIDE;
			}

			if (startWidth === newState) {
				return EMPTY;
			}
			const shrinking = newState === UiFirstLevelNavigationState.SLIM;
			const range = UiFirstLevelNavigationState.WIDE - UiFirstLevelNavigationState.SLIM;

			/**
			 * Calculate the current animation progress based on the current menu width. Since the functions that
			 * calculate width are non-linear we need to convert width to time offset where the animation is.
			 */
			let timeOffset = 0;
			const progress = (startWidth - UiFirstLevelNavigationState.SLIM) / range;
			if (newState === UiFirstLevelNavigationState.WIDE) {
				timeOffset = easeInCirc(progress) * NAV_ANIMATION_DURATION;
			} else if (newState === UiFirstLevelNavigationState.SLIM) {
				timeOffset = (1 - easeOutCirc(progress)) * NAV_ANIMATION_DURATION;
			}

			return animationFrames().pipe(
				map((frame) => {
					let animationProgress = (timeOffset + frame.elapsed) / NAV_ANIMATION_DURATION;
					if (animationProgress > 1) {
						animationProgress = 1;
					}

					const widthPerc = shrinking ? easeInCirc(1 - animationProgress) : easeOutCirc(animationProgress);
					return Math.round(UiFirstLevelNavigationState.SLIM + widthPerc * range);
				}),
				takeWhile((width) => width !== newState, true),
			);
		}),
		startWith(UiFirstLevelNavigationState.SLIM),
		shareReplay(1),
	);

	public set state(state: UiFirstLevelNavigationState) {
		this.state$.next(state);
	}

	public get state() {
		return this.state$.getValue();
	}

	public set secondLvlNavHovered(hovered: boolean) {
		this.secondLvlNavHovered$.next(hovered);
	}

	public get secondLvlNavHovered() {
		return this.secondLvlNavHovered$.getValue();
	}

	public toggle() {
		this.state =
			this.state === UiFirstLevelNavigationState.WIDE
				? UiFirstLevelNavigationState.SLIM
				: UiFirstLevelNavigationState.WIDE;
	}
}

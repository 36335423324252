// Define your router selectors in '/store/router/router.selectors.ts
import { Params } from '@angular/router';
import { Pg } from '@imt-web-zone/shared/util';
import { RouterState, RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';

import { RouterUrlStateModel } from './router.model';

export class RouterSelectors {
	// base selector
	@Selector([RouterState])
	public static urlState({ state }: RouterStateOuterModel<RouterUrlStateModel>) {
		if (!state) {
			return null;
		}
		return state;
	}

	@Selector([RouterState])
	public static data({ state }: RouterStateOuterModel<RouterUrlStateModel>) {
		if (!state) {
			return null;
		}
		return state.data;
	}

	@Selector([RouterState])
	public static params({ state }: RouterStateOuterModel<RouterUrlStateModel>) {
		if (!state) {
			return null;
		}
		return state.params;
	}

	@Selector([RouterState])
	public static queryParams({
		state,
	}: RouterStateOuterModel<RouterUrlStateModel>): null | (Params & { pg?: Pg<any, any> }) {
		if (!state) {
			return null;
		}
		return state.queryParams;
	}

	@Selector([RouterState])
	public static url({ state }: RouterStateOuterModel<RouterUrlStateModel>) {
		if (!state) {
			return null;
		}
		return state.url;
	}
}

import { StateContext } from '@ngxs/store';
import { CacheFunctions } from '../../lib/cache/cache.functions';
import { EntityStateModel } from '../../lib/collection/internal/models';
import { AdaptersMap } from '../../lib/collection/internal/entity-state';
import { AsyncAction, AsyncLoadAction } from '../../lib/async-action/async-action';
import { catchError, from, map, mergeMap, Observable, of, switchMap, throwError } from 'rxjs';
import { createActionType, EffectActionsTypes } from './effect-actions-types';
import { EffectAction, RunEffectAction } from './effect-action';
import { Action } from '../async-action/action-models';
import { tap } from 'rxjs/operators';

function isLoadAction(action: any): action is AsyncLoadAction<any, any, any> {
	return typeof action.cacheKey !== 'undefined';
}

type ResponseEntity<R> = (res: R) => { [key: string]: any } | Array<{ [key: string]: any }>;

/**
 *
 * handle asynchronous action, resolve request states and cache
 */
export function effectFactory<R extends Record<string, any> = object>(
	ctx: StateContext<any>,
	action: (AsyncAction<any, R> & Action) | RunEffectAction,
	httpRequest$: Observable<R>,
	responseEntities: keyof R | ResponseEntity<R>,
	successActionCb: (res: R) => void,
): Observable<R | null> {
	let type: string;
	action.request = true;
	action.hideFromDevtools = true;
	if (action instanceof RunEffectAction) {
		type = action?.effectOptions?.type || '';
	} else {
		type = action.type;
	}

	const _isLoadAction = isLoadAction(action);

	/**
	 * Resolve Cache - if not cached Dispatch action [from cache]
	 */
	if (_isLoadAction && CacheFunctions.isActionCached(ctx, action)) {
		return ctx
			.dispatch({
				type: createActionType(type, EffectActionsTypes.FROM_CACHE),
				prevAction: action,
				fromCache: true,
			} as EffectAction)
			.pipe(map(() => null));
	}

	/**
	 * Dispatch [start] action
	 */
	patchState(ctx, { loading: true, error: null });
	return ctx
		.dispatch({
			...action,
			...{ hideFromDevtools: undefined, type: createActionType(type, EffectActionsTypes.START) },
		})
		.pipe(
			/**
			 * perform HTTP request
			 */
			mergeMap(() => httpRequest$),
			/**
			 * save cache + dispatch [success] action
			 */
			mergeMap((res) => {
				action.response = res;
				if (successActionCb) {
					const ids: Array<string> = getIds<R>(ctx, res, responseEntities);
					return resolveCallback$(successActionCb(res)).pipe(
						mergeMap(() => {
							if (_isLoadAction) {
								let metadata;

								const pg = res['pg'];
								if (pg?.returnTotalCount) {
									metadata = {
										totalCount: Number(pg['totalCount']),
									};
								}
								CacheFunctions.setActionCache(ctx, action, ids, metadata);
							}

							/**
							 * Dispatch [success] action
							 */
							return ctx.dispatch({
								type: createActionType(type, EffectActionsTypes.SUCCESS),
								prevAction: action,
								response: res,
							} as EffectAction);
						}),
						map(() => res),
					);
				}
				return of(res);
			}),
			tap(() => patchState(ctx, { loading: false })),

			/**
			 * Dispatch [error] action
			 */
			catchError((error: Error) => {
				patchState(ctx, { loading: false, error });
				return ctx
					.dispatch({
						type: createActionType(type, EffectActionsTypes.ERROR),
						prevAction: action,
						error,
					} as EffectAction)
					.pipe(switchMap(() => throwError(() => error)));
			}),
		);
}

/**
 * resolve function as simple function or promise
 *
 */
function resolveCallback$(funcExec: any) {
	return funcExec ? from(funcExec) : of(void 0);
}

function patchState(ctx: StateContext<any>, update: { error?: Error | null; loading?: boolean }) {
	const state = ctx.getState();
	ctx.setState({ ...state, ...update });
}

/**
 * Get Ids from response
 */
function getIds<R>(
	ctx: StateContext<EntityStateModel<any>>,
	res: any,
	responseEntities: keyof R | ResponseEntity<any>,
): Array<string> {
	const state = ctx.getState();
	let entities: any | Array<Record<string, unknown>>;
	if (typeof responseEntities === 'function') {
		entities = responseEntities(res);
	} else {
		entities = res[responseEntities];
	}

	if (!(entities instanceof Array)) {
		if (entities !== null && typeof entities === 'object') {
			entities = [entities];
		} else {
			return [];
		}
	} else if (entities?.length && typeof entities[0] === 'string') {
		return entities;
	}
	return (<Array<Record<string, string>>>entities)
		.map((entity) => {
			let idKey: string;
			try {
				idKey = AdaptersMap[state.storeKey as string]?.mustGetIdOf(entity);
			} catch {
				return '';
			}

			// todo remove code bellow, use only return value above
			// code bellow is used just for backward compatibility
			// this solution does not work for computed ids
			if (idKey) {
				return idKey;
			}
			return entity[state.idKey as string];
		})
		.filter((entity) => entity !== '');
}

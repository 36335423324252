import { createSelector, getActionTypeFromInstance, StateContext } from '@ngxs/store';
import { CacheData, CacheModelState } from './cache.model';
import { Params } from '@angular/router';
import { stringify } from 'qs';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';
import { CollectionStateModel } from '../collection/collection.model';
import { AsyncLoadAction } from '../async-action/async-action';
import { CacheExpiration } from '@imt-web-zone/shared/model';
import { EntityStateModel } from '../collection/internal/models';
import { elvis, NGXS_META_KEY } from '../collection/internal/internal';
import { LoadActionParams } from '../async-action/async-action.interface';
import { inject, Injectable } from '@angular/core';
import { NGXS_CACHE_OPTIONS } from './symbols';

export type CacheStateSelector<T> = (state: CacheModelState) => T & Partial<EntityStateModel<any>>;

@Injectable({
	providedIn: 'root',
})
export class CacheFunctions {
	public static cacheOptions: CacheFunctions['_cacheOptions'];
	public _cacheOptions = inject(NGXS_CACHE_OPTIONS);

	constructor() {
		CacheFunctions.cacheOptions = this._cacheOptions;
	}

	/**
	 * returns map with cached parameters
	 */

	public static getCacheSelector(stateToken: any): CacheStateSelector<CacheModelState['cache']> {
		return createSelector([stateToken], (state) => {
			return state?.cache || {};
		});
	}

	public static getCacheDataSelector(stateToken: any, url: string): CacheStateSelector<CacheData> {
		return createSelector([stateToken], (state) => {
			return (state?.cache || {})[url] || null;
		});
	}

	/**
	 * returns true if cache contains some keys
	 */
	public static getCacheExistsSelector(stateToken: any): CacheStateSelector<boolean> {
		return createSelector([stateToken], (state) => {
			return !!Object.keys(state?.cache || {}).length;
		});
	}

	/**
	 * returns map with cached parameters
	 * @deprecated use getCacheSelector
	 */
	public static cacheSelector(context: any): CacheStateSelector<CacheModelState['cache']> {
		const that = context;
		return (state: any) => {
			const subState = elvis(state, that[NGXS_META_KEY].path) as CacheModelState;
			return subState?.cache || {};
		};
	}

	/**
	 *
	 * @deprecated use getCacheSelector
	 */
	public static cacheDataSelector(context: any, url: string): CacheStateSelector<CacheData> {
		const that = context;
		return (state: any) => {
			const subState = elvis(state, that[NGXS_META_KEY].path) as CacheModelState;
			return subState?.cache[url] || null;
		};
	}

	/**
	 * returns true if cache contains some keys
	 * @deprecated getCacheDataSelector
	 */
	public static cacheExistsSelector(context: any): CacheStateSelector<boolean> {
		const that = context;
		return (state: any) => {
			const subState = elvis(state, that[NGXS_META_KEY].path) as CacheModelState;
			return !!Object.keys(subState.cache || {}).length;
		};
	}

	/**
	 *
	 * clears whole cache or one key
	 */
	public static clearCache<T extends CacheModelState>(ctx: StateContext<T>, url?: string) {
		let state = ctx.getState();
		state = { ...state };
		if (url) {
			state.cache = { ...state.cache };
			delete state.cache[url];
			ctx.patchState(state);
		} else {
			state.cache = {};
			ctx.setState(state);
		}
	}

	/**
	 * checks if newly loaded data misses some id from previous load. Returns missing ids
	 */
	public static compareChanges<T extends CacheModelState>(
		ctx: StateContext<T>,
		url: string,
		loadedIds: Array<string> = [],
	): Array<string> {
		const state = ctx.getState();
		const cachedData = state?.cache?.[url];
		if (cachedData) {
			const cachedIdsMap = cachedData.ids.reduce((obj, id) => {
				obj[id] = true;
				return obj;
			}, {} as Record<string, true>);
			return loadedIds.filter((id) => !cachedIdsMap[id]);
		}
		return [];
	}

	/**
	 *
	 * updates cache by key
	 */
	public static updateCache<T extends CacheModelState>(
		ctx: StateContext<T>,
		url: string,
		ids: Array<string> = [],
		metadata?: CacheData['metadata'],
	) {
		let state = ctx.getState();
		state = { ...state };
		state.cache = {
			...state.cache,
			...{
				[url]: {
					timestamp: Date.now(),
					ids,
					metadata,
				},
			},
		};
		ctx.patchState(state);
	}

	/**
	 * if state of the cached entity is not child of collectionState,
	 * you must overload deleting mechanism
	 */
	public static deleteExpiredItems<T extends CacheModelState>(ctx: StateContext<T>, idsToDelete: Array<string>) {
		CacheFunctions._deleteExpiredItemsFromState(ctx, idsToDelete);
	}

	/**
	 * Check if action is cached
	 */
	public static isActionCached<T extends CacheModelState>(
		ctx: StateContext<T>,
		action: AsyncLoadAction<any, any, any>,
	) {
		if (action.metadata?.['cacheIgnore'] === true) {
			return false;
		}
		const state = ctx.getState();
		const isCached = !!Object.keys(state?.cache || {}).length;
		if (!isCached) {
			return false;
		} else {
			return this.isCached(state, action);
		}
	}

	public static setActionCache<T extends CacheModelState>(
		ctx: StateContext<T>,
		action: AsyncLoadAction<any, any>,
		ids: Array<string>,
		metadata?: CacheData['metadata'],
	) {
		this._deleteExpiredItems(ctx, action.cacheKey, ids);
		CacheFunctions.updateCache(ctx, action.cacheKey, ids, metadata);
	}

	@ImmutableContext()
	private static _deleteExpiredItemsFromState(ctx: StateContext<any>, idsToDelete: Array<string>) {
		const state = ctx.getState();
		if (!state.ids) {
			return;
		}

		ctx.setState((_state: CollectionStateModel<any>) => {
			_state.ids = _state.ids.filter((_id) => {
				const found = idsToDelete.includes(_id);
				if (found) {
					delete _state.entities[_id];
				}
				return !found;
			});
			return _state;
		});
	}

	/**
	 * serialize parameters from object to string: `{ userId: 1, companyId: 2 }` => 'userId=1&companyId=2'
	 *
	 */
	public static serializeParams(loadAction: { params: LoadActionParams }, type?: string): string {
		type = type || getActionTypeFromInstance(loadAction);
		const stringParams: Array<string> = [];
		CacheFunctions.stringifyParams('p', stringParams, loadAction.params, 'params');
		CacheFunctions.stringifyParams('q', stringParams, loadAction.params, 'query');
		CacheFunctions.stringifyParams('h', stringParams, loadAction.params, 'headers');
		return type + ' ' + (stringParams.length ? '?' + stringParams.join('&') : '').replace(/&$/, '');
	}

	private static stringifyParams(
		prefix: string,
		stringParams: Array<string>,
		params: Params = {},
		paramKey: keyof LoadActionParams,
	) {
		let parameters = params[paramKey];
		if (parameters) {
			parameters = Object.keys(parameters).reduce((obj, key) => {
				obj[`${prefix}_${key}`] = parameters[key];
				return obj;
			}, {} as Record<string, string>);
			stringParams.push(stringify(parameters, { encode: false, arrayFormat: 'brackets' }));
		}
	}

	/**
	 *
	 * compares cache expiration with value saved in the store
	 * returns true if cache exists and is valid
	 *
	 */
	private static isCached(state: CacheModelState, action: AsyncLoadAction<any, any, any>): boolean {
		const defaultCache = CacheFunctions.getDefaultCache(state.storeKey as string);
		const cacheExpiration: CacheExpiration = action.metadata?.cacheExpiration || defaultCache;
		if (cacheExpiration === 'never') {
			return true;
		} else if (cacheExpiration === 0) {
			return false;
		}
		const date = state.cache[action.cacheKey];

		if (!date) {
			return false;
		}
		return !this.isCacheExpired(date.timestamp, cacheExpiration);
	}

	/**
	 *
	 * delete expired items - items that were not loaded in response with same input parameters
	 *
	 */
	private static _deleteExpiredItems<T extends CacheModelState>(
		ctx: StateContext<T>,
		cacheKey: string,
		loadedIds: Array<string>,
	) {
		const cache = ctx.getState()?.cache?.[cacheKey];
		if (cache?.ids?.length) {
			const loadedIdsMap = loadedIds.reduce((obj, id) => {
				obj[id] = true;
				return obj;
			}, {} as Record<string, true>);
			const idsToDelete = cache?.ids.filter((id) => !loadedIdsMap[id]);
			this.deleteExpiredItems(ctx, idsToDelete);
		}
	}

	/**
	 *
	 * check if cache is expired
	 */
	private static isCacheExpired(date: number, cacheExpiration = 0) {
		// this.debugLog('expired', date + cacheExpiration < new Date().getTime());
		return date + cacheExpiration < new Date().getTime();
	}

	private static getDefaultCache(stateKey: string): CacheExpiration {
		let ngxsOptions = this.cacheOptions;

		if (
			ngxsOptions.cacheExpiration?.states &&
			typeof ngxsOptions.cacheExpiration?.states[stateKey] !== 'undefined'
		) {
			return ngxsOptions.cacheExpiration?.states[stateKey];
		} else if (typeof ngxsOptions.cacheExpiration?.default !== 'undefined') {
			return ngxsOptions.cacheExpiration?.default;
		}
		return 0;
	}
}

import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';

// This interface is for obviousness and explicitly describes that `activeLangFn`
// returns string corresponds to the active lang.
type TranslocoActiveLang = string;

/**
 * Function which uses `APP_INITIALIZER` and ensures that translation files are loaded
 * before application is bootstrapped.
 * Expects asynchronous function with logic for loading and setting up default and active language.
 * See: https://ngneat.github.io/transloco/docs/recipes/prefetch/
 */
export const translocoInit = (
	activeLangFn: (...deps: Array<unknown>) => Promise<TranslocoActiveLang>,
	...deps: Array<unknown>
): Provider => {
	return {
		provide: APP_INITIALIZER,
		useFactory: (...deps: Array<unknown>) => {
			const transloco = inject(TranslocoService);

			return async () => {
				const activeLang = await activeLangFn(...deps);

				// This will make sure the application doesn't bootstrap before Transloco
				// loads the translation file based on the current active language.
				await firstValueFrom(transloco.load(activeLang));
			};
		},
		multi: true,
		deps,
	};
};

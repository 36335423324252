import { inject, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceInit } from '@imt-web-zone/core/util-core';

@Injectable({ providedIn: 'root' })
export class AppLoader implements ServiceInit {
	private router = inject(Router);

	constructor() {
		AppLoader.showAppLoader();
	}

	public async initialize() {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd && AppLoader.isVisible))
			.subscribe((ev) => {
				AppLoader.removeAppLoader();
			});
	}

	public static get loader() {
		return document ? document.getElementById('app-init-loader') : null;
	}

	public static get isVisible() {
		const loader = AppLoader.loader;
		return loader && loader?.style?.display !== 'none';
	}

	public static removeAppLoader(): void {
		const loader = AppLoader.loader;
		if (loader) {
			loader.style.display = 'none';
		}
	}

	public static showAppLoader(): void {
		const loader = AppLoader.loader;
		if (loader) {
			if (window.location.pathname.startsWith('/remote')) {
				loader.style.display = 'none';
			}

			loader.style.display = 'block';
		}
	}
}

import { InjectionToken } from '@angular/core';

import { StatesClasses } from './ngxs-testing.interface';
import { provideStore } from '@ngxs/store';

/**
 * Helper token to determine whether we are in a testing environment, respectivelly
 * whether we are explicitly using `provideTestingStore()` instead of `provideStore`.
 */
export const NGXS_TESTING_ENV = new InjectionToken<boolean>('NGXS_TESTING_ENV');

export function provideTestingStore(states: StatesClasses = []) {
	return [
		provideStore(states, {
			developmentMode: true,
		}),
		{
			provide: NGXS_TESTING_ENV,
			useValue: true,
		},
	];
}

import { State, Action, StateContext } from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import { ImmutableContext } from '@imt-web-zone/shared/util-immer-adapter';
import { CollectionState, CollectionStateModel, IdStrategy } from '@imt-web-zone/shared/util-store';
import { updateLog, listLogsSuccess } from '../scenario-logs/scenario-logs.state';
import {
	DLQS_LOGS_STATE_TOKEN,
	DlqsLogModel,
	DlqsLogsAdapter,
	DlqsLogsService,
	getInitialDlqsLogsStateModel,
	UpdateDlqLog,
	listDlqsLogs,
	ListDlqsLogs,
	addDlqLog,
	AddDlqLog,
	updateDlqLog,
	dlqExecutionLog,
	DlqExecutionLog,
} from '@imt-web-zone/zone/state-dlqs-logs';

@State({
	name: DLQS_LOGS_STATE_TOKEN,
	defaults: getInitialDlqsLogsStateModel(),
})
@Injectable()
export class DlqsLogsState extends CollectionState<DlqsLogModel> {
	private service = inject(DlqsLogsService);

	constructor() {
		super(DlqsLogsState, 'imtId', IdStrategy.EntityIdGenerator);
	}

	@Action(listDlqsLogs)
	public listDlqsLogsRequest(ctx: StateContext<CollectionStateModel<DlqsLogModel>>, action: ListDlqsLogs) {
		const { params, query } = action.params;
		const request = this.service.listDlqsLogs$(params.dlqId, query, query?.pg);
		return this.createEffect$(ctx, action, request, 'dlqLogs', (res) => {
			const logs = res.dlqLogs.map((log) => DlqsLogsAdapter.toStore(log, action.params.params.dlqId));
			this.listDlqsLogsRequestSuccess(ctx, logs, { ignoreRunning: true });
		});
	}

	@ImmutableContext()
	public listDlqsLogsRequestSuccess(
		ctx: StateContext<CollectionStateModel<DlqsLogModel>>,
		payload: Array<DlqsLogModel>,
		metadata: ListDlqsLogs['metadata'],
	) {
		listLogsSuccess(ctx, payload, metadata);
	}

	@Action(addDlqLog)
	public addDlqLog(ctx: StateContext<CollectionStateModel<DlqsLogModel>>, action: AddDlqLog) {
		const state = ctx.getState();
		const log = state.entities[state.ids.find((id) => state.entities[id].id === action.payload.id)];

		if (log && action.payload.imtId.startsWith('new_')) {
			this.update(ctx, {
				payload: { id: log.imtId, data: action.payload },
			});
		} else {
			this.createOrReplace(ctx, action);
		}
	}

	@Action(updateDlqLog)
	@ImmutableContext()
	public updateScenarioLog(ctx: StateContext<CollectionStateModel<DlqsLogModel>>, action: UpdateDlqLog) {
		updateLog(ctx, action);
	}

	@Action(dlqExecutionLog)
	public scenarioExecutionLogRequest(ctx: StateContext<CollectionStateModel<DlqsLogModel>>, action: DlqExecutionLog) {
		const { params } = action.params;
		const request = this.service.executionLog$(params.dlqId, params.executionId);
		return this.createEffect$(ctx, action, request, 'dlqLog', (res) => {
			this.upsert(ctx, { payload: DlqsLogsAdapter.toStore(res.dlqLog, action.params.params.dlqId) });
			this.setActive(ctx, { payload: res.dlqLog.imtId });
		});
	}
}

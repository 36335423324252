import { ConnectedPosition } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { UiTooltipArrowPosition, UiTooltipConfig, UiTooltipPosition } from './ui-tooltip.interface';

export const DEFAULT_TOOLTIP_CONFIG = new InjectionToken<UiTooltipConfig>('DEFAULT_TOOLTIP_CONFIG');

export type ConnectedPositionWithPos = ConnectedPosition & {
	arrowPosition: UiTooltipArrowPosition;
	tooltipPosition: UiTooltipPosition;
};

export const TOOLTIP_CONNECT_POSITIONS: ConnectedPositionWithPos[] = [
	{
		originX: 'center',
		originY: 'top',
		overlayX: 'center',
		overlayY: 'bottom',
		weight: 4,
		arrowPosition: UiTooltipArrowPosition.Bottom,
		tooltipPosition: UiTooltipPosition.Top,
	},
	{
		originX: 'center',
		originY: 'bottom',
		overlayX: 'center',
		overlayY: 'top',
		weight: 3,
		arrowPosition: UiTooltipArrowPosition.Top,
		tooltipPosition: UiTooltipPosition.Bottom,
	},
	{
		originX: 'start',
		originY: 'center',
		overlayX: 'end',
		overlayY: 'center',
		weight: 2,
		arrowPosition: UiTooltipArrowPosition.Right,
		tooltipPosition: UiTooltipPosition.Left,
	},
	{
		originX: 'end',
		originY: 'center',
		overlayX: 'start',
		overlayY: 'center',
		weight: 1,
		arrowPosition: UiTooltipArrowPosition.Left,
		tooltipPosition: UiTooltipPosition.Right,
	},
	{
		originX: 'start',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'bottom',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.BottomLeft,
		tooltipPosition: UiTooltipPosition.TopLeft,
	},
	{
		originX: 'end',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'bottom',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.BottomRight,
		tooltipPosition: UiTooltipPosition.TopRight,
	},

	{
		originX: 'end',
		originY: 'top',
		overlayX: 'start',
		overlayY: 'top',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.LeftTop,
		tooltipPosition: UiTooltipPosition.RightTop,
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'bottom',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.LeftBottom,
		tooltipPosition: UiTooltipPosition.RightBottom,
	},

	{
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.TopLeft,
		tooltipPosition: UiTooltipPosition.BottomLeft,
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.TopRight,
		tooltipPosition: UiTooltipPosition.BottomRight,
	},

	{
		originX: 'start',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'bottom',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.RightBottom,
		tooltipPosition: UiTooltipPosition.LeftBottom,
	},
	{
		originX: 'start',
		originY: 'top',
		overlayX: 'end',
		overlayY: 'top',
		weight: 0,
		arrowPosition: UiTooltipArrowPosition.RightTop,
		tooltipPosition: UiTooltipPosition.LeftTop,
	},
];

import { environment } from '@imt-web-zone/shared/environments';

export interface CommonStateVersions {
	version: string;
	forman: string;
	inspector: string;
	iml: string;
	buildDate: string;
}

export interface CommonModel extends CommonStateVersions {
	initialUrl?: string;
	pendingRequests: number;
	isLogoIndicatorVisible: boolean;
	lastLogoChange: number;
	build: string;
}

export function getInitialCommonState(onInit = false): CommonModel {
	const commonState: CommonModel = {
		pendingRequests: 0,
		isLogoIndicatorVisible: false,
		version: environment.versions.app,
		forman: undefined,
		inspector: undefined,
		iml: environment.versions.iml,
		buildDate: process.env.BUILD,
		build: process.env.BUILD_TIMESTAMP,
		lastLogoChange: +new Date(),
	};

	if (onInit) {
		commonState.initialUrl = window.location.pathname + window.location.search;
	}
	return commonState;
}

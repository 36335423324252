import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NGXS_PLUGINS, NoopNgxsExecutionStrategy, provideStore } from '@ngxs/store';
import { NavigationActionTiming, RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { environment } from '@imt-web-zone/shared/environments';
import { TITLE_OPTIONS, TITLE_VALUE_PROVIDER } from '@imt-web-zone/shared/util';
import { SESSION_STATE_TOKEN } from '@imt-web-zone/zone/state-session';
import { TitleTranslateProvider } from '@imt-web-zone/zone/data-access';

import { formsMetaReducer } from '@imt-web-zone/zone/data-access-state';
import { routerMetaReducer } from '@imt-web-zone/zone/state-router';
import { authMetaReducer } from './auth.meta-reducer';
import { type DevtoolsOption } from '@imt-web-zone/shared/model';
import { StorageOption, withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { ReactiveFormsModule } from '@angular/forms';
import { withNgxsFormPlugin } from '@ngxs/form-plugin';
import { UtilDispatchModule } from '@imt-web-zone/shared/util-dispatch';
import { DEVTOOLS_OPTIONS } from './devtools-ext/devtools.plugin';
import { NgxsStoragePluginOptions } from '@ngxs/storage-plugin/internals';
import { getNgxsSetupDefaults } from './ngxs-config';
import {
	CacheFunctions,
	CustomRouterStateSerializer,
	FORMS_STATE_KEY,
	NGXS_CACHE_OPTIONS,
	NgxsSetupOptions,
	NgxsSetupStorageOptions,
} from '@imt-web-zone/shared/util-store';
import { DevtoolsExtensionModule } from './devtools-ext/devtools-extension.module';

type StatesClasses = Parameters<typeof provideStore>['0'];

// const isDev = ['local','development'].includes(environment.env)

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, UtilDispatchModule.forRoot(), DevtoolsExtensionModule.forRoot()],
	providers: [
		{
			provide: TITLE_VALUE_PROVIDER,
			useClass: TitleTranslateProvider,
		},
		{
			provide: NGXS_PLUGINS,
			useValue: authMetaReducer,
			multi: true,
		},
		{
			provide: NGXS_PLUGINS,
			useValue: routerMetaReducer,
			multi: true,
		},
		{
			provide: NGXS_PLUGINS,
			useValue: formsMetaReducer,
			multi: true,
		},
	],
})
export class StoreModule {
	public static forRoot(defaultStates: StatesClasses, options: NgxsSetupOptions): ModuleWithProviders<StoreModule> {
		const ngxsSetupDefaults = getNgxsSetupDefaults();
		return {
			ngModule: StoreModule,
			providers: [
				{
					provide: DEVTOOLS_OPTIONS,
					useValue: this.ngxsSetupDevtoolsFactory(options),
				},
				{
					provide: DEVTOOLS_OPTIONS,
					useValue: options.devtools,
				},
				{
					provide: TITLE_OPTIONS,
					useFactory: () => {
						return {
							...ngxsSetupDefaults.title,
							...options.title,
						};
					},
				},
				{
					provide: NGXS_CACHE_OPTIONS,
					useFactory: () => {
						return {
							cacheExpiration: {
								...ngxsSetupDefaults.cacheExpiration,
								...options.cacheExpiration,
							},
						};
					},
				},
				provideStore(
					[...defaultStates],
					{
						executionStrategy: NoopNgxsExecutionStrategy,
						developmentMode: !environment.production,
						compatibility: {
							strictContentSecurityPolicy: true,
						},
						selectorOptions: {
							suppressErrors: false,
						},
					},
					withNgxsStoragePlugin(this.ngxsStorageOptionsFactory(options)),
					withNgxsRouterPlugin({
						navigationActionTiming: NavigationActionTiming.PostActivation,
					}),
					withNgxsFormPlugin(),
				),
				{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
			],
		};
	}

	/**
	 * Configuration for DevtoolsExtensionModule
	 * @param options
	 * @private
	 */
	private static ngxsSetupDevtoolsFactory(options?: NgxsSetupOptions): DevtoolsOption {
		let devtoolsConfig = getNgxsSetupDefaults().devtools;
		if (options.devtools) {
			devtoolsConfig = { ...devtoolsConfig, ...options.devtools } as any;
		}
		return devtoolsConfig;
	}

	/**
	 * Configuration for `withNgxsStoragePlugin`
	 *
	 * @param config
	 * @private
	 */
	private static ngxsStorageOptionsFactory(config: NgxsSetupOptions): NgxsStoragePluginOptions {
		// todo
		// migrations: [
		// 	{
		// 		version: 1,
		// 		key: 'version',
		// 		versionKey: 'session',
		// 		migrate: state => {
		// 			return {
		// 				session: {
		// 					1: {
		// 						organizationId: '1',
		// 						teamId: '4',
		// 						adminOrganizationId: '1',
		// 						adminTeamId: '1',
		// 						zone: 'master.integromat.local'
		// 					}
		// 				},
		// 				version: 2 // Important to set this to the next version!
		// 			};
		// 		}
		// 	}
		// ]
		let ngxsStorageOptions: NgxsStoragePluginOptions = {
			keys: [
				// todo move to core module and remvoe from here
				SESSION_STATE_TOKEN.getName(),
			],
			storage: StorageOption.LocalStorage,
			serialize: JSON.stringify,
			deserialize: JSON.parse,
			beforeSerialize: (obj: any) => obj,
			afterDeserialize: (obj: any) => obj,
		};

		if (config.ngxsStorage) {
			const ngxsStorageConfig: NgxsSetupStorageOptions = { ...config.ngxsStorage };
			if (ngxsStorageConfig.saveForms) {
				(ngxsStorageOptions.keys as Array<string>).push(FORMS_STATE_KEY);
			}
		}
		return ngxsStorageOptions;
	}

	// TODO: this class is not injected anywhere else, so we need to inject here manually
	public cacheFunctions = inject(CacheFunctions);
}

import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
	interval,
	Subject,
	Observable,
	BehaviorSubject,
	takeWhile,
	finalize,
	scan,
	startWith,
	switchMap,
	map,
} from 'rxjs';

export enum UiCountdownState {
	Counting = 'counting',
	NotCounting = 'notCounting',
	Finished = 'finished',
}

export interface UiCountdownChange {
	state: UiCountdownState;
	timestamp: number;
}

export type UiCountdownDateType = Date | string | number;

class UiCountdownContext {
	public $implicit: UiCountdownDateType = null;
	public imtUiCountdown: UiCountdownDateType = null;
	public timestamp: number;
	public state: UiCountdownState = UiCountdownState.NotCounting;
}

// Countdown counting once per second
const COUNTDOWN_INTERVAL = 1000;

@Directive({
	standalone: true,
	selector: '[imtUiCountdown]',
})
export class UiCountdownDirective implements OnInit {
	private viewContainer = inject(ViewContainerRef);
	public templateRef = inject<TemplateRef<UiCountdownContext>>(TemplateRef);

	/**
	 * Date type which is referencing to the point in the future.
	 */
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('imtUiCountdown') public set countdownTo(value: UiCountdownDateType) {
		// Prevent from triggering counting with the same value
		if (this.lastValue && this.getTime(this.lastValue) === this.getTime(value)) {
			return;
		}

		// Calculate time difference between now and provided timestamp.
		const msDiff = this.getTime(value) - this.getTime(Date.now());
		this.lastValue = value;

		this.context.timestamp = msDiff;
		this.context.$implicit = this.context.imtUiCountdown = value;

		// Check whether provided date is valid and whether is in future.
		if (!isNaN(msDiff) && msDiff > 0) {
			this.context.state = UiCountdownState.Counting;
			this.countdownUpdate$.next(msDiff);
		} else {
			this.context.state = UiCountdownState.NotCounting;
		}

		this.updateView();
	}

	/**
	 * `UiCountdownChange` event listener function.
	 */
	// We have to use `@Input()` because Angular doesn't support `@Output` binding for sugar syntax (*) since 2016
	// https://github.com/angular/angular/issues/12121
	// eslint-disable-next-line @angular-eslint/no-input-rename
	@Input('imtUiCountdownChange') public countdownChange: (change: UiCountdownChange) => unknown;

	private context: UiCountdownContext = new UiCountdownContext();
	private embeddedView: EmbeddedViewRef<UiCountdownContext>;
	private countdownUpdate$ = new Subject<number>();
	private countdownChange$ = new Subject<UiCountdownChange>();
	private initialized$ = new BehaviorSubject<boolean>(false);
	private lastValue = null;

	// Because there is a chance that provided `change` listener function is not available in a time
	// when `change` is called (in general @Inputs() are available after `OnInit` lifecycle hook)
	// we have to wait for initialization before emitting the countdown change.
	private waitForInitialization$ = (initialized$: BehaviorSubject<boolean>) =>
		new Observable<void>((observer) => {
			initialized$
				.pipe(
					takeWhile((initialized) => initialized === false, true),
					finalize(() => {
						observer.next();
						observer.complete();
					}),
				)
				.subscribe();
		});

	constructor() {
		// Updating created embedded view on every interval tick.
		this.countdownUpdate$
			.pipe(
				// eslint-disable-next-line max-len
				switchMap((msDiff) => this.countdownInterval(msDiff)), // trigger a new counting on every countdown time update
				takeUntilDestroyed(),
			)
			// update view context on every interval emission
			.subscribe((msLeft) => this.applyViewChange(this.embeddedView, { timestamp: msLeft }));

		// Emitting changes to the change listener if is provided.
		this.countdownChange$
			.pipe(
				switchMap((change) => this.waitForInitialization$(this.initialized$).pipe(map(() => change))),
				takeUntilDestroyed(),
			)
			.subscribe((change) => {
				// eslint-disable-next-line max-len
				// If change listener function is provided (from outside where `UiCountdown` is used) let's emit change event.
				if (typeof this.countdownChange === 'function') {
					this.countdownChange(change);
				}
			});
	}

	public ngOnInit(): void {
		this.initialized$.next(true);
	}

	/**
	 * Returns a stream which gets provided milliseconds, starts counting with and on every emission deducts one second
	 * and returns result as a seconds.
	 */
	private countdownInterval = (ms: number): Observable<number> => {
		const durationInSeconds = ms / 1000;

		return interval(COUNTDOWN_INTERVAL).pipe(
			scan((acc, _) => --acc, durationInSeconds),
			startWith(durationInSeconds),
			takeWhile((secondsLeft) => secondsLeft >= 0), // do counting until no seconds left
			map((secondsLeft) => Math.round(secondsLeft * 1000)), // convert seconds to milliseconds
			finalize(() =>
				// when counting reaches the end, update countdown context correspondingly
				this.applyViewChange(this.embeddedView, {
					timestamp: 0,
					state: UiCountdownState.Finished,
				}),
			),
		);
	};

	/**
	 * Creates an embedded view with given context (data)
	 */
	private updateView(): void {
		this.viewContainer.clear();

		// If embedded view already exists, just update it (don't recreate it)
		if (this.embeddedView && !this.embeddedView.destroyed) {
			return this.applyViewChange(this.embeddedView, this.context);
		}

		this.embeddedView = this.viewContainer.createEmbeddedView(this.templateRef, this.context);

		const { state, timestamp } = this.context;
		this.countdownChange$.next({ state, timestamp });
	}

	/**
	 * Apply changes to the existing embedded view context and emits change via change listener.
	 */
	private applyViewChange(
		embeddedView: EmbeddedViewRef<UiCountdownContext>,
		contextUpdate: Partial<UiCountdownContext>,
	): void {
		if (!(embeddedView && !embeddedView.destroyed)) {
			return;
		}

		// Synchronize "internal" context with embedded view context
		embeddedView.context = { ...embeddedView.context, ...(this.context = { ...this.context, ...contextUpdate }) };
		embeddedView.markForCheck();

		const { state, timestamp } = embeddedView.context;
		this.countdownChange$.next({ state, timestamp });
	}

	/**
	 * Returns a timestamp accordingly to the given date.
	 * TODO: Consider using some modern date utility library, for instance: https://date-fns.org/
	 */
	private getTime(value: UiCountdownDateType): number {
		return new Date(value).getTime();
	}
}

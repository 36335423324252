import { PgLast } from '@imt-web-zone/shared/util';
import { ScenarioLogsExecution } from '@imt-web-zone/zone/state-scenario-logs';

export class DlqsLogApiModel {
	constructor(
		public imtId: string,
		public duration: number,
		public transfer: number,
		public operations: number,
		public teamId: number,
		public id: string,
		public type: string,
		public authorId: number | null,
		public timestamp: Date,
		public status: number,
		public reason?: string,
		public instant?: boolean,
	) {}
}

/**
 * ListDlqsLogsPayload
 */

export interface ListDlqsLogsPayload {
	params: {
		dlqId: string;
	};
	query?: {
		status?: 1 | 2 | 3;
		from?: number;
		to?: number;
		pg?: PgLast<DlqsLogApiModel, 'imtId'>;
	};
}

/**
 * ListDlqsLogsResponse
 */

export interface ListDlqsLogsResponse {
	dlqLogs: Array<{
		imtId: string;
		duration: number;
		transfer: number;
		operations: number;
		teamId: number;
		id: string;
		type: string;
		authorId: null;
		timestamp: Date;
		status: number;
	}>;
	pg: {
		sortBy: string;
		sortDir: string;
		limit: number;
		offset: number;
	};
}

export interface DlqExecutionLogPayload {
	params: {
		dlqId?: string;
		executionId?: string;
	};
}

export interface DlqExecutionLogResponse {
	dlqLog: ScenarioLogsExecution;
}

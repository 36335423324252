import { SELECT_RX_SNAPSHOT_SYMBOL } from './internals';

/**
 * Initializes property assigned by the value from facade.[selector]RxSnapshot()
 *
 * @deprecated use signal based selectors instead
 */
export function SelectRxSnapshot() {
	return function (target: any, key: string) {
		Object.defineProperty(target, key, {
			configurable: false,
			get: function () {
				// return snapshot
				return target[SELECT_RX_SNAPSHOT_SYMBOL(key)]();
			},
			set: function (value: any) {
				if (typeof value !== 'function') {
					console.log(value, target.constructor.name, key);
					throw new Error(`Value of "${key}" must be a function!`);
				}
				target[SELECT_RX_SNAPSHOT_SYMBOL(key)] = value(this);
			},
		});
	};
}

/**
 * @deprecated use @SelectRxSnapshot()
 */
export const SelectSnapshot = SelectRxSnapshot;

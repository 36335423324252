import { Injectable, InjectionToken, Inject, Optional, OnDestroy, Injector, inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store } from '@ngxs/store';
import { Title } from '@angular/platform-browser';
import type { TitleOptions } from '@imt-web-zone/shared/model';
import { Subject, Subscription, switchMap, filter, map, takeUntil, Observable } from 'rxjs';
import { TITLE_VALUE_PROVIDER, TitleValueProvider } from './title-value.provider';

export const TITLE_OPTIONS = new InjectionToken<TitleOptions>('imt-title-options');

export interface TitleServiceData {
	text?: string;
	/**
	 * @deprecated use titleSelector$
	 * @param state
	 * @param states
	 */
	titleSelector?: (state: any, ...states: Array<any>) => any;
	/**
	 * @deprecated use titleSelector$
	 */
	titleSelectorKey?: string;
	titleSelector$?: (injector: Injector) => Observable<string>;
}

export interface EntityTitleData {
	[key: string]: any;
	title?: TitleServiceData;
}

@Injectable({
	providedIn: 'root',
	deps: [TITLE_OPTIONS, TITLE_VALUE_PROVIDER],
})
export class TitleService implements OnDestroy {
	private until$: Subject<void> = new Subject();
	private lastValue: string | undefined;
	private changesSubscription!: Subscription;
	private injector = inject(Injector);

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private title: Title,
		@Inject(TITLE_OPTIONS) @Optional() private options: TitleOptions,
		@Inject(TITLE_VALUE_PROVIDER) private valueProvider: TitleValueProvider,
	) {
		if (options) {
			this.subscribeChanges();
		}
	}

	public ngOnDestroy(): void {
		this.until$?.next();
		this.until$?.complete();
		this.changesSubscription?.unsubscribe();
	}

	public async setTitle(value?: string): Promise<void> {
		this.lastValue = value;

		const titleValue = value || (await this.valueProvider.getValue(this.options.defaultTitle));
		const appName = await this.valueProvider.getValue(this.options.app);
		if (titleValue) {
			const appNamePart = appName ? `${this.options.separator} ${appName}` : '';
			this.title.setTitle(`${value || this.options.defaultTitle} ${appNamePart}`);
		} else {
			this.title.setTitle(`${appName || ''}`);
		}
	}

	public async setOptions(options: Partial<TitleOptions>): Promise<void> {
		this.options = { ...this.options, ...options };
		await this.setTitle(this.lastValue);

		if (!this.changesSubscription) {
			this.subscribeChanges();
		}
	}

	private subscribeChanges(): void {
		this.changesSubscription = this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route = this.activatedRoute;
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				}),
				filter((route) => route.outlet === 'primary'),
				switchMap((route) => {
					const data: EntityTitleData = route.snapshot.data as EntityTitleData;
					return this.processData(data);
				}),
			)
			.subscribe();
	}

	private async processData(data: EntityTitleData): Promise<void> {
		this.until$.next();
		if (data && data.title && data.title.titleSelector) {
			const key = data.title.titleSelectorKey || 'name';
			this.store
				.select(data.title.titleSelector)
				.pipe(
					filter((x) => !!(x && x[key])),
					takeUntil(this.until$),
					switchMap((x: any) => this.setTitle(x[key])),
				)
				.subscribe();
		} else if (data?.title?.titleSelector$) {
			data?.title
				?.titleSelector$(this.injector)
				.pipe(
					filter((str) => !!str),
					takeUntil(this.until$),
					switchMap((str: any) => this.setTitle(str)),
				)
				.subscribe();
		} else {
			const value = data && data.title ? await this.valueProvider.getValue(data.title.text as string) : undefined;
			await this.setTitle(value);
		}
	}
}

import { Injectable, Injector, inject } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { HttpHandlerService } from './http-client.factory';
import { HTTP_INTERCEPTORS_API } from './interceptors/interceptors.symbols';

@Injectable({ providedIn: 'root' })
export class HttpApiClient extends HttpClient {
	constructor() {
		const backend = inject(HttpBackend);
		const injector = inject(Injector);

		super(new HttpHandlerService(backend, injector, HTTP_INTERCEPTORS_API));
	}
}

import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';

interface ImtUiAuthUserData {
	locale: string;
	timezoneOffset: string;
}

export const IMT_UI_AUTH_USER_DATA = new InjectionToken<Observable<ImtUiAuthUserData>>('IMT_UI_AUTH_USER_DATA');

/**
 * Helps providing auth user data in place where `ImtUiAuthUserData` is needed without need of injecting store.
 */
export const imtUiAuthUserDataProvider = (
	factoryFn: (...deps: Array<unknown>) => Observable<ImtUiAuthUserData>,
	...deps: Array<unknown>
): Provider => {
	return {
		provide: IMT_UI_AUTH_USER_DATA,
		useFactory: factoryFn,
		deps,
	};
};

import { ActionType, createAction, props } from '@imt-web-zone/shared/util-store';
import { CommonModel } from './common.model';

export const changePendingRequests = createAction(
	'[Common] ChangePendingRequests',
	(payload: { change: number }) => ({
		change: payload.change,
		hideFromDevtools: true
	})
);
export type ChangePendingRequests = ActionType<typeof changePendingRequests>;

export const changeLogoIndicatorVisibility = createAction(
	'[Common] ChangeIndicatorVisibility',
	props<{ visible: boolean }>(),
);

export type ChangeLogoIndicatorVisibility = ActionType<typeof changeLogoIndicatorVisibility>;

export const changeLogo = createAction('[Common] ChangeLogo');
export type ChangeLogo = ActionType<typeof changeLogo>;

export const updateCommonState = createAction(
	'[Common] update commonState data',
	props<{ payload: Partial<CommonModel> }>(),
);
export type UpdateCommonState = ActionType<typeof updateCommonState>;

export const setInitialUrlRequests = createAction('[Common] SetInitialUrlRequests', props<{ url: string }>());
export type SetInitialUrlRequests = ActionType<typeof setInitialUrlRequests>;

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UiNotificationButtonType } from './ui-notification-button';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
	selector: 'dmo-notification-button',
	standalone: true,
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiNotificationButtonComponent {
	@HostBinding('class') public get hostDefaultClasses() {
		const defaultClasses = [
			'dmo-text-xs dmo-inline-block dmo-rounded-4 dmo-px-12 dmo-py-4 dmo-rounded dmo-font-bold dmo-cursor-pointer dmo-whitespace-nowrap dmo-text-center',
		];

		switch (this.type) {
			case UiNotificationButtonType.INFO:
				defaultClasses.push(
					this.subdued
						? 'dmo-text-bothblack dmo-bg-static-info-subdued'
						: 'dmo-bg-active-info dmo-text-inverse',
				);
				break;
			case UiNotificationButtonType.SUCCESS:
				defaultClasses.push(
					this.subdued
						? 'dmo-text-bothblack dmo-bg-static-success-subdued'
						: 'dmo-bg-active-success dmo-text-inverse',
				);
				break;
			case UiNotificationButtonType.WARNING:
				defaultClasses.push(
					this.subdued
						? 'dmo-text-bothblack dmo-bg-static-warning-subdued'
						: 'dmo-bg-active-warning dmo-text-bothblack',
				);
				break;
			case UiNotificationButtonType.DANGER:
				defaultClasses.push(
					this.subdued
						? 'dmo-text-bothblack dmo-bg-static-danger-subdued'
						: 'dmo-bg-active-danger dmo-text-inverse',
				);
				break;
			case UiNotificationButtonType.BRAND:
				defaultClasses.push(
					this.subdued
						? 'dmo-text-bothblack dmo-bg-static-brand-subdued'
						: 'dmo-bg-active-brand dmo-text-inverse',
				);
				break;
		}

		return defaultClasses.join(' ');
	}

	@Input({ required: true }) public type?: UiNotificationButtonType;

	@Input({ transform: coerceBooleanProperty }) public subdued = false;
}

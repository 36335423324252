import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { map, filter, take } from 'rxjs/operators';
import { ModeEnum } from './api-config.interface';
import { ApiConfigFacade } from './api-config.facade';

@Injectable({ providedIn: 'root' })
export class ApiConfigGuard implements CanActivate, CanActivateChild {
	private router = inject(Router);

	private apiConfigFacade = inject(ApiConfigFacade);

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.resolve(route, state);
	}

	public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.resolve(childRoute, state);
	}

	// TODO: remove "install" redirect logic from this guard
	private resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this.apiConfigFacade.config$.pipe(
			filter((config) => !!config),
			map((config) => {
				// when install guide must be shown, redirect to it's url, if not, activate the route
				const masterWithShowInstallGuide = config.mode === ModeEnum.MASTER && config.showInstallGuide;
				if (masterWithShowInstallGuide && !state.url.startsWith('/install')) {
					return this.router.createUrlTree(['install']);
				}

				if (state.url.startsWith('/install')) {
					if (masterWithShowInstallGuide) {
						return true;
					}

					return this.router.createUrlTree(['/']);
				}

				// TODO: https://integromat.atlassian.net/browse/UP-111
				// Since we have install guide in a slave mode, which must to be shown to the user
				// when `showInstallGuide` is `true`, we need to redirect him when this condition is met.
				// ===========================================================
				const slaveWithShowInstallGuide = config.mode === ModeEnum.SLAVE && config.showInstallGuide;
				if (slaveWithShowInstallGuide && !state.url.startsWith('/zone-install')) {
					return this.router.createUrlTree(['zone-install']);
				}
				// ===========================================================

				if (!Object.keys(config).length && !state.url.startsWith('/error')) {
					this.router.navigateByUrl('/error');
					return false;
				}
				return true;
			}),
			take(1),
		);
	}
}

import { toNumber, removeUndefined } from '@imt-web-zone/shared/util-store';
import { DlqApiModel } from './dlqs.interface';
import { DlqModel } from './dlqs.model';

export class DlqsAdapter {
	public static toStore(item: Partial<DlqApiModel>, scenarioId?: number): DlqModel {
		return removeUndefined(
			new DlqModel(
				item.id,
				toNumber(item.scenarioId || scenarioId),
				item.reason,
				new Date(item.created),
				item.size,
				item.resolved,
				item.retry,
				item.attempts,
				item.index,
				item.executionId,
			),
		);
	}

	public static toApi(item: Partial<DlqModel>): DlqApiModel {
		return removeUndefined(
			new DlqApiModel(
				item.id,
				toNumber(item.scenarioId),
				item.reason,
				item.created,
				item.size,
				item.resolved,
				item.retry,
				item.attempts,
				item.index,
				item.executionId,
			),
		);
	}
}

import { CollectionStateModel, getInitialCollectionState } from '@imt-web-zone/shared/util-store';

export class DlqsLogModel {
	constructor(
		public imtId?: string,
		public duration?: number,
		public transfer?: number,
		public operations?: number,
		public teamId?: string,
		public id?: string,
		public type?: string,
		public authorId?: string | null,
		public timestamp?: Date,
		public status?: number,
		public dlqId?: string,
		public reason?: string,
		public instant?: boolean,
	) {}
}

export function getInitialDlqsLogsStateModel(): CollectionStateModel<DlqsLogModel> {
	return getInitialCollectionState<DlqsLogModel>();
}

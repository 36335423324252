import {
	createNgModule,
	inject,
	Injectable,
	Injector,
	NgModuleRef,
	runInInjectionContext,
	Type,
	ViewContainerRef,
} from '@angular/core';
import { CanMatchFn, LoadChildrenCallback } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class LazyLoader {
	private injector = inject(Injector);

	public static instance: LazyLoader | undefined;

	/**
	 * When lazy loading modules, in order to prevent repeatedly calling `createNgModule` for same module, we are
	 * storing already created `NgModuleRef`s into cache. If we wouldn't do so, it may have nasty side effect for
	 * `@Injectable({ providedIn: 'any' })`. Because each time `createNgModule` is called for lazy loaded module,
	 * then fresh new instance of `@Injectable({ providedIn: 'any' })` would be created.
	 */
	private readonly cache = new Map<Type<unknown>, unknown>();

	public static canMatch(...modules: Array<LoadChildrenCallback>): CanMatchFn {
		return async () => {
			const injector = inject(Injector);
			for (const module of modules) {
				const lazyModule = (await module()) as any;

				if (!this.instance?.cache.has(lazyModule)) {
					const ngModule = createNgModule(lazyModule, injector);
					this.instance?.cache.set(lazyModule, ngModule);
				}
			}
			return true;
		};
	}

	constructor() {
		LazyLoader.instance = this;
	}

	// TODO: Explore possibility of caching lazy components same as lazy modules.
	public async loadComponent(args: { loader: LoadChildrenCallback; container: ViewContainerRef }) {
		const module = await (args.loader() as Promise<unknown>);
		// const component = module[args.component];
		args.container.createComponent(module as Type<unknown>);
	}

	public async loadModule<T>(args: { loader: () => Promise<Type<T>>; injector?: Injector }): Promise<NgModuleRef<T>> {
		const lazyModule = await args.loader();

		if (this.cache.has(lazyModule)) {
			return this.cache.get(lazyModule) as NgModuleRef<T>;
		}

		const injector = args.injector || this.injector;

		const ngModule = createNgModule(lazyModule, injector) as NgModuleRef<T>;

		this.cache.set(lazyModule, ngModule);

		return ngModule;
	}

	public async loadService<T>(args: { loader: () => Promise<Type<T>>; injector?: Injector }): Promise<T> {
		const lazyService = await args.loader();

		if (this.cache.has(lazyService)) {
			return this.cache.get(lazyService) as T;
		}

		const service = runInInjectionContext<T>(args.injector || this.injector, () => inject(lazyService));

		this.cache.set(lazyService, service);

		return service;
	}
}

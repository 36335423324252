import { AuthUserModel, getInitialAuthUserModel } from './auth-user.model';
import { initialCache } from '@imt-web-zone/shared/util-store';

export enum AuthorizationType {
	JWT = 'jwt',
	SESSION = 'session',
	EMAIL = 'email',
}

export interface AuthStateModel<S = any> {
	user?: AuthUserModel | null;
	type?: AuthorizationType;
	tfaEnabled?: boolean;
	signedOut?: boolean;
	userUnreadNotifications?: number;
	userZoneUnreadNotifications?: Array<S>;
}

export function getInitialAuthStateModel(): AuthStateModel<any> {
	return {
		type: undefined,
		userUnreadNotifications: undefined,
		userZoneUnreadNotifications: undefined,
		user: getInitialAuthUserModel(),
		...initialCache(),
	};
}

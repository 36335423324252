import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ChangeTheme, ChangeThemeFailed, SetApiConfig } from './api-config.actions';
import { inject, Injectable, Injector, Renderer2, RendererFactory2, SecurityContext } from '@angular/core';
import { BodyTagDef, ImtAssetsInjector, ImtUiAssetsInjector } from '@imt-web-zone/shared/util-assets-injector';
import { APP_NAME, AppNames } from '@imt-web-zone/shared/core';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '@imt-web-zone/shared/environments';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';
import { ThemeService } from '@imt-web-zone/make-design-system/util-theme-service';
import { ApiConfigData } from './api-config.interface';
import { ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';
// @todo: move to presentation layer/facade
import { UiToastMessageService } from '@imt-web-zone/make-design-system/ui-toast-message';

export const API_CONFIG_STATE_TOKEN = new StateToken<ApiConfigData>('apiConfig');

@State({
	name: API_CONFIG_STATE_TOKEN,
	defaults: {} as ApiConfigData,
})
@Injectable()
export class ApiConfigStateBase {
	private readonly customHeaderParamValue = 'custom-code-injection-head';
	private readonly customFooterParamValue = 'custom-code-injection-footer';
	private readonly customParamName = 'x-name';
	private readonly domSanitizer: DomSanitizer;
	private el?: HTMLElement;
	private renderer2: Renderer2;
	private tryFallbackScript = false;
	protected assetInjector: ImtAssetsInjector = inject(ImtUiAssetsInjector);
	protected zoneAssetsService = inject(ZoneAssetsService);
	protected rendererFactory: RendererFactory2;
	protected toastService: UiToastMessageService;
	protected transloco: TranslocoService;
	protected appName: AppNames;
	protected document: Document;
	protected baseHref = inject(APP_BASE_HREF);
	private themeService = inject(ThemeService);

	// eslint-disable-next-line @nx/workspace-no-constructor-di
	constructor(injector: Injector) {
		this.appName = injector.get(APP_NAME);
		this.toastService = injector.get(UiToastMessageService);
		this.rendererFactory = injector.get(RendererFactory2);
		this.transloco = injector.get(TranslocoService);
		this.document = injector.get(DOCUMENT);
		this.domSanitizer = injector.get(DomSanitizer);
		this.renderer2 = this.rendererFactory.createRenderer(null, null);
	}

	@Action(SetApiConfig)
	public async setConfig(ctx: StateContext<ApiConfigData>, action: SetApiConfig) {
		const state = ctx.getState();
		const theme = state.brand?.theme;
		if (action.config?.brand?.theme && theme !== action.config?.brand?.theme) {
			ctx.dispatch(new ChangeTheme(action.config?.brand?.theme, theme));
		}

		if (environment.staticFolderUrl && action.config.domains) {
			action.config.domains.static = environment.staticFolderUrl;
		}
		// We need to patch state before custom code injection, because some service dependencies
		ctx.patchState({ ...state, ...action.config });

		const currentCustomHeader = state.codeInjection?.header;
		const newCustomHeader = action.config?.codeInjection?.header;

		if (currentCustomHeader !== newCustomHeader) {
			await this.changeCustomHead(newCustomHeader);
		}

		const currentCustomFooter = state.codeInjection?.footer;
		const newCustomFooter = action.config?.codeInjection?.footer;

		if (currentCustomFooter !== newCustomFooter) {
			await this.changeCustomFooter(newCustomFooter);
		}
	}

	@Action(ChangeTheme)
	public changeTheme(ctx: StateContext<ApiConfigData>, action: ChangeTheme) {
		this.injectTheme(ctx, action);

		// Remove class for the previous theme
		this.setThemeClass(action.theme as string, action.prevTheme as string);
		this.themeService.setTheme();
	}

	public async changeCustomHead(customHead?: string) {
		return this.changeCustomElement(
			customHead,
			'head',
			this.customHeaderParamValue,
			'customCodeInjection.headerFailed',
		);
	}

	public async changeCustomFooter(customFooter?: string) {
		return this.changeCustomElement(
			customFooter,
			'body',
			this.customFooterParamValue,
			'customCodeInjection.footerFailed',
		);
	}

	@Action(ChangeThemeFailed)
	public async themeFailed(ctx: StateContext<ApiConfigData>, action: ChangeThemeFailed) {
		this.tryFallbackScript = true;
		this.toastService.showDanger({
			text: this.transloco.translate('themes.failed', {
				theme: action.prevTheme,
			}),
		});
		await this.injectTheme(ctx, action);
		this.tryFallbackScript = false;
	}

	private setThemeClass(theme: string, previousTheme?: string) {
		// Remove class for the previous theme
		const classList = document.querySelector('html')?.classList;
		if (classList && previousTheme) {
			classList.remove(previousTheme);
		}
		classList?.add(theme);
	}

	/**
	 *
	 * append theme styles to <head>, if loading of theme fails switch to fallback theme
	 *
	 */
	private async injectTheme(ctx: StateContext<ApiConfigData>, action: ChangeTheme) {
		const result = await this.assetInjector.inject(this.renderer2, [this.getTagConfig(action.theme as string)]);
		const el = result && result[0];
		if (el?.result === 'error') {
			el.el.remove();
			if (!this.tryFallbackScript) {
				ctx.dispatch(
					new ChangeThemeFailed(action.prevTheme || environment.appConfig.defaultTheme, action.theme),
				);
			}
		} else if (el) {
			this.el?.remove();
			this.el = el?.el;
		}
	}

	private getTagConfig(theme: string) {
		const src = this.zoneAssetsService.zoneThemeAssetPath(`/${theme}.theme.css`);

		return {
			tag: 'link',
			src: `${src}?v=${process.env['VERSION']}`,
			position: 'head',
		} as BodyTagDef;
	}

	private async changeCustomElement(
		customElement: string | undefined,
		selector: 'head' | 'body',
		customParamValue: string,
		errorKey: string,
	) {
		this.removeCustomElements(selector, customParamValue);
		if (customElement) {
			return this.createCustomElements(customElement, selector, customParamValue, errorKey);
		}
	}

	private removeCustomElements(selector: 'head' | 'body', customParamValue: string) {
		const head: HTMLHeadElement = this.renderer2.selectRootElement(selector, true);
		const elements = head.querySelectorAll(`[${this.customParamName}='${customParamValue}']`);
		if (elements?.length > 0) {
			Array.from(elements).forEach((value) => this.renderer2.removeChild(head, value));
		}
	}

	private async createCustomElements(
		customElement: string,
		selector: 'head' | 'body',
		customParamValue: string,
		errorKey: string,
	) {
		try {
			this.renderCustomElements(customElement, selector, customParamValue);
		} catch (e) {
			this.toastService.showDanger({
				text: await firstValueFrom(this.transloco.selectTranslate(errorKey)),
			});
			this.removeCustomElements(selector, customParamValue);
		}
	}

	private renderCustomElements(customElement: string, selector: 'head' | 'body', customParamValue: string) {
		const wrap: HTMLElement = this.renderer2.createElement('div');
		this.renderer2.setProperty(wrap, 'innerHTML', customElement);
		const parrent = this.renderer2.selectRootElement(selector, true);

		const array = Array.from(wrap.children);
		if (array.length < 1) {
			throw new Error('No children');
		}
		this.renderChildren(array, customParamValue, parrent);
	}

	private renderChildren(array: Element[], customParamValue: string, parent: any) {
		array.forEach((value) => {
			const tag = value.tagName.toLowerCase();
			const el = this.renderer2.createElement(tag);
			if (value.innerHTML) {
				const { context, safeValue } = this.prepareSanitizeContext(tag, value.innerHTML);
				this.renderer2.setProperty(el, 'innerHTML', this.domSanitizer.sanitize(context, safeValue));
			}
			const attributes = Array.from(value.attributes);
			attributes.forEach((x) => this.renderer2.setAttribute(el, x.name, x.value));
			this.renderer2.setAttribute(el, this.customParamName, customParamValue);
			this.renderer2.appendChild(parent, el);
			const children: Element[] = Array.from(el.children);
			if (children?.length > 0) {
				this.renderChildren(children, customParamValue, el);
			}
		});
	}

	private prepareSanitizeContext(tag: string, value: string) {
		switch (tag) {
			case 'script':
				return {
					context: SecurityContext.SCRIPT,
					safeValue: this.domSanitizer.bypassSecurityTrustScript(value),
				};
			case 'style':
				return { context: SecurityContext.STYLE, safeValue: this.domSanitizer.bypassSecurityTrustStyle(value) };
			default:
				return { context: SecurityContext.HTML, safeValue: this.domSanitizer.bypassSecurityTrustHtml(value) };
		}
	}
}

import { ChangeDetectorRef, DestroyRef, inject, Type } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateToken, Store } from '@ngxs/store';

/**
 *
 *
 * Returns data from the store as a snapshot.
 * Internally it will call `markForCheck()` each time once selector is emitted.
 *
 * must be used with @Getter() property decorator
 *
 * example:
 *
 * @Component({
 * 	templateUrl: './functions.component.html',
 * })
 * export class FunctionsComponent {
 * 	@Getter() public functions = viewSelectSnapshot(this, CollectionState.getEntities(FUNCTIONS_STATE_TOKEN));
 *
 */
export function viewSelectSnapshot<T>(ctx: DestroyRef, selector: (state: any, ...states: Array<any>) => T): T;
export function viewSelectSnapshot<T>(ctx: DestroyRef, selector: string | Type<any>): T;
export function viewSelectSnapshot<T>(ctx: DestroyRef, selector: StateToken<T>): T;
export function viewSelectSnapshot<T>(ctx: DestroyRef, selector: unknown): T {
	const store = inject(Store);
	const cdr = inject(ChangeDetectorRef);
	store
		.select(selector as StateToken<T>)
		.pipe(takeUntilDestroyed(ctx))
		.subscribe((res) => {
			cdr.markForCheck();
		});

	return (() => {
		return store.selectSnapshot(selector as StateToken<T>) as T;
	}) as unknown as T;
}

import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ServiceInit } from '@imt-web-zone/core/util-core';
import { ZoneTheme } from './zone-theme';

export type ThemesVariant = 'violet' | 'black' | 'blue' | 'green' | 'silver' | 'orange' | 'red';
export type ThemeMode = 'light' | 'dark';
export type ComponentThemes = `${ZoneTheme}-table` | `${ZoneTheme}-tooltip` | `${ZoneTheme}-dropdown`;

export type ActiveTheme = {
	activeTheme: ZoneTheme;
	mode?: ThemeMode;
	variant?: ThemesVariant;
	componentThemes?: Array<ComponentThemes>;
};

export type DefaultActiveTheme = ActiveTheme & { localStorageKey?: string };

@Injectable({
	providedIn: 'root',
})
export class ThemeService implements ServiceInit {
	private document: Document = inject(DOCUMENT);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	private rootElm: HTMLElement = this.document.querySelector('html')!;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	private bodyElm: HTMLElement = this.rootElm.querySelector('body')!;

	public async initialize(defaultActiveTheme: DefaultActiveTheme) {
		const activeTheme = this.resolveDefaultActiveTheme(defaultActiveTheme);

		// if (environment.env === 'local') {
		(this.document.defaultView as any).setTheme = (activeTheme: ActiveTheme) => {
			this.setTheme(activeTheme);
		};
		// }

		if (defaultActiveTheme) {
			this.setTheme(activeTheme);
		}
	}

	public setTheme(activeTheme: ActiveTheme = {} as ActiveTheme) {
		// Cleanup previous settings
		// this.rootElm.classList.remove(
		// 	 'black', 'blue', 'green', 'silver', 'orange', 'red'
		// );
		// Set component themes
		if (activeTheme.componentThemes) {
			this.bodyElm.removeAttribute('data-theme');
			this.bodyElm.setAttribute('data-theme', activeTheme.componentThemes.join(' '));
		}

		// set color variant
		if (activeTheme.variant) {
			this.rootElm.setAttribute('data-theme-var', activeTheme.variant === 'violet' ? '' : activeTheme.variant);
		}
		this.setCssMode(activeTheme);
	}

	private setCssMode(theme: ActiveTheme) {
		if (theme.activeTheme === 'domino' && theme.mode === 'dark') {
			this.rootElm.classList.add(`dmo-${theme.mode}`);
		} else {
			this.rootElm.classList.remove(`dmo-dark`);
		}
	}

	private resolveDefaultActiveTheme(defaultActiveTheme: DefaultActiveTheme) {
		const { localStorageKey, ...defaultConfig } = defaultActiveTheme;
		let activeTheme = defaultConfig;
		if (localStorageKey) {
			const config = localStorage.getItem(localStorageKey);
			if (config) {
				try {
					activeTheme = { ...defaultConfig, ...(JSON.parse(config) as ActiveTheme) };
				} catch (e) {
					console.error(e);
				}
			}
		}
		return activeTheme as ActiveTheme;
	}
}

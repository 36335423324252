import { StateContext } from '@ngxs/store';

import { ImmutableStateContext } from '../common/immutable-state-context';

export function ImmutableContext() {
	return function (
		// eslint-disable-next-line @typescript-eslint/ban-types
		_target: Object,
		_key: string,
		descriptor: PropertyDescriptor,
	): PropertyDescriptor {
		const method = descriptor.value;

		descriptor.value = function (ctx: StateContext<any>, action: any, ...args: any[]) {
			return method.apply(this, [new ImmutableStateContext(ctx), action, ...args]);
		};

		return descriptor;
	};
}

<h4 class="modal-title">
	<ng-content></ng-content>
</h4>
<div class="d-flex justify-content-end">
	<ng-content select="[imt-ui-modal-header-button]"></ng-content>
	<button
		*ngIf="!hideCrossBtn"
		type="button"
		class="close"
		aria-label="Close"
		(click)="onDismiss(reason)"
		data-testid="btn-close-modal"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>

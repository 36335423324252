export function toString(value: string | number): string {
	return typeof value === 'number' ? value.toString() : value;
}

export function toNumber(value: string | number): number {
	return typeof value === 'string' ? +value : value;
}

export function removeUndefined<T>(item: any): T {
	if (item) {
		for (const prop in item) {
			if (item[prop] === undefined) {
				delete item[prop];
			}
		}
		return item;
	}
	return {} as T;
}

export function map<T, U>(item: Array<T>, cb: (value: T, index: number, array: Array<T>) => U) {
	if (!item) {
		return void 0;
	}
	return item.map(cb);
}

export function reduce<T, U>(
	item: Array<T>,
	cb: (previousValue: U, currentValue: T, currentIndex: number, array: Array<T>) => U,
	initialValue: U,
): U | void {
	if (!item) {
		return void 0;
	}
	return item.reduce(cb, initialValue);
}

/**
 * Accepts an unlimited number of arguments and returns first argument that is not `undefined`
 */
export function coalesce<T>(...values: Array<T>): T | undefined {
	return values.find((value) => !(value === undefined));
}

import { inject, Injectable, Provider } from '@angular/core';
import { LogsInitConfiguration, datadogLogs, LogsEvent } from '@datadog/browser-logs';
import { distinctUntilChanged, merge, Observable, of } from 'rxjs';
import { debugLogger, ServiceInit } from '@imt-web-zone/core/util-core';
import { NavigationEnd, Router } from '@angular/router';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';
import { DATADOG_CLIENT, DatadogConfig } from './internals';

const debug = debugLogger.create('datadogService');
@Injectable({
	providedIn: 'root',
})
export class DatadogService implements ServiceInit {
	public static providers: Array<Provider> = [
		{
			provide: DATADOG_CLIENT,
			useFactory: () => datadogLogs,
		},
	];

	private client = inject(DATADOG_CLIENT, { optional: true }) || datadogLogs;
	private userId$!: Observable<string>;
	private router = inject(Router);
	private config!: DatadogConfig;
	private growthBookService = inject(GrowthbookService);

	private readonly LOGS_FEATURE_FLAG = 'debug-logs';

	public async initialize(config: DatadogConfig) {
		this.config = config;
		this.init();
	}

	public setMetadata(key: string, value: any) {
		this.client.setGlobalContextProperty(key, value);
		debug(`context updated:`, `${key} => "${value}"`);
	}

	/**
	 * setup debugLogger for remote logging and subscribe to "debug-logs" feature changes
	 * @private
	 */
	private setRemoteDebug() {
		merge(
			of(this.growthBookService.getFeatureValue(this.LOGS_FEATURE_FLAG, '')),
			this.growthBookService.getFeatureValue$(this.LOGS_FEATURE_FLAG, ''),
		)
			.pipe(distinctUntilChanged())
			.subscribe((featureValue) => {
				debug(`remote debugger namespaces set to: "${featureValue}"`);
				debugLogger.setRemoteDebug(featureValue as string);
			});

		debugLogger.remoteDebugFn = (nameSpace: string, ...args: Array<any>) => {
			this.client.logger.debug(`DEBUG: [${nameSpace}] ${this.formatDebugArgs(args)}`);
		};
	}

	private init() {
		if (!this.config.config.clientToken) {
			debug('datadog not initialized. "clientToken" is missing.');
			return;
		}

		this.subscribeToEnabledFeatureFlag();

		this.userId$ = this.config!.userId$;
		const initConfig: LogsInitConfiguration = { ...this.config!.config };

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		initConfig.version = process.env['VERSION'];
		initConfig.forwardErrorsToLogs = true;
		initConfig.site = 'datadoghq.com';
		initConfig.forwardConsoleLogs = ['warn', 'error'];
		initConfig.beforeSend = function (log) {
			// !!! do not insert debug() into this function !!!
			if (self.isDebugLog(log) && self.featureFlagEnabled() === false) {
				return false;
			}

			if (log.http || log.message?.startsWith('HttpErrorResponse')) {
				return false;
			}
			return true;
		};

		this.client.init(initConfig);
		this.client.onReady(() => {
			debug(`datadog initialized`);
		});

		this.setRemoteDebug();
		this.onUserChange();
		this.onContextChange();
		// this.onReload();
		this.setRouterScope();
	}

	private onContextChange() {
		if (this.config.contextChanged$) {
			this.config.contextChanged$.subscribe(([prop, value]) => {
				this.setMetadata(prop, value);
			});
		}
	}

	private subscribeToEnabledFeatureFlag() {
		const featureFlag = this.config.featureFlag;
		if (featureFlag) {
			merge(
				of(this.growthBookService.getFeatureValue(featureFlag, false)),
				this.growthBookService.getFeatureValue$(featureFlag, false),
			)
				.pipe(distinctUntilChanged())
				.subscribe((featureValue) => {
					debug(`Datadog service ${featureValue ? 'ENABLED' : 'DISABLED'}`);
				});
		}
	}

	/**
	 * returns boolean if feature flag exist. If featureFlag not defined, returns null
	 *
	 * @private
	 */
	private featureFlagEnabled() {
		const featureFlag = this.config.featureFlag;
		if (featureFlag) {
			return this.growthBookService.getFeatureValue(featureFlag, false);
		}
		return null;
	}

	private isDebugLog(log: LogsEvent) {
		return log.origin === 'logger' && log.status === 'debug';
	}

	// private onReload() {
	// this.config?.reload$.pipe(distinctUntilChanged()).subscribe((token) => {
	// 	this.config.config.clientToken = token;
	// 	console.log('refresh browser')
	// 	// this.init();
	// });
	// }

	private onUserChange() {
		this.userId$.pipe(distinctUntilChanged()).subscribe((userId) => {
			if (userId) {
				this.client.setUser({ id: userId });
			} else {
				this.client.clearUser();
			}
		});
	}

	// todo: when MFE is ready prepare multiple loggers - one logger per each MFE, logged from custom Error Handler
	//  https://docs.datadoghq.com/logs/log_collection/javascript/#define-multiple-loggers
	/**
	 *
	 * stores routeConfig url and datadog scope to as context properties on each Navigation end event
	 *
	 * if route uses matcher - compose routeConfig path manually from the matcher.posParam
	 *
	 *
	 * @private
	 */
	private setRouterScope() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const routeConfigPath = [];
				let route = this.router.routerState.root;
				// let urlTree = this.router.parseUrl(this.router.url);
				while (route.firstChild) {
					route = route.firstChild;
					if (route?.routeConfig?.path) {
						routeConfigPath.push(route.routeConfig.path);
					} else if (route?.routeConfig?.matcher) {
						try {
							const matcherRes = route.routeConfig?.matcher(route.snapshot.url, null as any, null as any);
							if (matcherRes?.posParams) {
								for (const key in matcherRes.posParams) {
									routeConfigPath.push(':' + key);
								}
							}
						} catch (err) {
							console.error(err);
						}
					}
				}
				const data = route.snapshot.data;
				let routeConfigUrl = routeConfigPath.join('/');
				if (!routeConfigUrl.startsWith('/')) {
					routeConfigUrl = '/' + routeConfigUrl;
				}

				this.setMetadata('routeConfigPath', routeConfigUrl);
				this.setMetadata('scope', data['ddScope']);
			}
		});
	}

	private formatDebugArgs(args: Array<unknown>) {
		return args.map((arg) => {
			if (arg instanceof Array || arg instanceof Object) {
				return JSON.stringify(arg, null, 2);
			}
			return arg;
		});
	}
}

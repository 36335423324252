import { Selector } from '@ngxs/store';
import { CollectionState, EntityStateModel } from '@imt-web-zone/shared/util-store';
import { HashMap } from '@imt-web-zone/shared/util-store';
import { OrganizationModel, ORGANIZATIONS_STATE_TOKEN } from '@imt-web-zone/shared/data-access';
// TODO: Remove when organizations & auth states will be refactored to standalone
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TeamModel } from '@imt-web-zone/zone/state-teams';
import { AuthSelectors } from '@imt-web-zone/zone/state-auth';

export class OrganizationSelectors {
	/**
	 * Returns organization that are not deleted and current user has access to them.
	 */
	@Selector([ORGANIZATIONS_STATE_TOKEN, AuthSelectors.getAuthUserId])
	public static zoneOrganizations(state: EntityStateModel<OrganizationModel>, userId: string) {
		if (!state || !state.entities || !state.ids) {
			return [];
		}

		return state.entities && state.ids ? state.ids.map((id) => state.entities[id]) : [];
	}

	@Selector([ORGANIZATIONS_STATE_TOKEN])
	public static organizationsLoaded(state: EntityStateModel<OrganizationModel>) {
		if (state && state.entities && state.ids) {
			return state.ids.length > 0;
		}
		return null;
	}

	@Selector([
		CollectionState.entitiesMap.bind(ORGANIZATIONS_STATE_TOKEN),
		CollectionState.activeId.bind(ORGANIZATIONS_STATE_TOKEN),
	])
	public static activeTeamOrganization(organizationsMap: HashMap<OrganizationModel>, team: TeamModel) {
		if (!organizationsMap || !team) {
			return null;
		}
		return organizationsMap[team.organizationId];
	}
}
